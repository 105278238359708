import React, { useRef, useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import * as actions from '../actions'
import useReactRouter from 'use-react-router'
import ReCAPTCHA from 'react-google-recaptcha'

// Redux
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// import images
import logoSm from '../../../assets/images/logo-sm-light.png'
import { useTranslation } from 'react-i18next'

const Login = ({ login }) => {
  const { t } = useTranslation('common')
  const { history } = useReactRouter()
  const recaptchaRef = useRef(null)
  const [captchaToken, setCaptchaToken] = useState('')

  function onChange(value) {
    setCaptchaToken(value)
  }

  const handlerSubmit = async (_event, values) => {
    login({ email: values.email, password: values.password, history, captchaToken })
  }
  return (
    <React.Fragment>
      <div className='account-pages my-5 pt-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <div className='position-relative'>
                <h3 className='text-white text-center'>{t('pages.login.BETATESTING')}</h3>
                <Card className='overflow-hidden'>
                  <div className='bg-primary'>
                    <div className='text-primary text-center p-4'>
                      <h5 className='text-white font-size-20'>{t('pages.login.welcomeBack')}</h5>
                      <p className='text-white-50'>{t('pages.login.signToAccess')}</p>
                      <Link to={`${process.env.PUBLIC_URL}/`} className='logo logo-admin'>
                        <img src={logoSm} height='48' alt='logo' />
                      </Link>
                    </div>
                  </div>

                  <CardBody className='p-4'>
                    <div className='p-3'>
                      <AvForm className='form-horizontal mt-4' onValidSubmit={handlerSubmit}>
                        {/* {errors ? <Alert color="danger">{errors}</Alert> : null} */}

                        <div className='form-group'>
                          <AvField
                            name='email'
                            label={t('pages.login.email')}
                            className='form-control'
                            placeholder={t('pages.login.enterEmail')}
                            type='email'
                            required
                          />
                        </div>
                        <div className='form-group'>
                          <AvField
                            name='password'
                            label={t('pages.login.password')}
                            type='password'
                            required
                            placeholder={t('pages.login.enterPassword')}
                          />
                        </div>

                        <Row className='form-group'>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey='6LdckQIkAAAAAIti_aaT_fPhD3knm7R3qyfeysc8'
                            onChange={onChange}
                            theme='dark'
                            className={'g-recaptcha'}
                            style={{ padding: '10px' }}
                          />
                          <Col sm={12} className='text-center d-flex align-center justify-content-between'>
                            <div className='custom-control custom-checkbox'>
                              <input type='checkbox' className='custom-control-input' id='customControlInline' />
                              <label className='custom-control-label' htmlFor='customControlInline'>
                                {t('pages.login.rememberMe')}
                              </label>
                            </div>
                            <button className='btn btn-primary w-md waves-effect waves-light' type='submit'>
                              {t('pages.login.logIn')}
                            </button>
                          </Col>
                        </Row>
                        {/* <Row className="form-group mt-2 mb-0">
                          <div className="col-12 mt-4">
                            <Link to="/forget-password">
                              <i className="mdi mdi-lock"></i> Forgot your
                              password?
                            </Link>
                          </div>
                        </Row> */}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className='mt-5 text-center'>
                <p>
                  {t('pages.login.forgotPassword')}
                  <Link to={`${process.env.PUBLIC_URL}/resetPassword`} className='font-weight-medium text-primary'>
                    {' '}
                    {t('pages.login.reset')}
                  </Link>{' '}
                </p>
              </div>
              <div className='mt-5 text-center'>
                <p>
                  {t('pages.login.donHaveAccount')}
                  <Link to={`${process.env.PUBLIC_URL}/signup`} className='font-weight-medium text-primary'>
                    {' '}
                    {t('pages.login.signupNow')}
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = {
  login: actions.login,
}

export default connect(null, mapDispatchToProps)(Login)
