import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import { Table } from '../../../../components/Table'
import * as actions from '../../actions'
import MinerActions from '../../components/MinerActions'
import { CreateMinerModal } from '../../components/modals'
import { Row, Col } from 'reactstrap'
import Cookies from 'js-cookie'

const Miners = props => {
  const [miners, setMiners] = useState([])
  const setMinersList = props.setMinersList
  const setAlgoCoinsList = props.setAlgoCoinsList
  const setCoinsList = props.setCoinsList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 100,
  )

  useEffect(() => {
    setMinersList({ page: currentPage, size: showCount, orderBy: 'id' })
    setCoinsList({ page: 1, size: 1000, orderBy: 'id' })
    setAlgoCoinsList({ page: currentPage, size: showCount, orderBy: 'id' })
  }, [setMinersList, currentPage, showCount])

  useEffect(() => {
    const minersArray = props.minersList.map(miner => {
      return {
        ...miner,
        action: <MinerActions miner={miner} />,
      }
    })
    setMiners(minersArray)
  }, [props.minersList])

  const minersData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'Name',
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'cardType',
        field: 'cardType',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'Actions',
        field: 'action',
        width: 10,
      },
    ],
    rows: miners,
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <CreateMinerModal />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            name={'miners'}
            className={'dc__table'}
            responsive
            data={minersData}
            total={props.minersTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  minersList: store.admin.minersList,
  minersTotal: store.admin.minersTotal,
})

const mapDispatchToProps = {
  setMinersList: actions.setMinersList,
  setCoinsList: actions.setCoinsList,
  setAlgoCoinsList: actions.setAlgoCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Miners)
