import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

const Donate = () => {
  const [wallet1Copied, setWallet1Copied] = useState(false)
  const [wallet2Copied, setWallet2Copied] = useState(false)
  const [wallet3Copied, setWallet3Copied] = useState(false)
  const { t } = useTranslation('common')
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className={'download_page'}>
          <Row>
            <Col sm={12} md={12} className={'text-center'}>
              <h2>{t('pages.donate.betaTestingPeriod')}</h2>
              <h4>
                {t('pages.donate.foundThisSystem')}
                <br /> {t('pages.donate.everyCoinHelps')}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} className={'text-center'}>
              <div className={'wallets'}>
                <CopyToClipboard
                  text={'0xc86b3C081D9F277Db9495F746b2742a14A059093'}
                  onCopy={() => {
                    setWallet1Copied(true)
                    setTimeout(() => setWallet1Copied(false), 2000)
                  }}
                >
                  <div className={'wallet'}>
                    <div className={'wallet__icon'}>
                      <img alt={'ETC'} src={require('../../../assets/images/coinIcons/etc.png').default} />
                    </div>
                    <div className={'wallet__address'}>
                      {wallet1Copied ? t('pages.donate.copied') : '0xc86b3C081D9F277Db9495F746b2742a14A059093'}
                    </div>
                  </div>
                </CopyToClipboard>
                <CopyToClipboard
                  text={'0xA61c8aB3E04696CA89b3639074978F4A3c006619'}
                  onCopy={() => {
                    setWallet2Copied(true)
                    setTimeout(() => setWallet2Copied(false), 2000)
                  }}
                >
                  <div className={'wallet'}>
                    <div className={'wallet__icon'}>
                      <img alt={'ETH'} src={require('../../../assets/images/coinIcons/eth.png').default} />
                    </div>
                    <div className={'wallet__address'}>
                      {wallet2Copied ? t('pages.donate.copied') : '0xA61c8aB3E04696CA89b3639074978F4A3c006619'}
                    </div>
                  </div>
                </CopyToClipboard>
                <CopyToClipboard
                  text={'TNqErF6bVpNmXkJwdRzCP7gUboUGMxED6e'}
                  onCopy={() => {
                    setWallet3Copied(true)
                    setTimeout(() => setWallet3Copied(false), 2000)
                  }}
                >
                  <div className={'wallet'}>
                    <div className={'wallet__icon'}>
                      <img alt={'USDT'} src={require('../../../assets/images/coinIcons/usdt.png').default} />
                    </div>
                    <div className={'wallet__address'}>
                      {wallet3Copied ? t('pages.donate.copied') : 'TNqErF6bVpNmXkJwdRzCP7gUboUGMxED6e'}
                    </div>
                  </div>
                </CopyToClipboard>
                <h6>{t('pages.donate.clickWalletAddress')}</h6>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Donate
