import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'
import CreateServerModal from './CreateServerModal'
import ServerActions from '../ServerActions'
import SelectWithImages from '../../../../components/UI/SelectWithImages'

const CreatePoolModal = props => {
  const [title, setTitle] = useState('')
  const [sslPort, setSslPort] = useState('')
  const [stratumPort, setStratumPort] = useState('')
  const [altStratumPort, setAltStratumPort] = useState('')
  const [type, setType] = useState('')
  const [coins, setCoins] = useState('')
  const [soloPort, setSoloPort] = useState('')
  const [poolServers, setPoolServers] = useState([])
  const [serverOrder, setServerOrder] = useState(0)
  const { t } = useTranslation('common')
  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'title': {
        setTitle(e.target.value)
        return
      }
      case 'sslPort': {
        setSslPort(e.target.value)
        return
      }
      case 'stratumPort': {
        setStratumPort(e.target.value)
        return
      }
      case 'altStratumPort': {
        setAltStratumPort(e.target.value)
        return
      }
      case 'type': {
        setType(e.target.value)
        return
      }
      case 'coins': {
        setCoins(e.target.value)
        return
      }
      case 'soloPort': {
        setSoloPort(e.target.value)
        return
      }
      default: {
        return
      }
    }
  }

  const [errors, setErrors] = useState({})

  const submitFunc = () => {
    const newErrors = {}
    if (title.length === 0) newErrors.title = { message: t('pages.customPools.poolTitleError') }
    if (poolServers.length === 0) newErrors.poolServers = { message: t('pages.customPools.serversError') }
    if (!coins || (coins && coins.length === 0)) newErrors.coins = { message: t('pages.customPools.coinsError') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    let coinsString = ''
    coins.map(coin => {
      coinsString += coin.value + ', '
      return coin
    })
    coinsString = coinsString.substr(0, coinsString.length - 2)

    props.createPool({
      title: title,
      sslPort: sslPort,
      stratumPort: stratumPort,
      altStratumPort: altStratumPort,
      soloPort: soloPort,
      type: type,
      coins: coinsString,
      poolServers: poolServers.map(server => {
        return {
          country: server.country,
          address: server.address,
          type: server.type,
          coin: server.coin,
        }
      }),
    })
    toggle()
  }
  const [show, setShow] = useState(false)
  const [showServerModal, setShowServerModal] = useState(false)
  const toggleServerModal = () => {
    setShowServerModal(!showServerModal)
  }

  const toggle = () => {
    setShow(!show)
  }

  const addServer = server => {
    server.order = serverOrder
    setPoolServers([...poolServers, server])
    setServerOrder(+serverOrder + +1)
  }
  const updateServer = server => {
    const index = poolServers.findIndex(fServer => fServer.order === server.order)
    setPoolServers([...poolServers.slice(0, index), server, ...poolServers.slice(index + 1)])
  }
  const removeServer = server => {
    setPoolServers(poolServers.filter(fServer => fServer !== server))
  }
  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'power_unit__create'}>
        <Button onClick={toggle} color='primary' className={'mining_preset__create'}>
          {t('pages.customPools.addCustomPool')}
        </Button>
      </div>
      <Modal isOpen={show} toggle={toggle} className={'modal-dialog-centered'} onClick={e => e.stopPropagation()}>
        <ModalHeader tag={'h4'} toggle={toggle}>
          {t('pages.customPools.addPool')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.customPools.title')}</Label>
                <Input
                  className={errors.title ? 'invalid' : ''}
                  type={'text'}
                  name={'title'}
                  onChange={onChange}
                  value={title}
                />
                <div className={'error'}>{errors.title && errors.title.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.customPools.SSLPort')}</Label>
                <Input
                  className={errors.sslPort ? 'invalid' : ''}
                  type={'text'}
                  name={'sslPort'}
                  onChange={onChange}
                  value={sslPort}
                />
                <div className={'error'}>{errors.sslPort && errors.sslPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.customPools.stratumPort')}</Label>
                <Input
                  className={errors.stratumPort ? 'invalid' : ''}
                  type={'text'}
                  name={'stratumPort'}
                  onChange={onChange}
                  value={stratumPort}
                />
                <div className={'error'}>{errors.stratumPort && errors.stratumPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.customPools.altStratumPort')}</Label>
                <Input
                  className={errors.altStratumPort ? 'invalid' : ''}
                  type={'text'}
                  name={'altStratumPort'}
                  onChange={onChange}
                  value={altStratumPort}
                />
                <div className={'error'}>{errors.altStratumPort && errors.altStratumPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.customPools.soloPort')}</Label>
                <Input
                  className={errors.soloPort ? 'invalid' : ''}
                  type={'text'}
                  name={'soloPort'}
                  onChange={onChange}
                  value={soloPort}
                />
                <div className={'error'}>{errors.soloPort && errors.soloPort.message}</div>
              </Col>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.customPools.coins')}</Label>
                <SelectWithImages
                  classNamePrefix={'form_control_react_select'}
                  value={coins}
                  onchange={option => {
                    setCoins(option)
                  }}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  options={props.coinsList.map(coin => {
                    return {
                      icon: coin.nameSymbol,
                      label: coin.nameSymbol,
                      value: coin.nameSymbol,
                    }
                  })}
                  placeholder={t('pages.customPools.selectCoin')}
                />
                <div className={'error'}>{errors.coins && errors.coins.message}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={'form-group'}>
                <div className={'createPoolServers'}>
                  <h5>{t('pages.customPools.servers')}</h5>
                  <CreateServerModal
                    key={poolServers + poolServers.length}
                    addServer={addServer}
                    show={showServerModal}
                    toggle={toggleServerModal}
                  />
                </div>
                <table className={'table table-striped table-bordered'}>
                  <thead className={'thead-light'}>
                    <tr>
                      <th>{t('pages.customPools.countryRegion')}</th>
                      <th>{t('pages.customPools.IPAddress')}</th>
                      <th>{t('pages.customPools.actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poolServers.map(server => {
                      return (
                        <tr key={server.order}>
                          <td>{server.country}</td>
                          <td>{server.address}</td>
                          <td>
                            <ServerActions
                              key={server.order}
                              server={server}
                              updateServer={updateServer}
                              removeServer={removeServer}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className={'error'}>{errors.poolServers && errors.poolServers.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            {t('pages.customPools.cancel')}
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            {t('pages.customPools.save')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  coinsList: store.presets.coinsList,
})

const mapDispatchToProps = {
  createPool: actions.createPool,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePoolModal)
