import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'
import CreateMinerParamModal from './CreateMinerParamModal'
import MinerParamActions from '../MinerParamActions'
import MinerVersionActions from '../MinerVersionActions'
import CreateMinerVersionModal from './CreateMinerVersionModal'

const CreateMinerModal = props => {
  const [name, setName] = useState('')
  const [cardType, setCardType] = useState('')
  const [template, setTemplate] = useState('')
  const [minerParams, setMinerParams] = useState([])
  const [minerVersions, setMinerVersions] = useState([])
  const [minerParamOrder, setMinerParamOrder] = useState(0)
  const [minerVersionOrder, setMinerVersionOrder] = useState(0)
  const [errors, setErrors] = useState({})
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }
  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'name': {
        setName(e.target.value)
        return
      }
      case 'cardType': {
        setCardType(e.target.value)
        return
      }
      case 'template': {
        setTemplate(e.target.value)
        return
      }
      default:
        return
    }
  }

  const submitFunc = () => {
    const newErrors = {}
    if (name.length === 0) newErrors.name = { message: "Miner Name can't be empty" }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    props.createMiner({
      name: name,
      cardType: cardType,
      template: template,
      minerParams: minerParams.map(param => {
        return {
          paramName: param.paramName,
          consts: param.consts,
        }
      }),
      minerVersions: minerVersions.map(version => {
        return {
          version: version.version,
          dualCoins: version.dualCoins,
          params: version.params.map(param => {
            return {
              id: param.id,
              algo: param.algo,
              algoInMP: param.algoInMP,
            }
          }),
        }
      }),
    })
    toggle()
  }

  const [showParamModal, setShowParamModal] = useState(false)
  const [showVersionModal, setShowVersionModal] = useState(false)
  const toggleParamModal = () => {
    setShowParamModal(!showParamModal)
  }
  const toggleVersionModal = () => {
    setShowVersionModal(!showVersionModal)
  }

  const addParam = param => {
    param.order = minerParamOrder
    setMinerParams([...minerParams, param])
    setMinerParamOrder(+minerParamOrder + +1)
  }
  const updateParam = param => {
    const index = minerParams.findIndex(fParam => fParam.order === param.order)
    setMinerParams([...minerParams.slice(0, index), param, ...minerParams.slice(index + 1)])
  }
  const removeParam = param => {
    setMinerParams(minerParams.filter(fParam => fParam !== param))
  }

  const addVersion = version => {
    version.order = minerVersionOrder
    setMinerVersions([version, ...minerVersions])
    setMinerVersionOrder(+minerVersionOrder + +1)
  }
  const updateVersion = version => {
    const index = minerVersions.findIndex(fVersion => fVersion.order === version.order)
    setMinerVersions([...minerVersions.slice(0, index), version, ...minerVersions.slice(index + 1)])
  }
  const removeVersion = version => {
    setMinerVersions(minerVersions.filter(fVersion => fVersion !== version))
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <Button onClick={toggle} color='primary' className={'mining_preset__create'}>
        Add Miner
      </Button>
      <Modal isOpen={show} toggle={toggle} className={'modal-dialog-centered'} onClick={e => e.stopPropagation()}>
        <ModalHeader tag={'h4'} toggle={toggle}>
          Create Miner
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={6} className={'form-group'}>
                <Label>Name</Label>
                <Input
                  className={errors.name ? 'invalid' : ''}
                  type={'text'}
                  name={'name'}
                  onChange={onChange}
                  value={name}
                />
                <div className={'error'}>{errors.name && errors.name.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>Card Type</Label>
                <Input
                  className={errors.cardType ? 'invalid' : ''}
                  type={'text'}
                  name={'cardType'}
                  onChange={onChange}
                  value={cardType}
                />
                <div className={'error'}>{errors.cardType && errors.cardType.message}</div>
              </Col>
              <Col xs={12} className={'form-group'}>
                <Label>Template String</Label>
                <Input type={'textarea'} name={'template'} onChange={onChange} value={template} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={'form-group'}>
                <div className={'createMinerParams'}>
                  <h5>Params</h5>
                  <CreateMinerParamModal
                    key={minerParams + minerParams.length}
                    addParam={addParam}
                    show={showParamModal}
                    toggle={toggleParamModal}
                  />
                </div>
                <table className={'table table-striped table-bordered'}>
                  <thead className={'thead-light'}>
                    <tr>
                      <th>paramName</th>
                      <th>Values</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {minerParams.map(param => {
                      return (
                        <tr key={param.order}>
                          <td>{param.paramName}</td>
                          <td>
                            {param.consts
                              ? param.consts
                                  .map(constant => {
                                    return constant.paramValue
                                  })
                                  .join(', ')
                              : []}
                          </td>
                          <td>
                            <MinerParamActions
                              key={param.order}
                              param={param}
                              updateParam={updateParam}
                              removeParam={removeParam}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={'form-group'}>
                <div className={'editMinerParams'}>
                  <h5>Versions</h5>
                  <CreateMinerVersionModal
                    versions={minerVersions}
                    key={minerVersions + minerVersions.length}
                    addVersion={addVersion}
                    show={showVersionModal}
                    toggle={toggleVersionModal}
                  />
                </div>
                <div className={'versionsTable'}>
                  <table className={'table table-striped table-bordered'}>
                    <thead className={'thead-light'}>
                      <tr>
                        <th>Version</th>
                        <th>Algorithms</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {minerVersions
                        ? minerVersions.map(version => {
                            return (
                              <tr key={version.order}>
                                <td>{version.version}</td>
                                <td>{version.params ? version.params.map(param => param.algo).join(', ') : []}</td>
                                <td>
                                  <MinerVersionActions
                                    versions={minerVersions}
                                    key={version.order}
                                    version={version}
                                    updateVersion={updateVersion}
                                    removeVersion={removeVersion}
                                  />
                                </td>
                              </tr>
                            )
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = {
  createMiner: actions.createMiner,
}

export default connect(null, mapDispatchToProps)(CreateMinerModal)
