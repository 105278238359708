import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input } from 'reactstrap'

import * as actions from '../../actions'
import Switch from 'react-switch'
import SwitchServer from '../../../../components/UI/Switch.js'
import MiningPresetRow from '../MiningPresetRow'
import SelectWithImages from '../../../../components/UI/SelectWithImages'
import NumericInput from 'react-numeric-input'
import Select, { components } from 'react-select'
import SelectWithCryptoImages from '../../../../components/UI/SelectWithCryptoImages'
import { useTranslation } from 'react-i18next'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import uuid from 'node-uuid'

const MiningPresetModalEdit = ({
  setWalletsList,
  slotsList,
  walletsList,
  coinsList,
  poolsList,
  minersList,
  createWalletInPreset,
  updateMiningPreset,
  overclockingProfilesList,
  ...props
}) => {
  const [errors, setErrors] = useState({})
  const [miningPresetName, setMiningPresetName] = useState({
    id: props.preset.id,
    name: props.preset.name,
  })
  const { t } = useTranslation('common')
  const [showDelete, setShowDelete] = useState(false)
  const [create_wallet, setCreateWallet] = useState(false)
  const [configure_pool, setConfigurePool] = useState(false)
  const [configure_pool_dual, setConfigurePoolDual] = useState(false)
  const [configure_miner, setConfigureMiner] = useState(false)
  const [presets, setPresets] = useState([])
  const [showAlertRestartRigs, setShowAlertRestartRigs] = useState(false)

  useEffect(() => {
    const flightsheets = props.preset.flightSheets.map(flightSheet => {
      flightSheet.minerObj = {
        ...flightSheet.minerObj,
        value: flightSheet.minerObj.id,
        label: flightSheet.minerObj.name,
        minerParams:
          minersList.length > 0 ? minersList.find(miner => miner.id === flightSheet.minerObj.id).minerParams : [],
        minerVersions:
          minersList.length > 0 ? minersList.find(miner => miner.id === flightSheet.minerObj.id).minerVersions : [],
      }
      flightSheet.coinObj = {
        ...flightSheet.coinObj,
        icon: flightSheet.coinObj.nameSymbol,
        label: flightSheet.coinObj.nameSymbol,
        value: flightSheet.coinObj.nameSymbol,
      }
      flightSheet.walletObj = {
        ...flightSheet.walletObj,
        value: flightSheet.walletObj.address,
        label: flightSheet.walletObj.name,
      }
      const poolObjId = flightSheet.poolObj.id
      flightSheet.poolObj = {
        ...flightSheet.poolObj,
        value: flightSheet.poolObj.id,
        label: flightSheet.poolObj.title,
        coins: poolsList.find(pool => pool.id === poolObjId) && poolsList.find(pool => pool.id === poolObjId).coins,
        poolServers:
          poolsList.find(pool => pool.id === poolObjId) && poolsList.find(pool => pool.id === poolObjId).poolServers,
        type: poolsList.find(pool => pool.id === poolObjId) && poolsList.find(pool => pool.id === poolObjId).type,
      }

      if (flightSheet.dualCoinObj) {
        flightSheet.dualCoinObj = {
          ...flightSheet.dualCoinObj,
          icon: flightSheet.dualCoinObj.nameSymbol,
          label: flightSheet.dualCoinObj.nameSymbol,
          value: flightSheet.dualCoinObj.nameSymbol,
        }
        flightSheet.dualWalletObj = {
          ...flightSheet.dualWalletObj,
          value: flightSheet.dualWalletObj ? flightSheet.dualWalletObj.address : '',
          label: flightSheet.dualWalletObj ? flightSheet.dualWalletObj.name : '',
        }
        const poolObjIdDual = flightSheet.dualPoolObj.id
        flightSheet.dualPoolObj = {
          ...flightSheet.dualPoolObj,
          value: flightSheet.dualPoolObj.id,
          label: flightSheet.dualPoolObj.title,
          coins:
            poolsList.find(pool => pool.id === poolObjIdDual) &&
            poolsList.find(pool => pool.id === poolObjIdDual).coins,
          poolServers:
            poolsList.find(pool => pool.id === poolObjId) && poolsList.find(pool => pool.id === poolObjId).poolServers,
          type: poolsList.find(pool => pool.id === poolObjId) && poolsList.find(pool => pool.id === poolObjId).type,
        }

        if (flightSheet.dualCoinObj) {
          flightSheet.dualCoinObj = {
            ...flightSheet.dualCoinObj,
            icon: flightSheet.dualCoinObj.nameSymbol,
            label: flightSheet.dualCoinObj.nameSymbol,
            value: flightSheet.dualCoinObj.nameSymbol,
          }
        }
        if (flightSheet.dualWalletObj) {
          flightSheet.dualWalletObj = {
            ...flightSheet.dualWalletObj,
            value: flightSheet.dualWalletObj.address,
            label: flightSheet.dualWalletObj.name,
          }
        }
        if (flightSheet.dualPoolObj) {
          const poolObjIdDual = flightSheet.dualPoolObj.id
          flightSheet.dualPoolObj = {
            ...flightSheet.dualPoolObj,
            value: flightSheet.dualPoolObj.id,
            label: flightSheet.dualPoolObj.title,
            coins:
              poolsList.find(pool => pool.id === poolObjIdDual) &&
              poolsList.find(pool => pool.id === poolObjIdDual).coins,
            poolServers:
              poolsList.find(pool => pool.id === poolObjIdDual) &&
              poolsList.find(pool => pool.id === poolObjIdDual).poolServers,
            type:
              poolsList.find(pool => pool.id === poolObjIdDual) &&
              poolsList.find(pool => pool.id === poolObjIdDual).type,
          }
        }
      }

      flightSheet.minerParams
        ? (flightSheet.minerValues = {
            miner_id: flightSheet.minerObj.id,
            logs: !!flightSheet.minerParams.logs,
            debug: !!flightSheet.minerParams.debug,
            rotateLogs: !!flightSheet.minerParams.rotateLogs,
            watchdog: !!flightSheet.minerParams.watchdog,
            dualMode: !!flightSheet.minerParams.dualMode,
            worker: flightSheet.minerParams.worker,
            password: flightSheet.minerParams.password,
            algo: flightSheet.minerParams.algo
              ? {
                  algo: props.algoCoinsList.find(algo => algo.algo === flightSheet.minerParams.algo)
                    ? props.algoCoinsList.find(algo => algo.algo === flightSheet.minerParams.algo).algo
                    : null,
                  coins: props.algoCoinsList.find(algo => algo.algo === flightSheet.minerParams.algo)
                    ? props.algoCoinsList.find(algo => algo.algo === flightSheet.minerParams.algo).coins
                    : null,
                  label: flightSheet.minerParams.algo,
                  value: flightSheet.minerParams.algo,
                }
              : '',
            dualAlgo: flightSheet.minerParams.dualAlgo
              ? {
                  algo: props.algoCoinsList.find(algo => algo.algo === flightSheet.minerParams.dualAlgo)
                    ? props.algoCoinsList.find(algo => algo.algo === flightSheet.minerParams.dualAlgo).algo
                    : null,
                  coins: props.algoCoinsList.find(algo => algo.algo === flightSheet.minerParams.dualAlgo)
                    ? props.algoCoinsList.find(algo => algo.algo === flightSheet.minerParams.dualAlgo).coins
                    : null,
                  label: flightSheet.minerParams.dualAlgo,
                  value: flightSheet.minerParams.dualAlgo,
                }
              : '',
            miner_params_add: flightSheet.minerParams.miner_params_add ? flightSheet.minerParams.miner_params_add : '',
            logsStorage: flightSheet.minerParams.logsStorage
              ? {
                  label:
                    flightSheet.minerParams.logsStorage === 'server'
                      ? t('pages.miningPresets.SaveOnServer')
                      : t('pages.miningPresets.saveLocally'),
                  value: flightSheet.minerParams.logsStorage,
                }
              : '',
            logsDays: flightSheet.minerParams.logsDays ? flightSheet.minerParams.logsDays : '',
            startMiningIn: flightSheet.minerParams.startMiningIn ? flightSheet.minerParams.startMiningIn : 0,
            version:
              flightSheet.minerParams.version &&
              flightSheet.minerObj.minerVersions &&
              flightSheet.minerObj.minerVersions[0]
                ? {
                    label:
                      flightSheet.minerParams.version === flightSheet.minerObj.minerVersions[0].version
                        ? flightSheet.minerParams.version + ' (Latest)'
                        : flightSheet.minerParams.version,
                    value: flightSheet.minerParams.version,
                  }
                : '',
            protocol: flightSheet.minerParams.protocol
              ? {
                  label: flightSheet.minerObj.minerParams.find(param => param.paramName === 'protocol')
                    ? flightSheet.minerObj.minerParams.find(param => param.paramName === 'protocol').consts &&
                      flightSheet.minerObj.minerParams
                        .find(param => param.paramName === 'protocol')
                        .consts.find(constant => constant.paramValue === flightSheet.minerParams.protocol) &&
                      flightSheet.minerObj.minerParams
                        .find(param => param.paramName === 'protocol')
                        .consts.find(constant => constant.paramValue === flightSheet.minerParams.protocol).paramLabel
                    : '',
                  value: flightSheet.minerParams.protocol,
                }
              : '',
            fork: flightSheet.minerParams.fork
              ? {
                  label: flightSheet.minerObj.minerParams.find(param => param.paramName === 'fork')
                    ? flightSheet.minerObj.minerParams.find(param => param.paramName === 'fork').consts &&
                      flightSheet.minerObj.minerParams
                        .find(param => param.paramName === 'fork')
                        .consts.find(constant => constant.paramValue === flightSheet.minerParams.fork) &&
                      flightSheet.minerObj.minerParams
                        .find(param => param.paramName === 'fork')
                        .consts.find(constant => constant.paramValue === flightSheet.minerParams.fork).paramLabel
                    : '',
                  value: flightSheet.minerParams.fork,
                }
              : '',
            errors: {},
          })
        : (flightSheet.minerValues = {
            errors: {},
            worker: '$wallet.$workername',
            algo: '',
            dualAlgo: '',
            version: '',
          })
      return flightSheet.minerValues.dualMode
        ? {
            id: parseInt(flightSheet.id),
            miningPresetId: parseInt(props.preset.id),
            order: flightSheet.number,
            ssl: flightSheet.poolSsl,
            sslDual: flightSheet.poolSslDual,
            pool: flightSheet.poolObj,
            userPool: flightSheet.userPool ? flightSheet.userPool.replace(',', '\n') : '',
            userPoolDual: flightSheet.dualUserPool ? flightSheet.dualUserPool.replace(',', '\n') : '',
            poolDual: flightSheet.dualPoolObj,
            errors: {},
            coin: flightSheet.coinObj,
            wallet: flightSheet.walletObj,
            coinDual: flightSheet.dualCoinObj,
            walletDual: flightSheet.dualWalletObj,
            miner: {
              ...flightSheet.minerObj,
              minerValues: flightSheet.minerValues,
            },
            poolAddress: flightSheet.serverIds ? flightSheet.serverIds.split(',') : [],
            poolAddressDual: flightSheet.dualServerIds ? flightSheet.dualServerIds.split(',') : [],
            minerValues: flightSheet.minerValues,
            stratumPort: flightSheet.poolObj.stratumPort,
            stratumPortDual: flightSheet.dualPoolObj ? flightSheet.dualPoolObj.stratumPort : '',
            sslPort: flightSheet.poolObj.sslPort && flightSheet.poolObj.sslPort,
            pplns: !!(
              flightSheet.poolObj.type === 'SP' &&
              flightSheet.poolObj.poolServers.find(server => flightSheet.serverIds.includes(server.address)) &&
              flightSheet.poolObj.poolServers.find(server => flightSheet.serverIds.includes(server.address)).type ===
                'pplns'
            ),
            sslPortDual: flightSheet.dualPoolObj
              ? flightSheet.dualPoolObj.sslPort && flightSheet.dualPoolObj.sslPort
              : false,
            pplnsDual: flightSheet.dualPoolObj
              ? !!(
                  flightSheet.dualPoolObj.type === 'SP' &&
                  flightSheet.dualPoolObj.poolServers.find(server =>
                    flightSheet.dualServerIds.includes(server.address),
                  ) &&
                  flightSheet.dualPoolObj.poolServers.find(server => flightSheet.dualServerIds.includes(server.address))
                    .type === 'pplns'
                )
              : false,
            minersList: minersList.map(miner => {
              if (miner.id === flightSheet.minerValues.miner_id) {
                return {
                  ...miner,
                  label: miner.name,
                  value: miner.id,
                  type: miner.cardType,
                  minerValues: {
                    errors: {},
                    worker: '$wallet.$workername',
                    algo: '',
                    dualAlgo: '',
                    version: '',
                  },
                }
              }
              return {
                ...miner,
                label: miner.name,
                value: miner.id,
                type: miner.cardType,
                minerValues: {
                  errors: {},
                  worker: '$wallet.$workername',
                  algo: '',
                  dualAlgo: '',
                  version: '',
                },
              }
            }),
          }
        : {
            id: parseInt(flightSheet.id),
            miningPresetId: parseInt(props.preset.id),
            order: flightSheet.number,
            ssl: flightSheet.poolSsl,
            pool: flightSheet.poolObj,
            userPool: flightSheet.userPool ? flightSheet.userPool.split('').join('\n') : '',
            errors: {},
            coin: flightSheet.coinObj,
            wallet: flightSheet.walletObj,
            miner: {
              ...flightSheet.minerObj,
              minerValues: flightSheet.minerValues,
            },
            poolAddress: flightSheet.serverIds ? flightSheet.serverIds.split(',') : [],
            minerValues: flightSheet.minerValues,
            stratumPort: flightSheet.poolObj.stratumPort,
            sslPort: flightSheet.poolObj.sslPort && flightSheet.poolObj.sslPort,
            pplns: !!(
              flightSheet.poolObj.type === 'SP' &&
              flightSheet.poolObj.poolServers.find(server => flightSheet.serverIds.includes(server.address)) &&
              flightSheet.poolObj.poolServers.find(server => flightSheet.serverIds.includes(server.address)).type ===
                'pplns'
            ),
            minersList: minersList.map(miner => {
              if (miner.id === flightSheet.minerValues.miner_id) {
                return {
                  ...miner,
                  label: miner.name,
                  value: miner.id,
                  type: miner.cardType,
                  minerValues: flightSheet.minerValues,
                }
              }
              return {
                ...miner,
                label: miner.name,
                value: miner.id,
                type: miner.cardType,
                minerValues: {
                  errors: {},
                  worker: '$wallet.$workername',
                  algo: '',
                  dualAlgo: '',
                  version: '',
                },
              }
            }),
          }
    })
    setPresets(flightsheets)
    setSlotError(false)
    setCurrentPreset(flightsheets && flightsheets[0])
    setCreateWallet(false)
    setConfigurePool(false)
    setConfigurePoolDual(false)
    setConfigureMiner(false)

    setSlot(() => {
      const findSlot = props.preset && slotsList.find(slot => slot.id == props.preset.slotId)
      if (!findSlot) return null
      return {
        ...findSlot,
        label: findSlot.name ? findSlot.name : '',
        value: findSlot ? findSlot.id : '',
      }
    })
  }, [props.preset.id])

  const [currentPreset, setCurrentPreset] = useState({})
  const setPreset = () => {
    const preset = {
      order: presets.length + 1,
      ssl: false,
      pplns: false,
      pool: null,
      errors: {},
      coin: '',
      wallet: '',
      miner: '',
      poolAddress: [],
      position: 0,
      minerValues: { errors: {} },
      userPool: '',
      sslDual: false,
      pplnsDual: false,
      poolDual: null,
      coinDual: '',
      walletDual: '',
      poolAddressDual: [],
      userPoolDual: '',
      minersList: minersList.map(miner => {
        return {
          ...miner,
          label: miner.name,
          value: miner.id,
          type: miner.cardType,
          minerValues: {
            errors: {},
            worker: '$wallet.$workername',
            algo: '',
            dualAlgo: '',
            version: '',
          },
        }
      }),
    }
    setPresets([...presets, preset])
    setShowDelete(presets.length > 0)
  }

  const [updateState, setUpdateState] = useState(false)
  const setCurrentPresetFunc = preset => {
    setCurrentPreset(preset)
    setUpdateState(!updateState)
  }

  const removePreset = preset => {
    if (currentPreset === preset) {
      setCreateWallet(false)
      setConfigurePool(false)
      setConfigurePoolDual(false)
      setConfigureMiner(false)
      setCurrentPreset(null)
    }

    const newArr = presets.filter(item => {
      return item.order !== preset.order
    })
    newArr.forEach(function callback(item, i, arr) {
      if (item.order > preset.order) arr[i].order = item.order - 1
    })
    setPresets(newArr)
    setShowDelete(newArr.length > 1)
  }

  const setAddress = address => {
    const verification = currentPreset.poolAddress.find(el => el === address)
    if (!verification) {
      //setPoolAddress([...poolAddress, address])
      currentPreset.poolAddress = [...currentPreset.poolAddress, address]
      if (currentPreset.pool.sslPort) currentPreset.sslPort = currentPreset.pool.sslPort
      currentPreset.stratumPort = currentPreset.pool.stratumPort
    } else {
      const correctAddress = currentPreset.poolAddress.filter(el => el !== address)
      //setPoolAddress(correctAddress)
      currentPreset.poolAddress = correctAddress
      currentPreset.port =
        currentPreset.pool.sslPort && currentPreset.ssl ? currentPreset.pool.sslPort : currentPreset.pool.stratumPort
    }
    setUpdateState(!updateState)
  }

  const setAddressDual = address => {
    const verification = currentPreset.poolAddressDual.find(el => el === address)
    if (!verification) {
      //setPoolAddress([...poolAddress, address])
      currentPreset.poolAddressDual = [...currentPreset.poolAddressDual, address]
      if (currentPreset.poolDual.sslPort) currentPreset.sslPortDual = currentPreset.poolDual.sslPort
      currentPreset.stratumPortDual = currentPreset.poolDual.stratumPort
    } else {
      const correctAddress = currentPreset.poolAddressDual.filter(el => el !== address)
      //setPoolAddress(correctAddress)
      currentPreset.poolAddressDual = correctAddress
      currentPreset.portDual =
        currentPreset.poolDual.sslPort && currentPreset.sslDual
          ? currentPreset.poolDual.sslPort
          : currentPreset.poolDual.stratumPort
    }
    setUpdateState(!updateState)
  }

  const changeName = event => {
    setMiningPresetName({
      id: miningPresetName.id,
      name: event.target.value,
    })
  }
  const [slot, setSlot] = useState(null)
  const hideCreateWallet = () => setCreateWallet(false)

  const toggleSwitchSSL = () => {
    currentPreset.ssl = !currentPreset.ssl
    //setSwitchSSL(!switchSSL)
    setUpdateState(!updateState)
  }

  const toggleSwitchPPLNS = () => {
    currentPreset.pplns = !currentPreset.pplns
    currentPreset.poolAddress = []
    setUpdateState(!updateState)
    //setswitchPPLNS(!switchPPLNS)
  }

  const toggleSwitchSSLDual = () => {
    currentPreset.sslDual = !currentPreset.sslDual
    setUpdateState(!updateState)
    //setSwitchSSLDual(!switchSSLDual)
  }

  const toggleSwitchPPLNSDual = () => {
    currentPreset.pplnsDual = !currentPreset.pplnsDual
    currentPreset.poolAddressDual = []
    setUpdateState(!updateState)
    //setswitchPPLNSDual(!switchPPLNSDual)
  }

  const [newWalletName, setNewWalletName] = useState('')
  const [newWalletAddress2, setNewWalletAddress2] = useState('')
  const [newWalletSource, setNewWalletSource] = useState('')
  const [newWalletCoin, setNewWalletCoin] = useState('')

  const walletValuesFunc = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'wallet_name': {
        setNewWalletName(e.target.value)
        return
      }
      case 'wallet_address': {
        setNewWalletAddress2(e.target.value)
        return
      }
      case 'wallet_coin': {
        setNewWalletCoin(e.target.options[e.target.selectedIndex].value)
        return
      }
      case 'wallet_source': {
        setNewWalletSource(e.target.value)
        return
      }
      default: {
        return
      }
    }
  }
  const walletValuesFuncSelect = option => {
    const newErrors = errors
    delete newErrors['wallet_coin']
    setErrors(newErrors)
    setNewWalletCoin(option)
  }

  const createWalletFunc = async () => {
    const newErrors = {}
    if (newWalletName.length === 0) newErrors.wallet_name = { message: t('pages.miningPresets.walletName') }
    if (newWalletAddress2.length === 0)
      newErrors.wallet_address = {
        message: t('pages.miningPresets.walletAddress'),
      }
    if (newWalletCoin.value.length === 0) newErrors.wallet_coin = { message: t('pages.miningPresets.walletCoin') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return
    await createWalletInPreset({
      name: newWalletName,
      address: newWalletAddress2,
      coin: newWalletCoin.value,
      source: newWalletSource,
    })
    await setWalletsList({ page: 1, size: 1000, filters: {} })
    hideCreateWallet()
  }

  const [presetNameError, setPresetNameError] = useState({})

  const updateMiningPresetFunc = async ({ updateRigsStr = false }) => {
    setPresetNameError({})
    let presetError = false
    if (miningPresetName.length === 0) {
      setPresetNameError({ message: t('pages.miningPresets.presetNameError') })
      presetError = true
    }

    presets.forEach(function callback(preset) {
      preset.errors = {}
      if (preset.coin.length === 0) {
        preset.errors.coin = { message: t('pages.miningPresets.selectCoin') }
        presetError = true
      }
      if (preset.wallet.length === 0) {
        preset.errors.wallet = {
          message: t('pages.miningPresets.selectWallet'),
        }
        presetError = true
      }
      if (preset.miner.length === 0) {
        preset.errors.miner = { message: t('pages.miningPresets.selectMiner') }
        presetError = true
      }
      if (preset.pool === 0) {
        preset.errors.pool = { message: t('pages.miningPresets.selectPool') }
        presetError = true
      } else {
        if (preset.poolAddress.length === 0 && preset.userPool.length === 0) {
          preset.errors.pool = {
            message: t('pages.miningPresets.selectPoolServerError'),
          }
          presetError = true
        }
      }

      if (preset.miner && preset.miner.minerValues) {
        preset.miner.minerValues.errors = {}
        if (preset.miner.minerValues.dualMode) {
          if (preset.coinDual.length === 0) {
            preset.errors.coinDual = {
              message: t('pages.miningPresets.selectCoin'),
            }
            presetError = true
          }
          if (preset.walletDual.length === 0) {
            preset.errors.walletDual = {
              message: t('pages.miningPresets.selectWallet'),
            }
            presetError = true
          }
          if (preset.poolDual === 0) {
            preset.errors.poolDual = {
              message: t('pages.miningPresets.selectPool'),
            }
            presetError = true
          } else {
            if (preset.poolAddressDual.length === 0 && preset.userPoolDual.length === 0) {
              preset.errors.poolDual = {
                message: t('pages.miningPresets.selectPoolServer'),
              }
              presetError = true
            }
          }
          /*if (preset.userPoolDual&&preset.userPoolDual.length>0){
                    const regex=/(.*)(:?)(\d*)$/
                    preset.userPoolDual.split('\n').map(function(url) {
                      if (!regex.test(url)){
                        preset.errors.poolDual = { message: 'Invalid Server Url' }
                        presetError = true
                      }
                    })
                  }*/
        }

        if (
          preset.miner.minerVersions &&
          preset.miner.minerVersions.length > 0 &&
          (!preset.miner.minerValues.version ||
            (preset.miner.minerValues.version && preset.miner.minerValues.version.length < 1))
        ) {
          preset.miner.minerValues.errors.version = {
            message: t('pages.miningPresets.selectMinerVersion'),
          }
          preset.errors.miner = {
            message: t('pages.miningPresets.configureMiner'),
          }
          presetError = true
        }
        if (
          preset.miner.minerParams &&
          preset.miner.minerParams.find(param => param.paramName === 'fork') &&
          (!preset.miner.minerValues.fork ||
            (preset.miner.minerValues.fork && preset.miner.minerValues.fork.length < 1))
        ) {
          preset.miner.minerValues.errors.fork = {
            message: t('pages.miningPresets.selectMinerFork'),
          }
          preset.errors.miner = {
            message: t('pages.miningPresets.configureMiner'),
          }
          presetError = true
        }
        if (
          preset.miner.minerParams &&
          preset.miner.minerParams.find(param => param.paramName === 'protocol') &&
          (!preset.miner.minerValues.protocol ||
            (preset.miner.minerValues.protocol && preset.miner.minerValues.protocol.length < 1))
        ) {
          preset.miner.minerValues.errors.protocol = {
            message: t('pages.miningPresets.selectMinerProtocol'),
          }
          preset.errors.miner = {
            message: t('pages.miningPresets.configureMiner'),
          }
          presetError = true
        }

        if (
          !preset.miner.minerValues.algo ||
          (preset.miner.minerValues.algo && preset.miner.minerValues.algo.length < 1)
        ) {
          preset.miner.minerValues.errors.algo = {
            message: t('pages.miningPresets.selectMinerAlgo'),
          }
          preset.errors.miner = { message: t('pages.miningPresets.configureMiner') }
          presetError = true
        }
        if (
          preset.miner.minerValues.dualMode &&
          (!preset.miner.minerValues.dualAlgo ||
            (preset.miner.minerValues.dualAlgo && preset.miner.minerValues.dualAlgo.length < 1))
        ) {
          preset.miner.minerValues.errors.dualAlgo = {
            message: t('pages.miningPresets.selectMinerDualAlgo'),
          }
          preset.errors.miner = { message: t('pages.miningPresets.configureMiner') }
          presetError = true
        }
        if (
          !preset.miner.minerValues.worker ||
          (preset.miner.minerValues.worker && preset.miner.minerValues.worker.length < 1)
        ) {
          preset.miner.minerValues.errors.worker = {
            message: t('pages.miningPresets.selectWorkerParameter'),
          }
          preset.errors.miner = {
            message: t('pages.miningPresets.configureMiner'),
          }
          presetError = true
        }

        if (
          preset.miner.minerValues.logs &&
          (!preset.miner.minerValues.logsStorage ||
            (preset.miner.minerValues.logsStorage && preset.miner.minerValues.logsStorage < 1))
        ) {
          preset.miner.minerValues.errors.logsStorage = {
            message: t('pages.miningPresets.selectMinerLogsStorage'),
          }
          preset.errors.miner = {
            message: t('pages.miningPresets.configureMiner'),
          }
          presetError = true
        }

        if (
          preset.miner.minerValues.rotateLogs &&
          (!preset.miner.minerValues.logsDays ||
            (preset.miner.minerValues.logsDays && preset.miner.minerValues.logsDays < 1))
        ) {
          preset.miner.minerValues.errors.logsDays = {
            message: t('pages.miningPresets.enterDays'),
          }
          preset.errors.miner = {
            message: t('pages.miningPresets.configureMiner'),
          }
          presetError = true
        }
      }
    })
    if ((presetNameError && presetNameError.message) || presetError) return
    const updatePreset = {
      name: miningPresetName.name,
      id: miningPresetName.id,
      flightSheets: [],
    }
    presets.forEach(function callback(preset) {
      const servers = []
      const serversDual = []
      if (preset.pool.type !== 'SP') {
        preset.poolAddress.forEach(function callback(item, i) {
          const port = preset.sslPort && preset.ssl ? preset.sslPort : preset.stratumPort
          if (port && port.length > 0) {
            servers[i] = item + ':' + port
          } else {
            servers[i] = item
          }
        })
      } else {
        preset.poolAddress.forEach(function callback(item, i) {
          servers[i] = item
        })
      }
      if (preset.miner.minerValues.dualMode) {
        if (preset.poolDual.type !== 'SP') {
          preset.poolAddressDual.forEach(function callback(item, i) {
            const port = preset.sslPortDual && preset.sslDual ? preset.sslPortDual : preset.stratumPortDual
            if (port && port.length > 0) {
              serversDual[i] = item + ':' + port
            } else {
              serversDual[i] = item
            }
          })
        } else {
          preset.poolAddressDual.forEach(function callback(item, i) {
            serversDual[i] = item
          })
        }
      }
      let minerParams = {}

      minerParams = {
        logs: !!preset.miner.minerValues.logs,
        debug: !!preset.miner.minerValues.debug,
        rotateLogs: !!preset.miner.minerValues.rotateLogs,
        watchdog: !!preset.miner.minerValues.watchdog,
        dualMode: !!preset.miner.minerValues.dualMode,
        worker: preset.miner.minerValues.worker,
        password: preset.miner.minerValues.password ? preset.miner.minerValues.password : '',
        startMiningIn: preset.miner.minerValues.startMiningIn ? +preset.miner.minerValues.startMiningIn : 0,
        algo: preset.miner.minerValues.algo && preset.miner.minerValues.algo.value,
        dualAlgo: preset.miner.minerValues.dualAlgo && preset.miner.minerValues.dualAlgo.value,
        miner_params_add: preset.miner.minerValues.miner_params_add ? preset.miner.minerValues.miner_params_add : null,
        version: preset.miner.minerValues.version.value,
      }
      if (preset.miner.minerValues.logs) {
        minerParams.logsStorage = preset.miner.minerValues.logsStorage.value
      } else {
        minerParams.logsStorage = null
      }
      if (preset.miner.minerValues.rotateLogs) {
        minerParams.logsDays = preset.miner.minerValues.logsDays
      } else {
        minerParams.logsDays = null
      }
      if (preset.miner.minerParams.find(param => param.paramName === 'fork')) {
        minerParams.fork = preset.miner.minerValues.fork.value
      } else {
        minerParams.fork = null
      }
      if (preset.miner.minerParams.find(param => param.paramName === 'protocol')) {
        minerParams.protocol = preset.miner.minerValues.protocol.value
      } else {
        minerParams.protocol = null
      }

      slot ? (updatePreset.slotId = slot.value) : (updatePreset.slotId = null)
      updatePreset.flightSheets.push(
        preset.miner.minerValues.dualMode
          ? {
              id: preset.id,
              wallet: preset.wallet.address,
              coin: preset.coin.nameSymbol,
              pool: servers.toString(),
              miner: preset.miner.name,
              number: preset.order,
              minerParams: minerParams,
              walletId: preset.wallet.id,
              coinId: preset.coin.id,
              poolId: preset.pool.id,
              minerId: preset.miner.id,
              poolSsl: preset.ssl || preset.pplns,
              serverIds: preset.poolAddress.toString(),
              userPool: preset.userPool && preset.userPool.split('\n').join(','),
              dualUserPool: preset.userPoolDual && preset.userPoolDual.split('\n').join(','),

              dualWalletId: preset.walletDual.id,
              dualCoinId: preset.coinDual.id,
              dualPoolId: preset.poolDual.id,
              dualPoolSsl: preset.sslDual || preset.pplnsDual,
              dualServerIds: preset.poolAddressDual.toString(),
              dualWallet: preset.walletDual.address,
              dualCoin: preset.coinDual.nameSymbol,
              dualPool: serversDual.toString(),
            }
          : {
              id: preset.id,
              wallet: preset.wallet.address,
              coin: preset.coin.nameSymbol,
              pool: servers.toString(),
              miner: preset.miner.name,
              number: preset.order,
              minerParams: minerParams,
              walletId: preset.wallet.id,
              coinId: preset.coin.id,
              poolId: preset.pool.id,
              minerId: preset.miner.id,
              poolSsl: preset.ssl || preset.pplns,
              serverIds: preset.poolAddress.toString(),
              userPool: preset.userPool && preset.userPool.split('\n').join(','),
            },
      )
    })

    const result = await updateMiningPreset(updatePreset, updateRigsStr)
    if (result) {
      props.toggle()
    }
  }

  const OffSymbol2 = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 10,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        {t('pages.miningPresets.no')}
      </div>
    )
  }

  const OnSymbol2 = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 10,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        {t('pages.miningPresets.yes')}
      </div>
    )
  }

  const changeMinerField = (event, inputString, inputEvent) => {
    let name = ''
    if (event && event.target) {
      name = event.target.name
    }
    if (inputString && inputString.name) {
      name = inputString.name
    }
    if (inputEvent && inputEvent.name) {
      name = inputEvent.name
    }
    if (name === 'version') {
      currentPreset.miner.minerValues.version = event
      currentPreset.miner.minerValues.dualMode = false
      delete currentPreset.miner.minerValues.errors.version
      if (currentPreset.coin.value !== 'Other')
        currentPreset.miner.minerValues.algo = props.algoCoinsList
          .filter(
            algo =>
              algo.coins.split(', ').find(coin => coin === currentPreset.coin.value) &&
              currentPreset.miner.minerValues.version &&
              currentPreset.miner.minerVersions.filter(
                version =>
                  currentPreset.miner.minerValues.version.value === version.version &&
                  version.params.filter(param => param.algo.toLowerCase() === algo.algo.toLowerCase()).length > 0,
              ).length > 0,
          )
          .map(algo => {
            return {
              ...algo,
              ...algo,
              label: algo.algo,
              value: algo.algo,
            }
          })[0]
    }
    if (name === 'fork') {
      currentPreset.miner.minerValues.fork = event
      delete currentPreset.miner.minerValues.errors.fork
    }
    if (name === 'logsStorage') {
      currentPreset.miner.minerValues.logsStorage = event
      delete currentPreset.miner.minerValues.errors.logsStorage
    }
    if (name === 'protocol') {
      currentPreset.miner.minerValues.protocol = event
      delete currentPreset.miner.minerValues.errors.protocol
    }
    if (name === 'worker') {
      currentPreset.miner.minerValues.worker = event.target.value
      delete currentPreset.miner.minerValues.errors.worker
    }
    if (name === 'password') {
      currentPreset.miner.minerValues.password = event.target.value
      delete currentPreset.miner.minerValues.errors.password
    }

    if (name === 'logs') {
      currentPreset.miner.minerValues.logs = event.target.checked
    }
    if (name === 'debug') {
      currentPreset.miner.minerValues.debug = event.target.checked
    }
    if (name === 'rotateLogs') {
      currentPreset.miner.minerValues.rotateLogs = event.target.checked
    }
    if (name === 'watchdog') {
      currentPreset.miner.minerValues.watchdog = event.target.checked
    }
    if (name === 'dualMode') {
      currentPreset.miner.minerValues.dualMode = event.target.checked
      currentPreset.coinDual = ''
      currentPreset.walletDual = ''
      currentPreset.poolDual = ''
      currentPreset.sslDual = false
      currentPreset.pplnsDual = false
      currentPreset.poolAddressDual = []
      currentPreset.userPoolDual = ''
    }
    if (name === 'logsDays') {
      currentPreset.miner.minerValues.logsDays = inputEvent.value
      delete currentPreset.miner.minerValues.errors.logsDays
    }
    if (name === 'startMiningIn') {
      currentPreset.miner.minerValues.startMiningIn = +inputEvent.value
      delete currentPreset.miner.minerValues.errors.startMiningIn
    }
    if (name === 'algo') {
      if ((event && slot && slot.algo !== event.value) || !event) setSlot(null)
      currentPreset.miner.minerValues.algo = event
      delete currentPreset.miner.minerValues.errors.algo
    }
    if (name === 'dualAlgo') {
      currentPreset.miner.minerValues.dualAlgo = event
      delete currentPreset.miner.minerValues.errors.dualAlgo
    }

    if (name === 'miner_params_add') currentPreset.miner.minerValues.miner_params_add = event.target.value
    delete currentPreset.errors.miner
    setUpdateState(!updateState)
  }

  const onChangeDoubleCoin = coin => {
    delete currentPreset.miner.minerValues.errors.dualAlgo
    currentPreset.miner.minerValues.dualAlgo = props.algoCoinsList
      .filter(
        algo =>
          algo.coins.split(', ').find(fCoin => fCoin === coin) &&
          currentPreset.miner.minerValues.version &&
          currentPreset.miner.minerVersions.filter(
            version =>
              currentPreset.miner.minerValues.version.value === version.version &&
              version.params.filter(param => param.algo.toLowerCase() === algo.algo.toLowerCase()).length > 0,
          ).length > 0,
      )
      .map(algo => {
        return {
          ...algo,
          label: algo.algo,
          value: algo.algo,
        }
      })[0]
  }
  const [slotError, setSlotError] = useState(false)
  const slotOption = props => {
    return (
      <components.Option {...props}>
        {props.data.label}
        {props.data.comment && <div className={'slot_description'}>{props.data.comment}</div>}
      </components.Option>
    )
  }

  return (
    <div>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertRestartRigs}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          updateMiningPresetFunc({ updateRigsStr: true })
        }}
        onCancel={() => {
          updateMiningPresetFunc({ updateRigsStr: false })
          setShowAlertRestartRigs(false)
        }}
        confirmBtnText={t('sweetAlerts.yes')}
        cancelBtnText={t('sweetAlerts.no')}
        showCloseButton={true}
      >
        {t('pages.miningPresets.updateRigsModal')}
      </SweetAlert>
      <Modal isOpen={props.show} className={'modal-dialog-centered popup popup_large '}>
        <ModalHeader tag={'h4'}>{t('pages.miningPresets.editMiningPreset')}</ModalHeader>
        <ModalBody>
          <Button color='primary' className={'mining_preset__create_miner'} onClick={setPreset}>
            {t('pages.miningPresets.addMiner')}
          </Button>
          <Row className={'mining_preset__row'}>
            <Col md={6} xs={8} className={'form-group'}>
              <Label>{t('pages.miningPresets.name')}</Label>
              <Input
                className={presetNameError && presetNameError.message ? 'invalid' : ''}
                type={'text'}
                name={'preset_name'}
                onChange={changeName}
                value={miningPresetName.name}
              />
              <div className={'error'}>{presetNameError && presetNameError.message}</div>
            </Col>
            <Col md={4} xs={12} className={'form-group'}>
              <Label>{t('pages.miningPresets.overclockingProfile')}</Label>
              <Select
                components={{ Option: slotOption }}
                isClearable={true}
                hideSelectedOptions
                placeholder={t('inputs.select')}
                classNamePrefix={'form_control_react_select'}
                onChange={option => {
                  setSlotError(false)
                  if (
                    !(
                      currentPreset &&
                      currentPreset.miner &&
                      currentPreset.miner.minerValues &&
                      currentPreset.miner.minerValues.algo
                    )
                  ) {
                    setSlotError(true)
                    return
                  }
                  setSlot(option)
                }}
                value={slot}
                options={slotsList
                  .filter(slot => {
                    const algo = currentPreset?.miner?.minerValues?.algo?.value
                    if (!algo) return true
                    return slot.algo === algo
                  })
                  .map(slot => {
                    let coins = slot.coin ? slot.coin : ''
                    if (slot.dualCoin) coins += '+' + slot.dualCoin
                    if (coins.length > 0) coins = ' (' + coins + ')'
                    return {
                      ...slot,
                      label: slot.name + coins,
                      value: slot.id,
                    }
                  })}
              />
              {slotError && <div className={'error'}>{t('pages.miningPresets.slotError')}</div>}
            </Col>
          </Row>
          <div className={'miningPresetsRows'}>
            {presets.map((preset, key) => {
              return (
                <MiningPresetRow
                  key={key}
                  preset={preset}
                  walletsList={walletsList}
                  coinsList={coinsList}
                  poolsList={poolsList}
                  minersList={minersList}
                  algoCoinsList={props.algoCoinsList}
                  setCreateWallet={setCreateWallet}
                  setConfigurePool={setConfigurePool}
                  setConfigureMiner={setConfigureMiner}
                  removePreset={removePreset}
                  showDelete={showDelete}
                  setCurrentPreset={setCurrentPresetFunc}
                  //setSwitchSSL={setSwitchSSL}
                  //setSwitchSSLDual={setSwitchSSLDual}
                  edit={miningPresetName.id}
                  setConfigurePoolDual={setConfigurePoolDual}
                  onChangeDoubleCoin={onChangeDoubleCoin}
                />
              )
            })}
          </div>
          <Row className={'mining_preset__row'} key={123132}>
            <Col xs={12}>
              <div className={'mining_preset__settings_area'}>
                <div className={create_wallet ? 'mining_preset__add_wallet active' : 'mining_preset__add_wallet'}>
                  <Row>
                    <Col xs={6} className={'form-group'}>
                      <Label>{t('pages.miningPresets.name')}</Label>
                      <Input
                        className={errors.wallet_name ? 'invalid' : ''}
                        type={'text'}
                        name={'wallet_name'}
                        onChange={walletValuesFunc}
                      />
                      <div className={'error'}>{errors.wallet_name && errors.wallet_name.message}</div>
                    </Col>
                    <Col xs={6} className={'form-group'}>
                      <Label>{t('pages.miningPresets.address')}</Label>
                      <Input
                        className={errors.wallet_address ? 'invalid' : ''}
                        type={'text'}
                        name={'wallet_address'}
                        onChange={walletValuesFunc}
                      />
                      <div className={'error'}>{errors.wallet_address && errors.wallet_address.message}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={'form-group'}>
                      <Label>{t('pages.miningPresets.coin')}</Label>
                      <SelectWithImages
                        name={'wallet_coin'}
                        placeholder={t('inputs.selectCoin')}
                        value={newWalletCoin}
                        onchange={walletValuesFuncSelect}
                        error={errors.wallet_coin}
                        options={coinsList.map(coin => {
                          return {
                            ...coin,
                            icon: coin.nameSymbol,
                            label: coin.nameSymbol,
                            value: coin.nameSymbol,
                          }
                        })}
                        hideSelectedOptions={true}
                      />
                      <div className={'error'}>{errors.wallet_coin && errors.wallet_coin.message}</div>
                    </Col>
                    <Col xs={6} className={'form-group'}>
                      <Label>{t('pages.miningPresets.source')}</Label>
                      <Input
                        className={errors.wallet_source ? 'invalid' : ''}
                        type={'text'}
                        name={'wallet_source'}
                        onChange={walletValuesFunc}
                      />
                      <div className={'error'}>{errors.wallet_source && errors.wallet_source.message}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Button color='secondary' onClick={hideCreateWallet}>
                        {t('pages.miningPresets.cancel')}
                      </Button>
                      <Button color='primary' onClick={createWalletFunc}>
                        {t('pages.miningPresets.create')}
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div
                  key={currentPreset && currentPreset.pool}
                  className={configure_pool ? 'mining_preset__configure_pool active' : 'mining_preset__configure_pool'}
                >
                  <Row>
                    <Col xs={12} className={'form-group'}>
                      {currentPreset && currentPreset.pool && currentPreset.pool.sslPort ? (
                        <Row>
                          <Col xs={12} className={'form-group switch-group'}>
                            <Switch
                              uncheckedIcon={<OffSymbol2 />}
                              checkedIcon={<OnSymbol2 />}
                              onColor='#02a499'
                              onChange={toggleSwitchSSL}
                              checked={currentPreset.ssl}
                              height={20}
                              width={40}
                            />
                            <span>{t('pages.miningPresets.SSLUrls')}</span>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      {currentPreset &&
                      currentPreset.pool &&
                      currentPreset.pool.type &&
                      currentPreset.pool.type === 'SP' ? (
                        <Row>
                          <Col xs={12} className={'form-group switch-group'}>
                            <div
                              style={{
                                marginRight: '4px',
                                color: currentPreset.pplns ? '#9ca8b3' : 'white',
                              }}
                            >
                              solo
                            </div>{' '}
                            <Switch
                              uncheckedIcon={null}
                              checkedIcon={null}
                              onColor='#02a499'
                              onChange={toggleSwitchPPLNS}
                              checked={currentPreset.pplns}
                              height={20}
                              width={40}
                            />
                            <div
                              style={{
                                marginLeft: '4px',
                                color: currentPreset.pplns ? 'white' : '#9ca8b3',
                              }}
                            >
                              pplns
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      <h6>{t('pages.miningPresets.selectPoolServer')}</h6>
                      <div className={'poolServersList'}>
                        {currentPreset && currentPreset.pool && currentPreset.pool.poolServers
                          ? currentPreset.pool.poolServers
                              .filter(
                                server =>
                                  (currentPreset.pool.type !== 'SP' ||
                                    (server.type === 'solo' &&
                                      currentPreset.pplns === false &&
                                      (server.coin === currentPreset.coin.value ||
                                        currentPreset.coin.value === 'Other')) ||
                                    (server.type === 'pplns' && currentPreset.pplns === true)) &&
                                  (server.coin === currentPreset.coin.value ||
                                    !server.coin ||
                                    currentPreset.coin.value === 'Other'),
                              )
                              .map(server => {
                                return (
                                  <Row key={uuid()}>
                                    <Col xs={12} className={'form-group switch-group'}>
                                      <SwitchServer
                                        address={server.address}
                                        setAddress={setAddress}
                                        toggle={currentPreset.poolAddress.indexOf(server.address.toString()) !== -1}
                                      />
                                      <span
                                        style={{
                                          color:
                                            currentPreset.poolAddress.indexOf(server.address.toString()) !== -1
                                              ? 'white'
                                              : '#9ca8b3',
                                        }}
                                      >
                                        <span>{server.country}</span>
                                        {server.address}
                                        {currentPreset.pool.sslPort ||
                                        currentPreset.pool.stratumPort ||
                                        currentPreset.pool.altStratumPort
                                          ? currentPreset.pool.sslPort && currentPreset.ssl
                                            ? `:${currentPreset.pool.sslPort}`
                                            : `:${currentPreset.pool.stratumPort}`
                                          : ''}
                                      </span>
                                    </Col>
                                  </Row>
                                )
                              })
                          : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className={'form-group'}>
                      <h6>{t('pages.miningPresets.advancedSettings')}</h6>
                      <Label>{t('pages.miningPresets.enterUrls')}</Label>
                      <textarea
                        className={'form-control'}
                        name='pool_urls'
                        value={currentPreset.userPool}
                        onChange={event => {
                          currentPreset.userPool = event.target.value
                          delete currentPreset.errors.pool
                          setUpdateState(!updateState)
                        }}
                      ></textarea>
                    </Col>
                  </Row>
                </div>

                <div
                  key={currentPreset && currentPreset.poolDual + '2'}
                  className={
                    configure_pool_dual ? 'mining_preset__configure_pool active' : 'mining_preset__configure_pool'
                  }
                >
                  <Row>
                    <Col xs={12} className={'form-group'}>
                      {currentPreset && currentPreset.poolDual && currentPreset.poolDual.sslPort ? (
                        <Row>
                          <Col xs={12} className={'form-group switch-group'}>
                            <Switch
                              uncheckedIcon={<OffSymbol2 />}
                              checkedIcon={<OnSymbol2 />}
                              onColor='#02a499'
                              onChange={toggleSwitchSSLDual}
                              checked={currentPreset.sslDual}
                              height={20}
                              width={40}
                            />
                            <span>{t('pages.miningPresets.SSLUrls')}</span>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      {currentPreset &&
                      currentPreset.poolDual &&
                      currentPreset.poolDual.type &&
                      currentPreset.poolDual.type === 'SP' ? (
                        <Row>
                          <Col xs={12} className={'form-group switch-group'}>
                            <div
                              style={{
                                marginRight: '4px',
                                color: currentPreset.pplnsDual ? '#9ca8b3' : 'white',
                              }}
                            >
                              solo
                            </div>{' '}
                            <Switch
                              uncheckedIcon={null}
                              checkedIcon={null}
                              onColor='#02a499'
                              onChange={toggleSwitchPPLNSDual}
                              checked={currentPreset.pplnsDual}
                              height={20}
                              width={40}
                            />
                            <div
                              style={{
                                marginLeft: '4px',
                                color: currentPreset.pplnsDual ? 'white' : '#9ca8b3',
                              }}
                            >
                              pplns
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      <h6>{t('pages.miningPresets.selectPoolServer')}</h6>
                      <div className={'poolServersList'}>
                        {currentPreset && currentPreset.poolDual && currentPreset.poolDual.poolServers
                          ? currentPreset.poolDual.poolServers
                              .filter(
                                server =>
                                  (currentPreset.poolDual.type !== 'SP' ||
                                    (server.type === 'solo' &&
                                      currentPreset.pplnsDual === false &&
                                      server.coin === currentPreset.coinDual.value) ||
                                    (server.type === 'pplns' && currentPreset.pplnsDual === true)) &&
                                  (server.coin === currentPreset.coinDual.value || !server.coin),
                              )
                              .map(server => {
                                return (
                                  <Row key={currentPreset.order + server.address + currentPreset.coinDual.value}>
                                    <Col xs={12} className={'form-group switch-group'}>
                                      <SwitchServer
                                        address={server.address}
                                        setAddress={setAddressDual}
                                        toggle={currentPreset.poolAddressDual.indexOf(server.address.toString()) !== -1}
                                      />
                                      <span
                                        style={{
                                          color:
                                            currentPreset.poolAddressDual.indexOf(server.address.toString()) !== -1
                                              ? 'white'
                                              : '#9ca8b3',
                                        }}
                                      >
                                        <span>{server.country}</span>
                                        {server.address}
                                        {currentPreset.poolDual.sslPort ||
                                        currentPreset.poolDual.stratumPort ||
                                        currentPreset.poolDual.altStratumPort
                                          ? currentPreset.poolDual.sslPort && currentPreset.sslDual
                                            ? `:${currentPreset.poolDual.sslPort}`
                                            : `:${currentPreset.poolDual.stratumPort}`
                                          : ''}
                                      </span>
                                    </Col>
                                  </Row>
                                )
                              })
                          : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className={'form-group'}>
                      <h6>{t('pages.miningPresets.advancedSettings')}</h6>
                      <Label>{t('pages.miningPresets.enterUrls')}</Label>
                      <textarea
                        className={'form-control'}
                        name='pool_urls'
                        value={currentPreset.userPoolDual}
                        onChange={event => {
                          currentPreset.userPoolDual = event.target.value
                          delete currentPreset.errors.poolDual
                          setUpdateState(!updateState)
                        }}
                      ></textarea>
                    </Col>
                  </Row>
                </div>

                <div
                  className={
                    configure_miner ? 'mining_preset__configure_miner active' : 'mining_preset__configure_miner'
                  }
                >
                  {currentPreset && currentPreset.miner && currentPreset.miner.minerParams ? (
                    <div style={{ height: '100%' }}>
                      <div>
                        <Row>
                          {currentPreset.miner.minerVersions &&
                          currentPreset.miner.minerVersions.length > 0 &&
                          currentPreset.miner.minerValues &&
                          currentPreset.miner.minerValues.version &&
                          currentPreset.miner.minerVersions.filter(version => {
                            return (
                              currentPreset.miner.minerValues.version.value === version.version &&
                              version.dualCoins?.length > 0
                            )
                          }).length > 0 ? (
                            <div
                              className={'miner_dual_mode'}
                              style={{
                                display: configure_miner ? 'block' : 'none',
                              }}
                            >
                              <Label className={'checkboxLabel'}>
                                <input
                                  name={'dualMode'}
                                  type={'checkbox'}
                                  checked={currentPreset.miner.minerValues.dualMode}
                                  onChange={changeMinerField}
                                />
                                {t('pages.miningPresets.dualMode')}
                              </Label>
                            </div>
                          ) : null}
                          {currentPreset.miner.minerVersions && currentPreset.miner.minerVersions.length > 0 ? (
                            <Col
                              md={
                                currentPreset.miner.minerParams.filter(
                                  param => param.paramName === 'protocol' || param.paramName === 'fork',
                                ).length === 2
                                  ? 2
                                  : 3
                              }
                              xs={6}
                              className={'form-group'}
                              key={currentPreset.order + currentPreset.miner.name + 'version'}
                            >
                              <Label>{t('pages.miningPresets.version')}</Label>
                              <Select
                                maxMenuHeight={200}
                                name={'version'}
                                placeholder={t('inputs.select')}
                                hideSelectedOptions
                                classNamePrefix={'form_control_react_select'}
                                className={
                                  currentPreset.miner.minerValues.errors.version &&
                                  currentPreset.miner.minerValues.errors.version.message
                                    ? 'invalid-select special_select'
                                    : 'special_select'
                                }
                                onChange={changeMinerField}
                                value={currentPreset.miner.minerValues.version}
                                options={currentPreset.miner.minerVersions
                                  .filter(
                                    version =>
                                      version.params.filter(
                                        param =>
                                          props.algoCoinsList.filter(algo => {
                                            return (
                                              algo.coins.split(', ').find(coin => coin === currentPreset.coin?.value) &&
                                              algo.algo === param.algo
                                            )
                                          }).length > 0,
                                      ).length > 0,
                                  )
                                  .map((version, i) => {
                                    if (i === 0)
                                      return {
                                        label: version.version + ' (Latest)',
                                        value: version.version,
                                      }
                                    return {
                                      label: version.version,
                                      value: version.version,
                                    }
                                  })}
                              />
                              <div className={'error'}>
                                {currentPreset.miner.minerValues.errors.version &&
                                  currentPreset.miner.minerValues.errors.version.message}
                              </div>
                            </Col>
                          ) : null}
                          {currentPreset.miner.minerParams.find(param => param.paramName === 'fork') ? (
                            <Col
                              md={
                                currentPreset.miner.minerParams.filter(
                                  param => param.paramName === 'protocol' || param.paramName === 'fork',
                                ).length === 2
                                  ? 2
                                  : 3
                              }
                              xs={6}
                              className={'form-group'}
                              key={currentPreset.order + currentPreset.miner.name + 'fork'}
                            >
                              <Label>{t('pages.miningPresets.fork')}</Label>
                              <Select
                                maxMenuHeight={200}
                                placeholder={t('inputs.select')}
                                name={'fork'}
                                hideSelectedOptions
                                classNamePrefix={'form_control_react_select'}
                                className={
                                  currentPreset.miner.minerValues.errors.fork &&
                                  currentPreset.miner.minerValues.errors.fork.message
                                    ? 'invalid-select special_select'
                                    : 'special_select'
                                }
                                onChange={changeMinerField}
                                value={currentPreset.miner.minerValues.fork}
                                options={currentPreset.miner.minerParams
                                  .filter(param => param.paramName === 'fork')[0]
                                  .consts.map(constVal => {
                                    return {
                                      label: constVal.paramLabel,
                                      value: constVal.paramValue,
                                    }
                                  })}
                              />
                              <div className={'error'}>
                                {currentPreset.miner.minerValues.errors.fork &&
                                  currentPreset.miner.minerValues.errors.fork.message}
                              </div>
                            </Col>
                          ) : null}

                          <Col
                            md={
                              currentPreset.miner.minerParams.filter(
                                param => param.paramName === 'protocol' || param.paramName === 'fork',
                              ).length === 2
                                ? 2
                                : 3
                            }
                            xs={6}
                            className={'form-group'}
                            key={currentPreset.order + currentPreset.miner.name + 'algo'}
                          >
                            <Label>{t('pages.miningPresets.algorithm')}</Label>
                            <SelectWithCryptoImages
                              name={'algo'}
                              placeholder={t('inputs.selectAlgo')}
                              hideSelectedOptions
                              error={currentPreset.miner.minerValues.errors.algo}
                              onchange={changeMinerField}
                              value={currentPreset.miner.minerValues.algo}
                              options={props.algoCoinsList
                                .filter(
                                  algo =>
                                    algo.coins.split(', ').find(coin => coin === currentPreset.coin.value) &&
                                    currentPreset.miner.minerValues.version &&
                                    currentPreset.miner.minerVersions.filter(
                                      version =>
                                        currentPreset.miner.minerValues.version.value === version.version &&
                                        version.params.filter(
                                          param => param.algo.toLowerCase() === algo.algo.toLowerCase(),
                                        ).length > 0,
                                    ).length > 0,
                                )
                                .map(algo => {
                                  return {
                                    ...algo,
                                    label: algo.algo,
                                    value: algo.algo,
                                  }
                                })}
                            />
                            <div className={'error'}>
                              {currentPreset.miner.minerValues.errors.algo &&
                                currentPreset.miner.minerValues.errors.algo.message}
                            </div>
                          </Col>
                          {currentPreset.miner.minerValues.dualMode ? (
                            <Col
                              md={
                                currentPreset.miner.minerParams.filter(
                                  param => param.paramName === 'protocol' || param.paramName === 'fork',
                                ).length === 2
                                  ? 2
                                  : 3
                              }
                              xs={6}
                              className={'form-group'}
                              key={currentPreset.order + currentPreset.miner.name + 'dualAlgo'}
                            >
                              <Label>{t('pages.miningPresets.dualAlgorithm')}</Label>
                              <SelectWithCryptoImages
                                name={'dualAlgo'}
                                placeholder={t('inputs.selectAlgo')}
                                hideSelectedOptions
                                error={currentPreset.miner.minerValues.errors.dualAlgo}
                                onchange={changeMinerField}
                                value={currentPreset.miner.minerValues.dualAlgo}
                                options={
                                  currentPreset.coinDual &&
                                  props.algoCoinsList
                                    .filter(algo => {
                                      return (
                                        algo.coins.split(', ').find(coin => coin === currentPreset.coinDual.value) &&
                                        currentPreset.miner.minerValues.version &&
                                        currentPreset.miner.minerVersions.filter(
                                          version =>
                                            currentPreset.miner.minerValues.version.value === version.version &&
                                            version.params.filter(
                                              param => param.algo.toLowerCase() === algo.algo.toLowerCase(),
                                            ).length > 0,
                                        ).length > 0
                                      )
                                    })
                                    .map(algo => {
                                      return {
                                        ...algo,
                                        label: algo.algo,
                                        value: algo.algo,
                                      }
                                    })
                                }
                              />
                              <div className={'error'}>
                                {currentPreset.miner.minerValues.errors.dualAlgo &&
                                  currentPreset.miner.minerValues.errors.dualAlgo.message}
                              </div>
                            </Col>
                          ) : null}

                          {currentPreset.miner.minerParams.find(param => param.paramName === 'protocol') ? (
                            <Col
                              md={
                                currentPreset.miner.minerParams.filter(
                                  param => param.paramName === 'protocol' || param.paramName === 'fork',
                                ).length === 2
                                  ? 2
                                  : 3
                              }
                              xs={6}
                              className={'form-group'}
                              key={currentPreset.order + currentPreset.miner.name + 'protocol'}
                            >
                              <Label>{t('pages.miningPresets.protocol')}</Label>
                              <Select
                                maxMenuHeight={200}
                                placeholder={t('inputs.select')}
                                name={'protocol'}
                                hideSelectedOptions
                                classNamePrefix={'form_control_react_select'}
                                className={
                                  currentPreset.miner.minerValues.errors.protocol &&
                                  currentPreset.miner.minerValues.errors.protocol.message
                                    ? 'invalid-select special_select'
                                    : 'special_select'
                                }
                                onChange={changeMinerField}
                                value={currentPreset.miner.minerValues.protocol}
                                options={currentPreset.miner.minerParams
                                  .filter(param => param.paramName === 'protocol')[0]
                                  .consts.map(constVal => {
                                    return {
                                      label: constVal.paramLabel,
                                      value: constVal.paramValue,
                                    }
                                  })}
                              />
                              <div className={'error'}>
                                {currentPreset.miner.minerValues.errors.protocol &&
                                  currentPreset.miner.minerValues.errors.protocol.message}
                              </div>
                            </Col>
                          ) : null}
                          <Col
                            md={
                              currentPreset.miner.minerParams.filter(
                                param => param.paramName === 'protocol' || param.paramName === 'fork',
                              ).length === 2
                                ? 2
                                : 3
                            }
                            xs={4}
                            className={'form-group'}
                            key={currentPreset.order + currentPreset.miner.name + 'password'}
                          >
                            <Label>{t('pages.miningPresets.password')}</Label>
                            <Input
                              className={'form-control'}
                              name={'password'}
                              onChange={changeMinerField}
                              value={currentPreset.miner.minerValues.password}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={2}
                            xs={4}
                            key={currentPreset.order + currentPreset.miner.name + 'logs'}
                            className={'form-group align-center'}
                          >
                            <div>
                              <Label className={'checkboxLabel'}>
                                <input
                                  name={'logs'}
                                  type={'checkbox'}
                                  checked={currentPreset.miner.minerValues.logs}
                                  onChange={changeMinerField}
                                />
                                {t('pages.miningPresets.enableLogs')}
                              </Label>
                              <Label className={'checkboxLabel'}>
                                <input
                                  name={'debug'}
                                  type={'checkbox'}
                                  checked={currentPreset.miner.minerValues.debug}
                                  onChange={changeMinerField}
                                />
                                {t('pages.miningPresets.debug')}
                              </Label>
                            </div>
                          </Col>
                          <Col
                            md={3}
                            xs={6}
                            className={'form-group'}
                            key={currentPreset.order + currentPreset.miner.name + 'logsStorage'}
                          >
                            <Label>{t('pages.miningPresets.logsStorage')}</Label>
                            <Select
                              placeholder={t('inputs.select')}
                              maxMenuHeight={200}
                              name={'logsStorage'}
                              hideSelectedOptions
                              classNamePrefix={'form_control_react_select'}
                              className={
                                currentPreset.miner.minerValues.errors.logsStorage &&
                                currentPreset.miner.minerValues.errors.logsStorage.message
                                  ? 'invalid-select special_select'
                                  : 'special_select'
                              }
                              onChange={changeMinerField}
                              value={currentPreset.miner.minerValues.logsStorage}
                              options={[
                                {
                                  label: t('pages.miningPresets.SaveOnServer'),
                                  value: 'server',
                                },
                                {
                                  label: t('pages.miningPresets.saveLocally'),
                                  value: 'local',
                                },
                              ]}
                            />
                            <div className={'error'}>
                              {currentPreset.miner.minerValues.errors.logsStorage &&
                                currentPreset.miner.minerValues.errors.logsStorage.message}
                            </div>
                          </Col>
                          {/* <Col
                            md={2}
                            xs={4}
                            className={'form-group align-center'}
                            key={currentPreset.order + currentPreset.miner.name + 'rotateLogs'}
                          >
                            <Label className={'checkboxLabel'}>
                              <input
                                name={'rotateLogs'}
                                type={'checkbox'}
                                checked={currentPreset.miner.minerValues.rotateLogs}
                                onChange={changeMinerField}
                              />
                              {t('pages.miningPresets.rotateLogs')}
                            </Label>
                          </Col> */}
                          <Col
                            md={2}
                            xs={4}
                            className={'form-group'}
                            key={currentPreset.order + currentPreset.miner.name + 'startMiningIn'}
                          >
                            <Label>Start Mining In, ss</Label>
                            <NumericInput
                              className={
                                currentPreset.miner.minerValues.errors.startMiningIn &&
                                currentPreset.miner.minerValues.errors.startMiningIn.message
                                  ? 'invalid form-control'
                                  : 'form-control'
                              }
                              step={1}
                              min={0}
                              max={9999999999}
                              name={'startMiningIn'}
                              onChange={changeMinerField}
                              value={currentPreset.miner.minerValues.startMiningIn}
                            />
                            <div className={'error'}>
                              {currentPreset.miner.minerValues.errors.startMiningIn &&
                                currentPreset.miner.minerValues.errors.startMiningIn.message}
                            </div>
                          </Col>
                          {/* <Col
                            md={2}
                            xs={4}
                            className={currentPreset.miner.minerValues.rotateLogs ? 'form-group' : 'form-group hidden'}
                            key={currentPreset.order + currentPreset.miner.name + 'logsDays'}
                          >
                            <Label>{t('pages.miningPresets.days')}</Label>
                            <NumericInput
                              className={
                                currentPreset.miner.minerValues.errors.logsDays &&
                                currentPreset.miner.minerValues.errors.logsDays.message
                                  ? 'invalid form-control'
                                  : 'form-control'
                              }
                              step={1}
                              min={0}
                              max={9999999999}
                              name={'logsDays'}
                              onChange={changeMinerField}
                              value={currentPreset.miner.minerValues.logsDays}
                            />
                            <div className={'error'}>
                              {currentPreset.miner.minerValues.errors.logsDays &&
                                currentPreset.miner.minerValues.errors.logsDays.message}
                            </div>
                          </Col> */}
                          {/* <Col
                            md={3}
                            xs={6}
                            className={'form-group align-center'}
                            key={currentPreset.order + currentPreset.miner.name + 'watchdog'}
                          >
                            <Label className={'checkboxLabel'}>
                              <input
                                name={'watchdog'}
                                type={'checkbox'}
                                checked={currentPreset.miner.minerValues.watchdog}
                                onChange={changeMinerField}
                              />
                              {t('pages.miningPresets.watchdog')}
                            </Label>
                          </Col> */}
                        </Row>
                      </div>

                      <Row key={currentPreset.order + currentPreset.miner.name + 'worker'}>
                        <Col xs={8} className={'form-group'}>
                          <h6>{t('pages.miningPresets.walletWorkerparameters')}</h6>
                          <Label>$wallet.$workername</Label>
                          <Input
                            className={
                              currentPreset.miner.minerValues.errors.worker &&
                              currentPreset.miner.minerValues.errors.worker.message
                                ? 'invalid form-control'
                                : 'form-control'
                            }
                            name={'worker'}
                            value={currentPreset.miner.minerValues.worker}
                            onChange={changeMinerField}
                          />
                          <div className={'error'}>
                            {currentPreset.miner.minerValues.errors.worker &&
                              currentPreset.miner.minerValues.errors.worker.message}
                          </div>
                        </Col>
                      </Row>

                      <Row key={currentPreset.order + currentPreset.miner.name + 'miner_params_add'}>
                        <Col xs={12} className={'form-group'}>
                          <h6>{t('pages.miningPresets.AdditionalArgumentsMiner')}</h6>
                          <Label>{t('pages.miningPresets.enterParams')}</Label>
                          <textarea
                            className={'form-control'}
                            name={'miner_params_add'}
                            value={currentPreset.miner.minerValues.miner_params_add}
                            onChange={changeMinerField}
                          ></textarea>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              setShowAlertRestartRigs(true)
            }}
          >
            {t('pages.miningPresets.save')}
          </Button>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.miningPresets.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  walletsList: store.presets.walletsList,
  coinsList: store.presets.coinsList,
  poolsList: store.presets.poolsList,
  minersList: store.presets.minersList,
  pool: store.presets.pool,
  overclockingProfilesList: store.profiles.overclockingProfilesList,
  algoCoinsList: store.profiles.algoCoinsList,
  slotsList: store.profiles.slotsList,
})

const mapDispatchToProps = {
  setWalletsList: actions.setWalletsList,
  setCoinsList: actions.setCoinsList,
  setPoolsList: actions.setPoolsList,
  setMinersList: actions.setMinersList,
  setPool: actions.setPool,
  createWalletInPreset: actions.createWallet,
  updateMiningPreset: actions.updateMiningPreset,
  setMiningPresetListNoLoading: actions.setMiningPresetListNoLoading,
}

export default connect(mapStateToProps, mapDispatchToProps)(MiningPresetModalEdit)
