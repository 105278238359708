import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'

import { connect } from 'react-redux'
import * as actions from '../actions'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { PowerUnitOutletManagement } from './modals'

const PowerUnitActions = props => {
  const { t } = useTranslation('common')
  const [showAlertDelete, setShowAlertDelete] = useState(false)

  const deletePresetFunc = async () => {
    setShowAlertDelete(false)
    await props.deleteMiningPreset(props.preset.id)
  }
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }

  return (
    <div className={'actions__items'}>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertDelete}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          deletePresetFunc()
        }}
        onCancel={() => {
          setShowAlertDelete(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        {t('pages.powerUnits.PresetRemovedPermanently')}
      </SweetAlert>
      <div
        className={'actions__item'}
        tooltip={t('pages.powerUnits.editPowerUnit')}
        flow='left'
        //onClick={(e)=>{setShowAlertDelete(true)}}
      >
        <i className='fas fa-pencil'></i>
      </div>
      <div
        className={'actions__item'}
        tooltip={t('pages.powerUnits.deletePowerUnit')}
        flow='left'
        onClick={() => {
          setShowAlertDelete(true)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
      <PowerUnitOutletManagement key={props.key * 100} />
      <Dropdown isOpen={show} toggle={toggle} direction={'left'}>
        <DropdownToggle className={'actions__item'} tooltip={t('pages.powerUnits.powerUnitActions')} flow='left'>
          <i className='fas fa-power-off'></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>{t('pages.powerUnits.turnOff')}</DropdownItem>
          <DropdownItem>{t('pages.powerUnits.reboot')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

const mapDispatchToProps = {
  deleteMiningPreset: actions.deleteMiningPreset,
}

export default connect(null, mapDispatchToProps)(PowerUnitActions)
