import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import * as actions from '../actions'
import OverclockingProfilesActions from '../components/OverclockingProfilesActions'
import { Table } from '../../../components/Table'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const OverclockingProfilesPage = ({
  setOverclockingProfilesList,
  overclockingProfilesList,
  setAlgoCoinsList,
  overclockingProfilesTotal,
}) => {
  const { t } = useTranslation('common')
  const [overclockingProfileList, setOverclockingProfileList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )

  useEffect(() => {
    setOverclockingProfilesList({ page: currentPage, size: showCount, filters: {} })
  }, [setOverclockingProfilesList, currentPage, showCount])

  useEffect(() => {
    setAlgoCoinsList({ page: 1, size: 1000 })
  }, [setAlgoCoinsList])

  useEffect(() => {
    const overclockingProfilesArray = overclockingProfilesList.map(overclockingProfile => {
      return {
        ...overclockingProfile,
        action: <OverclockingProfilesActions profile={overclockingProfile} />,
      }
    })
    setOverclockingProfileList(overclockingProfilesArray)
  }, [overclockingProfilesList])

  const overclockingProfiles = {
    columns: [
      {
        label: t('pages.overclocking.id'),
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.overclocking.name'),
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.overclocking.type'),
        field: 'type',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.overclocking.action'),
        field: 'action',
      },
    ],
    rows: overclockingProfileList,
  }

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.overclocking.overclockingProfiles')}</h3>
          </div>
        </div>
        <div className={'mining_preset__body'}>
          <Table
            className={'mining_preset__table table table-striped'}
            data={overclockingProfiles}
            responsive={true}
            noBottomColumns
            total={overclockingProfilesTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  overclockingProfilesList: store.profiles.overclockingProfilesList,
  overclockingProfilesTotal: store.profiles.overclockingProfilesTotal,
  algoCoinsList: store.profiles.algoCoinsList,
})

const mapDispatchToProps = {
  setOverclockingProfilesList: actions.setOverclockingProfilesList,
  setAlgoCoinsList: actions.setAlgoCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverclockingProfilesPage)
