import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import * as actions from '../../../../RigsData/actions'
import { Spinner } from 'reactstrap'
import Ansi from 'ansi-to-react'

const Console = props => {
  useEffect(() => {
    props.setConsole({ rigId: props.worker.id, setConsole: true })
  }, [])

  // useEffect(() => {
  //   const consoleInfo = setInterval(() => {
  //     if (new Date().getSeconds() < 10) {
  //       props.getConsoleData({ rigId: props.worker.id })
  //     }
  //   }, 2000)

  //   return () => {
  //     props.setConsole({ rigId: props.worker.id, setConsole: false })
  //     clearInterval(consoleInfo)
  //   }
  // }, [])

  useEffect(() => {
    const consoleInfo = setInterval(() => {
      props.getConsoleData({ rigId: props.worker.id })
    }, 10000)

    return () => {
      props.setConsole({ rigId: props.worker.id, setConsole: false })
      clearInterval(consoleInfo)
    }
  }, [])
  return (
    <div>
      <Row className={'worker_charts'}>
        <Col xs={12} className='worker-block worker-block_logs'>
          <div className='console_preloader'>
            <div className='status'>{!!props.getReqFromRigConsole && <Spinner color={'primary'} />}</div>
          </div>
          {props.getReqFromRigConsole ? (
            <pre className='rig_console' style={{ opacity: '0.2' }}>
              {props.rigConsole?.split('\n').map(log => {
                return (
                  <div>
                    <Ansi>{log}</Ansi>
                  </div>
                )
              })}
            </pre>
          ) : (
            <pre className='rig_console'>
              {props.rigConsole?.split('\n').map(log => {
                return (
                  <div>
                    <Ansi>{log}</Ansi>
                  </div>
                )
              })}
            </pre>
          )}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = store => ({
  rigConsole: store.rigs.rig.rigConsole,
  getReqFromRigConsole: store.rigs.rig.getReqFromRigConsole,
})

const mapDispatchToProps = {
  setConsole: actions.setConsole,
  getConsoleData: actions.getConsoleData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Console)
