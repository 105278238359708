import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'

const EditInviteCodeModal = props => {
  const [inviteCode, setInviteCode] = useState(props.inviteCode.inviteCode)
  const [possibleUsersNum, setPossibleUsersNum] = useState(props.inviteCode.possibleUsersNum)
  const [factUsersNum, setFactUsersNum] = useState(props.inviteCode.factUsersNum)
  const [errors, setErrors] = useState({})

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'inviteCode': {
        setInviteCode(e.target.value)
        return
      }
      case 'possibleUsersNum': {
        setPossibleUsersNum(e.target.value)
        return
      }
      case 'factUsersNum': {
        setFactUsersNum(e.target.value)
        return
      }
      default:
        return
    }
  }

  const submitFunc = () => {
    //setErrors({})
    const newErrors = {}
    if (inviteCode.length === 0) newErrors.inviteCode = { message: "InviteCode can't be empty" }
    if (possibleUsersNum.length === 0)
      newErrors.possibleUsersNum = {
        message: "Possible Users Number can't be empty",
      }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    props.updateInviteCode({
      id: props.inviteCode.id,
      inviteCode: inviteCode,
      possibleUsersNum: +possibleUsersNum,
      factUsersNum: +factUsersNum,
    })
    props.toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'actions__item'} tooltip='Edit InviteCode' flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          Edit InviteCode
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={6} className={'form-group'}>
                <Label>Invite Code</Label>
                <Input
                  className={errors.name ? 'invalid' : ''}
                  type={'text'}
                  name={'inviteCode'}
                  onChange={onChange}
                  value={inviteCode}
                />
                <div className={'error'}>{errors.inviteCode && errors.inviteCode.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>Possible Users Number</Label>
                <Input
                  className={errors.possibleUsersNum ? 'invalid' : ''}
                  type={'text'}
                  name={'possibleUsersNum'}
                  onChange={onChange}
                  value={possibleUsersNum}
                />
                <div className={'error'}>{errors.possibleUsersNum && errors.possibleUsersNum.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>Fact Users Number</Label>
                <Input
                  className={errors.factUsersNum ? 'invalid' : ''}
                  type={'text'}
                  name={'factUsersNum'}
                  onChange={onChange}
                  value={factUsersNum}
                />
                <div className={'error'}>{errors.factUsersNum && errors.factUsersNum.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = {
  updateInviteCode: actions.updateInviteCode,
}

export default connect(null, mapDispatchToProps)(EditInviteCodeModal)
