import { types } from './types'
import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
// import { errorNotification } from '../../utils/notificationHelper'
import { addAlert } from '../../store/layout/actions'

const SET_COINS_LIST = `
  query coinList($page: Int, $size: Int, $orderBy: String, $sequence: String) {
    coinList(page: $page, size: $size, orderBy: $orderBy, sequence: $sequence) {
      items {
        id
        nameSymbol
        name
        units
      }
      total
    }
  }
`

const CREATE_COIN = `
  mutation createCoin ($name: String!, $nameSymbol: String!, $units: String!) {
    createCoin(name: $name, nameSymbol: $nameSymbol, units: $units) {
      id
      nameSymbol
      name
      units
    }
  }
`

const UPDATE_COIN = `
  mutation updateCoin ($id: ID!, $name: String!, $nameSymbol: String!, $units: String!) {
    updateCoin(id: $id, name: $name, nameSymbol: $nameSymbol, units: $units) {
      id
      nameSymbol
      name
      units
    }
  }
`
const DELETE_COIN = `
  mutation deleteCoin ($id: ID!) {
    deleteCoin(id: $id)
  }
`

const SET_ALGOCOINS_LIST = `
  query algoCoinsList($page: Int, $size: Int, $orderBy: String) {
    algoCoinsList(page: $page, size: $size, orderBy: $orderBy) {
      items {
        id
        algo
        coins
      }
      total
    }
  }
`

const CREATE_ALGOCOIN = `
  mutation createAlgoCoin ($algo: String!, $coins: String!) {
    createAlgoCoin(algo: $algo, coins: $coins) {
      id
      algo
      coins
    }
  }
`

const UPDATE_ALGOCOIN = `
  mutation updateAlgoCoin ($id: ID!, $algo: String!, $coins: String!) {
    updateAlgoCoin(id: $id, algo: $algo, coins: $coins) {
      id
      algo
      coins
    }
  }
`
const DELETE_ALGOCOIN = `
  mutation deleteAlgoCoin ($id: ID!) {
    deleteAlgoCoin(id: $id)
  }
`

const SET_INVITECODES_LIST = `
  query inviteCodeList($page: Int, $size: Int, $orderBy: String, $sequence: String) {
    inviteCodeList(page: $page, size: $size, orderBy: $orderBy, sequence: $sequence) {
      items {
        id
        inviteCode
        possibleUsersNum
        factUsersNum
      }
      total
    }
  }
`

const CREATE_INVITECODE = `
  mutation createInviteCode ($inviteCode: String!, $possibleUsersNum: Int!, $factUsersNum: Int!) {
    createInviteCode(inviteCode: $inviteCode, possibleUsersNum: $possibleUsersNum, factUsersNum: $factUsersNum) {
      id
      inviteCode
      possibleUsersNum
      factUsersNum
    }
  }
`

const UPDATE_INVITECODE = `
  mutation updateInviteCode ($id: ID!, $inviteCode: String!, $possibleUsersNum: Int!, $factUsersNum: Int!) {
    updateInviteCode(id: $id, inviteCode: $inviteCode, possibleUsersNum: $possibleUsersNum, factUsersNum: $factUsersNum) {
      id
      inviteCode
      possibleUsersNum
      factUsersNum
    }
  }
`
const DELETE_INVITECODE = `
  mutation deleteInviteCode ($id: ID!) {
    deleteInviteCode(id: $id)
  }
`

const SET_POOLS_LIST = `
  query poolList($page: Int, $size: Int, $orderBy: String, $sequence: String) {
    poolList(page: $page, size: $size, orderBy: $orderBy, sequence: $sequence) {
      items {
        id
        title
        sslPort
        stratumPort
        altStratumPort
        type
        coins
        soloPort
        poolServers{
          id
          country
          address
          type
          coin
        }
      }
      total
    }
  }
`

const CREATE_POOL = `
  mutation createPool ($title: String, $sslPort: String, $stratumPort: String, $altStratumPort: String, $type: String, $coins: String, $soloPort: String, $poolServers: [PoolServerInput]) {
    createPool(title: $title, sslPort: $sslPort, stratumPort: $stratumPort, altStratumPort: $altStratumPort, type: $type, coins: $coins, soloPort: $soloPort, poolServers: $poolServers) {
      id
      title
      sslPort
      stratumPort
      altStratumPort
      type
      coins
      soloPort
      poolServers{
        id
        country
        address
        type
        coin
      }
    }
  }
`

const UPDATE_POOL = `
 mutation updatePool ($id: ID!, $title: String, $sslPort: String, $stratumPort: String, $altStratumPort: String, $type: String, $coins: String, $soloPort: String, $poolServers: [PoolServerInput], $updateRigs: String) {
    updatePool(id: $id, title: $title, sslPort: $sslPort, stratumPort: $stratumPort, altStratumPort: $altStratumPort, type: $type, coins: $coins, soloPort: $soloPort, poolServers: $poolServers, updateRigs: $updateRigs) {
      id
      title
      sslPort
      stratumPort
      altStratumPort
      type
      coins
      soloPort
      poolServers{
        id
        country
        address
        type
        coin
      }
    }
  }
`
const DELETE_POOL = `
  mutation deletePool ($id: ID!) {
    deletePool(id: $id)
  }
`

const SET_MINERS_LIST = `
  query minerList($page: Int, $size: Int, $orderBy: String, $sequence: String) {
    minerList(page: $page, size: $size, orderBy: $orderBy, sequence: $sequence) {
      items {
        id
        name
        cardType
        template
        minerVersions{
            id
            minerId
            version
            dualCoins
            params{
                id
                minerVersionId
                algo
                algoInMP
            }
        }
        minerParams{
          id
          paramName
          consts{
            id
            paramLabel
            paramValue
          }
        }
      }
      total
    }
  }
`

const CREATE_MINER = `
  mutation createMiner ($name: String!, $cardType: String,  $template: String, $minerParams: [MinerParamsInput], $minerVersions: [MinerVersionInput]) {
    createMiner(name: $name, cardType: $cardType, template: $template, minerParams: $minerParams, minerVersions: $minerVersions) {
      id
        name
        cardType
        template
        minerVersions{
            id
            minerId
            version
            dualCoins
            params{
                id
                minerVersionId
                algo
                algoInMP
            }
        }
        minerParams{
          id
          paramName
          consts{
            id
            paramLabel
            paramValue
          }
        }
     }
  }
`

const UPDATE_MINER = `
  mutation updateMiner ($id: ID!, $name: String!, $cardType: String, $template: String, $minerParams: [MinerParamsInput], $minerVersions: [MinerVersionInput]) {
    updateMiner(id: $id, name: $name, cardType: $cardType, template: $template, minerParams: $minerParams, minerVersions: $minerVersions)
  }
`
const DELETE_MINER = `
  mutation deleteMiner ($id: ID!) {
    deleteMiner(id: $id)
  }
`

export const setCoinsList =
  ({ page, size, orderBy, sequence, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_COINS_LIST,
        variables: { page, size, orderBy, sequence, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }
      const { coinList } = data
      if (!coinList) {
        throw new Error("Can't get coins")
      }

      dispatch({
        type: types.SET_COINS_LIST,
        data: coinList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createCoin = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_COIN,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCoin } = data
    if (!createCoin) {
      throw new Error("Can't Update Coin")
    }

    dispatch({
      type: types.CREATE_COIN,
      data: createCoin,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateCoin = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_COIN,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCoin } = data
    if (!updateCoin) {
      throw new Error("Can't Update Coin")
    }

    dispatch({
      type: types.UPDATE_COIN,
      data: updateCoin,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteCoin = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_COIN,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deleteCoin } = data
    if (!deleteCoin) {
      throw new Error("Can't Update Coin")
    }

    dispatch({
      type: types.DELETE_COIN,
      data: formData.id,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setAlgoCoinsList =
  ({ page, size, orderBy, sequence, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_ALGOCOINS_LIST,
        variables: { page, size, orderBy, sequence, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }
      const { algoCoinsList } = data
      if (!algoCoinsList) {
        throw new Error("Can't get algoCoins")
      }

      dispatch({
        type: types.SET_ALGOCOINS_LIST,
        data: algoCoinsList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createAlgoCoin = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_ALGOCOIN,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createAlgoCoin } = data
    if (!createAlgoCoin) {
      throw new Error("Can't Update AlgoCoin")
    }

    dispatch({
      type: types.CREATE_ALGOCOIN,
      data: createAlgoCoin,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateAlgoCoin = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_ALGOCOIN,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateAlgoCoin } = data
    if (!updateAlgoCoin) {
      throw new Error("Can't Update AlgoCoin")
    }

    dispatch({
      type: types.UPDATE_ALGOCOIN,
      data: updateAlgoCoin,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteAlgoCoin = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_ALGOCOIN,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deleteAlgoCoin } = data
    if (!deleteAlgoCoin) {
      throw new Error("Can't Update AlgoCoin")
    }

    dispatch({
      type: types.DELETE_ALGOCOIN,
      data: formData.id,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setInviteCodesList =
  ({ page, size, orderBy, sequence, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_INVITECODES_LIST,
        variables: { page, size, orderBy, sequence, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }
      const { inviteCodeList } = data
      if (!inviteCodeList) {
        throw new Error("Can't get inviteCodes")
      }

      dispatch({
        type: types.SET_INVITECODES_LIST,
        data: inviteCodeList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createInviteCode = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_INVITECODE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createInviteCode } = data
    if (!createInviteCode) {
      throw new Error("Can't Update InviteCode")
    }

    dispatch({
      type: types.CREATE_INVITECODE,
      data: createInviteCode,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateInviteCode = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_INVITECODE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateInviteCode } = data
    if (!updateInviteCode) {
      throw new Error("Can't Update InviteCode")
    }

    dispatch({
      type: types.UPDATE_INVITECODE,
      data: updateInviteCode,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteInviteCode = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_INVITECODE,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deleteInviteCode } = data
    if (!deleteInviteCode) {
      throw new Error("Can't Update InviteCode")
    }

    dispatch({
      type: types.DELETE_INVITECODE,
      data: formData.id,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setPoolsList =
  ({ page, size, orderBy, sequence, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_POOLS_LIST,
        variables: { page, size, orderBy, sequence, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }
      const { poolList } = data
      if (!poolList) {
        throw new Error("Can't get pools")
      }

      dispatch({
        type: types.SET_POOLS_LIST,
        data: poolList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createPool = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_POOL,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createPool } = data
    if (!createPool) {
      throw new Error("Can't Update Pool")
    }

    dispatch({
      type: types.CREATE_POOL,
      data: createPool,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updatePool = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_POOL,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { updatePool } = data
    if (!updatePool) {
      throw new Error("Can't Update Pool")
    }

    dispatch({
      type: types.UPDATE_POOL,
      data: updatePool,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Update Pool Successed!', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deletePool = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_POOL,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deletePool } = data
    if (!deletePool) {
      throw new Error("Can't Update Pool")
    }

    dispatch({
      type: types.DELETE_POOL,
      data: formData.id,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setMinersList =
  ({ page, size, orderBy, sequence, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_MINERS_LIST,
        variables: { page, size, orderBy, sequence, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }
      const { minerList } = data
      if (!minerList) {
        throw new Error("Can't get miners")
      }

      dispatch({
        type: types.SET_MINERS_LIST,
        data: minerList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const createMiner = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    //console.log(formData)
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_MINER,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createMiner } = data
    if (!createMiner) {
      throw new Error("Can't Update Miner")
    }

    dispatch({
      type: types.CREATE_MINER,
      data: createMiner,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const updateMiner = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_MINER,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { updateMiner } = data
    if (!updateMiner) {
      throw new Error("Can't Update Miner")
    }

    dispatch({
      type: types.UPDATE_MINER,
      data: formData,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteMiner = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: DELETE_MINER,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deleteMiner } = data
    if (!deleteMiner) {
      throw new Error("Can't Update Miner")
    }

    dispatch({
      type: types.DELETE_MINER,
      data: formData.id,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}
