import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import Select from 'react-select'
import * as actions from '../../actions'
import Autocomplete from '../../../../components/UI/Autocomplete'

const CreateMinerVersionModal = props => {
  const [versionName, setVersionName] = useState('')
  const messagesEndRef = useRef(null)
  const [errors, setErrors] = useState({})
  const [paramsCount, setParamsCount] = useState(0)
  const [params, setParams] = useState([])
  const [dualCoins, setDualCoins] = useState([])

  const changeParamAlgo = (value, paramEdit) => {
    setParams(
      params.map(param => {
        if (param === paramEdit) param.algo = value
        return param
      }),
    )
  }

  const changeParamAlgoInMP = (value, paramEdit) => {
    setParams(
      params.map(param => {
        if (param === paramEdit) param.algoInMP = value
        return param
      }),
    )
  }

  const deleteParam = paramEdit => {
    setParams(params.filter(param => param !== paramEdit))
  }

  const [otherVersion, setOtherVersion] = useState('')
  const [showCopyAlert, setShowCopyAlert] = useState(false)
  const copyAlertToggle = () => {
    setShowCopyAlert(!setShowCopyAlert)
  }
  const copyParamsFromAnotherVersion = version => {
    if (version && version.params) {
      setParams(
        version.params.map((param, i) => {
          return {
            order: i,
            ...param,
          }
        }),
      )
      setDualCoins(
        version.dualCoins && version.dualCoins.length > 0
          ? version.dualCoins.split(', ').map(coin => {
              return { label: coin, value: coin }
            })
          : [],
      )

      setParamsCount(version.params.length)
    }
    setShowCopyAlert(false)
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) messagesEndRef.current.scrollIntoView(false)
  }
  useEffect(() => {
    scrollToBottom()
  }, [params.length])

  const submitFunc = () => {
    const newErrors = {}
    if (versionName.length === 0) newErrors.version = { message: 'Please Enter Version' }
    if (params.length === 0) newErrors.params = { message: 'Please Add Algorithms' }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return
    props.addVersion({
      params: params,
      version: versionName,
      dualCoins: dualCoins.map(coin => coin.value).join(', '),
    })
    props.toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <Button onClick={props.toggle} color='primary' className={'mining_preset__create'}>
        Add Version
      </Button>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <Modal
          title='Please select another version'
          isOpen={showCopyAlert}
          toggle={copyAlertToggle}
          className={'modal-dialog-centered'}
        >
          <ModalHeader tag={'h4'} toggle={copyAlertToggle}>
            Copy From Another Version
          </ModalHeader>
          <ModalBody>
            <div className={'miner_versions_select_box'}>
              <label>Version</label>
              <Select
                classNamePrefix={'form_control_react_select'}
                value={otherVersion}
                onChange={option => {
                  setOtherVersion(option)
                }}
                options={props.versions.map(version => {
                  return {
                    label: version.version,
                    value: version.version,
                    version: version,
                  }
                })}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={copyAlertToggle}>
              Cancel
            </Button>
            <Button color='primary' onClick={() => copyParamsFromAnotherVersion(otherVersion.version)}>
              Copy
            </Button>
          </ModalFooter>
        </Modal>
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          Add New Version
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>Version</Label>
                <Input
                  className={errors.versionName ? 'invalid' : ''}
                  type={'text'}
                  name={'versionName'}
                  onChange={e => {
                    setVersionName(e.target.value)
                  }}
                  value={versionName}
                />
                <div className={'error'}>{errors.version && errors.version.message}</div>
              </Col>
              <Col xs={12} className={'form-group'}>
                <Label>dualCoins</Label>
                <Select
                  classNamePrefix={'form_control_react_select'}
                  value={dualCoins}
                  onChange={option => {
                    setDualCoins(option)
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  isMulti={true}
                  options={props.coinsList.map(coin => {
                    return { value: coin.nameSymbol, label: coin.nameSymbol }
                  })}
                />
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <Button
                      onClick={() => {
                        setParams([...params, { algo: '', order: paramsCount }])
                        setParamsCount(+paramsCount + +1)
                      }}
                      color='primary'
                      className={'mining_preset__create'}
                      style={{ float: 'right' }}
                    >
                      Add
                    </Button>
                    <Button
                      onClick={() => {
                        setShowCopyAlert(true)
                      }}
                      color='primary'
                      className={'mining_preset__create'}
                      style={{ float: 'left' }}
                    >
                      Copy From Another Version
                    </Button>
                  </Col>
                </Row>
                <Row className={'text-center'}>
                  <Col xs={5}>Algorithm</Col>
                  <Col xs={5}>AlgoInMP</Col>
                </Row>
                <div className={'miner_versions_scroll_area'}>
                  {params.map(param => {
                    return (
                      <Row key={param.order}>
                        <Col xs={5}>
                          {/*<Select
                            classNamePrefix={'form_control_react_select'}
                            value={param.algoOption}
                            onChange={option => {
                              param.algo = option.value
                              param.algoOption = option
                            }}
                            hideSelectedOptions={true}
                            options={props.algoCoinsList.map(algo => {
                              return { value: algo.algo, label: algo.algo }
                            })}
                          />*/}
                          <Autocomplete
                            value={param.algo}
                            onChange={value => {
                              changeParamAlgo(value, param)
                            }}
                            items={props.algoCoinsList.map(algo => {
                              return { value: algo.algo }
                            })}
                          />
                        </Col>
                        <Col xs={5}>
                          <Input value={param.algoInMP} onChange={e => changeParamAlgoInMP(e.target.value, param)} />
                        </Col>
                        <Col className={'text-center align-center actions__items'} xs={2}>
                          <div
                            className={'actions__item'}
                            tooltip='Delete row'
                            flow='left'
                            onClick={() => {
                              deleteParam(param)
                            }}
                          >
                            <i className='fas fa-trash'></i>
                          </div>
                        </Col>
                      </Row>
                    )
                  })}
                  <div ref={messagesEndRef} />
                </div>
                <div className={'error'}>{errors.params && errors.params.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  coinsList: store.admin.coinsList,
  algoCoinsList: store.admin.algoCoinsList,
})

export default connect(mapStateToProps, null)(CreateMinerVersionModal)
