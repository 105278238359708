import { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import useReactRouter from 'use-react-router'
import * as actions from '../pages/Login/actions'
import { connect } from 'react-redux'
import { AccountSettings } from '../pages/Login/components/modals'
import { useTranslation } from 'react-i18next'

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)
  const { history } = useReactRouter()
  const toggle = () => {
    setMenu(!menu)
  }
  const { t } = useTranslation('common')
  const setLogout = async () => {
    await props.logout(history)
  }

  const [modal, setModal] = useState(false)

  const toggleSettings = () => {
    //event.preventDefault()
    setModal(!modal)
    setMenu(false)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className='d-inline-block'>
        <DropdownToggle className='btn header-item waves-effect' id='page-header-user-dropdown' tag='button'>
          <i className='mdi mdi-account-circle header-profile-user'></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag='a' href='#'>
            <i className='mdi mdi-account-circle font-size-17 align-middle mr-1'></i>
            {t('topBar.profile')}
          </DropdownItem>
          <DropdownItem>
            <AccountSettings toggle={toggleSettings} isOpen={modal} />
          </DropdownItem>
          <div className='dropdown-divider'></div>
          <Link to={'#'} onClick={setLogout} className='dropdown-item'>
            <i className='mdi mdi-logout font-size-17 align-middle mr-1'></i>
            <span>{t('topBar.logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

const mapDispatchToProps = {
  logout: actions.logOut,
}

export default connect(null, mapDispatchToProps)(ProfileMenu)
