import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'
import * as actions from '../../actions'
import Select from 'react-select'

const CreateScheduleModal = props => {
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [event, setEvent] = useState(null)
  const [activator, setActivator] = useState(null)
  const [initialPreset, setInitialPreset] = useState(null)
  const [newPreset, setNewPreset] = useState(null)
  const { t } = useTranslation('common')
  const eventOptions = [{ label: 'Block98', value: 'block98' }]
  const activatorOptions = [
    { label: t('pages.schedule.byEvent'), value: 'byEvent' },
    { label: t('pages.schedule.manual'), value: 'manual' },
  ]

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'name': {
        setName(e.target.value)
        return
      }
    }
  }

  const submitFunc = () => {
    const newErrors = {}
    if (name.length === 0) newErrors.name = { message: t('pages.schedule.nameError') }
    if (!activator) newErrors.activator = { message: t('pages.schedule.activatorError') }
    if (!event) newErrors.event = { message: t('pages.schedule.eventError') }
    if (!initialPreset) newErrors.initialPreset = { message: t('pages.schedule.initialPresetError') }
    if (!newPreset) newErrors.newPreset = { message: t('pages.schedule.newPresetError') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return
    props.createSchedule({
      name: name,
      event: event.value,
      activator: activator.value,
      action: 'stop',
      firstPresetId: initialPreset.value,
      secondPresetId: newPreset.value,
    })
    toggle()
  }
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'power_unit__create'}>
        <Button onClick={toggle} color='primary' className={'mining_preset__create'}>
          {t('pages.schedule.add')}
        </Button>
      </div>
      <Modal isOpen={show} toggle={toggle} className={'modal-dialog-centered'} onClick={e => e.stopPropagation()}>
        <ModalHeader tag={'h4'} toggle={toggle}>
          {t('pages.schedule.addHeader')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.schedule.name')}</Label>
                <Input
                  className={errors.name ? 'invalid' : ''}
                  type={'text'}
                  name={'name'}
                  onChange={onChange}
                  value={name}
                />
                <div className={'error'}>{errors.name && errors.name.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.schedule.event')}</Label>
                <Select
                  isClearable={true}
                  hideSelectedOptions
                  placeholder={t('inputs.select')}
                  className={errors.event ? 'invalid-select' : ''}
                  classNamePrefix={'form_control_react_select'}
                  onChange={option => {
                    const newErrors = errors
                    delete newErrors['event']
                    setErrors(newErrors)

                    setEvent(option)
                  }}
                  value={event}
                  options={eventOptions}
                />
                <div className={'error'}>{errors.event && errors.event.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.schedule.activator')}</Label>
                <Select
                  isClearable={true}
                  hideSelectedOptions
                  placeholder={t('inputs.select')}
                  className={errors.activator ? 'invalid-select' : ''}
                  classNamePrefix={'form_control_react_select'}
                  onChange={option => {
                    const newErrors = errors
                    delete newErrors['activator']
                    setErrors(newErrors)

                    setActivator(option)
                  }}
                  value={activator}
                  options={activatorOptions}
                />
                <div className={'error'}>{errors.activator && errors.activator.message}</div>
              </Col>

              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.schedule.initialPreset')}</Label>
                <Select
                  isClearable={true}
                  hideSelectedOptions
                  placeholder={t('inputs.select')}
                  className={errors.initialPreset ? 'invalid-select' : ''}
                  classNamePrefix={'form_control_react_select'}
                  onChange={option => {
                    const newErrors = errors
                    delete newErrors['initialPreset']
                    setErrors(newErrors)

                    setInitialPreset(option)
                  }}
                  value={initialPreset}
                  options={props.miningPresetList.map(preset => {
                    return { label: preset.name, value: preset.id }
                  })}
                />
                <div className={'error'}>{errors.initialPreset && errors.initialPreset.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.schedule.newPreset')}</Label>
                <Select
                  isClearable={true}
                  hideSelectedOptions
                  placeholder={t('inputs.select')}
                  className={errors.newPreset ? 'invalid-select' : ''}
                  classNamePrefix={'form_control_react_select'}
                  onChange={option => {
                    const newErrors = errors
                    delete newErrors['newPreset']
                    setErrors(newErrors)

                    setNewPreset(option)
                  }}
                  value={newPreset}
                  options={props.miningPresetList.map(preset => {
                    return { label: preset.name, value: preset.id }
                  })}
                />
                <div className={'error'}>{errors.newPreset && errors.newPreset.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            {t('pages.schedule.cancel')}
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            {t('pages.schedule.save')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  miningPresetList: store.presets.miningPresetList,
})

const mapDispatchToProps = {
  createSchedule: actions.createSchedule,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateScheduleModal)
