import { types } from './types'

const initState = {
  forwardAutofan: false,
  critTemp: 85,
  critTempAction: 'Stop Mining',
  autofan: {},
  localRigsSettings: {},
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_LOCAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      const localSettings = {
        ...stateCopy.localRigsSettings,
        action: data.action,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
      }
      return {
        ...stateCopy,
        localRigsSettings: localSettings,
      }
    }

    case types.DEL_LOCAL_SETTINGS: {
      const stateCopy = { ...state }
      const localSettings = {}
      return {
        ...stateCopy,
        localRigsSettings: localSettings,
      }
    }

    case types.SET_GENERAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        id: data.id,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
        critTempAction: data.critTempAction,
        la: data.la,
        laAction: data.laAction,
        laBool: data.laBool,
        lowHashBool: data.lowHashBool,
        lowHashTime: data.lowHashTime,
        lowHashAction: data.lowHashAction,
        zeroHashBool: data.zeroHashBool,
        zeroHashTime: data.zeroHashTime,
        zeroHashAction: data.zeroHashAction,
        hashrateWatchdog: data.hashrateWatchdog,
      }
    }

    case types.UPDATE_GENERAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
        critTempAction: data.critTempAction,
        la: data.la,
        laAction: data.laAction,
        laBool: data.laBool,
        lowHashBool: data.lowHashBool,
        lowHashTime: data.lowHashTime,
        lowHashAction: data.lowHashAction,
        zeroHashBool: data.zeroHashBool,
        zeroHashTime: data.zeroHashTime,
        zeroHashAction: data.zeroHashAction,
        hashrateWatchdog: data.hashrateWatchdog,
      }
    }

    case types.UPDATE_LOCAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      const localSettings = {
        ...stateCopy.localRigsSettings,
        action: data.critTempAction,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
      }
      return {
        ...stateCopy,
        localRigsSettings: localSettings,
      }
    }

    case types.DEFOULT_LOCAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      const localSettings = {
        ...stateCopy.localRigsSettings,
        action: data.critTempAction,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
      }
      return {
        ...stateCopy,
        localRigsSettings: localSettings,
      }
    }

    case types.DEFOULT_GENERAL_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        critTemp: data.critTemp,
        highTemp: data.highTemp,
        critTempAction: data.critTempAction,
      }
    }

    case types.SET_AUTOFAN: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        autofan: data,
      }
    }

    case types.ACTION_AUTOFAN: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        autofan: data,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
