import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'
import Select from 'react-select'

const CreateAlgoCoinModal = props => {
  const [algo, setAlgo] = useState('')
  const [coins, setCoins] = useState([])
  const [errors, setErrors] = useState({})

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'algo': {
        setAlgo(e.target.value)
        return
      }
      case 'coins': {
        setCoins(e.target.value)
        return
      }
      default:
        return
    }
  }

  const toggle = () => {
    setShow(!show)
  }

  const submitFunc = () => {
    //setErrors({})
    const newErrors = {}
    if (algo.length === 0) newErrors.algo = { message: "Algorithm can't be empty" }
    if (coins.length === 0) newErrors.coins = { message: "Coins can't be empty" }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    props.createAlgoCoin({
      algo: algo,
      coins: coins.map(coin => coin.value).join(', '),
    })
    toggle()
  }
  const [show, setShow] = useState(false)

  return (
    <div onClick={e => e.stopPropagation()}>
      <Button onClick={toggle} color='primary' className={'mining_preset__create'}>
        Add AlgoCoin
      </Button>
      <Modal isOpen={show} toggle={toggle} className={'modal-dialog-centered'} onClick={e => e.stopPropagation()}>
        <ModalHeader tag={'h4'} toggle={toggle}>
          Create AlgoCoin
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>Algorithm</Label>
                <Input
                  className={errors.algo ? 'invalid' : ''}
                  type={'text'}
                  name={'algo'}
                  onChange={onChange}
                  value={algo}
                />
                <div className={'error'}>{errors.algo && errors.algo.message}</div>
              </Col>
              <Col xs={12} className={'form-group'}>
                <Label>Coins</Label>
                <Select
                  classNamePrefix={errors.coins ? 'invalid form_control_react_select' : 'form_control_react_select'}
                  value={coins}
                  onChange={option => {
                    setCoins(option)
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  isMulti={true}
                  options={props.coinsList.map(coin => {
                    return { value: coin.nameSymbol, label: coin.nameSymbol }
                  })}
                />
                <div className={'error'}>{errors.coins && errors.coins.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  coinsList: store.admin.coinsList,
  algoCoinsList: store.admin.algoCoinsList,
})

const mapDispatchToProps = {
  createAlgoCoin: actions.createAlgoCoin,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAlgoCoinModal)
