import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, Row, Col, Label, Input, Form } from 'reactstrap'

import Select from 'react-select'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import SweetAlert from 'react-bootstrap-sweetalert'
import * as actions from '../../actions'
import useReactRouter from 'use-react-router'
import { useTranslation } from 'react-i18next'

const AccountSettings = ({ ...props }) => {
  const { t } = useTranslation('common')
  const { history } = useReactRouter()
  const [showAlertTerminate, setShowAlertTerminate] = useState(false)
  const [passwordTerminate, setPasswordTerminate] = useState('')
  const [passwordTerminateError, setPasswordTerminateError] = useState(false)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [oldPasswordError, setOldPasswordError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [newPasswordErrorReg, setNewPasswordErrorReg] = useState(false)
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(false)

  const deleteAccount = async () => {
    if (passwordTerminate.length <= 0) {
      setPasswordTerminateError(true)
    } else {
      await props.deleteAccount({ userId: props.user.id, history })
      setShowAlertTerminate(false)
    }
  }
  const changePass = async () => {
    let error = false
    if (oldPassword.length <= 0) {
      setOldPasswordError(true)
      error = true
    }
    if (newPassword.length <= 0) {
      setNewPasswordError(true)
      error = true
    }
    if (newPassword !== newPasswordConfirm) {
      setNewPasswordConfirmError(true)
      error = true
    }
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%?^&*()_~+=<>';:`])(?=.{8,})")

    if (!strongRegex.test(newPassword)) {
      setNewPasswordErrorReg(true)
      error = true
    }

    if (!error) {
      props.updatePassword({
        userId: props.user.id,
        oldPass: oldPassword,
        newPass: newPassword,
      })
    }
  }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <li tag='li' onClick={props.toggle}>
        <i className='mdi mdi-settings font-size-17 align-middle mr-1'></i> {t('pages.login.settings')}
      </li>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className={'modal-dialog-centered popup popup_large '}
        onClick={e => e.stopPropagation()}
      >
        <SweetAlert
          title={t('pages.login.accountTerminationWarning')}
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          custom
          customIcon={<i className='fas fa-times sweet-alert-icon red'></i>}
          show={showAlertTerminate}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            deleteAccount()
          }}
          onCancel={() => {
            setShowAlertTerminate(false)
          }}
          confirmBtnText={t('pages.login.delete')}
          cancelBtnText={t('pages.login.cancel')}
          showCloseButton={true}
        >
          <div className={'red'}>{t('pages.login.allDataRelated')}</div>
          <Form className={'modal-body'}>
            <Row>
              <Col xs={12} className={'form-group text-left mt-10'}>
                <Label>{t('pages.login.password')}</Label>
                <Input
                  className={passwordTerminateError ? 'invalid' : ''}
                  type={'text'}
                  name={'profile_name'}
                  value={passwordTerminate}
                  onChange={e => {
                    setPasswordTerminate(e.target.value)
                    setPasswordTerminateError(false)
                  }}
                />
                <div className={'error'}>{passwordTerminateError ? t('pages.login.pleaseEnterPassword') : ''}</div>
              </Col>
            </Row>
          </Form>
        </SweetAlert>
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.login.accountSettings')}
        </ModalHeader>
        <ModalBody>
          <Tabs>
            <TabList className={'card_tabs'}>
              <Tab>{t('pages.login.general')}</Tab>
              <Tab>{t('pages.login.loginHistory')}</Tab>
              <Tab>{t('pages.login.access')}</Tab>
            </TabList>

            <TabPanel>
              <div className={'account__body'}>
                <div className={'account'}>
                  <div className={'account__row'}>
                    <label>{t('pages.login.login')}</label>
                    <div className={'account__row__field'}>
                      <Input placeholder={t('pages.login.login')} />
                    </div>
                  </div>
                  <div className={'account__row'}>
                    <label>{t('pages.login.email')}</label>
                    <Button color={'danger'}>{t('pages.login.verify')}</Button>
                    <div className={'account__row__field'}>
                      <Input placeholder={t('pages.login.email')} />
                    </div>
                  </div>
                  <div className={'account__row'}>
                    <label>{t('pages.login.fullName')}</label>
                    <div className={'account__row__field'}>
                      <Input placeholder={t('pages.login.fullName')} />
                    </div>
                  </div>
                  <div className={'account__row'}>
                    <label>{t('pages.login.phone')}</label>
                    <div className={'account__row__field'}>
                      <Input placeholder={t('pages.login.phone')} />
                    </div>
                  </div>
                  <div className={'account__row'}>
                    <label>{t('pages.login.telegram')}</label>
                    <div className={'account__row__field'}>
                      <Input placeholder={t('pages.login.telegram')} />
                    </div>
                  </div>
                  <div className={'account__row'}>
                    <label>{t('pages.login.skype')}</label>
                    <div className={'account__row__field'}>
                      <Input placeholder={t('pages.login.skype')} />
                    </div>
                  </div>
                  <div className={'account__row'}>
                    <label>{t('pages.login.whatsApp')}</label>
                    <div className={'account__row__field'}>
                      <Input placeholder={t('pages.login.whatsApp')} />
                    </div>
                  </div>

                  <div className={'account__row'}>
                    <label>{t('pages.login.timeZone')}</label>
                    <div className={'account__row__field'}>
                      <Select
                        classNamePrefix={'form_control_react_select'}
                        placeholder={t('pages.login.timeZone')}
                        options={[{ value: '+3', label: 'Europe/Moscow UTC+3' }]}
                      />
                    </div>
                  </div>
                  <div className={'account__row account__row_center'}>
                    <Button color='primary' onClick={props.toggle}>
                      {t('pages.login.save')}
                    </Button>
                    <Button color='secondary' onClick={props.toggle}>
                      {t('pages.login.cancel')}
                    </Button>
                  </div>
                </div>
                <div className={'account_pass'}>
                  <div className={'account__row'}>
                    <label>{t('pages.login.currentPassword')}</label>
                    <div className={'account__row__field'}>
                      <Input
                        className={oldPasswordError ? 'invalid' : ''}
                        type={'password'}
                        value={oldPassword}
                        onChange={e => {
                          setOldPassword(e.target.value)
                          setOldPasswordError(false)
                        }}
                      />

                      <div className={'error'}>
                        {oldPasswordError ? t('pages.login.pleaseEnterCurrentPassword') : ''}
                      </div>
                    </div>
                  </div>
                  <div className={'account__row'}>
                    <label>{t('pages.login.newPassword')}</label>
                    <div className={'account__row__field'}>
                      <Input
                        className={newPasswordError ? 'invalid' : ''}
                        type={'password'}
                        value={newPassword}
                        onChange={e => {
                          setNewPassword(e.target.value)
                          setNewPasswordError(false)
                          setNewPasswordErrorReg(false)
                        }}
                      />

                      <div className={'error'}>
                        {newPasswordError ? t('pages.login.pleaseEnterNewPassword') : ''}
                        {newPasswordErrorReg ? t('pages.login.passwordConditions') : ''}
                      </div>
                    </div>
                  </div>
                  <div className={'account__row'}>
                    <label>{t('pages.login.repeatNewPassword')}</label>
                    <div className={'account__row__field'}>
                      <Input
                        className={newPasswordConfirmError ? 'invalid' : ''}
                        type={'password'}
                        value={newPasswordConfirm}
                        onChange={e => {
                          setNewPasswordConfirm(e.target.value)
                          setNewPasswordConfirmError(false)
                        }}
                      />
                      <div className={'error'}>{newPasswordConfirmError ? t('pages.login.passwordsMatch') : ''}</div>
                    </div>
                  </div>
                  <div className={'account__row account__row_center'}>
                    <Button color='primary' onClick={() => changePass()}>
                      {t('pages.login.updatePassword')}
                    </Button>
                  </div>
                  <div className={'account_password_description'}>{t('pages.login.newPasswordContain')}</div>
                  <h5 className={'account_pass_header'}> {t('pages.login.accountTermination')}</h5>
                  <div className={'account__row account__row_center'}>
                    <Button color='primary' onClick={() => setShowAlertTerminate(true)}>
                      {t('pages.login.deleteAccount')}
                    </Button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className={'account__body'}>
                <div className={'account'}>
                  <h5>{t('pages.login.loginHistory')}</h5>
                  <table className={'table table-striped table-bordered lastlogin_table'}>
                    <thead className={'thead-light'}>
                      <tr>
                        <th>{t('pages.login.dateTime')}</th>
                        <th>{t('pages.login.IPAddress')}</th>
                        <th>{t('pages.login.browser')}</th>
                        <th colSpan={2}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>02.01.2022 22:12:12</td>
                        <td>202.212.110.119</td>
                        <td>Chrome 36.21.31, Linux</td>
                        <td colSpan={2}>20 {t('pages.login.daysAgo')}</td>
                      </tr>
                      <tr>
                        <td>02.01.2022 22:12:12</td>
                        <td>202.212.110.119</td>
                        <td>Chrome 36.21.31, Linux</td>
                        <td colSpan={2}>20 {t('pages.login.daysAgo')}</td>
                      </tr>
                      <tr>
                        <td>02.01.2022 22:12:12</td>
                        <td>202.212.110.119</td>
                        <td>Chrome 36.21.31, Linux</td>
                        <td colSpan={2}>20 {t('pages.login.daysAgo')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={'account_pass'}>
                  <h5>{t('pages.login.sessions')}</h5>
                  <div className={'sessions'}>
                    <div className={'session'}>
                      <div className={'session__data'}>
                        02.01.2022 22:12:12,
                        <br />
                        IP: 202.212.110.119,
                        <br />
                        Chrome 36.21.31, Linux
                      </div>
                      <div className={'session__status session__status_active'}>{t('pages.login.active')}</div>
                      <div className={'session__action'}>
                        <Button color={'primary'}> {t('pages.login.logout')}</Button>
                      </div>
                    </div>
                    <div className={'session'}>
                      <div className={'session__data'}>
                        02.01.2022 22:12:12,
                        <br />
                        IP: 202.212.110.119,
                        <br />
                        Chrome 36.21.31, Linux
                      </div>
                      <div className={'session__status'}>{t('pages.login.notActive')}</div>
                      <div className={'session__action'}>
                        <Button color={'primary'}> {t('pages.login.terminate')}</Button>
                      </div>
                    </div>
                    <div className={'session'}>
                      <div className={'session__data'}>
                        02.01.2022 22:12:12,
                        <br />
                        IP: 202.212.110.119,
                        <br />
                        Chrome 36.21.31, Linux
                      </div>
                      <div className={'session__status'}>{t('pages.login.notActive')}</div>
                      <div className={'session__action'}>
                        <Button color={'primary'}>{t('pages.login.terminate')}</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className={'account__body account__fifty'}>
                <div className={'account'}>
                  <div className={'account__add'}>
                    <h5> {t('pages.login.IPAddressWhiteList')}</h5>
                    <Button color={'primary'}>{t('pages.login.add')}</Button>
                  </div>

                  <table className={'table table-striped table-bordered text-center'}>
                    <thead className={'thead-light'}>
                      <tr>
                        <th>{t('pages.login.IPAddress')}</th>
                        <th>{t('pages.login.mask')}</th>
                        <th>{t('pages.login.actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>101.201.20.20</td>
                        <td>255.255.255.255</td>
                        <td>
                          <div className={'actions__items'}>
                            <div className={'actions__item'} tooltip={t('pages.login.delete')} flow='left'>
                              <i className='fas fa-trash'></i>
                            </div>
                            <div className={'actions__item'} tooltip={t('pages.login.edit')} flow='left'>
                              <i className='fas fa-pencil'></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={'account_pass'}>
                  <div className={'account__add'}>
                    <h5>{t('pages.login.APITokens')}</h5>
                    <Button color={'primary'}>{t('pages.login.add')}</Button>
                  </div>

                  <table className={'table table-striped table-bordered text-center'}>
                    <thead className={'thead-light'}>
                      <tr>
                        <th>{t('pages.login.name')}</th>
                        <th>{t('pages.login.token')}</th>
                        <th>{t('pages.login.actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>token1</td>
                        <td>IYU2***********w732gDF</td>
                        <td>
                          <div className={'actions__items'}>
                            <div className={'actions__item'} tooltip={t('pages.login.delete')} flow='left'>
                              <i className='fas fa-trash'></i>
                            </div>
                            <div className={'actions__item'} tooltip={t('pages.login.edit')} flow='left'>
                              <i className='fas fa-pencil'></i>
                            </div>
                            <div className={'actions__item'} tooltip={t('pages.login.showToken')} flow='left'>
                              <i className='fas fa-bars'></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </ModalBody>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  user: store.login,
})

const mapDispatchToProps = {
  updatePassword: actions.changePassword,
  deleteAccount: actions.deleteAccount,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)
