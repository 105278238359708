import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import * as Login from '../pages/Login'
import Loader from '../components/Loader/reducer'
import * as Rigs from '../pages/RigsData'
import * as Workers from '../pages/Workers'
import * as CustomPools from '../pages/CustomPools'
import * as Presets from '../pages/MiningPresets'
import * as DCManagement from '../pages/DCManagement'
import * as OverclockingProfiles from '../pages/OverclockingProfiles'
import * as AdminPage from '../pages/AdminPage'
import * as Settings from '../pages/Settings'
import * as Schedule from '../pages/Schedule'
import LayoutReduser from './layout/reducer'

import thunk from 'redux-thunk'

export const history = createBrowserHistory()

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    login: Login.reducer,
    loading: Loader,
    rigs: Rigs.reducer,
    main: Workers.reducer,
    presets: Presets.reducer,
    customPools: CustomPools.reducer,
    layout: LayoutReduser,
    dcManagement: DCManagement.reducer,
    profiles: OverclockingProfiles.reducer,
    admin: AdminPage.reducer,
    settings: Settings.reducer,
    schedule: Schedule.reducer,
  })

const store = createStore(reducers(history), compose(applyMiddleware(routerMiddleware(history), thunk)))
export default store
