import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'
import WorkerCreatedMessageModal from './WorkerCreatedMessageModal'
import * as actions from '../../../RigsData/actions'
import { useTranslation } from 'react-i18next'

const WorkerCreateModal = props => {
  const [rigName, setRigName] = useState('')
  const [rigPass, setRigPass] = useState('')
  const [dataCenterId, setDataCenterId] = useState(0)
  const [roomId, setRoomId] = useState(0)
  const [errors, setErrors] = useState({})
  const [rooms, setRooms] = useState([])
  const [workerMessageModal, setWorkerMessageModal] = useState(false)
  const [rigRegistData, setRigRegistData] = useState({})
  const { t } = useTranslation('common')

  useEffect(() => {
    const rooms = []
    props.rooms.map(room => {
      if (room.dataCenterId === dataCenterId) {
        rooms.push(room)
      }
      return room
    })
    setRooms(rooms)
  }, [dataCenterId, props.rooms])

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }

    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case 'worker_name': {
        setRigName(e.target.value)
        return
      }
      case 'worker_pass': {
        setRigPass(e.target.value)
        return
      }
      case 'room_id': {
        setRoomId(e.target.value)
        return
      }
      case 'dataCenter_id': {
        setDataCenterId(e.target.value)
        return
      }
    }
  }

  const toggle = () => {
    setWorkerMessageModal(!workerMessageModal)
    props.toggle()
  }

  const submitFunc = async () => {
    const newErrors = {}
    if (rigName.length === 0) newErrors.worker_name = { message: t('pages.workers.nameError') }
    if (rigPass.length === 0) newErrors.worker_pass = { message: t('pages.workers.passwordError') }
    if (rigPass.length < 5) newErrors.worker_pass = { message: t('pages.workers.minimumCharacters') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return
    const createdRig = await props.createRig({
      name: rigName,
      password: rigPass,
      roomId,
      dcId: dataCenterId,
    })

    if (createdRig) {
      setRigRegistData(createdRig)
      setWorkerMessageModal(!workerMessageModal)
    }
  }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <li tag='li' onClick={props.toggle}>
        {t('pages.workers.worker')}
      </li>
      <Modal isOpen={props.isOpen} toggle={props.toggle} className={'modal-dialog-centered'}>
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.workers.createWorker')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={6} className={'form-group'}>
                <WorkerCreatedMessageModal isOpen={workerMessageModal} toggle={toggle} rig={rigRegistData} />
                <Label>{t('pages.workers.name')}</Label>
                <Input
                  className={errors.worker_name ? 'invalid' : ''}
                  type={'text'}
                  name={'worker_name'}
                  onChange={onChange}
                />
                <div className={'error'}>{errors.worker_name && errors.worker_name.message}</div>
              </Col>

              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.workers.password')}</Label>
                <Input
                  className={errors.worker_pass ? 'invalid' : ''}
                  type={'password'}
                  name={'worker_pass'}
                  onChange={onChange}
                />
                <div className={'error'}>{errors.worker_pass && errors.worker_pass.message}</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Label>{t('pages.workers.dataCenters')}</Label>
                <select
                  className={errors.room_dataCenter_id ? 'form-control invalid' : 'form-control'}
                  name={'dataCenter_id'}
                  onChange={onChange}
                  placeholder={t('inputs.select')}
                >
                  <option value={''}>{t('pages.workers.selectDataCenter')}</option>
                  {props.dataCenters.map((dc, key) => {
                    return (
                      <option key={key} value={dc.id}>
                        {dc.name}
                      </option>
                    )
                  })}
                </select>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.workers.rooms')}</Label>
                <select
                  className={errors.room_dataCenter_id ? 'form-control invalid' : 'form-control'}
                  name={'room_id'}
                  onChange={onChange}
                  placeholder={t('inputs.select')}
                >
                  <option value={''}>{t('pages.workers.selectRoom')}</option>
                  {rooms.map((room, key) => {
                    return (
                      <option key={key} value={room.id}>
                        {room.name}
                      </option>
                    )
                  })}
                </select>
              </Col>
            </Row>

            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.workers.description')}</Label>
                <textarea className={'form-control'} name='worker_description'></textarea>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.workers.cancel')}
          </Button>
          <Button color='primary' onClick={submitFunc}>
            {t('pages.workers.create')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  rigsList: store.rigs.rigsList,
  rigCardsList: store.rigs.rigCardsList,
  dataCenters: store.dcManagement.dataCentersList,
  rooms: store.dcManagement.roomsList,
})

const mapDispatchToProps = {
  createRig: actions.createRig,
  setRigsList: actions.setRigsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerCreateModal)
