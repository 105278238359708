import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import { Input, Label, Button } from 'reactstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import * as actions from '../actions'

const WatchdogSettingsTab = props => {
  const [do_not_reboot_if_ethernet, set_do_not_reboot_if_ethernet] = useState(false)
  const { t } = useTranslation('common')
  const [turn_off_workers_if_ethernet, set_turn_off_workers_if_ethernet] = useState(false)
  const [turn_off_workers_if_ethernet_min, set_turn_off_workers_if_ethernet_min] = useState('')

  const [power_cycle_if_worker_is_unavailable, set_power_cycle_if_worker_is_unavailable] = useState(false)
  const [power_cycle_if_worker_is_unavailable_sec, set_power_cycle_if_worker_is_unavailable_sec] = useState('')
  //   const [
  //     power_cycle_if_worker_is_unavailable_use,
  //     set_power_cycle_if_worker_is_unavailable_use,
  //   ] = useState('')

  const [power_cycle_if_worker_went_unavailable_after_reboot, set_power_cycle_if_worker_went_unavailable_after_reboot] =
    useState(false)
  //   const [
  //     power_cycle_if_worker_went_unavailable_after_reboot_sec,
  //     set_power_cycle_if_worker_went_unavailable_after_reboot_sec,
  //   ] = useState('')

  const [gpu_lostoffline, set_gpu_lostoffline] = useState(false)
  const [gpu_lostoffline_times, set_gpu_lostoffline_times] = useState('')
  //   const [gpu_lostoffline_action, set_gpu_lostoffline_action] = useState('')

  const [shutdown_worker_if_cards_are_in_idle_mode, set_shutdown_worker_if_cards_are_in_idle_mode] = useState(false)
  const [shutdown_worker_if_cards_are_in_idle_mode_min, set_shutdown_worker_if_cards_are_in_idle_mode_min] =
    useState('')

  const [min_power_consumption_per_card, set_min_power_consumption_per_card] = useState(false)
  const [min_power_consumption_per_card_watts, set_min_power_consumption_per_card_watts] = useState('')
  const [min_power_consumption_per_card_select, set_min_power_consumption_per_card_select] = useState('')

  const [max_power_consumption_per_card, set_max_power_consumption_per_card] = useState(false)
  const [max_power_consumption_per_card_watts, set_max_power_consumption_per_card_watts] = useState('')
  const [max_power_consumption_per_card_select, set_max_power_consumption_per_card_select] = useState('')

  const [worker_availability_watchdog, set_worker_availability_watchdog] = useState(false)
  const [hashrate_watchdog, set_hashrate_watchdog] = useState(props.settings.hashrateWatchdog ? true : false)
  const [power_watchdog, set_power_watchdog] = useState(false)

  /////////// LOWHASH ///////////////
  const [if_low_hashrate_alert_is_longer_than, set_if_low_hashrate_alert_is_longer_than] = useState(
    props.settings.lowHashBool ? props.settings.lowHashBool : false,
  )
  const [if_low_hashrate_alert_is_longer_than_min, set_if_low_hashrate_alert_is_longer_than_min] = useState(
    props.settings.lowHashTime ? props.settings.lowHashTime : '',
  )
  const [if_low_hashrate_alert_is_longer_than_select, set_if_low_hashrate_alert_is_longer_than_select] = useState(
    props.settings.lowHashAction ? props.settings.lowHashAction : 'Nothing',
  )

  ///////////// ZEROHASH /////////////
  const [if_zero_hashrate_alert_is_longer_than_min, set_if_zero_hashrate_alert_is_longer_than_min] = useState(
    props.settings.zeroHashTime ? props.settings.zeroHashTime : '',
  )
  const [if_card_hangs_or_zero_hashrate, set_if_card_hangs_or_zero_hashrate] = useState(
    props.settings.zeroHashBool ? props.settings.zeroHashBool : false,
  )
  const [if_card_hangs_or_zero_hashrate_select, set_if_card_hangs_or_zero_hashrate_select] = useState(
    props.settings.zeroHashAction ? props.settings.zeroHashAction : 'Nothing',
  )

  ////////// LA ///////////////////////
  const [if_irq_eats_cpu_andor_la, set_if_irq_eats_cpu_andor_la] = useState(
    props.settings.laBool ? props.settings.laBool : false,
  )
  const [if_irq_eats_cpu_andor_la_min, set_if_irq_eats_cpu_andor_la_min] = useState(
    props.settings.la ? props.settings.la : '',
  )
  const [if_irq_eats_cpu_andor_la_select, set_if_irq_eats_cpu_andor_la_select] = useState(
    props.settings.laAction ? props.settings.laAction : 'Nothing',
  )

  const [hashrate_watchdog_action, set_hashrate_watchdog_action] = useState(false)
  function changeLaActionHashrateWatcdog() {
    set_hashrate_watchdog_action(true)
    set_hashrate_watchdog(!hashrate_watchdog)
  }

  function applySettings() {
    props.updateGeneralSettings({
      la: if_irq_eats_cpu_andor_la ? if_irq_eats_cpu_andor_la_min : '',
      laAction: if_irq_eats_cpu_andor_la ? if_irq_eats_cpu_andor_la_select : '',
      laBool: if_irq_eats_cpu_andor_la,
      lowHashTime: if_low_hashrate_alert_is_longer_than ? if_low_hashrate_alert_is_longer_than_min : 0,
      lowHashBool: if_low_hashrate_alert_is_longer_than,
      lowHashAction: if_low_hashrate_alert_is_longer_than ? if_low_hashrate_alert_is_longer_than_select : '',
      zeroHashTime: if_card_hangs_or_zero_hashrate ? if_zero_hashrate_alert_is_longer_than_min : 0,
      zeroHashBool: if_card_hangs_or_zero_hashrate,
      zeroHashAction: if_card_hangs_or_zero_hashrate ? if_card_hangs_or_zero_hashrate_select : '',
      hashrateWatchdog: hashrate_watchdog,
    })
  }

  useEffect(() => {
    console.log(props.settings.zeroHashAction)
    if (hashrate_watchdog_action) {
      if (hashrate_watchdog) {
        props.updateGeneralSettings({
          hashrateWatchdog: true,
        })
      } else {
        props.updateGeneralSettings({
          la: '',
          laAction: '',
          laBool: false,
          lowHashBool: false,
          lowHashTime: '',
          lowHashAction: '',
          zeroHashBool: false,
          zeroHashTime: '',
          zeroHashAction: '',
          hashrateWatchdog: false,
        })
        set_if_irq_eats_cpu_andor_la(false)
        set_if_card_hangs_or_zero_hashrate(false)
        set_if_low_hashrate_alert_is_longer_than(false)
      }
      set_hashrate_watchdog_action(false)
    }
  }, [hashrate_watchdog_action])

  return (
    <div className={'settings__watchdog'}>
      <div className={'settings__section'}>
        <div className={'settings__section_header'}>
          <h4>{t('pages.settings.workerAvailabilityWatchdog')}</h4>
          <Switch
            onColor='#02a499'
            onChange={() => set_worker_availability_watchdog(!worker_availability_watchdog)}
            checked={worker_availability_watchdog}
            height={20}
            width={40}
          />
        </div>
        <div className={'settings__section_body'} style={{ opacity: worker_availability_watchdog ? 1 : 0.2 }}>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={do_not_reboot_if_ethernet && worker_availability_watchdog}
                onChange={() => set_do_not_reboot_if_ethernet(!do_not_reboot_if_ethernet)}
                disabled={!worker_availability_watchdog}
              />
              {t('pages.settings.doNotRebootEthernet')}
            </Label>
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={turn_off_workers_if_ethernet && worker_availability_watchdog}
                onChange={() => set_turn_off_workers_if_ethernet(!turn_off_workers_if_ethernet)}
                disabled={!worker_availability_watchdog}
              />
              {t('pages.settings.turnOffWorkersIfEthernet')}
            </Label>
            <Input
              placeholder={t('pages.settings.mins')}
              disabled={!worker_availability_watchdog || !turn_off_workers_if_ethernet}
              value={turn_off_workers_if_ethernet_min}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              onChange={e => set_turn_off_workers_if_ethernet_min(e.target.value)}
            />
            {t('pages.settings.MinutesRPCServerRequired')}
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={power_cycle_if_worker_is_unavailable && worker_availability_watchdog}
                onChange={() => set_power_cycle_if_worker_is_unavailable(!power_cycle_if_worker_is_unavailable)}
                disabled={!worker_availability_watchdog}
              />
              {t('pages.settings.PowerCycleIfWorkerUnavailable')}
            </Label>
            <Input
              placeholder={t('pages.settings.secs')}
              disabled={!worker_availability_watchdog || !power_cycle_if_worker_is_unavailable}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={power_cycle_if_worker_is_unavailable_sec}
              onChange={e => set_power_cycle_if_worker_is_unavailable_sec(e.target.value)}
            />
            {t('pages.settings.seconds')}
            <div className={'settings__radiogroup'}>
              <Label className={'checkboxLabel'}>
                <input
                  name={'power_cycle_if_worker_is_unavailable_sec'}
                  type={'radio'}
                  //checked={do_not_reboot_if_ethernet}
                  //   onChange={(_e) =>
                  //     set_power_cycle_if_worker_is_unavailable_use('use_rpc')
                  //   }
                  disabled={!worker_availability_watchdog || !power_cycle_if_worker_is_unavailable}
                />
                {t('pages.settings.useRPCPDUDevice')}
              </Label>
              <Label className={'checkboxLabel'}>
                <input
                  name={'power_cycle_if_worker_is_unavailable_sec'}
                  type={'radio'}
                  //checked={do_not_reboot_if_ethernet}
                  //   onChange={(_e) =>
                  //     set_power_cycle_if_worker_is_unavailable_use('use_usb')
                  //   }
                  disabled={!worker_availability_watchdog || !power_cycle_if_worker_is_unavailable}
                />
                {t('pages.settings.useUSBWatchdogDevice')}
              </Label>
            </div>
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={power_cycle_if_worker_went_unavailable_after_reboot && worker_availability_watchdog}
                onChange={() =>
                  set_power_cycle_if_worker_went_unavailable_after_reboot(
                    !power_cycle_if_worker_went_unavailable_after_reboot,
                  )
                }
                disabled={!worker_availability_watchdog}
              />
              {t('pages.settings.powerCycleWorkerWentUnavailable')}
            </Label>
            <Input
              placeholder={'1'}
              disabled={!worker_availability_watchdog || !power_cycle_if_worker_went_unavailable_after_reboot}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              //   value={power_cycle_if_worker_went_unavailable_after_reboot_sec}
              onChange={e => set_power_cycle_if_worker_is_unavailable_sec(e.target.value)}
            />
            {t('pages.settings.maxTimesRPCServerRequired')}
          </div>
        </div>
      </div>

      <div className={'settings__section'}>
        <div className={'settings__section_header'}>
          <h4>{t('pages.settings.hashrateWatchdog')}</h4>
          <Switch
            onColor='#02a499'
            checked={hashrate_watchdog}
            onChange={() => changeLaActionHashrateWatcdog()}
            height={20}
            width={40}
          />
        </div>
        <div className={'settings__section_body'} style={{ opacity: hashrate_watchdog ? 1 : 0.2 }}>
          <div className={'settings__row'} style={{ opacity: 0.2 }}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={hashrate_watchdog}
                onChange={() => set_gpu_lostoffline(!gpu_lostoffline)}
                disabled={!hashrate_watchdog}
              />
              {t('pages.settings.GPULostWorkerAction')}
            </Label>
            <div className={'settings__radiogroup'}>
              <Label className={'checkboxLabel'}>
                <input
                  name={'gpu_lostoffline_action'}
                  type={'radio'}
                  //   value={gpu_lostoffline_action}
                  disabled={!hashrate_watchdog || !gpu_lostoffline}
                  //   onChange={(_e) => set_gpu_lostoffline_action('reboot')}
                />
                {t('pages.settings.reboot')}
              </Label>
              <Label className={'checkboxLabel'}>
                <input
                  name={'gpu_lostoffline_action'}
                  type={'radio'}
                  disabled={!hashrate_watchdog || !gpu_lostoffline}
                  //   onChange={(_e) => set_gpu_lostoffline_action('power_cycle')}
                />
                {t('pages.settings.rebootHard')}
              </Label>
            </div>
            <Input
              placeholder={'1'}
              disabled={!hashrate_watchdog || !gpu_lostoffline}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={gpu_lostoffline_times}
              onChange={e => set_gpu_lostoffline_times(e.target.value)}
            />
            {t('pages.settings.timesUntilGPUGetsOnline')}
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={if_low_hashrate_alert_is_longer_than && hashrate_watchdog}
                onChange={() => set_if_low_hashrate_alert_is_longer_than(!if_low_hashrate_alert_is_longer_than)}
                disabled={!hashrate_watchdog}
              />
              {t('pages.settings.ifLowHashrateAlert')}
            </Label>
            <Input
              placeholder={'0'}
              disabled={!hashrate_watchdog}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={if_low_hashrate_alert_is_longer_than_min}
              onChange={e => set_if_low_hashrate_alert_is_longer_than_min(e.target.value)}
            />
            {t('pages.settings.minutesThen')}
            <Select
              classNamePrefix={'form_control_react_select'}
              options={[
                { value: '1', label: t('pages.settings.rebootHard') },
                { value: '2', label: t('pages.settings.reboot') },
                { value: '3', label: t('pages.settings.nothing') },
              ]}
              isDisabled={!hashrate_watchdog}
              value={if_low_hashrate_alert_is_longer_than_select.label}
              placeholder={if_low_hashrate_alert_is_longer_than_select}
              onChange={option => set_if_low_hashrate_alert_is_longer_than_select(option.label)}
            />
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={if_card_hangs_or_zero_hashrate && hashrate_watchdog}
                onChange={() => set_if_card_hangs_or_zero_hashrate(!if_card_hangs_or_zero_hashrate)}
                disabled={!hashrate_watchdog}
              />
              {t('pages.settings.ifCardHangsZeroHashrate')}
            </Label>
            <Input
              placeholder={'0'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={if_zero_hashrate_alert_is_longer_than_min}
              onChange={e => set_if_zero_hashrate_alert_is_longer_than_min(e.target.value)}
            />
            {t('pages.settings.minutesThen')}
            <Select
              hideSelectedOptions
              placeholder={if_card_hangs_or_zero_hashrate_select}
              classNamePrefix={'form_control_react_select'}
              options={[
                { value: '1', label: t('pages.settings.rebootHard') },
                { value: '2', label: t('pages.settings.reboot') },
                { value: '3', label: t('pages.settings.nothing') },
              ]}
              isDisabled={!hashrate_watchdog || !if_card_hangs_or_zero_hashrate}
              value={if_card_hangs_or_zero_hashrate_select.label}
              onChange={option => set_if_card_hangs_or_zero_hashrate_select(option.label)}
            />
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={if_irq_eats_cpu_andor_la && hashrate_watchdog}
                onChange={() => set_if_irq_eats_cpu_andor_la(!if_irq_eats_cpu_andor_la)}
                disabled={!hashrate_watchdog}
              />
              {t('pages.settings.ifIRQSatsCPULA')}
            </Label>
            <Input
              placeholder={'0'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={if_irq_eats_cpu_andor_la_min}
              onChange={e => set_if_irq_eats_cpu_andor_la_min(e.target.value)}
            />
            {t('pages.settings.during5Mins')}
            <Select
              hideSelectedOptions
              placeholder={if_irq_eats_cpu_andor_la_select}
              classNamePrefix={'form_control_react_select'}
              options={[
                { value: 1, label: t('pages.settings.reboot') },
                { value: 2, label: t('pages.settings.rebootHard') },
                { value: 3, label: t('pages.settings.nothing') },
              ]}
              value={if_irq_eats_cpu_andor_la_select.label}
              onChange={option => set_if_irq_eats_cpu_andor_la_select(option.label)}
            />
          </div>
          <div className={'settings__row'}>
            <Button
              color='primary'
              onClick={() => {
                applySettings()
              }}
              disabled={!hashrate_watchdog}
            >
              {t('pages.miningPresets.apply')}
            </Button>
          </div>
        </div>
      </div>

      <div className={'settings__section'}>
        <div className={'settings__section_header'}>
          <h4>{t('pages.settings.powerWatchdog')}</h4>
          <Switch
            onColor='#02a499'
            onChange={() => set_power_watchdog(!power_watchdog)}
            checked={power_watchdog}
            height={20}
            width={40}
          />
        </div>
        <div className={'settings__section_body'} style={{ opacity: power_watchdog ? 1 : 0.2 }}>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={shutdown_worker_if_cards_are_in_idle_mode && power_watchdog}
                onChange={() =>
                  set_shutdown_worker_if_cards_are_in_idle_mode(!shutdown_worker_if_cards_are_in_idle_mode)
                }
                disabled={!power_watchdog}
              />
              {t('pages.settings.shutdownWorkerIfCardsIdleMode')}
            </Label>
            <Input
              placeholder={t('pages.settings.mins')}
              disabled={!power_watchdog || !shutdown_worker_if_cards_are_in_idle_mode}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={shutdown_worker_if_cards_are_in_idle_mode_min}
              onChange={e => set_shutdown_worker_if_cards_are_in_idle_mode_min(e.target.value)}
            />
            {t('pages.settings.minutes')}
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={min_power_consumption_per_card && power_watchdog}
                onChange={() => set_min_power_consumption_per_card(!min_power_consumption_per_card)}
                disabled={!power_watchdog}
              />
              {t('pages.settings.minPowerConsumptionCard')}
            </Label>
            <Input
              placeholder={''}
              disabled={!power_watchdog || !min_power_consumption_per_card}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={min_power_consumption_per_card_watts}
              onChange={e => set_min_power_consumption_per_card_watts(e.target.value)}
            />
            {t('pages.settings.wattsThen')}
            <Select
              hideSelectedOptions
              placeholder={t('inputs.select')}
              classNamePrefix={'form_control_react_select'}
              options={[
                { value: '1', label: t('pages.settings.reboot') },
                { value: '2', label: t('pages.settings.powerOff') },
              ]}
              isDisabled={!power_watchdog || !min_power_consumption_per_card}
              value={min_power_consumption_per_card_select}
              onChange={option => set_min_power_consumption_per_card_select(option)}
            />
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={max_power_consumption_per_card && power_watchdog}
                onChange={() => set_max_power_consumption_per_card(!max_power_consumption_per_card)}
                disabled={!power_watchdog}
              />
              {t('pages.settings.maxPowerConsumptionCard')}
            </Label>
            <Input
              placeholder={''}
              disabled={!power_watchdog || !max_power_consumption_per_card}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={max_power_consumption_per_card_watts}
              onChange={e => set_max_power_consumption_per_card_watts(e.target.value)}
            />
            {t('pages.settings.wattsThen')}
            <Select
              hideSelectedOptions
              placeholder={t('inputs.select')}
              classNamePrefix={'form_control_react_select'}
              options={[
                { value: '1', label: t('pages.settings.reboot') },
                { value: '2', label: t('pages.settings.powerCycle') },
              ]}
              isDisabled={!power_watchdog || !max_power_consumption_per_card}
              value={max_power_consumption_per_card_select}
              onChange={option => set_max_power_consumption_per_card_select(option)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  settings: store.settings,
})

const mapDispatchToProps = {
  updateGeneralSettings: actions.updateGeneralSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchdogSettingsTab)
