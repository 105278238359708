import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'node-uuid'

const WorkerCards = props => {
  const { t } = useTranslation('common')
  const rig = props.rig

  return useMemo(() => {
    return (
      <div className={'worker_cards'} key={rig.name + rig.rigMac}>
        {rig.videoCards && rig.active
          ? rig.videoCards.map(card => {
              if (isNaN(parseFloat(card.fanInfo)) || !card.detected) card.fanInfo = 0
              rig.avgFan += parseFloat(card.fanInfo)
              if (!card.detected) {
                return (
                  <div key={uuid()} className={'worker_card card__not_detected'}>
                    <span></span>
                    <div className={'worker_card__info'}>
                      {t('pages.workers.GPU')} {t('pages.workers.isOFFLINEMissing')}
                    </div>
                  </div>
                )
              }
              if (card.lowHash && rig.action !== 'stop') {
                return (
                  <div key={uuid()} className={'worker_card card__problem'}>
                    <span></span>
                    <div className={'worker_card__info'}>
                      {t('pages.workers.GPU')}
                      {card.cardNum} {t('pages.workers.cardFan')} {card.fanInfo}
                      %, {t('pages.workers.cardTemp')} {card.gpuTemp}
                      {card.memTemp ? `/${card.memTemp}` : ''}℃ <div style={{ color: 'red' }}>Mining Failure</div>
                    </div>
                  </div>
                )
              }

              if (
                rig.action === 'stop' ||
                (rig.action !== 'stop' &&
                  rig.action !== 'stopError' &&
                  +rig.gpuTotalSpeed <= 0 &&
                  rig.miningPresetId) ||
                card.offMining ||
                !rig.miningPresetId
              ) {
                return (
                  <div key={uuid()} className={'worker_card card__no_mining'}>
                    <span></span>
                    <div className={'worker_card__info'}>
                      {t('pages.workers.GPU')}
                      {card.cardNum} {t('pages.workers.cardFan')} {card.fanInfo}
                      %, {t('pages.workers.cardTemp')} {card.gpuTemp}
                      {card.memTemp ? `/${card.memTemp}` : ''}℃<br />
                      {t('pages.workers.awaitingMining')}
                    </div>
                  </div>
                )
              }

              return (
                <div
                  key={uuid()}
                  className={
                    +card.highTemp ? 'worker_card card_issue' : card.critTemp ? 'worker_card card_error' : 'worker_card'
                  }
                >
                  <span></span>
                  <div className={'worker_card__info'}>
                    {t('pages.workers.GPU')}
                    {card.cardNum} {t('pages.workers.cardFan')} {card.fanInfo}%, {t('pages.workers.cardTemp')}{' '}
                    {card.gpuTemp}℃{' '}
                    {+card.highTemp ? (
                      <div className={'worker_card__info_temp'}>{t('pages.workers.hot')}</div>
                    ) : card.critTemp ? (
                      <div className={'worker_card__info_temp'}>{t('pages.workers.burn')}</div>
                    ) : (
                      <div className={'worker_card__info_temp'}>{t('pages.workers.normal')}</div>
                    )}
                  </div>
                </div>
              )
            })
          : rig.videoCards && !rig.active
          ? rig.videoCards.map(card => {
              if (isNaN(parseFloat(card.fanInfo))) card.fanInfo = 0
              rig.avgFan += parseFloat(card.fanInfo)

              return (
                <div key={uuid()} className={'worker_card card__unknown'}>
                  <span></span>
                  <div className={'worker_card__info'}>
                    {t('pages.workers.GPU')}
                    {card.cardNum}
                  </div>
                </div>
              )
            })
          : ''}
      </div>
    )
  }, [
    props.checkMiningTime,
    rig.id,
    rig.gpuTotalShares,
    rig.gpuTotalSpeed,
    rig.hashRate,
    rig.gpuTotalSpeedDual,
    rig.errorsTotal,
    rig.powerInfo,
    rig.active,
    t,
  ])
}

export default WorkerCards
