import React, { useEffect, useState } from 'react'
import { Col, Input, Row } from 'reactstrap'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const TableMPs = ({
  data,
  stickyHeader = false,
  responsive = false,
  maxHeight = false,
  firstColumnCheckbox = false,
  entries = [10, 20, 50, 100, 250],
  showEntries = true,
  showPagination = true,
  duplicatePagination = false,
  duplicateEntries = false,
  name = 'dataTable',
  uncheckAll = false,
  miningPresetsTotal,
  currentPage,
  setCurrentPage,
  showCount,
  setShowCount,
  ...props
}) => {
  if (window.location.pathname !== Cookies.get('pathName')) {
    Cookies.set('pathName', window.location.pathname, { expires: 30 })
    Cookies.set('currentPage' + name, 1, { expires: 30 })
    for (const key of Object.keys(Cookies.get())) {
      if (key.toString().includes('currentPage')) {
        Cookies.set(key, 1, { expires: 30 })
      }
    }
  }
  const [search, setSearch] = useState('')

  const { t } = useTranslation('common')
  const [allChecked, setAllChecked] = useState(false)
  const [updateState, setUpdateState] = useState(false)
  const [dataRows, setDataRows] = useState(data.rows)
  const [dataColumns, setDataColumns] = useState(data.columns)
  const [sortBy, setSortBy] = useState(
    Cookies.get(window.location.pathname + name + 'sortBy')
      ? Cookies.get(window.location.pathname + name + 'sortBy')
      : false,
  )
  const [sortDirection, setSortDirection] = useState(
    Cookies.get(window.location.pathname + name + 'sortDirection')
      ? Cookies.get(window.location.pathname + name + 'sortDirection') === 'true'
      : true,
  )
  const [sortType, setSortType] = useState(
    Cookies.get(window.location.pathname + name + 'sortType')
      ? Cookies.get(window.location.pathname + name + 'sortType')
      : 'int',
  )
  const [sortByValue, setSortByValue] = useState(
    Cookies.get(window.location.pathname + name + 'sortByValue')
      ? Cookies.get(window.location.pathname + name + 'sortByValue') === 'true'
      : false,
  )
  useEffect(() => {
    let newRows = []
    if (props.favoriteFirst) {
      const favorites = sort(
        data.rows.filter(row => row.favorite),
        sortBy,
        sortType,
        sortDirection,
        sortByValue,
      )
      const other = sort(
        data.rows.filter(row => !row.favorite),
        sortBy,
        sortType,
        sortDirection,
        sortByValue,
      )
      newRows = [...favorites, ...other]
    } else {
      newRows = sort(data.rows, sortBy, sortType, sortDirection, sortByValue)
    }
    if (data.rows.length > 0) {
      setCurrentPage(Cookies.get('currentPage' + name) ? parseInt(Cookies.get('currentPage' + name)) : 0)
    }
    setDataRows(
      newRows
        .filter(row => {
          let find = !props.searchable
          dataColumns
            .filter(column => column.search)
            .map(column => {
              if (row[column.field].toString().toLowerCase().search(search.toLowerCase()) >= 0) {
                find = true
              }
              return column
            })
          return find
        })
        .map(row => {
          return {
            ...row,
            checked: dataRows.find(row_f => row_f.id === row.id)
              ? dataRows.find(row_f => row_f.id === row.id).checked
              : false,
          }
        }),
    )
  }, [data.rows, props.favoriteFirst, search])

  useEffect(() => {
    setUncheckAll()
  }, [uncheckAll])

  useEffect(() => {
    setDataColumns(
      data.columns.map(column_new => {
        return {
          ...column_new,
          sorted: column_new.field === sortBy,
          sortDirection: column_new.field === sortBy ? (sortDirection ? 'asc' : 'desc') : 'asc',
        }
      }),
    )
  }, [data.columns, sortBy, sortDirection])

  const setVisible = props.setVisible

  useEffect(() => {
    if (setVisible) setVisible(dataRows)
  }, [dataRows, currentPage, showCount, setVisible])

  const setCurrentPageFunc = currentPage => {
    setCurrentPage(currentPage)
    setUncheckAll()
    Cookies.set('currentPage' + name, currentPage, { expires: 30 })
  }
  const setShowCountFunc = showCount => {
    setShowCount(showCount)
    Cookies.set(window.location.pathname + name + 'showCount', showCount, {
      expires: 30,
    })
  }
  const allCheckbox = () => {
    setAllChecked(!allChecked)
    const rows = []
    for (let i = currentPage * showCount; i < (currentPage + 1) * showCount && i < dataRows.length; i++) {
      if ((dataRows[i].checked && !allChecked === false) || (!dataRows[i].checked && !allChecked === true)) {
        rows.push(dataRows[i])
      }
      dataRows[i].checked = !allChecked
    }
    if (props.checkAll) props.checkAll(rows)
    setUpdateState(!updateState)
  }

  useEffect(() => {
    setUncheckAll()
  }, [props.clearCheck])

  const checkRow = row => {
    if (props.checkRow) props.checkRow(row)
    row.checked = !row.checked
    let allChecked = true
    for (let i = currentPage * showCount; i < (currentPage + 1) * showCount && i < dataRows.length; i++) {
      if (!dataRows[i].checked) allChecked = false
    }
    setUpdateState(!updateState)
    setAllChecked(allChecked)
  }
  const nextPage = () => {
    if (currentPage * showCount < miningPresetsTotal) {
      setCurrentPageFunc(currentPage + +1)
      //uncheckAll()
    }
  }
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPageFunc(currentPage - 1)
      //uncheckAll()
    }
  }

  const setUncheckAll = () => {
    setAllChecked(false)
    const rows = []
    for (let i = 0; i < dataRows.length; i++) {
      if (dataRows[i].checked) {
        rows.push(dataRows[i])
      }
      dataRows[i].checked = false
    }
    if (props.checkAll) props.checkAll(rows)
  }

  const makeSort = (field, sortType, column) => {
    Cookies.set(window.location.pathname + name + 'sortBy', field, {
      expires: 30,
    })
    Cookies.set(window.location.pathname + name + 'sortType', sortType, {
      expires: 30,
    })
    Cookies.set(window.location.pathname + name + 'sortByValue', column.sortByValue, { expires: 30 })
    setSortByValue(column.sortByValue)
    if (field === sortBy) {
      setSortDirection(!sortDirection)
      Cookies.set(window.location.pathname + name + 'sortDirection', !sortDirection, { expires: 30 })
      if (props.favoriteFirst) {
        const favorites = sort(
          dataRows.filter(row => row.favorite),
          field,
          sortType,
          !sortDirection,
          !!column.sortByValue,
        )
        const other = sort(
          dataRows.filter(row => !row.favorite),
          field,
          sortType,
          !sortDirection,
          !!column.sortByValue,
        )
        setDataRows([...favorites, ...other])
      } else {
        sort(dataRows, field, sortType, !sortDirection, !!column.sortByValue)
      }
      setDataColumns(
        dataColumns.map(column_new => {
          return {
            ...column_new,
            sorted: column_new === column,
            sortDirection: column_new === column ? (!sortDirection ? 'asc' : 'desc') : 'asc',
          }
        }),
      )
    } else {
      setSortBy(field)
      setSortType(sortType)
      setSortDirection(true)
      Cookies.set(window.location.pathname + name + 'sortDirection', true, {
        expires: 30,
      })
      sort(dataRows, field, sortType, true, !!column.sortByValue)
      if (props.favoriteFirst) {
        const favorites = sort(
          dataRows.filter(row => row.favorite),
          field,
          sortType,
          true,
          !!column.sortByValue,
        )
        const other = sort(
          dataRows.filter(row => !row.favorite),
          field,
          sortType,
          true,
          !!column.sortByValue,
        )
        setDataRows([...favorites, ...other])
      } else {
        sort(dataRows, field, sortType, true, !!column.sortByValue)
      }
      setDataColumns(
        dataColumns.map(column_new => {
          return {
            ...column_new,
            sorted: column_new === column,
            sortDirection: 'asc',
          }
        }),
      )
    }
  }

  const sort = (data, sortBy, sortType, sortDirection, sortByValue = false) => {
    if (!data || data.length <= 0) return data
    if (sortType === 'int') {
      if (sortDirection) {
        data.sort((a, b) => {
          let comp1 = -Number.MAX_VALUE
          let comp2 = -Number.MAX_VALUE
          if (sortByValue) {
            comp1 = a[sortBy + 'Value']
            comp2 = b[sortBy + 'Value']
          } else {
            comp1 = a[sortBy]
            comp2 = b[sortBy]
          }
          if (!comp1) comp1 = -Number.MAX_VALUE
          if (!comp2) comp2 = -Number.MAX_VALUE
          return comp1 - comp2
        })
      } else {
        data.sort((a, b) => {
          let comp1 = -Number.MAX_VALUE
          let comp2 = -Number.MAX_VALUE
          if (sortByValue) {
            comp1 = a[sortBy + 'Value']
            comp2 = b[sortBy + 'Value']
          } else {
            comp1 = a[sortBy]
            comp2 = b[sortBy]
          }
          if (!comp1) comp1 = -Number.MAX_VALUE
          if (!comp2) comp2 = -Number.MAX_VALUE
          return comp2 - comp1
        })
      }
    }
    if (sortType === 'string') {
      if (sortDirection) {
        data.sort((a, b) => {
          if (sortByValue) {
            if (!a[sortBy + 'Value']) a[sortBy + 'Value'] = ''
            if (!b[sortBy + 'Value']) b[sortBy + 'Value'] = ''
          } else {
            if (!a[sortBy]) a[sortBy] = ''
            if (!b[sortBy]) b[sortBy] = ''
          }
          return sortByValue
            ? a[sortBy + 'Value'].localeCompare(b[sortBy + 'Value'])
            : a[sortBy].localeCompare(b[sortBy])
        })
      } else {
        data.sort((a, b) => {
          if (sortByValue) {
            if (!a[sortBy + 'Value']) a[sortBy + 'Value'] = ''
            if (!b[sortBy + 'Value']) b[sortBy + 'Value'] = ''
          } else {
            if (!a[sortBy]) a[sortBy] = ''
            if (!b[sortBy]) b[sortBy] = ''
          }
          return sortByValue
            ? b[sortBy + 'Value'].localeCompare(a[sortBy + 'Value'])
            : b[sortBy].localeCompare(a[sortBy])
        })
      }
    }
    return data
  }

  return (
    <div className={'dataTable__container'}>
      <Row>
        {showEntries ? (
          <Col sm={6} md={5}>
            <div className={'dataTables_entries'}>
              <label>{t('table.showPerPage')}</label>
              <select
                className='custom-select custom-select-sm form-control form-control-sm'
                value={showCount}
                onChange={e => {
                  setShowCountFunc(parseInt(e.target.value))
                  setCurrentPageFunc(1)
                  //uncheckAll()
                }}
              >
                {entries.map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  )
                })}
              </select>
              {miningPresetsTotal > 0 ? (
                <div className='dataTables_info'>
                  {miningPresetsTotal > showCount && currentPage != 1
                    ? miningPresetsTotal > showCount && currentPage < 3
                      ? 1 + showCount
                      : 1 + showCount * (currentPage - 1)
                    : 1}{' '}
                  -{' '}
                  {miningPresetsTotal > showCount
                    ? showCount * currentPage > miningPresetsTotal
                      ? miningPresetsTotal + ' ' + t('table.of')
                      : showCount * currentPage + ' ' + t('table.of')
                    : null}{' '}
                  {miningPresetsTotal}
                </div>
              ) : (
                <div className='dataTables_info'>{t('table.showing')} 0</div>
              )}
            </div>
          </Col>
        ) : null}
        <Col sm={6} md={7}>
          <div className='dataTables_paginate'>
            {showPagination && duplicatePagination ? (
              <ul data-test='pagination' className='pagination'>
                <li
                  data-test='page-item'
                  className={currentPage === 1 ? 'disabled page-item' : 'page-item'}
                  onClick={prevPage}
                >
                  <button data-test='page-link' aria-label='Previous' className='page-link page-link'>
                    <span>{t('table.previous')}</span>
                  </button>
                </li>
                {currentPage - 3 > 1 ? (
                  <li onClick={() => setCurrentPageFunc(1)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      1..
                    </button>
                  </li>
                ) : null}
                {currentPage - 1 > 1 ? (
                  <li onClick={() => setCurrentPageFunc(currentPage - 2)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage - 1}
                    </button>
                  </li>
                ) : null}
                {currentPage > 1 ? (
                  <li onClick={() => setCurrentPageFunc(currentPage - 1)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage}
                    </button>
                  </li>
                ) : null}

                <li data-test='page-item' className='active page-item'>
                  <button data-test='page-link' className='page-link page-link'>
                    {currentPage}
                  </button>
                </li>

                {(currentPage + +1) * showCount < miningPresetsTotal ||
                ((currentPage + +1) * showCount - miningPresetsTotal > 0 &&
                  (currentPage + +1) * showCount - miningPresetsTotal < showCount) ? (
                  <li onClick={() => setCurrentPageFunc(currentPage + +1)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage + +1}
                    </button>
                  </li>
                ) : null}

                {(currentPage + +2) * showCount < miningPresetsTotal ||
                ((currentPage + +2) * showCount - miningPresetsTotal > 0 &&
                  (currentPage + +2) * showCount - miningPresetsTotal < showCount) ? (
                  <li onClick={() => setCurrentPageFunc(currentPage + +2)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage + +2}
                    </button>
                  </li>
                ) : null}

                {(currentPage + +3) * showCount < miningPresetsTotal ||
                ((currentPage + +3) * showCount - miningPresetsTotal > 0 &&
                  (currentPage + +3) * showCount - miningPresetsTotal < showCount) ? (
                  <li
                    onClick={() =>
                      setCurrentPageFunc(
                        Math.floor(
                          miningPresetsTotal % showCount === 0
                            ? (miningPresetsTotal - 1) / showCount
                            : miningPresetsTotal / showCount,
                        ) + +1,
                      )
                    }
                    data-test='page-item'
                    className='page-item'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      ..
                      {Math.floor(
                        miningPresetsTotal % showCount === 0
                          ? (miningPresetsTotal - 1) / showCount
                          : miningPresetsTotal / showCount,
                      ) + +1}
                    </button>
                  </li>
                ) : null}
                <li
                  data-test='page-item'
                  className={currentPage * showCount >= miningPresetsTotal ? 'disabled page-item' : 'page-item'}
                  onClick={nextPage}
                >
                  <button data-test='page-link' aria-label='Next' className='page-link page-link'>
                    <span>{t('table.next')}</span>
                  </button>
                </li>
              </ul>
            ) : null}
            {props.searchable ? (
              <div className={'quicksearch'}>
                <Input
                  onChange={e => {
                    setSearch(e.target.value)
                    setCurrentPageFunc(1)
                  }}
                  value={search}
                  name={'quicksearch'}
                  className={'form-control'}
                  placeholder={t('table.quickSearch')}
                />
                <i
                  style={{ display: search ? 'block' : 'none' }}
                  className='fas fa-times'
                  onClick={() => {
                    setSearch('')
                    setCurrentPageFunc(1)
                  }}
                ></i>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      <div
        className={'table_container_responsive'}
        style={{
          overflowX: responsive ? 'auto' : 'visible',
          maxHeight: responsive ? 1000000 : 'unset',
        }}
      >
        <div
          className={'table_container'}
          style={{
            maxHeight: maxHeight ? maxHeight : 'unset',
            overflowY: maxHeight ? 'auto' : 'visible',
            overflowX: 'visible',
          }}
        >
          <table className={props.className + ' table table-striped table-bordered'}>
            <thead
              className={stickyHeader ? 'sticky_header thead-light' : 'thead-light'}
              style={{ top: maxHeight || responsive ? -1 : null }}
            >
              <tr>
                {firstColumnCheckbox ? (
                  <th className={'tableCheckbox'}>
                    <input checked={allChecked} type={'checkbox'} onChange={() => allCheckbox()} />
                  </th>
                ) : null}
                {dataColumns &&
                  dataColumns.map(column => {
                    return (
                      <th
                        className={column.sort ? 'sorting_column' : ''}
                        key={column.field + column.sorted + column.sortDirection}
                        onClick={() => {
                          if (column.sort) makeSort(column.field, column.sortType, column)
                        }}
                      >
                        {column.label}
                        {column.sort && column.sorted ? (
                          <span className={'column_sort_direction'}>
                            {column.sortDirection === 'asc' ? (
                              <i className='fas fa-caret-up'></i>
                            ) : (
                              <i className='fas fa-caret-down'></i>
                            )}
                          </span>
                        ) : column.sort && !column.sorted ? (
                          <span style={{ opacity: 0.7 }} className={'column_sort'}>
                            <i className='fas fa-sort'></i>
                          </span>
                        ) : null}
                      </th>
                    )
                  })}
              </tr>
            </thead>
            <tbody>
              {dataRows.map((row, key) => {
                return (
                  <tr
                    key={currentPage + row.id}
                    style={row.favorite ? { backgroundColor: 'rgb(98 110 212 / 26%)' } : {}}
                  >
                    {firstColumnCheckbox ? (
                      <td className={'tableCheckbox'}>
                        <input
                          checked={row.checked}
                          type={'checkbox'}
                          onChange={() => checkRow(row)}
                          key={currentPage + row + key}
                        />
                      </td>
                    ) : null}
                    {dataColumns &&
                      dataColumns.map(column => {
                        const styles = {}
                        if (column.width) styles.minWidth = column.width
                        if (column.maxWidth) styles.maxWidth = column.maxWidth
                        return (
                          <td
                            style={styles}
                            key={currentPage + key + column.field}
                            className={column.addToFavorite ? 'add_to_favorite_column' : ''}
                          >
                            {row[column.field]}
                            {column.addToFavorite ? (
                              !row.favorite ? (
                                <div
                                  className={'add_to_favorite'}
                                  tooltip={t('table.watchWorker')}
                                  onClick={() => props.addToFavorite(row)}
                                >
                                  <i className='fas fa-eye'></i>
                                </div>
                              ) : (
                                <div
                                  className={'remove_from_favorite'}
                                  tooltip={t('table.removeFromWatch')}
                                  onClick={() => props.addToFavorite(row)}
                                >
                                  <i className='fas fa-eye-slash'></i>
                                </div>
                              )
                            ) : null}
                          </td>
                        )
                      })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Row>
        {showEntries && duplicateEntries ? (
          <Col sm={6} md={5}>
            <div className={'dataTables_entries'}>
              <label>{t('table.showPerPage')}</label>
              <select
                className='custom-select custom-select-sm form-control form-control-sm'
                value={showCount}
                onChange={e => {
                  setShowCountFunc(parseInt(e.target.value))
                  setCurrentPageFunc(1)
                  //uncheckAll()
                }}
              >
                {entries.map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  )
                })}
              </select>
              {miningPresetsTotal > 0 ? (
                <div className='dataTables_info'>
                  {miningPresetsTotal > showCount ? 1 + +currentPage * showCount : 1} -{' '}
                  {miningPresetsTotal > showCount
                    ? showCount * (currentPage + +1) > miningPresetsTotal
                      ? miningPresetsTotal + ' ' + t('table.of')
                      : showCount * (currentPage + +1) + ' ' + t('table.of')
                    : null}{' '}
                  {miningPresetsTotal}
                </div>
              ) : (
                <div className='dataTables_info'>{t('table.showing')} 0</div>
              )}
            </div>
          </Col>
        ) : null}
        {showPagination ? (
          <Col sm={duplicateEntries ? 6 : 12} md={duplicateEntries ? 7 : 12}>
            <div className='dataTables_paginate'>
              <ul data-test='pagination' className='pagination'>
                <li
                  data-test='page-item'
                  className={currentPage === 1 ? 'disabled page-item' : 'page-item'}
                  onClick={prevPage}
                >
                  <button data-test='page-link' aria-label='Previous' className='page-link page-link'>
                    <span>{t('table.previous')}</span>
                  </button>
                </li>

                {currentPage - 3 > 0 ? (
                  <li onClick={() => setCurrentPageFunc(1)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      1..
                    </button>
                  </li>
                ) : null}

                {currentPage - 2 > 0 ? (
                  <li onClick={() => setCurrentPageFunc(currentPage - 2)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage - 2}
                    </button>
                  </li>
                ) : null}

                {currentPage - 1 > 0 ? (
                  <li onClick={() => setCurrentPageFunc(currentPage - 1)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage - 1}
                    </button>
                  </li>
                ) : null}

                <li data-test='page-item' className='active page-item'>
                  <button data-test='page-link' className='page-link page-link'>
                    {currentPage}
                  </button>
                </li>

                {(currentPage + +1) * showCount < miningPresetsTotal ||
                ((currentPage + +1) * showCount - miningPresetsTotal > 0 &&
                  (currentPage + +1) * showCount - miningPresetsTotal < showCount) ? (
                  <li onClick={() => setCurrentPageFunc(currentPage + +1)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage + +1}
                    </button>
                  </li>
                ) : null}

                {(currentPage + +2) * showCount < miningPresetsTotal ||
                ((currentPage + +2) * showCount - miningPresetsTotal > 0 &&
                  (currentPage + +2) * showCount - miningPresetsTotal < showCount) ? (
                  <li onClick={() => setCurrentPageFunc(currentPage + +2)} data-test='page-item' className='page-item'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage + +2}
                    </button>
                  </li>
                ) : null}

                {(currentPage + +3) * showCount < miningPresetsTotal ||
                ((currentPage + +3) * showCount - miningPresetsTotal > 0 &&
                  (currentPage + +3) * showCount - miningPresetsTotal < showCount) ? (
                  <li
                    onClick={() =>
                      setCurrentPageFunc(
                        Math.floor(
                          miningPresetsTotal % showCount === 0
                            ? (miningPresetsTotal - 1) / showCount
                            : miningPresetsTotal / showCount,
                        ) + +1,
                      )
                    }
                    data-test='page-item'
                    className='page-item'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      ..
                      {Math.floor(
                        miningPresetsTotal % showCount === 0
                          ? (miningPresetsTotal - 1) / showCount
                          : miningPresetsTotal / showCount,
                      ) + +1}
                    </button>
                  </li>
                ) : null}

                <li
                  data-test='page-item'
                  className={currentPage * showCount >= miningPresetsTotal ? 'disabled page-item' : 'page-item'}
                  onClick={nextPage}
                >
                  <button data-test='page-link' aria-label='Next' className='page-link page-link'>
                    <span>{t('table.next')}</span>
                  </button>
                </li>
              </ul>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  )
}

const mapStateToProps = store => ({
  clearCheck: store.main.clearCheck,
})
export default connect(mapStateToProps, null)(TableMPs)
