import React, { useState } from 'react'
import { Input } from 'reactstrap'

const UiRangeSlider = props => {
  const [val, setVal] = useState(props.default)
  const [valOld, setValOld] = useState(props.default)
  return (
    <div>
      <Input
        className={'form-control'}
        onChange={e => {
          setVal(e.target.value)
          if (!isNaN(parseFloat(e.target.value))) {
            props.setValue(parseFloat(e.target.value))
            setValOld(parseFloat(e.target.value))
          }
        }}
        onBlur={e => {
          let s = e.target.value
          if (s.length === 0) {
            s = '0'
            if (props.isFan) s = '60'
            setVal(s)
            props.setValue(s)
            setValOld(s)
          } else if (!isNaN(parseFloat(s))) {
            s = parseFloat(s)
            if (s < props.min) s = props.min
            if (s > props.max) s = props.max
            setVal(s)
            props.setValue(s)
            setValOld(s)
          } else {
            setVal(valOld)
          }
        }}
        value={val}
        step={props.step}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
      />
    </div>
  )
}

export default UiRangeSlider
