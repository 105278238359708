import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Modal, ModalHeader, Button, ModalFooter, ModalBody, Label, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import * as actions from '../actions'

const CritTempModal = props => {
  const [modal, setModal] = useState(false)
  const [critical_temperature, set_critical_temperature] = useState(85)
  const [high_temperature, set_high_temperature] = useState(69)
  const [worker_action, set_worker_action] = useState({ value: '0', label: '' })

  const toggle = async () => {
    setModal(!modal)
    if (modal) {
      setTimeout(() => props.onCancel(), 500)
      props.deleteLocalSettings()
    }
  }
  const { t } = useTranslation('common')

  useEffect(() => {
    setTimeout(() => toggle(), 10)
    props.setLocalSettings({ rigId: props.workersItems.map(rig => rig.id).toString() })
  }, [])

  useEffect(() => {
    set_critical_temperature(props.localSettings.critTemp)
    set_high_temperature(props.localSettings.highTemp)
    if (props.localSettings.action === 'Stop Miner') {
      set_worker_action({ value: '1', label: t('pages.settings.stopMiner') })
    }
    if (props.localSettings.action === 'Power Off') {
      set_worker_action({ value: '5', label: t('pages.settings.powerOff') })
    }
    if (props.localSettings.action === 'Reboot') {
      set_worker_action({ value: '6', label: t('pages.settings.reboot') })
    }
    if (props.localSettings.action === 'Exclude GPU') {
      set_worker_action({ value: '2', label: t('pages.settings.excludeGPU') })
    }
    if (props.localSettings.action === 'Nothing') {
      set_worker_action({ value: '4', label: t('pages.settings.nothing') })
    }
  }, [props.localSettings.critTemp, props.localSettings.action, props.localSettings.highTemp])

  return (
    <Modal className={'modal-dialog-centered'} isOpen={modal} onCancel={toggle} toggle={toggle}>
      <ModalHeader tag={'h4'} toggle={toggle}>
        {t('tooltips.setCritTemp')}
      </ModalHeader>
      <ModalBody>
        {props.workersItems && (
          <Row>
            <Col xs={12} className={'form-group'}>
              <Label>{t('pages.workers.selectedRigs')}</Label>
              <Input
                readOnly={true}
                type={'textarea'}
                value={props.workersItems && props.workersItems.map(rig => rig.name).join(', ')}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} className={'form-group'}>
            <Label>{t('pages.settings.criticalTemperature')}</Label>
            <Input
              placeholder={''}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={critical_temperature ? critical_temperature : ''}
              onChange={e => set_critical_temperature(e.target.value)}
            />
          </Col>

          <Col xs={12} className={'form-group'}>
            <Label className={'checkboxLabel'}>{t('pages.settings.workerAction')}</Label>
            <Select
              classNamePrefix={'form_control_react_select'}
              options={[
                { value: '1', label: t('pages.settings.stopMiner') },
                {
                  value: '2',
                  label: t('pages.settings.excludeGPU'),
                },
                { value: '3', label: t('pages.settings.slowDown') },
                { value: '4', label: t('pages.settings.nothing') },
                {
                  value: '5',
                  label: t('pages.settings.powerOff'),
                },
                {
                  value: '6',
                  label: t('pages.settings.reboot'),
                },
              ]}
              value={worker_action}
              defaultValue={''}
              onChange={option => set_worker_action(option)}
              placeholder={t('inputs.select')}
            />
          </Col>

          <Col xs={12} className={'form-group'}>
            <Label>{t('pages.settings.highTemperature')}</Label>
            <Input
              placeholder={''}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={high_temperature ? high_temperature : ''}
              onChange={e => set_high_temperature(e.target.value)}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          {t('pages.workers.cancel')}
        </Button>
        <Button
          color='secondary'
          onClick={() => {
            props.toDefoultLocalSettings({ rigIds: props.workersItems.map(el => el.id).toString() })
            toggle()
          }}
        >
          {t('pages.settings.toDefoult')}
        </Button>
        <Button
          color='primary'
          onClick={() => {
            props.updateLocalSettings({
              critTemp: +critical_temperature,
              highTemp: +high_temperature,
              critTempAction: worker_action.label,
              rigIds: props.workersItems.map(el => el.id).toString(),
            })
            toggle()
          }}
        >
          {t('pages.workers.apply')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = store => ({
  localSettings: store.settings.localRigsSettings,
})

const mapDispatchToProps = {
  setLocalSettings: actions.setLocalSettings,
  toDefoultLocalSettings: actions.toDefoultLocalSettings,
  updateLocalSettings: actions.updateLocalSettings,
  deleteLocalSettings: actions.deleteLocalSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(CritTempModal)
