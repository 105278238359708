import { types } from './types'

//TopBar worker items visible
export const visibleWorkerActions = workers => dispatch => {
  try {
    dispatch({
      type: types.SET_WORKER_ITEMS,
      data: workers,
    })
  } catch (err) {
    console.log('err: ', err)
  }
}

export const setClearCheck = () => dispatch => {
  try {
    dispatch({
      type: types.SET_CLEAR_CHECK,
      data: [],
    })
  } catch (err) {
    console.log('err: ', err)
  }
}
