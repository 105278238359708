import React from 'react'
import { connect } from 'react-redux'
import MiningAlert from './MiningAlert'

const AlertsOverlayComponent = props => {
  return (
    <div className='alerts'>
      {props.alerts.map((alert, key) => {
        return <MiningAlert alert={alert} key={key} />
      })}
    </div>
  )
}

const mapStateToProps = store => ({
  alerts: store.layout.alerts,
})

export default connect(mapStateToProps, null)(AlertsOverlayComponent)
