import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './Modal.scss'
const DataCenterCreateModal = ({
  title = 'Modal',
  subTitle,
  onCancel = () => {},
  onFinish,
  onFinish2,
  isOpen,
  finishTitle,
  finishTitle2,
  cancelTitle = 'Cancel',
  children,
  className,
  hideCanselBtn,
}) => {
  const onFinishHandler = async () => {
    onFinish && (await onFinish())
  }
  const onFinishHandler2 = async () => {
    onFinish2 && (await onFinish2())
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      className={`modal-dialog-centered modal-box ${className ? className : ''}`}
    >
      <ModalHeader toggle={onCancel} className={`${subTitle ? 'modal-header-custom_sub' : 'modal-header-custom'}`}>
        <div className='modal-heading'>
          <h4>{title}</h4>
          {subTitle && <h4>{subTitle}</h4>}
        </div>
      </ModalHeader>

      <ModalBody>{children}</ModalBody>

      <ModalFooter>
        {onFinish && (
          <Button color='primary' onClick={onFinishHandler}>
            {finishTitle}
          </Button>
        )}
        {onFinish2 && (
          <Button color='primary' onClick={onFinishHandler2}>
            {finishTitle2}
          </Button>
        )}
        {!hideCanselBtn && (
          <Button color='secondary' onClick={onCancel}>
            {cancelTitle}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

DataCenterCreateModal.prototype = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  finishTitle: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default DataCenterCreateModal
