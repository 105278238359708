import { types } from './types'

const initState = {
  dataCentersList: [],
  dcRigsList: [],
  roomsList: [],
  totalDC: 0,
  totalRooms: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_DATACENTERS_LIST: {
      const { data } = action
      const copyState = { ...state }
      copyState.dataCentersList = data.items
      copyState.totalDC = data.total

      return {
        ...copyState,
      }
    }

    case types.SET_DATACENTERS_RIGS_LIST: {
      const { data } = action
      const copyState = { ...state }
      copyState.dcRigsList = data.items

      return {
        ...copyState,
      }
    }

    case types.SET_ROOMS_LIST: {
      const { data } = action
      return {
        ...state,
        roomsList: data.items,
        totalRooms: data.total,
      }
    }

    case types.CREATE_DATACENTER: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.dataCentersList = [{ ...data }, ...stateCopy.dataCentersList]
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_ROOM: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.roomsList = [{ ...data }, ...stateCopy.roomsList]
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_DATACENTER: {
      const { data } = action
      const stateCopy = { ...state }
      const dataCentersListCopy = [...stateCopy.dataCentersList]

      stateCopy.dataCentersList = dataCentersListCopy.map(dc => {
        if (+dc.id === +data.id) {
          dc = { ...data }
        }
        return dc
      })

      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_ROOM: {
      const { data } = action
      const stateCopy = { ...state }
      const roomsListCopy = [...stateCopy.roomsList]

      stateCopy.roomsList = roomsListCopy.map(room => {
        if (+room.id === +data.id) {
          room = { ...data }
        }
        return room
      })

      return {
        ...stateCopy,
      }
    }

    case types.DELETE_DATACENTER: {
      const { data } = action
      const stateCopy = { ...state }
      const dataCentersListCopy = [...stateCopy.dataCentersList]

      stateCopy.dataCentersList = dataCentersListCopy.filter(dc => +dc.id !== +data)

      return {
        ...stateCopy,
      }
    }

    case types.DELETE_ROOM: {
      const { data } = action
      const stateCopy = { ...state }
      const roomsListCopy = [...stateCopy.roomsList]

      stateCopy.roomsList = roomsListCopy.filter(room => +room.id !== +data)

      return {
        ...stateCopy,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
