import { types } from './types'

import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'

const SET_DATACENTERS = `
  query dataCentersList($page: Int!, $size: Int) {
    dataCentersList(page: $page, size: $size) {
      items {
        id
        name
        address
        description
        powerCapacity
        lineType
        transformerCapacity
        linkRPCServer
        rooms{
            id
            name
        }
      }
      total
    } 
  }
`

const SET_DATACENTERS_RIGS = `
  query dcRigsList {
    dcRigsList {
      items {
        id
        powCons
        dcId
      }
      total
    } 
  }
`

const SET_ROOMS = `
  query roomList($page: Int!, $size: Int) {
    roomList(page: $page, size: $size) {
      items {
        id
        name
        dcName
        dataCenterId
      }
      total
    } 
  }
`

const CREATE_DATA_CENTER = `
  mutation createDataCenter($name: String!, $address: String, $description: String, $powerCapacity: Int, $lineType: String, $transformerCapacity: Int, $linkRPCServer: String,) {
    createDataCenter(name: $name, address: $address, description: $description, powerCapacity: $powerCapacity, lineType: $lineType, transformerCapacity: $transformerCapacity, linkRPCServer: $linkRPCServer) {
      id
      name
      address
      description
      powerCapacity
      lineType
      transformerCapacity
      linkRPCServer
    }
  }
`

const UPDATE_DATA_CENTER = `
  mutation updateDataCenter($name: String!, $dataCenterId: ID!, $address: String, $description: String, $powerCapacity: Int, $lineType: String, $transformerCapacity: Int, $linkRPCServer: String,) {
    updateDataCenter(name: $name, dataCenterId: $dataCenterId, address: $address, description: $description, powerCapacity: $powerCapacity, lineType: $lineType, transformerCapacity: $transformerCapacity, linkRPCServer: $linkRPCServer) {
      id
      name
      address
      description
      powerCapacity
      lineType
      transformerCapacity
      linkRPCServer
    }
  }
`

const UPDATE_ROOM = `
  mutation updateRoom($name: String!, $dataCenterId: ID!, $roomId: ID!) {
    updateRoom(name: $name, dataCenterId: $dataCenterId, roomId: $roomId) {
      id
      name
      dcName
      dataCenterId
    }
  }
`

const DELETE_DATA_CENTER = `
  mutation deleteDataCenter($dataCenterId: ID!) {
    deleteDataCenter(dataCenterId: $dataCenterId)
  }
`

const DELETE_ROOM = `
  mutation deleteRoom($roomId: ID!) {
    deleteRoom(roomId: $roomId)
  }
`

const CREATE_ROOM = `
  mutation createRoom($name: String!, $dataCenterId: ID!) {
    createRoom(name: $name, dataCenterId: $dataCenterId) {
      id
      dataCenterId
      dcName
      name
    }
  }
`

// datacenters List
export const setRoomsList =
  ({ page, size }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_ROOMS,
        variables: { page, size },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { roomList } = data
      if (!roomList) {
        throw new Error("Can't get Rooms!")
      }

      dispatch({
        type: types.SET_ROOMS_LIST,
        data: roomList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      // dispatch(errorNotification(errorMessage))
    }
  }

// datacenters List
export const setDataCentersList =
  ({ page, size }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_DATACENTERS,
        variables: { page, size },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { dataCentersList } = data
      if (!dataCentersList) {
        throw new Error("Can't get DataCenters!")
      }

      dispatch({
        type: types.SET_DATACENTERS_LIST,
        data: dataCentersList,
      })
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      // dispatch(errorNotification(errorMessage))
    }
  }

// datacenters rigs List
export const setDCRigsList = () => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_DATACENTERS_RIGS,
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty')
    }

    const { dcRigsList } = data
    if (!dcRigsList) {
      throw new Error("Can't get DataCenters!")
    }
    console.log(dcRigsList)

    dispatch({
      type: types.SET_DATACENTERS_RIGS_LIST,
      data: dcRigsList,
    })
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    // dispatch(errorNotification(errorMessage))
  }
}

// create DataCenter
export const createDataCenter = createDataCenterValues => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_DATA_CENTER,
      variables: { ...createDataCenterValues },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty')
    }

    const { createDataCenter } = data
    if (!createDataCenter) {
      throw new Error("Can't create DataCenter!")
    }

    dispatch({
      type: types.CREATE_DATACENTER,
      data: createDataCenter,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('DataCenter Created Successfully', 'success'))
    return true
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    return false
  }
}

// update DataCenter
export const updateDataCenter = updateDataCenterValues => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_DATA_CENTER,
      variables: { ...updateDataCenterValues },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty')
    }

    const { updateDataCenter } = data
    if (!updateDataCenter) {
      throw new Error("Can't update DataCenter!")
    }

    dispatch({
      type: types.UPDATE_DATACENTER,
      data: updateDataCenter,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('DataCenter Updated Successfully', 'success'))
    return true
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    return false
  }
}

// update room
export const updateRoom =
  ({ name, dataCenterId, roomId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_ROOM,
        variables: { name, dataCenterId, roomId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { updateRoom } = data
      if (!updateRoom) {
        throw new Error("Can't update Room!")
      }

      //console.log(updateRoom)

      dispatch({
        type: types.UPDATE_ROOM,
        data: updateRoom,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Room Updated Successfully', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

// delete DataCenter
export const deleteDataCenter =
  ({ dataCenterId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DELETE_DATA_CENTER,
        variables: { dataCenterId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { deleteDataCenter } = data
      if (!deleteDataCenter) {
        throw new Error("Can't Delete DataCenter!")
      }

      dispatch({
        type: types.DELETE_DATACENTER,
        data: dataCenterId,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('DataCenter Deleted Successfully', 'success'))
      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return false
    }
  }

// delete room
export const deleteRoom =
  ({ roomId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DELETE_ROOM,
        variables: { roomId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { deleteRoom } = data
      if (!deleteRoom) {
        throw new Error("Can't Delete Room!")
      }

      dispatch({
        type: types.DELETE_ROOM,
        data: roomId,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Room Deleted Successfully', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

// create Room
export const createRoom =
  ({ dataCenterId, name }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: CREATE_ROOM,
        variables: { dataCenterId, name },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { createRoom } = data
      if (!createRoom) {
        throw new Error("Can't create Room!")
      }

      dispatch({
        type: types.CREATE_ROOM,
        data: createRoom,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Room Created Successfully', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }
