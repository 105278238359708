import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import uuid from 'node-uuid'
import { Button } from 'reactstrap'
import { CreateDataCenter } from '../components/modals'
import DataCenterActions from '../components/DataCenterActions'
import Table from '../../../components/Table/Table'
import * as actions from '../actions'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const DataCentersPage = ({ dataCentersList, setDCRigsList, totalDC, dcRigsList, ...props }) => {
  const [dataCenters, setDataCenters] = useState([])
  const [dataCenterModal, setDataCenterModal] = useState(false)
  const { t } = useTranslation('common')
  const setDataCentersList = props.setDataCentersList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )

  useEffect(() => {
    setDataCentersList({ page: currentPage, size: showCount })
  }, [setDataCentersList, currentPage, showCount])

  useEffect(() => {
    setDCRigsList()
  }, [setDCRigsList])

  useEffect(() => {
    const dataCentersArray = dataCentersList.map(dc => {
      let freeCap = 0
      let powerSum = 0
      for (let i = 0; i < dcRigsList.length; i++) {
        if (dcRigsList[i].dcId === dc.id) {
          powerSum += +dcRigsList[i].powCons / 1000
          freeCap = dc.powerCapacity - powerSum
        }
      }

      return {
        ...dc,
        action: <DataCenterActions dataCenter={dc} />,
        roomsQty: dc.rooms ? dc.rooms.length : 0,
        powerUsed: powerSum.toFixed(2),
        freeCap: freeCap.toFixed(2),
      }
    })

    setDataCenters(dataCentersArray)
  }, [dataCentersList, dcRigsList])

  const dataCentersData = {
    columns: [
      {
        label: t('pages.dcManagement.id'),
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.dcManagement.name'),
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.dcManagement.roomsQty'),
        field: 'roomsQty',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.dcManagement.powerCapacity'),
        field: 'powerCapacity',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.dcManagement.powerUsed'),
        field: 'powerUsed',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.dcManagement.freeCap'),
        field: 'freeCap',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.dcManagement.status'),
        field: '',
        attributes: {
          key: uuid(),
        },
      },
      {
        label: t('pages.dcManagement.action'),
        field: 'action',
        sort: true,
        sortType: 'string',
      },
    ],
    rows: dataCenters,
    dcRigsList,
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.dcManagement.dataCenters')}</h3>
            <div className='dc-top'>
              <Button onClick={() => setDataCenterModal(!dataCenterModal)} color='primary'>
                {t('pages.dcManagement.add')}
              </Button>
              <CreateDataCenter onFinish={() => setDataCenterModal(false)} isActive={dataCenterModal} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Table
              className={'dc__table table table-striped'}
              data={dataCentersData}
              responsive
              noBottomColumns
              stickyHeader
              total={totalDC}
              setShowCount={setShowCount}
              showCount={showCount}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  dataCentersList: store.dcManagement.dataCentersList,
  dcRigsList: store.dcManagement.dcRigsList,
  totalDC: store.dcManagement.totalDC,
  roomsList: store.dcManagement.roomsList,
  rigsList: store.rigs.rigsList,
})

const mapDispatchToProps = {
  setDCRigsList: actions.setDCRigsList,
  setRoomsList: actions.setRoomsList,
  setDataCentersList: actions.setDataCentersList,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataCentersPage)
