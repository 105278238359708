import React from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { Line } from 'react-chartjs-2'
import { Modal } from 'components'
import * as actions from '../../actions'
import { useTranslation } from 'react-i18next'

const StatisticsDataCenter = props => {
  const { t } = useTranslation('common')
  return (
    <Modal
      title={`Statistics for DC - ${props.dataCenter.name}`}
      canselTitle={'Close'}
      isOpen={props.isActive}
      cancelTitle={t('pages.dcManagement.close')}
      onCancel={props.onFinish}
    >
      <div className={'dc-stats'}>
        <div className={'dc-stats__col'}>
          <div className={'dc-stats__item'}>
            <p className={'dc-stats__item-label'}>{t('pages.dcManagement.DCName')}:</p>
            <div className={'dc-stats__item-data'}>{props.dataCenter.name}</div>
          </div>

          <div className={'dc-stats__item'}>
            <p className={'dc-stats__item-label'}>{t('pages.dcManagement.address')}:</p>
            <div className={'dc-stats__item-data'}>{props.dataCenter.address}</div>
          </div>

          <div className={'dc-stats__item'}>
            <p className={'dc-stats__item-label'}>{t('pages.dcManagement.description')}:</p>
            <div className={'dc-stats__item-data'}>{props.dataCenter.description}</div>
          </div>
        </div>

        <div className={'dc-stats__col'}>
          <div className={'dc-stats__item'}>
            <p className={'dc-stats__item-label'}>{t('pages.dcManagement.powerCapacity')}:</p>
            <div className={'dc-stats__item-data'}>{props.dataCenter.powerCapacity}</div>
          </div>

          <div className={'dc-stats__item'}>
            <p className={'dc-stats__item-label'}>{t('pages.dcManagement.lineType')}:</p>
            <div className={'dc-stats__item-data'}>{props.dataCenter.lineType}</div>
          </div>

          <div className={'dc-stats__item'}>
            <p className={'dc-stats__item-label'}>{t('pages.dcManagement.transformerCapacity')}:</p>
            <div className={'dc-stats__item-data'}>{props.dataCenter.transformerCapacity}</div>
          </div>

          <div className={'dc-stats__item'}>
            <p className={'dc-stats__item-label'}>{t('pages.dcManagement.linkRPCServer')}:</p>
            <div className={'dc-stats__item-data'}>{props.dataCenter.linkRPCServer}</div>
          </div>

          <div className={'dc-stats__item dc-stats__item_down'}>
            <p className={'dc-stats__item-label'}>{t('pages.dcManagement.powerDown')}:</p>
            <div className={'dc-stats__item-data'}>{t('pages.dcManagement.N/A')}</div>
          </div>
        </div>
      </div>

      <div className={'dc-stats__chart'}>
        <Row className={'worker_charts'}>
          <Col md={8} xs={12} className={'worker_chart_parent'}>
            <Line />
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = {
  updateDataCenter: actions.updateDataCenter,
}

export default connect(null, mapDispatchToProps)(StatisticsDataCenter)
