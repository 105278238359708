import React, { useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'

import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from '../../store/actions'

import { changeLeftSidebarType, toggleRightSidebar } from '../cssFunction'

import RightSidebar from '../../components/RightSidebar'
import TopBar from './TopBar'
// Other Layout related Component
import Sidebar from './Sidebar'
import Footer from './Footer'

const Layout = props => {
  const propsArr = useMemo(() => {
    return {
      changeLayoutWidth: props.changeLayoutWidth,
      changeTopbarTheme: props.changeTopbarTheme,
      changeSidebarType: props.changeSidebarType,
      changeSidebarTheme: props.changeSidebarTheme,
      leftSideBarTheme: props.layout.leftSideBarTheme,
      layoutWidth: props.layout.layoutWidth,
      leftSideBarType: props.layout.leftSideBarType,
      topbarTheme: props.layout.topbarTheme,
    }
  }, [
    props.changeLayoutWidth,
    props.changeTopbarTheme,
    props.changeSidebarType,
    props.changeSidebarTheme,
    props.layout.leftSideBarTheme,
    props.layout.layoutWidth,
    props.layout.leftSideBarType,
    props.layout.topbarTheme,
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (propsArr.leftSideBarTheme) {
      changeSidebarTheme(propsArr.leftSideBarTheme)
    }
    if (propsArr.layoutWidth) {
      changeLayoutWidth(propsArr.layoutWidth)
    }
    if (propsArr.leftSideBarType) {
      changeSidebarType(propsArr.leftSideBarType)
    }
    if (propsArr.topbarTheme) {
      changeTopbarTheme(propsArr.topbarTheme)
    }
  }, [propsArr])

  useEffect(() => {
    changeLeftSidebarType(props.layout.leftSideBarType, false)
  }, [props.layout.leftSideBarType])

  const toggleMenuCallback = () => {
    if (props.layout.leftSideBarType === 'default') {
      props.changeSidebarType('condensed', false)
    } else if (props.layout.leftSideBarType === 'condensed') {
      props.changeSidebarType('default', false)
    }
  }
  const hideMenuCallback = () => {
    props.changeSidebarType('default', false)
  }
  const sideBarRef = useRef(null)
  const menuRef = useRef(null)

  function useOutsideAlerter(ref, menuButton, leftSideBarType) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */

      function handleClickOutside(event) {
        if (
          leftSideBarType === 'condensed' &&
          window.innerWidth <= 991 &&
          ref &&
          menuButton &&
          ref.current &&
          !ref.current.contains(event.target) &&
          menuButton.current &&
          !menuButton.current.contains(event.target)
        ) {
          event.preventDefault()
          event.stopPropagation()
          hideMenuCallback()
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, menuButton, leftSideBarType])
  }
  useOutsideAlerter(sideBarRef, menuRef, props.layout.leftSideBarType)

  return (
    <React.Fragment>
      <div id='layout-wrapper'>
        <TopBar
          toggleMenuCallback={toggleMenuCallback}
          hideMenuCallback={hideMenuCallback}
          toggleRightSidebar={toggleRightSidebar}
          ref={menuRef}
        />

        <div ref={sideBarRef} className='vertical-menu'>
          <div data-simplebar className='h-100'>
            <Sidebar
              theme={props.layout.leftSideBarTheme}
              type={props.layout.leftSideBarType}
              changeSidebarType={props.changeSidebarType}
            />
          </div>
        </div>
        <div className='main-content'>
          <div className='page-content' key={props.layout.length + 12}>
            {props.children}
          </div>
        </div>
        <Footer />

        <RightSidebar />
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => ({
  layout: state.layout,
})
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(Layout)
