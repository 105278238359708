import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'
import * as actions from '../../actions'
import { useTranslation } from 'react-i18next'

const RoomCreateModalOnPage = props => {
  const [errors, setErrors] = useState({})
  const { t } = useTranslation('common')
  const [roomName, setRoomName] = useState('')
  const [dataCenterId, setDataCenterId] = useState(0)

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'room_name': {
        setRoomName(e.target.value)
        return
      }
      case 'room_dataCenter_id': {
        setDataCenterId(e.target.value)
        return
      }
      default:
        return
    }
  }

  const submitFunc = () => {
    props.createRoom({ name: roomName, dataCenterId })
    props.toggleRoom()
  }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <Button onClick={props.toggleRoom} color='primary'>
        {t('pages.dcManagement.add')}
      </Button>
      <Modal isOpen={props.isOpenRoom} toggle={props.toggleRoom} className={'modal-dialog-centered'}>
        <ModalHeader tag={'h4'} toggle={props.toggleRoom}>
          {t('pages.dcManagement.createRoom')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <Label>{t('pages.dcManagement.dataCenterID')}</Label>
                <select
                  className={errors.room_dataCenter_id ? 'form-control invalid' : 'form-control'}
                  name={'room_dataCenter_id'}
                  onChange={onChange}
                  placeholder={t('inputs.select')}
                >
                  <option value={''}>{t('pages.dcManagement.selectDataCenter')}</option>
                  {props.dataCenters.map((dc, key) => {
                    return (
                      <option key={key} value={dc.id}>
                        {dc.name}
                      </option>
                    )
                  })}
                </select>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.dcManagement.name')}</Label>
                <Input
                  className={errors.room_name ? 'invalid' : ''}
                  type={'text'}
                  name={'room_name'}
                  onChange={onChange}
                />
                <div className={'error'}>{errors.room_name && errors.room_name.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggleRoom}>
            {t('pages.dcManagement.cancel')}
          </Button>
          <Button color='primary' onClick={submitFunc}>
            {t('pages.dcManagement.create')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  dataCenters: store.dcManagement.dataCentersList,
})

const mapDispatchToProps = {
  createRoom: actions.createRoom,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomCreateModalOnPage)
