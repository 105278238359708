import React from 'react'
import AutofanComp from './AutofanComp'

const AutoFanTab = () => {
  return (
    <div className={'settings__power_management'}>
      <AutofanComp type='global' />
    </div>
  )
}

export default AutoFanTab
