const cardsTemp = {
  norm: 68,
  load: 69,
  max: 79,
}
export default cardsTemp
// [
//   {
//     name: 'Radeon RX 470',
//     norm: 50,
//     load: 92,
//     max: 105,
//   },
//   {
//     name: 'Radeon RX 570',
//     norm: 45,
//     load: 81,
//     max: 97,
//   },
//   {
//     name: 'NVIDIA GeForce GTX 1660 SUPER',
//     norm: 40,
//     load: 67,
//     max: 93,
//   },
//   {
//     name: 'NVIDIA GeForce GTX 1060 6GB',
//     norm: 40,
//     load: 67,
//     max: 93,
//   },
//   {
//     name: 'NVIDIA GeForce GTX 1070',
//     norm: 41,
//     load: 83,
//     max: 94,
//   },
// ]
