import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'

import * as actions from '../../pages/RigsData/actions'
import * as presetActions from '../../pages/MiningPresets/actions'
import SweetAlert from 'react-bootstrap-sweetalert'
import { WorkerUpgrade, SetMiningPreset } from '../../pages/Workers/components/modals'

const WorkerActionsMobile = props => {
  const { t } = useTranslation('common')
  const [isSetMiningPreset, setIsSetMiningPreset] = useState(false)
  const [isWorkerUpgrade, setIsWorkerUpgrade] = useState(false)
  const [showAlertTurnOff, setShowAlertTurnOff] = useState(false)
  const [showAlertStart, setShowAlertStart] = useState(false)
  const [showAlertReboot, setShowAlertReboot] = useState(false)
  const [showAlertHardReboot, setShowAlertHardReboot] = useState(false)
  const [showAlertPause, setShowAlertPause] = useState(false)
  const [showAlertClearErrors, setShowAlertClearErrors] = useState(false)
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }
  const rebootWorker = async () => {
    await props.setRigsAction({
      rigIds: props.workersItems
        .map(worker => {
          return worker.id
        })
        .toString(),
      powerOff: false,
      hardReboot: false,
      reboot: true,
    })
    setShow(false)
  }

  const turnOFFWorker = async () => {
    await props.setRigsAction({
      rigIds: props.workersItems
        .map(worker => {
          return worker.id
        })
        .toString(),
      powerOff: true,
      reboot: false,
      hardReboot: false,
    })
    setShow(false)
  }

  const hardRebootWorker = async () => {
    await props.setRigsAction({
      rigIds: props.workersItems
        .map(worker => {
          return worker.id
        })
        .toString(),
      powerOff: false,
      reboot: false,
      hardReboot: true,
    })
    setShow(false)
  }

  const turnOff = () => {
    setShowAlertTurnOff(true)
    setShow(false)
  }
  const start = () => {
    setShowAlertStart(true)
  }
  const pause = () => {
    setShowAlertPause(true)
  }
  const reboot = () => {
    setShowAlertReboot(true)
    setShow(false)
  }
  const hardReboot = () => {
    setShowAlertHardReboot(true)
    setShow(false)
  }

  return (
    <div className={'d-sm-none'}>
      <div className={'alerts'}>
        <SweetAlert
          title={t('sweetAlerts.top.areConfirm')}
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          custom
          customIcon={<i className='fas fa-power-off sweet-alert-icon'></i>}
          show={showAlertTurnOff}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            turnOFFWorker(props.worker.id)
            setShowAlertTurnOff(false)
            props.clearSelected()
          }}
          onCancel={() => {
            setShowAlertTurnOff(false)
            props.clearSelected()
          }}
          confirmBtnText={t('sweetAlerts.top.confirm')}
          cancelBtnText={t('sweetAlerts.top.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.top.workerOff')}
        </SweetAlert>
        <SweetAlert
          title={t('sweetAlerts.top.areConfirm')}
          custom
          customIcon={<i className='fas fa-play sweet-alert-icon'></i>}
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertStart}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigsAction({
              rigIds: props.workersItems
                .map(worker => {
                  return worker.id
                })
                .toString(),
              action: 'run',
            })
            setShowAlertStart(false)
            props.clearSelected()
          }}
          onCancel={() => {
            setShowAlertStart(false)
            props.clearSelected()
          }}
          confirmBtnText={t('sweetAlerts.top.confirm')}
          cancelBtnText={t('sweetAlerts.top.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.top.miningOn')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.top.areConfirm')}
          custom
          customIcon={<i className='fas fa-stop sweet-alert-icon'></i>}
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertPause}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigsAction({
              rigIds: props.workersItems
                .map(worker => {
                  return worker.id
                })
                .toString(),
              action: 'stop',
            })
            setShowAlertPause(false)
            props.clearSelected()
          }}
          onCancel={() => {
            setShowAlertPause(false)
            props.clearSelected()
          }}
          confirmBtnText={t('sweetAlerts.top.confirm')}
          cancelBtnText={t('sweetAlerts.top.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.top.miningOff')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.top.areConfirm')}
          custom
          customIcon={<i className='fas fa-sync sweet-alert-icon'></i>}
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertReboot}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            rebootWorker(props.worker.id)
            setShowAlertReboot(false)
            props.clearSelected()
          }}
          onCancel={() => {
            setShowAlertReboot(false)
            props.clearSelected()
          }}
          confirmBtnText={t('sweetAlerts.top.confirm')}
          cancelBtnText={t('sweetAlerts.top.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.top.workerReboot')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.top.areConfirm')}
          custom
          customIcon={<i className='fas fa-sync sweet-alert-icon'></i>}
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertHardReboot}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            hardRebootWorker(props.worker.id)
            setShowAlertHardReboot(false)
            props.clearSelected()
          }}
          onCancel={() => {
            setShowAlertHardReboot(false)
            props.clearSelected()
          }}
          confirmBtnText={t('sweetAlerts.top.confirm')}
          cancelBtnText={t('sweetAlerts.top.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.top.workerRebootHard')}
        </SweetAlert>
        <SweetAlert
          title={t('sweetAlerts.top.areConfirm')}
          custom
          customIcon={<i className='far fa-times-circle sweet-alert-icon'></i>}
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertClearErrors}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigsAction({
              rigIds: props.workersItems
                .map(worker => {
                  return worker.id
                })
                .toString(),
              action: 'clearErrors',
            })
            setShowAlertClearErrors(false)
            props.clearSelected()
          }}
          onCancel={() => {
            setShowAlertClearErrors(false)
          }}
          confirmBtnText={t('sweetAlerts.top.confirm')}
          cancelBtnText={t('sweetAlerts.top.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.top.resetErrors')}
        </SweetAlert>
      </div>
      <Dropdown isOpen={show} toggle={toggle} direction={'down'}>
        <DropdownToggle className={'actions__item'} flow='down'>
          <i className='fas fa-stream'></i>
        </DropdownToggle>
        <DropdownMenu className={'worker_actions_mobile'}>
          <DropdownItem
            onClick={() => {
              setShowAlertClearErrors(true)
            }}
          >
            {t('tooltips.clearWorker')}
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setIsSetMiningPreset(true)
            }}
          >
            {t('pages.workers.setMiningPreset')}
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setIsWorkerUpgrade(true)
            }}
          >
            {t('pages.workers.workerUpdate')}
          </DropdownItem>
          <DropdownItem onClick={() => start()}>{t('pages.workers.startMining')}</DropdownItem>
          <DropdownItem onClick={() => pause()}>{t('pages.workers.stopMining')}</DropdownItem>
          <DropdownItem onClick={() => turnOff()}>{t('pages.workers.turnOff')}</DropdownItem>
          <DropdownItem onClick={() => reboot()}>{t('pages.workers.reboot')}</DropdownItem>
          <DropdownItem onClick={() => hardReboot()}>{t('pages.workers.hardReboot')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <div className={'actions__items'}>
        {isSetMiningPreset && (
          <SetMiningPreset
            onCancel={() => setIsSetMiningPreset(false)}
            worker={props.worker}
            workersItems={props.workersItems}
            clearSelected={props.clearSelected}
          />
        )}
        {isWorkerUpgrade && (
          <WorkerUpgrade
            onCancel={() => setIsWorkerUpgrade(false)}
            workersItems={props.workersItems}
            clearSelected={props.clearSelected}
          />
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  deleteRig: actions.deleteRig,
  setRigMinerAction: actions.setRigMinerAction,
  resetOrPowerOfWorker: actions.resetOrPowerOfWorker,
  setMiningPresetList: presetActions.setMiningPresetList,
}

export default connect(null, mapDispatchToProps)(WorkerActionsMobile)
