import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../actions'
import Loader from '../../../components/Loader'
import ReCAPTCHA from 'react-google-recaptcha'
import { Row, Col, Card, CardBody, Alert } from 'reactstrap'
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'
// import images
import logoSm from '../../../assets/images/logo-sm-light.png'
import { useTranslation } from 'react-i18next'

const SignUp = props => {
  const [captchaToken, setCaptchaToken] = useState('')
  const recaptchaRef = useRef(null)

  const handlerSubmit = (_event, values) => {
    props.signUp({ ...values, history: props.history, captchaToken })
  }

  function onChange(value) {
    setCaptchaToken(value)
  }

  const { t } = useTranslation('common')

  return (
    <React.Fragment>
      <div className='account-pages my-5 pt-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <div className='position-relative'>
                {props.loading ? <Loader /> : null}

                <Card className='overflow-hidden'>
                  <div className='bg-primary'>
                    <div className='text-primary text-center p-4'>
                      <h5 className='text-white font-size-20'>{t('pages.signup.register')}</h5>
                      <p className='text-white-50'>{t('pages.signup.getAccountNow')}</p>
                      <Link to={`${process.env.PUBLIC_URL}/`} className='logo logo-admin'>
                        <img src={logoSm} height='48' alt='logo' />
                      </Link>
                    </div>
                  </div>
                  <CardBody className='p-4'>
                    <div className='p-3'>
                      <AvForm className='form-horizontal mt-4' onValidSubmit={handlerSubmit}>
                        {props.user && props.user ? (
                          <Alert color='success'>{t('pages.signup.registerUserSuccessfully')}</Alert>
                        ) : null}
                        {props.registrationError && props.registrationError ? (
                          <Alert color='danger'>{props.registrationError}</Alert>
                        ) : null}

                        <div className='form-group'>
                          <AvField
                            name='email'
                            label={t('pages.signup.email')}
                            className='form-control'
                            placeholder={t('pages.signup.enterEmail')}
                            type='email'
                            required
                            errorMessage={t('pages.signup.provideRealEmail')}
                          />
                        </div>
                        <div className='form-group'>
                          <AvField
                            name='userName'
                            label={t('pages.signup.userName')}
                            type='text'
                            required
                            placeholder={t('pages.signup.enterUsername')}
                            errorMessage={t('pages.signup.usernameError')}
                          />
                        </div>
                        <div className='form-group'>
                          <AvField
                            name='password'
                            label={t('pages.signup.password')}
                            type='password'
                            required
                            placeholder={t('pages.signup.enterPassword')}
                            errorMessage={t('pages.signup.passwordError')}
                          />
                        </div>
                        <div className='form-group'>
                          <AvField
                            name='confirm_password'
                            label={t('pages.signup.confirmPassword')}
                            type='password'
                            required
                            placeholder={t('pages.signup.enterPassword')}
                            validate={{ match: { value: 'password' } }}
                            errorMessage={t('pages.signup.passwordMatch')}
                          />
                        </div>
                        <div className='form-group'>
                          <AvField
                            name='inviteCode'
                            label={t('pages.signup.passwordMatch')}
                            type='text'
                            placeholder={t('pages.signup.inviteCodeEnter')}
                            errorMessage={t('pages.signup.inviteCodeError')}
                          />
                        </div>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey='6LdckQIkAAAAAIti_aaT_fPhD3knm7R3qyfeysc8'
                          onChange={onChange}
                          theme='dark'
                          style={{ padding: '5px 0 10px 0' }}
                        />
                        <Row className='form-group'>
                          <div className='col-12 text-right'>
                            <button className='btn btn-primary w-md waves-effect waves-light' type='submit'>
                              {t('pages.signup.register')}
                            </button>
                          </div>
                        </Row>
                        <Row className='form-group mt-2 mb-0'>
                          <div className='col-12 mt-4'>
                            <p className='mb-0'>
                              {t('pages.signup.byRegistering')}{' '}
                              <Link to='#' className='text-primary'>
                                {t('pages.signup.termsUse')}
                              </Link>
                            </p>
                          </div>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className='mt-5 text-center'>
                <p>
                  {t('pages.signup.haveAccount')}{' '}
                  <Link to={`${process.env.PUBLIC_URL}/login`} className='font-weight-medium text-primary'>
                    {t('pages.signup.login')}
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = {
  signUp: actions.signUp,
}

export default connect(null, mapDispatchToProps)(SignUp)
