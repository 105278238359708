import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Coins, AlgoCoins, InviteCodes, Pools, Miners } from '../components/tabs'

const AdminPage = () => {
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <Tabs style={{ marginTop: '20px' }}>
              <TabList className={'card_tabs'}>
                <Tab>Coins</Tab>
                <Tab>AlgoCoins</Tab>
                <Tab>Invite Codes</Tab>
                <Tab>Pools</Tab>
                <Tab>Miners</Tab>
              </TabList>

              <TabPanel>
                <Coins />
              </TabPanel>
              <TabPanel>
                <AlgoCoins />
              </TabPanel>
              <TabPanel>
                <InviteCodes />
              </TabPanel>
              <TabPanel>
                <Pools />
              </TabPanel>
              <TabPanel>
                <Miners />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  coinsList: store.admin.coinsList,
})

const mapDispatchToProps = {
  setCoinsList: actions.setCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)
