import React from 'react'
import { Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const Download = () => {
  const { t } = useTranslation('common')
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className={'download_page'}>
          <Row>
            <Col sm={12} md={12}>
              <h5>{t('pages.download.betaRelease')}</h5>
              <a
                style={{ color: 'white' }}
                href={'https://repos.amos.farm/amos-a21.20-n510.60.02-5.10-stable.img.xz'}
                target={'_blank'}
                rel='noopener noreferrer'
              >
                amos-a21.20-n510.60.02-5.10-stable
              </a>
              <h5>{t('pages.download.currentRelease')}</h5>
              <a
                style={{ color: 'white' }}
                href={'https://repos.amos.farm/amos-a21.20-n510.60.02-5.10-stable.img.xz'}
                target={'_blank'}
                rel='noopener noreferrer'
              >
                amos-a21.20-n510.60.02-5.10-stable
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'supported_cards'}>
                <h5>{t('pages.download.supportedCardsList')}</h5>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className={'supported_cards'}>
                <h6>{t('pages.download.NVIDIACards')}</h6>
                <table className={'table table-striped table-bordered'}>
                  <tbody>
                    <tr>
                      <td>GTX 750Ti</td>
                      <td>GTX 960</td>
                      <td>GTX 970</td>
                      <td>GTX 980</td>
                      <td>GTX 1050</td>
                    </tr>
                    <tr>
                      <td>GTX 1060</td>
                      <td>GTX 1070</td>
                      <td>GTX 1070Ti</td>
                      <td>GTX 1080</td>
                      <td>GTX 1080Ti</td>
                    </tr>
                    <tr>
                      <td>GTX 1650</td>
                      <td>GTX 1660</td>
                      <td>GTX 1660Ti</td>
                      <td>GTX 1660 SUPER</td>
                      <td>RTX 2060</td>
                    </tr>
                    <tr>
                      <td>RTX 2060 SUPER</td>
                      <td>RTX 2070</td>
                      <td>RTX 2070 SUPER</td>
                      <td>RTX 2080</td>
                      <td>RTX 2080 SUPER</td>
                    </tr>
                    <tr>
                      <td>RTX 2080Ti</td>
                      <td>RTX 3060Ti</td>
                      <td>RTX 3070</td>
                      <td>RTX 3080</td>
                      <td>RTX 3090</td>
                    </tr>
                    <tr>
                      <td>LHR RTX 3060</td>
                      <td>LHR RTX 3060Ti</td>
                      <td>LHR RTX 3070</td>
                      <td>LHR RTX 3070Ti</td>
                      <td>LHR RTX 3080</td>
                    </tr>
                    <tr>
                      <td>LHR RTX 3080Ti</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className={'supported_cards'}>
                <h6>{t('pages.download.AMDCards')}</h6>
                <table className={'table table-striped table-bordered'}>
                  <tbody>
                    <tr>
                      <td>RX460</td>
                      <td>RX470</td>
                      <td>RX480</td>
                      <td>RX550</td>
                      <td>RX560</td>
                    </tr>
                    <tr>
                      <td>RX570</td>
                      <td>RX580</td>
                      <td>RX590</td>
                      <td>Vega56</td>
                      <td>Vega64</td>
                    </tr>
                    <tr>
                      <td>VegaFE</td>
                      <td>RX5500</td>
                      <td>RX5600XT</td>
                      <td>RX5700</td>
                      <td>RX5700XT</td>
                    </tr>
                    <tr>
                      <td>RX6600XT</td>
                      <td>RX6700XT</td>
                      <td>RX6800</td>
                      <td>RX6800XT</td>
                      <td>RX6900XT</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'supported_cards'}>
                <h5>{t('pages.download.mainboardBIOSSettings')}</h5>
                <ul>
                  <li>{t('pages.download.list1')}</li>
                  <li>{t('pages.download.list2')}</li>
                  <li>{t('pages.download.list3')}</li>
                  <li>{t('pages.download.list4')}</li>
                  <li>{t('pages.download.list5')}</li>
                  <li>{t('pages.download.list6')}</li>
                  <li>{t('pages.download.list7')}</li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={'supported_cards'}>
                <h5>{t('pages.download.howWriteImage')}</h5>
                <p>{t('pages.download.firstYouHave')}</p>
                <p>{t('pages.download.onceImageFlashing')}</p>
                <p>{t('pages.download.anotherWayWrite')}</p>
                <ul style={{ listStyleType: 'decimal' }}>
                  <li>{t('pages.download.secondList1')}</li>
                  <li>{t('pages.download.secondList2')}</li>
                  <li>{t('pages.download.secondList3')}</li>
                  <li>{t('pages.download.secondList4')}</li>
                  <li>{t('pages.download.secondList5')}</li>
                  <li>{t('pages.download.secondList6')}</li>
                  <li>{t('pages.download.secondList7')}</li>
                  <li>{t('pages.download.secondList8')}</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Download
