import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import * as actions from '../actions'
import SlotsActions from '../components/SlotsActions'
import { Table } from '../../../components/Table'
import { useTranslation } from 'react-i18next'
import { SlotModalCreate } from '../components/modals'
import * as miningActions from '../../MiningPresets/actions'
import Cookies from 'js-cookie'

const SlotsPage = ({ setSlotsList, slotsList, setAlgoCoinsList, slotsTotal }) => {
  const { t } = useTranslation('common')
  const [slotList, setSlotList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )

  useEffect(() => {
    setSlotsList({ page: currentPage, size: showCount })
  }, [setSlotsList, currentPage, showCount])

  useEffect(() => {
    setAlgoCoinsList({ page: 1, size: 1000 })
  }, [setAlgoCoinsList])

  useEffect(() => {
    const slotsArray = slotsList.map(slot => {
      return {
        ...slot,
        coin: slot.dualCoin ? slot.coin + '+' + slot.dualCoin : slot.coin,
        algo: slot.dualAlgo ? slot.algo + '+' + slot.dualAlgo : slot.algo,
        action: <SlotsActions slot={slot} />,
      }
    })
    setSlotList(slotsArray)
  }, [slotsList])

  const slots = {
    columns: [
      {
        label: t('pages.overclocking.id'),
        field: 'id',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.overclocking.name'),
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.overclocking.slotAlgo'),
        field: 'algo',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.overclocking.slotCoin'),
        field: 'coin',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.overclocking.slotComment'),
        field: 'comment',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.overclocking.rigsNumber'),
        field: 'rigsNumber',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.overclocking.action'),
        field: 'action',
      },
    ],
    rows: slotList,
  }

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.overclocking.slots')}</h3>
          </div>
        </div>
        <SlotModalCreate />
        <div className={'mining_preset__body'}>
          <Table
            className={'mining_preset__table table table-striped'}
            data={slots}
            responsive={true}
            noBottomColumns
            total={slotsTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  slotsList: store.profiles.slotsList,
  slotsTotal: store.profiles.slotsTotal,
  algoCoinsList: store.profiles.algoCoinsList,
})

const mapDispatchToProps = {
  setSlotsList: actions.setSlotsList,
  setAlgoCoinsList: actions.setAlgoCoinsList,
  setCoinsList: miningActions.setCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotsPage)
