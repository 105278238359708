import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import EditServerModal from './modals/EditServerModal'

const ServerActions = props => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation('common')
  const toggleEdit = () => {
    setShow(!show)
  }

  return (
    <div className={'actions__items'}>
      <EditServerModal
        toggle={toggleEdit}
        show={show}
        server={props.server}
        key={props.server ? props.server.order : 0}
        updateServer={props.updateServer}
      />
      <div
        className={'actions__item'}
        tooltip={t('pages.customPools.deleteServer')}
        flow='left'
        onClick={() => {
          props.removeServer(props.server)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
    </div>
  )
}

export default connect(null, null)(ServerActions)
