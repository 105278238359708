import React, { useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import * as actions from '../actions'
import useReactRouter from 'use-react-router'

// Redux
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// import images
import logoSm from '../../../assets/images/logo-sm-light.png'
import { useTranslation } from 'react-i18next'

const ResetPassword = ({ resetPassword }) => {
  const { history } = useReactRouter()
  const { t } = useTranslation('common')
  const handlerSubmit = (evebt, values) => {
    resetPassword({ email: values.email, history })
  }
  return (
    <React.Fragment>
      <div className='home-btn d-none d-sm-block'>
        <Link to={`${process.env.PUBLIC_URL}/`} className='text-dark'>
          <i className='fas fa-home h2'></i>
        </Link>
      </div>
      <div className='account-pages my-5 pt-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <div className='position-relative'>
                <Card className='overflow-hidden'>
                  <div className='bg-primary'>
                    <div className='text-primary text-center p-4'>
                      <h5 className='text-white font-size-20'>{t('pages.login.resetPassword')}</h5>
                      <Link to={`${process.env.PUBLIC_URL}/`} className='logo logo-admin'>
                        <img src={logoSm} height='48' alt='logo' />
                      </Link>
                    </div>
                  </div>

                  <CardBody className='p-4'>
                    <div className='p-3'>
                      <div className='alert alert-success mt-5' role='alert'>
                        {t('pages.login.enterEmailSentToYou')}
                      </div>
                      <AvForm className='form-horizontal mt-4' onValidSubmit={handlerSubmit}>
                        <div className='form-group'>
                          <AvField
                            name='email'
                            label={t('pages.login.email')}
                            className='form-control'
                            placeholder={t('pages.login.enterEmail')}
                            type='email'
                            required
                          />
                        </div>

                        <Row className='form-group'>
                          <Col sm={6}>&nbsp;</Col>
                          <Col sm={6} className='text-right'>
                            <button className='btn btn-primary w-md waves-effect waves-light' type='submit'>
                              {t('pages.login.reset')}
                            </button>
                          </Col>
                        </Row>
                        {/* <Row className="form-group mt-2 mb-0">
                          <div className="col-12 mt-4">
                            <Link to="/forget-password">
                              <i className="mdi mdi-lock"></i> Forgot your
                              password?
                            </Link>
                          </div>
                        </Row> */}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className='mt-5 text-center'>
                <p>
                  {t('pages.login.donHaveAccount')}
                  <Link to={`${process.env.PUBLIC_URL}/signup`} className='font-weight-medium text-primary'>
                    {' '}
                    {t('pages.login.signupNow')}
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = {
  login: actions.login,
  resetPassword: actions.resetPassword,
}

export default connect(null, mapDispatchToProps)(ResetPassword)
