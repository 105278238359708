import React, { useRef } from 'react'
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'

const SelectSlots = props => {
  const { t } = useTranslation('common')
  const slotOption = props => {
    return (
      <components.Option {...props}>
        {props.data.label}
        {props.data.comment && <div className={'slot_description'}>{props.data.comment}</div>}
      </components.Option>
    )
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <Select
        components={{ Option: slotOption }}
        options={props.options}
        classNamePrefix={'form_control_react_select'}
        className={props.error ? 'invalid-select' : ''}
        onChange={props.onchange}
        value={props.value}
        name={props.name}
        isSearchable
        isClearable
        isMulti={props.isMulti}
        closeMenuOnSelect={props.closeMenuOnSelect}
        hideSelectedOptions={props.hideSelectedOptions}
        placeholder={props.placeholder ? props.placeholder : t('inputs.select')}
        maxMenuHeight={200}
      />
    </div>
  )
}

export default SelectSlots
