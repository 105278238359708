import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input } from 'reactstrap'
import * as actionsProfiles from '../../actions'
import { useTranslation } from 'react-i18next'
import SelectWithImages from '../../../../components/UI/SelectWithImages'
import SelectWithCryptoImages from '../../../../components/UI/SelectWithCryptoImages'

const SlotModalCreate = props => {
  const { t } = useTranslation('common')
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [coin, setCoin] = useState(null)
  const [dualCoin, setDualCoin] = useState(null)
  const [algo, setAlgo] = useState(null)
  const [dualAlgo, setDualAlgo] = useState(null)
  const [comment, setComment] = useState('')

  const submit = async () => {
    const newErrors = {}
    if (!name || name.length === 0) newErrors.name = { message: t('pages.overclocking.nameError') }
    if (!algo) newErrors.algo = { message: t('pages.overclocking.algoError') }
    setErrors(newErrors)
    console.log(newErrors)
    if (Object.keys(newErrors).length > 0) return
    const res = await props.createSlot({
      name: name,
      coin: coin?.value || null,
      dualCoin: dualCoin?.value || null,
      algo: algo?.value || null,
      dualAlgo: dualAlgo?.value || null,
      comment: comment,
    })
    if (res) toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'power_unit__create'}>
        <Button onClick={toggle} color='primary' className={'mining_preset__create'}>
          {t('pages.overclocking.addSlot')}
        </Button>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        onClick={e => e.stopPropagation()}
        className={'modal-dialog-centered popup'}
      >
        <ModalHeader tag={'h4'} toggle={toggle}>
          {t('pages.overclocking.createSlot')}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className={'form-group'}>
              <Label>{t('pages.overclocking.slotName')}</Label>
              <Input
                className={errors.name ? 'invalid' : ''}
                type={'text'}
                name={'name'}
                onChange={e => {
                  setName(e.target.value)
                  errors.name = null
                  setErrors(errors)
                }}
              />
              <div className={'error'}>{errors.name && errors.name.message}</div>
            </Col>
            <Col xs={6} className={'form-group'}>
              <Label>{t('pages.overclocking.slotAlgo')}</Label>
              <SelectWithCryptoImages
                onChange={e => {
                  e.stopPropagation()
                }}
                placeholder={t('inputs.selectAlgo')}
                value={algo}
                onchange={option => {
                  setAlgo(option)
                  setCoin(null)
                  errors.algo = null
                  setErrors(errors)
                }}
                error={errors.algo}
                options={props.algoCoinsList.map(algo => {
                  return {
                    ...algo,
                    label: algo.algo,
                    value: algo.algo,
                  }
                })}
                hideSelectedOptions={true}
              />
              <div className={'error'}>{errors.algo && errors.algo.message}</div>
            </Col>
            {algo && algo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotCoin')}</Label>
                <SelectWithImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectCoin')}
                  value={coin}
                  onchange={option => setCoin(option)}
                  error={errors.coin}
                  options={[
                    { label: 'Unset', value: null },
                    ...props.coinsList
                      .filter(coin => algo.coins && algo.coins.indexOf(coin.nameSymbol) >= 0)
                      .map(coin => {
                        return {
                          ...coin,
                          icon: coin.nameSymbol,
                          label: coin.nameSymbol,
                          value: coin.nameSymbol,
                        }
                      }),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.coin && errors.coin.message}</div>
              </Col>
            )}
            {algo && algo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotDualAlgo')}</Label>
                <SelectWithCryptoImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectAlgo')}
                  value={dualAlgo}
                  onchange={option => {
                    setDualAlgo(option)
                    setDualCoin(null)
                  }}
                  error={errors.dualAlgo}
                  options={[
                    { label: 'Unset', value: null },
                    ...props.algoCoinsList.map(algo => {
                      return {
                        ...algo,
                        label: algo.algo,
                        value: algo.algo,
                      }
                    }),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.dualAlgo && errors.dualAlgo.message}</div>
              </Col>
            )}
            {algo && algo.value && dualAlgo && dualAlgo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotDualCoin')}</Label>
                <SelectWithImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectCoin')}
                  value={dualCoin}
                  onchange={option => setDualCoin(option)}
                  error={errors.dualCoin}
                  options={[
                    { label: 'Unset', value: null },
                    ...props.coinsList
                      .filter(coin => dualAlgo.coins && dualAlgo.coins.indexOf(coin.nameSymbol) >= 0)
                      .map(coin => {
                        return {
                          ...coin,
                          icon: coin.nameSymbol,
                          label: coin.nameSymbol,
                          value: coin.nameSymbol,
                        }
                      }),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.dualCoin && errors.dualCoin.message}</div>
              </Col>
            )}
            <Col xs={12} className={'form-group'}>
              <Label>{t('pages.overclocking.slotComment')}</Label>
              <Input type={'textarea'} name={'comment'} onChange={e => setComment(e.target.value)} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={submit}>
            {t('pages.overclocking.create')}
          </Button>
          <Button color='secondary' onClick={toggle}>
            {t('pages.overclocking.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  algoCoinsList: store.profiles.algoCoinsList,
  coinsList: store.presets.coinsList,
})

const mapDispatchToProps = {
  createSlot: actionsProfiles.createSlot,
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotModalCreate)
