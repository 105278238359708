import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'

const CreateCoinModal = props => {
  const [name, setName] = useState('')
  const [nameSymbol, setNameSymbol] = useState('')
  const [units, setUnits] = useState('')
  const [errors, setErrors] = useState({})

  const toggle = () => {
    setShow(!show)
  }

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'name': {
        setName(e.target.value)
        return
      }
      case 'nameSymbol': {
        setNameSymbol(e.target.value)
        return
      }
      case 'units': {
        setUnits(e.target.value)
        return
      }
      default:
        return
    }
  }

  const submitFunc = () => {
    //setErrors({})
    const newErrors = {}
    if (name.length === 0) newErrors.name = { message: "Coin Name can't be empty" }
    if (nameSymbol.length === 0) newErrors.nameSymbol = { message: "NameSymbol can't be empty" }
    if (units.length === 0) newErrors.units = { message: "Units can't be empty" }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    props.createCoin({
      name: name,
      nameSymbol: nameSymbol,
      units: units,
    })
    toggle()
  }
  const [show, setShow] = useState(false)

  return (
    <div onClick={e => e.stopPropagation()}>
      <Button onClick={toggle} color='primary' className={'mining_preset__create'}>
        Add Coin
      </Button>
      <Modal isOpen={show} toggle={toggle} className={'modal-dialog-centered'} onClick={e => e.stopPropagation()}>
        <ModalHeader tag={'h4'} toggle={toggle}>
          Create Coin
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={4} className={'form-group'}>
                <Label>Name</Label>
                <Input
                  className={errors.name ? 'invalid' : ''}
                  type={'text'}
                  name={'name'}
                  onChange={onChange}
                  value={name}
                />
                <div className={'error'}>{errors.name && errors.name.message}</div>
              </Col>
              <Col xs={4} className={'form-group'}>
                <Label>Name Symbol</Label>
                <Input
                  className={errors.nameSymbol ? 'invalid' : ''}
                  type={'text'}
                  name={'nameSymbol'}
                  onChange={onChange}
                  value={nameSymbol}
                />
                <div className={'error'}>{errors.nameSymbol && errors.nameSymbol.message}</div>
              </Col>
              <Col xs={4} className={'form-group'}>
                <Label>Units</Label>
                <Input
                  className={errors.units ? 'invalid' : ''}
                  type={'text'}
                  name={'units'}
                  onChange={onChange}
                  value={units}
                />
                <div className={'error'}>{errors.units && errors.units.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = {
  createCoin: actions.createCoin,
}

export default connect(null, mapDispatchToProps)(CreateCoinModal)
