export const types = {
  SET_WALLETS_LIST: 'wallets/setList',
  SET_COINS_LIST: 'coin/setList',
  SET_POOLS_LIST: 'pool/setList',
  SET_POOL: 'pool/setPool',
  SET_MINERS_LIST: 'miner/setList',
  SET_PRESETS_LIST: 'miningPreset/setList',
  SET_AVERAGE_HASH_LIST: 'miningPreset/setAverageHashList',
  UPDATE_MINING_PRESET: 'miningPreset/update',
  DELETE_MINING_PRESET: 'miningPreset/delete',
  COPY_MINING_PRESET: 'miningPreset/copy',
  CREATE_MINING_PRESET: 'miningPreset/createMiningPreset',
  CREATE_WALLET: 'wallets/createWallet',
  DELETE_WALLET: 'wallets/deleteWallet',
  UPDATE_WALLET: 'wallets/updateWallet',
}
