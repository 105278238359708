import React, { useState } from 'react'

import { connect } from 'react-redux'
import EditMinerVersionModal from './modals/EditMinerVersionModal'

const MinerVersionActions = props => {
  const [show, setShow] = useState(false)

  const toggleEdit = () => {
    setShow(!show)
  }

  return (
    <div className={'actions__items'}>
      <EditMinerVersionModal
        toggle={toggleEdit}
        show={show}
        version={props.version}
        key={props.version ? props.version.order : 0}
        updateVersion={props.updateVersion}
        versions={props.versions}
      />
      <div
        className={'actions__item'}
        tooltip='Delete version'
        flow='left'
        onClick={() => {
          props.removeVersion(props.version)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
    </div>
  )
}

export default connect(null, null)(MinerVersionActions)
