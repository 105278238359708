import { useMemo } from 'react'
import { connect } from 'react-redux'

import { changeLayout, changeTopbarTheme, changeLayoutWidth } from '../../store/actions'

import { toggleRightSidebar } from '../cssFunction'

// Other Layout related Component
import RightSidebar from '../../components/RightSidebar'
import TopBar from './TopBar'
import Navbar from './Navbar'
import Footer from './Footer'
import { useState } from 'react'
import { useEffect } from 'react'

const Layout = props => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const propsArr = useMemo(() => {
    return {
      changeLayout: props.changeLayout,
      topbarTheme: props.layout.topbarTheme,
      changeTopbarTheme: props.changeTopbarTheme,
      layoutWidth: props.layout.layoutWidth,
      changeLayoutWidth: props.changeLayoutWidth,
    }
  }, [
    props.changeLayout,
    props.changeTopbarTheme,
    props.changeLayoutWidth,
    props.layout.layoutWidth,
    props.layout.topbarTheme,
  ])

  //component did mount
  useEffect(() => {
    window.scrollTo(0, 0)
    propsArr.changeLayout('horizontal')
    if (propsArr.topbarTheme) {
      propsArr.changeTopbarTheme(propsArr.topbarTheme)
    }
    if (propsArr.layoutWidth) {
      propsArr.changeLayoutWidth(propsArr.layoutWidth)
    }
  }, [propsArr])
  /**
   * Open/close right sidebar
   */
  const toggleRightSidebarFunc = () => {
    toggleRightSidebar()
  }

  /**
   * Opens the menu - mobile
   */
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  return (
    <>
      <div id='layout-wrapper'>
        <TopBar
          theme={props.layout.topbarTheme}
          isMenuOpened={isMenuOpened}
          toggleRightSidebar={toggleRightSidebarFunc}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar menuOpen={isMenuOpened} />

        <div className='main-content'>
          <div className='page-content'>{props.children}</div>
        </div>
        <Footer />
        <RightSidebar />
      </div>
    </>
  )
}

const mapStatetoProps = state => ({
  layout: state.layout,
})

export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth,
})(Layout)
