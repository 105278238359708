import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Label, Button } from 'reactstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import * as actions from '../actions'
import CritTempSection from './CritTempSection'

const GeneralSettingsTab = props => {
  const { t } = useTranslation('common')
  const [use_data_centers_and_rooms, set_use_data_centers_and_rooms] = useState(false)
  const [use_external_devices_to_compare_power_consumption, set_use_external_devices_to_compare_power_consumption] =
    useState(false)
  const [
    use_external_pdu_devices_for_worker_power_management,
    set_use_external_pdu_devices_for_worker_power_management,
  ] = useState(false)
  const [globally_use_amos_watchdog, set_globally_use_amos_watchdog] = useState(false)
  const [globally_use_autofan_by_default, set_globally_use_autofan_by_default] = useState(false)
  const [external_fan_controlled_by, set_external_fan_controlled_by] = useState({
    value: '1',
    label: t('pages.settings.temperature'),
  })
  const [mark_worker_as_unavailable, set_mark_worker_as_unavailable] = useState('')
  const [mark_worker_as_lhr_warning, set_mark_worker_as_lhr_warning] = useState('')
  const [mark_worker_as_lhr_critical, set_mark_worker_as_lhr_critical] = useState('')
  const [high_temperature, set_high_temperature] = useState(props.settings.highTemp)
  const [critical_temperature, set_critical_temperature] = useState(props.settings.critTemp)
  const [worker_action, set_worker_action] = useState({ value: '1', label: t('pages.settings.stopMiner') })

  useEffect(() => {
    props.setAutofan({ type: 'global' })
    props.setGeneralSettings({ type: 'global' })
  }, [])

  useEffect(() => {
    set_critical_temperature(props.settings.critTemp)
    set_high_temperature(props.settings.highTemp)
    if (props.settings.critTempAction === 'Stop Miner') {
      set_worker_action({ value: '1', label: t('pages.settings.stopMiner') })
    }
    if (props.settings.critTempAction === 'Power Off') {
      set_worker_action({ value: '5', label: t('pages.settings.powerOff') })
    }
    if (props.settings.critTempAction === 'Reboot') {
      set_worker_action({ value: '6', label: t('pages.settings.reboot') })
    }
    if (props.settings.critTempAction === 'Exclude GPU') {
      set_worker_action({ value: '2', label: t('pages.settings.excludeGPU') })
    }
    if (props.settings.critTempAction === 'Nothing') {
      set_worker_action({ value: '4', label: t('pages.settings.nothing') })
    }
  }, [props.settings.critTemp, props.settings.critTempAction, props.settings.highTemp])

  const applySettings = async () => {
    await props.updateGeneralSettings({
      critTemp: +critical_temperature,
      highTemp: +high_temperature,
      critTempAction: worker_action.label,
    })
  }

  const defoultSettings = async () => {
    await props.toDefoultGeneralSettings({
      id: +props.settings.id,
    })
  }

  return (
    <div className={'settings__general'}>
      <div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={use_data_centers_and_rooms}
              onChange={() => set_use_data_centers_and_rooms(!use_data_centers_and_rooms)}
            />
            {t('pages.settings.useDataCentersRooms')}
          </Label>
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={use_external_devices_to_compare_power_consumption}
              onChange={() =>
                set_use_external_devices_to_compare_power_consumption(
                  !use_external_devices_to_compare_power_consumption,
                )
              }
            />
            {t('pages.settings.useExternalDevices')}
          </Label>
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={use_external_pdu_devices_for_worker_power_management}
              onChange={() =>
                set_use_external_pdu_devices_for_worker_power_management(
                  !use_external_pdu_devices_for_worker_power_management,
                )
              }
            />
            {t('pages.settings.UseExternalPDU')}
          </Label>
        </div>
        <hr />
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={globally_use_amos_watchdog}
              onChange={() => set_globally_use_amos_watchdog(!globally_use_amos_watchdog)}
            />
            {t('pages.settings.globallyAMOSWatchdog')}
          </Label>
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={globally_use_autofan_by_default}
              onChange={() => set_globally_use_autofan_by_default(!globally_use_autofan_by_default)}
            />
            {t('pages.settings.globallyAutoFANDefault')}
          </Label>
        </div>
      </div>

      <div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>{t('pages.settings.externalFANControlled')}</Label>
          <Select
            classNamePrefix={'form_control_react_select'}
            options={[{ value: '1', label: t('pages.settings.temperature') }]}
            value={external_fan_controlled_by}
            defaultValue={''}
            placeholder={t('inputs.select')}
            onChange={option => set_external_fan_controlled_by(option)}
          />
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>{t('pages.settings.markWorkerUnavailable')}</Label>
          <Input
            placeholder={'60'}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            value={mark_worker_as_unavailable}
            onChange={e => set_mark_worker_as_unavailable(e.target.value)}
          />
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>{t('pages.settings.markWorkerLHR')}</Label>
          <div className={'settings__row'}>
            <Input
              placeholder={'10'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={mark_worker_as_lhr_warning}
              onChange={e => set_mark_worker_as_lhr_warning(e.target.value)}
            />
            <Input
              placeholder={'21'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={mark_worker_as_lhr_critical}
              onChange={e => set_mark_worker_as_lhr_critical(e.target.value)}
            />
          </div>
        </div>

        <div className={'settings__dashed'}>
          <CritTempSection
            critical_temperature={critical_temperature}
            set_critical_temperature={set_critical_temperature}
            worker_action={worker_action}
            set_worker_action={set_worker_action}
          />
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>{t('pages.settings.highTemperature')}</Label>
          <Input
            placeholder={'20'}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            value={high_temperature ? high_temperature : '20'}
            onChange={e => set_high_temperature(e.target.value)}
          />
        </div>
        <div className='settings_row'>
          <Button
            color='primary'
            onClick={() => {
              applySettings()
            }}
          >
            {t('pages.miningPresets.apply')}
          </Button>
          <Button
            color='secondary'
            onClick={() => {
              defoultSettings()
            }}
          >
            {t('pages.settings.toDefoult')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  settings: store.settings,
})

const mapDispatchToProps = {
  setAutofan: actions.setAutofan,
  setGeneralSettings: actions.setGeneralSettings,
  updateGeneralSettings: actions.updateGeneralSettings,
  toDefoultGeneralSettings: actions.toDefoultGeneralSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsTab)
