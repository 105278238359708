import Switch from 'react-switch'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SwitchServer = props => {
  const [switch1, setSwitch1] = useState(props.toggle)
  const { t } = useTranslation('common')
  const toggleSwitch = () => {
    setSwitch1(!switch1)
    props.setAddress(props.address)
  }

  const OffSymbol = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 10,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        {t('switch.off')}
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 10,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        {t('switch.on')}
      </div>
    )
  }

  return (
    <Switch
      uncheckedIcon={<OffSymbol />}
      checkedIcon={<OnSymbol />}
      onColor='#626ed4'
      onChange={toggleSwitch}
      checked={switch1}
      height={20}
      width={40}
    />
  )
}

export default SwitchServer
