import React, { useState, useEffect } from 'react'
import { Input, Label } from 'reactstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const CritTempSection = props => {
  const { t } = useTranslation('common')

  return (
    <div>
      <div className={'settings__row'}>
        <Label className={'checkboxLabel'}>{t('pages.settings.criticalTemperature')}</Label>
        <Input
          placeholder={'20'}
          onKeyPress={event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
          value={props.critical_temperature}
          onChange={e => props.set_critical_temperature(e.target.value)}
        />
      </div>
      <div className={'settings__row'}>
        <Label className={'checkboxLabel'}>{t('pages.settings.workerAction')}</Label>
        <Select
          classNamePrefix={'form_control_react_select'}
          options={[
            { value: '1', label: t('pages.settings.stopMiner') },
            {
              value: '2',
              label: t('pages.settings.excludeGPU'),
            },
            { value: '3', label: t('pages.settings.slowDown') },
            { value: '4', label: t('pages.settings.nothing') },
            {
              value: '5',
              label: t('pages.settings.powerOff'),
            },
            {
              value: '6',
              label: t('pages.settings.reboot'),
            },
          ]}
          value={props.worker_action}
          defaultValue={''}
          onChange={option => props.set_worker_action(option)}
          placeholder={t('inputs.select')}
        />
      </div>
    </div>
  )
}

export default CritTempSection
