import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import { Input, Label, Tooltip } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import * as actions from '../actions'

const AutofanComp = props => {
  const [autofan_gpu, set_autofan_gpu] = useState(props.autofan.status ? props.autofan.status : false)
  const { t } = useTranslation('common')
  const [target_core_temp, set_target_core_temp] = useState(props.autofan.targetTemp ? props.autofan.targetTemp : '')
  const [target_mem_temp, set_target_mem_temp] = useState(
    props.autofan.targetMemTemp ? props.autofan.targetMemTemp : '',
  )
  const [min_fan_speed, set_min_fan_speed] = useState(props.autofan.minFanSpeed ? props.autofan.minFanSpeed : '')
  const [max_fan_speed, set_max_fan_speed] = useState(props.autofan.maxFanSpeed ? props.autofan.maxFanSpeed : '')
  const [keep_target_temp, set_keep_target_temp] = useState(
    props.autofan.keepTargetTemp ? props.autofan.keepTargetTemp : false,
  )
  const [cold_case, set_cold_case] = useState(props.autofan.coldCase ? props.autofan.coldCase : false)
  const [tooltip_open, set_tooltip_open] = useState(false)

  const toggle = () => {
    set_tooltip_open(!tooltip_open)
  }

  const actionAutofan = async () => {
    const result = await props.actionAutofan({
      type: props.type,
      rigsIds: props.type === 'local' ? props.rigsIds.map(el => el.id).toString() : '',
      autofanId: +props.autofan.id,
      minFanSpeed: +min_fan_speed,
      maxFanSpeed: +max_fan_speed,
      targetMemTemp: +target_mem_temp,
      targetTemp: +target_core_temp,
      status: !autofan_gpu,
      keepTargetTemp: keep_target_temp,
      coldCase: cold_case,
    })
    if (result) {
      set_autofan_gpu(!autofan_gpu)
    }
  }

  return (
    <div className={'settings__power_management'}>
      <div className={'settings__section'}>
        <div className={'settings__section_header'}>
          <h4>{t('pages.settings.autoFANGPU')}</h4>
          <Switch
            onColor='#02a499'
            onChange={() => actionAutofan()}
            checked={autofan_gpu ? autofan_gpu : false}
            height={20}
            width={40}
          />
        </div>

        <div className={'settings__section_body'}>
          <Tooltip placement='top' isOpen={tooltip_open} autohide={true} toggle={toggle} target='max_value'>
            maximum value 100
          </Tooltip>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>{t('pages.settings.targetCoreTemp')}</Label>
            <Input
              id='max_value'
              placeholder={target_core_temp}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={target_core_temp}
              onChange={e => set_target_core_temp(e.target.value)}
            />
          </div>

          <div className={'settings__row'}>
            <Label className={'checkboxLabel'} id='some'>
              {t('pages.settings.targetMemTemp')}
            </Label>
            <Input
              id='max_value'
              placeholder={target_mem_temp}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={target_mem_temp}
              onChange={e => set_target_mem_temp(e.target.value)}
            />
          </div>

          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>{t('pages.settings.minFanSpeed')}</Label>
            <Input
              id='max_value'
              placeholder={min_fan_speed}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={min_fan_speed}
              onChange={e => set_min_fan_speed(e.target.value)}
            />
          </div>

          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>{t('pages.settings.maxFanSpeed')}</Label>
            <Input
              id='max_value'
              placeholder={max_fan_speed}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={max_fan_speed}
              onChange={e => set_max_fan_speed(e.target.value)}
            />
          </div>

          <div className={'settings__row'}>
            <Label className={'checkboxLabel'} style={{ marginRight: '5px' }}>
              {t('pages.settings.keepTargetTemp')}
            </Label>
            <Switch
              onColor='#02a540'
              onChange={() => set_keep_target_temp(!keep_target_temp)}
              checked={keep_target_temp}
              height={20}
              width={40}
            />
          </div>

          <div className={'settings__row'}>
            <Label className={'checkboxLabel'} style={{ marginRight: '5px' }}>
              {t('pages.settings.coldCase')}
            </Label>
            <Switch
              onColor='#02a540'
              onChange={() => {
                set_cold_case(!cold_case)
                console.log('🚀 ~ file: AutofanComp.js:168 ~ cold_case:', !cold_case)
              }}
              checked={cold_case}
              height={20}
              width={40}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  autofan: store.settings.autofan,
})

const mapDispatchToProps = {
  setAutofan: actions.setAutofan,
  actionAutofan: actions.actionAutofan,
}

export default connect(mapStateToProps, mapDispatchToProps)(AutofanComp)
