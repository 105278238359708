import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'
import CreateServerModal from './CreateServerModal'
import ServerActions from '../ServerActions'
import Select from 'react-select'

const CreatePoolModal = props => {
  const [title, setTitle] = useState('')
  const [sslPort, setSslPort] = useState('')
  const [stratumPort, setStratumPort] = useState('')
  const [altStratumPort, setAltStratumPort] = useState('')
  const [type, setType] = useState('')
  const [coins, setCoins] = useState([])
  const [soloPort, setSoloPort] = useState('')
  const [poolServers, setPoolServers] = useState([])
  const [serverOrder, setServerOrder] = useState(0)
  const [errors, setErrors] = useState({})
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'title': {
        setTitle(e.target.value)
        return
      }
      case 'sslPort': {
        setSslPort(e.target.value)
        return
      }
      case 'stratumPort': {
        setStratumPort(e.target.value)
        return
      }
      case 'altStratumPort': {
        setAltStratumPort(e.target.value)
        return
      }
      case 'type': {
        setType(e.target.value)
        return
      }
      case 'coins': {
        setCoins(e.target.value)
        return
      }
      case 'soloPort': {
        setSoloPort(e.target.value)
        return
      }
      default:
        return
    }
  }

  const submitFunc = () => {
    const newErrors = {}
    if (title.length === 0) newErrors.title = { message: "Pool Title can't be empty" }
    if (poolServers.length === 0) newErrors.poolServers = { message: "Servers can't be empty" }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    props.createPool({
      title: title,
      sslPort: sslPort,
      stratumPort: stratumPort,
      altStratumPort: altStratumPort,
      soloPort: soloPort,
      type: type,
      coins: coins.map(coin => coin.value).join(', '),
      poolServers: poolServers.map(server => {
        return {
          country: server.country,
          address: server.address,
          type: server.type,
          coin: server.coin,
        }
      }),
    })
    toggle()
  }

  const [showServerModal, setShowServerModal] = useState(false)
  const toggleServerModal = () => {
    setShowServerModal(!showServerModal)
  }

  const addServer = server => {
    server.order = serverOrder
    setPoolServers([...poolServers, server])
    setServerOrder(+serverOrder + +1)
  }

  const updateServer = server => {
    const index = poolServers.findIndex(fServer => fServer.order === server.order)
    setPoolServers([...poolServers.slice(0, index), server, ...poolServers.slice(index + 1)])
  }

  const removeServer = server => {
    setPoolServers(poolServers.filter(fServer => fServer !== server))
  }
  return (
    <div onClick={e => e.stopPropagation()}>
      <Button onClick={toggle} color='primary' className={'mining_preset__create'}>
        Add Pool
      </Button>
      <Modal isOpen={show} toggle={toggle} className={'modal-dialog-centered'} onClick={e => e.stopPropagation()}>
        <ModalHeader tag={'h4'} toggle={toggle}>
          Create Pool
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>Title</Label>
                <Input
                  className={errors.title ? 'invalid' : ''}
                  type={'text'}
                  name={'title'}
                  onChange={onChange}
                  value={title}
                />
                <div className={'error'}>{errors.title && errors.title.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>SslPort</Label>
                <Input
                  className={errors.sslPort ? 'invalid' : ''}
                  type={'text'}
                  name={'sslPort'}
                  onChange={onChange}
                  value={sslPort}
                />
                <div className={'error'}>{errors.sslPort && errors.sslPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>StratumPort</Label>
                <Input
                  className={errors.stratumPort ? 'invalid' : ''}
                  type={'text'}
                  name={'stratumPort'}
                  onChange={onChange}
                  value={stratumPort}
                />
                <div className={'error'}>{errors.stratumPort && errors.stratumPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>AltStratumPort</Label>
                <Input
                  className={errors.altStratumPort ? 'invalid' : ''}
                  type={'text'}
                  name={'altStratumPort'}
                  onChange={onChange}
                  value={altStratumPort}
                />
                <div className={'error'}>{errors.altStratumPort && errors.altStratumPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>SoloPort</Label>
                <Input
                  className={errors.soloPort ? 'invalid' : ''}
                  type={'text'}
                  name={'soloPort'}
                  onChange={onChange}
                  value={soloPort}
                />
                <div className={'error'}>{errors.soloPort && errors.soloPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>Type</Label>
                <Input
                  className={errors.type ? 'invalid' : ''}
                  type={'text'}
                  name={'type'}
                  onChange={onChange}
                  value={type}
                />
                <div className={'error'}>{errors.type && errors.type.message}</div>
              </Col>
              <Col xs={12} className={'form-group'}>
                <Label>Coins</Label>
                <Select
                  classNamePrefix={errors.coins ? 'invalid form_control_react_select' : 'form_control_react_select'}
                  value={coins}
                  onChange={option => {
                    setCoins(option)
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  isMulti={true}
                  options={props.coinsList.map(coin => {
                    return { value: coin.nameSymbol, label: coin.nameSymbol }
                  })}
                />
                <div className={'error'}>{errors.coins && errors.coins.message}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={'form-group'}>
                <div className={'createPoolServers'}>
                  <h5>Servers</h5>
                  <CreateServerModal
                    key={poolServers + poolServers.length}
                    addServer={addServer}
                    show={showServerModal}
                    toggle={toggleServerModal}
                  />
                </div>
                <table className={'table table-striped table-bordered'}>
                  <thead className={'thead-light'}>
                    <tr>
                      <th>Country</th>
                      <th>Address</th>
                      <th>Type</th>
                      <th>Coin</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poolServers.map(server => {
                      return (
                        <tr key={server.order}>
                          <td>{server.country}</td>
                          <td>{server.address}</td>
                          <td>{server.type}</td>
                          <td>{server.coin}</td>
                          <td>
                            <ServerActions
                              key={server.order}
                              server={server}
                              updateServer={updateServer}
                              removeServer={removeServer}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className={'error'}>{errors.poolServers && errors.poolServers.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  coinsList: store.admin.coinsList,
  algoCoinsList: store.admin.algoCoinsList,
})
const mapDispatchToProps = {
  createPool: actions.createPool,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePoolModal)
