import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
}

export default withRouter(Navbar)
