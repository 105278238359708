import React from 'react'
import { Alert } from 'reactstrap'
import { connect } from 'react-redux'
import * as actions from '../store/layout/actions'

const MiningAlert = props => {
  const removeAlert = () => {
    props.removeAlert(props.alert.id)
  }

  setTimeout(() => {
    removeAlert()
  }, 4000)

  return (
    <Alert color={props.alert.style} toggle={removeAlert}>
      {props.alert.text}
    </Alert>
  )
}

const mapDispatchToProps = {
  removeAlert: actions.removeAlert,
}

export default connect(null, mapDispatchToProps)(MiningAlert)
