import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import Cookies from 'js-cookie'
import 'react-tabs/style/react-tabs.css'
// Import scss
import './assets/scss/theme.scss'
import {
  Login,
  SignUp,
  MainPage,
  MiningPresetsPage,
  OverclockingProfilesPage,
  WorkersPage,
  DataCentersPage,
  RoomsPage,
  ResetPassword,
  Maintenance,
  WalletsPage,
  Download,
  PowerUnitsPage,
  Settings,
  AdminPage,
  CustomPoolsPage,
  Donate,
  SlotsPage,
  Schedule,
} from './pages'

import common_en from './locales/en/en.json'
import common_ru from './locales/ru/ru.json'

// layouts
import VerticalLayout from './components/VerticalLayout/'
import HorizontalLayout from './components/HorizontalLayout/'
import AlertsOverlayComponent from './utils/AlertsOverlayComponent'
import Loader from './components/Loader/Loader'

// const AdminRoute = ({
//   component: Component,
//   layout: Layout,
//   props,
//   ...rest
// }) => {
//   const role = props.me.roles.find((item) => item === 'admin') || 'user'
//   return (
//     <Route
//       {...rest}
//       render={(propsNext) =>
//         !!props.token && role === 'admin' ? (
//           <Layout>
//             <Component {...propsNext} />
//           </Layout>
//         ) : (
//           <Redirect to={`${process.env.PUBLIC_URL}/login`} />
//         )
//       }
//     />
//   )
// }
const parseJson = json => {
  try {
    return JSON.parse(json)
  } catch (e) {
    return ''
  }
}

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: Cookies.get('lang') ? parseJson(Cookies.get('lang')) : 'en', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    ru: {
      common: common_ru,
    },
  },
  fallbackLng: 'en',
})

const PrivateRoute = ({ component: Component, layout: Layout, token, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      token ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to={`${process.env.PUBLIC_URL}/login`} />
      )
    }
  />
)

const NonAuthRoute = ({ component: Component, token, ...rest }) => (
  <Route
    {...rest}
    render={props => (token ? <Redirect to={`${process.env.PUBLIC_URL}/login`} /> : <Component {...props} />)}
  />
)

const App = props => {
  const getLayout = () => {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <I18nextProvider i18n={i18next}>
      <div className='App'>
        <AlertsOverlayComponent />
        <Loader />
        <Switch>
          <PrivateRoute
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={MainPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            exact
            path={`${process.env.PUBLIC_URL}/workers`}
            component={WorkersPage}
            token={props.token}
            layout={Layout}
          />
          <NonAuthRoute path={`${process.env.PUBLIC_URL}/login`} component={Login} />
          <NonAuthRoute path={`${process.env.PUBLIC_URL}/signup`} component={SignUp} />
          {/* <PrivateRoute
          path={`${process.env.PUBLIC_URL}/rigsData`}
          component={RigsDataPage}
          token={props.token}
          layout={Layout}
        /> */}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/miningPresets`}
            component={MiningPresetsPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/overclockingProfiles`}
            component={OverclockingProfilesPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/slots`}
            component={SlotsPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/dataCenters`}
            component={DataCentersPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/rooms`}
            component={RoomsPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/wallets`}
            component={WalletsPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/download`}
            component={Download}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/donate`}
            component={Donate}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/powerUnits`}
            component={PowerUnitsPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/settings`}
            component={Settings}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/adminPage`}
            component={AdminPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/customPools`}
            component={CustomPoolsPage}
            token={props.token}
            layout={Layout}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/schedule`}
            component={Schedule}
            token={props.token}
            layout={Layout}
          />
          <NonAuthRoute path={`${process.env.PUBLIC_URL}/resetPassword`} component={ResetPassword} />
          <NonAuthRoute path={`${process.env.PUBLIC_URL}/maintenance`} component={Maintenance} />
        </Switch>
      </div>
    </I18nextProvider>
  )
}

const mapStateToProps = store => ({
  me: store.login,
  layout: store.layout,
  alerts: store.alerts,
})

export default connect(mapStateToProps, null)(App)
