import React, { useEffect, useState } from 'react'
import { Col, Input, Row } from 'reactstrap'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as ReactDOM from 'react-dom'
import Loader from '../Loader'

const TableWorkers = ({
  data,
  stickyHeader = false,
  responsive = false,
  maxHeight = false,
  firstColumnCheckbox = false,
  entries = [10, 20, 50, 100],
  showEntries = true,
  showPagination = true,
  duplicatePagination = false,
  duplicateEntries = false,
  name = 'dataTable',
  uncheckAll = false,
  showCheckbox = false,
  total,
  currentPage,
  setCurrentPage,
  showCount,
  setShowCount,
  setSort,
  ...props
}) => {
  if (window.location.pathname !== Cookies.get('pathName')) {
    Cookies.set('pathName', window.location.pathname, { expires: 30 })
    Cookies.set(window.location.pathname + name + 'currentPage', 1, { expires: 30 })
    for (const key of Object.keys(Cookies.get())) {
      if (key.toString().includes('currentPage')) {
        Cookies.set(key, 1, { expires: 30 })
      }
    }
  }
  const { t } = useTranslation('common')
  const [allChecked, setAllChecked] = useState(false)
  const [updateState, setUpdateState] = useState(false)
  const [dataRows, setDataRows] = useState(data.rows)
  const [dataColumns, setDataColumns] = useState(data.columns)
  const [orderBy, setOrderBy] = useState(
    Cookies.get(window.location.pathname + name + 'orderBy')
      ? Cookies.get(window.location.pathname + name + 'orderBy')
      : false,
  )
  const [orderDirection, setOrderDirection] = useState(
    Cookies.get(window.location.pathname + name + 'orderDirection')
      ? Cookies.get(window.location.pathname + name + 'orderDirection') === 'ASC'
      : true,
  )

  const [searchTableValue, setSearchTableValue] = useState('')

  useEffect(() => {
    if (!searchTableValue) {
      ReactDOM.unstable_batchedUpdates(() => {
        props.setSearch(searchTableValue)
        setCurrentPageFunc(1)
      })
    } else {
      const setSearch = setTimeout(() => {
        ReactDOM.unstable_batchedUpdates(() => {
          props.setSearch(searchTableValue)
          setCurrentPageFunc(1)
        })
      }, 1000)

      return () => clearTimeout(setSearch)
    }
  }, [searchTableValue])

  useEffect(() => {
    if (data.rows.length > 0) {
      setCurrentPage(
        Cookies.get(window.location.pathname + name + 'currentPage')
          ? parseInt(Cookies.get(window.location.pathname + name + 'currentPage'))
          : 1,
      )
    }
    setDataRows(
      data.rows.map(row => {
        return {
          ...row,
          checked: dataRows.find(row_f => row_f.id === row.id)
            ? dataRows.find(row_f => row_f.id === row.id).checked
            : false,
        }
      }),
    )
  }, [data.rows, props.favoriteFirst, props.search])

  useEffect(() => {
    setUncheckAll()
  }, [uncheckAll])

  useEffect(() => {
    setDataColumns(
      data.columns.map(column_new => {
        return {
          ...column_new,
          sorted: column_new.field === orderBy,
          orderDirection: column_new.field === orderBy ? (orderDirection ? 'asc' : 'desc') : 'asc',
        }
      }),
    )
  }, [data.columns, orderBy, orderDirection])

  const setVisible = props.setVisible

  useEffect(() => {
    if (setVisible) setVisible(dataRows)
  }, [dataRows, currentPage, showCount, setVisible])

  const setCurrentPageFunc = currentPage => {
    setCurrentPage(currentPage)
    setUncheckAll()
    Cookies.set(window.location.pathname + name + 'currentPage', currentPage, { expires: 30 })
  }
  const setShowCountFunc = showCount => {
    setShowCount(showCount)
    Cookies.set(window.location.pathname + name + 'showCount', showCount, {
      expires: 30,
    })
  }
  const allCheckbox = () => {
    setAllChecked(!allChecked)
    const rows = []
    for (let i = 0; i < dataRows.length; i++) {
      rows.push(dataRows[i])
      dataRows[i].checked = !allChecked
    }
    console.log(allChecked)
    if (props.checkAll && !allChecked) props.checkAll(rows)
    setUpdateState(!updateState)
    if (allChecked) {
      console.log('here')
      props.checkAll([])
    }
  }

  useEffect(() => {
    setUncheckAll()
  }, [props.clearCheck])

  const checkRow = row => {
    if (props.checkRow) props.checkRow(row)
    row.checked = !row.checked
    let allChecked = true
    for (let i = currentPage * showCount; i < (currentPage + 1) * showCount && i < dataRows.length; i++) {
      if (!dataRows[i].checked) allChecked = false
    }
    setUpdateState(!updateState)
  }

  const nextPage = () => {
    if (currentPage * showCount < total) {
      setCurrentPageFunc(currentPage + +1)
      //uncheckAll()
    }
  }
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPageFunc(currentPage - 1)
      //uncheckAll()
    }
  }

  const setUncheckAll = () => {
    setAllChecked(false)
    const rows = []
    for (let i = 0; i < dataRows.length; i++) {
      if (dataRows[i].checked) {
        rows.push(dataRows[i])
      }
      dataRows[i].checked = false
    }
    if (props.checkAll) props.checkAll(rows)
  }

  const makeSort = field => {
    Cookies.set(window.location.pathname + name + 'orderBy', field, {
      expires: 30,
    })

    if (field === orderBy) {
      setOrderDirection(!orderDirection)
      Cookies.set(window.location.pathname + name + 'orderDirection', orderDirection ? 'DESC' : 'ASC', {
        expires: 30,
      })
    } else {
      setOrderBy(field)
      setOrderDirection(true)
      Cookies.set(window.location.pathname + name + 'orderDirection', 'ASC', {
        expires: 30,
      })
    }
    setSort(field, field === orderBy ? !orderDirection : true)
  }

  const tryRequire = icon => {
    try {
      return require('../../assets/images/coinIcons/' + icon.toLowerCase() + '.png').default
    } catch (err) {
      return require('../../assets/images/coinIcons/none.png').default
    }
  }

  return (
    <div className={'dataTable__container'}>
      <Row>
        {showEntries ? (
          <Col sm={6} md={5}>
            <div className={'dataTables_entries'}>
              <label>{t('table.showPerPage')}</label>
              <select
                className='custom-select custom-select-sm form-control form-control-sm'
                value={showCount}
                onChange={e => {
                  setShowCountFunc(parseInt(e.target.value))
                  setCurrentPageFunc(1)
                  //uncheckAll()
                }}
              >
                {entries.map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  )
                })}
              </select>
              {total > 0 ? (
                <div className='dataTables_info'>
                  {total > showCount && currentPage != 1
                    ? total > showCount && currentPage < 3
                      ? 1 + showCount
                      : 1 + showCount * (currentPage - 1)
                    : 1}{' '}
                  -{' '}
                  {total > showCount
                    ? showCount * currentPage > total
                      ? total + ' ' + t('table.of')
                      : showCount * currentPage + ' ' + t('table.of')
                    : null}{' '}
                  {total}
                </div>
              ) : (
                <div className='dataTables_info'>{t('table.showing')} 0</div>
              )}
            </div>
          </Col>
        ) : null}
        <Col sm={6} md={7}>
          <div className='dataTables_paginate'>
            {showPagination && duplicatePagination ? (
              <ul data-test='pagination' className='pagination'>
                <li
                  data-test='page-item'
                  className={currentPage === 1 ? 'disabled page-item' : 'page-item'}
                  onClick={prevPage}
                >
                  <button data-test='page-link' aria-label='Previous' className='page-link page-link'>
                    <span>{t('table.previous')}</span>
                  </button>
                </li>

                {currentPage - 2 > 0 ? (
                  <li
                    onClick={() => setCurrentPageFunc(1)}
                    data-test='page-item'
                    className='page-item d-none d-md-flex'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage === 3 ? '1' : '1..'}
                    </button>
                  </li>
                ) : null}

                {currentPage - 1 > 0 ? (
                  <li onClick={() => setCurrentPageFunc(1)} data-test='page-item' className='page-item d-md-none'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage === 2 ? '1' : '1..'}
                    </button>
                  </li>
                ) : null}

                {currentPage - 1 > 0 ? (
                  <li
                    onClick={() => setCurrentPageFunc(currentPage - 1)}
                    data-test='page-item'
                    className='page-item d-none d-md-flex'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage - 1}
                    </button>
                  </li>
                ) : null}

                <li data-test='page-item' className='active page-item'>
                  <button data-test='page-link' className='page-link page-link'>
                    {currentPage}
                  </button>
                </li>

                {(currentPage + +1) * showCount < total ||
                ((currentPage + +1) * showCount - total > 0 && (currentPage + +1) * showCount - total < showCount) ? (
                  <li
                    onClick={() => setCurrentPageFunc(currentPage + +1)}
                    data-test='page-item'
                    className='page-item d-none d-md-flex'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage + +1}
                    </button>
                  </li>
                ) : null}
                {(currentPage + +1) * showCount < total ||
                ((currentPage + +1) * showCount - total > 0 && (currentPage + +1) * showCount - total < showCount) ? (
                  <li
                    onClick={() =>
                      setCurrentPageFunc(
                        Math.floor(total % showCount === 0 ? (total - 1) / showCount : total / showCount) + +1,
                      )
                    }
                    data-test='page-item'
                    className='page-item d-md-none'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      ..
                      {Math.floor(total % showCount === 0 ? (total - 1) / showCount : total / showCount) + +1}
                    </button>
                  </li>
                ) : null}
                {(currentPage + +2) * showCount < total ||
                ((currentPage + +2) * showCount - total > 0 && (currentPage + +2) * showCount - total < showCount) ? (
                  <li
                    onClick={() =>
                      setCurrentPageFunc(
                        Math.floor(total % showCount === 0 ? (total - 1) / showCount : total / showCount) + +1,
                      )
                    }
                    data-test='page-item'
                    className='page-item d-none d-md-flex'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      ..
                      {Math.floor(total % showCount === 0 ? (total - 1) / showCount : total / showCount) + +1}
                    </button>
                  </li>
                ) : null}
                <li
                  data-test='page-item'
                  className={currentPage * showCount >= total ? 'disabled page-item' : 'page-item'}
                  onClick={nextPage}
                >
                  <button data-test='page-link' aria-label='Next' className='page-link page-link'>
                    <span>{t('table.next')}</span>
                  </button>
                </li>
              </ul>
            ) : null}
            {props.searchable ? (
              <div className={'quicksearch'}>
                <Input
                  onChange={e => {
                    setSearchTableValue(e.target.value)
                    //setCurrentPageFunc(1)
                  }}
                  value={searchTableValue}
                  name={'quicksearch'}
                  className={'form-control'}
                  placeholder={t('table.quickSearch')}
                />
                <i
                  style={{ display: searchTableValue ? 'block' : 'none' }}
                  className='fas fa-times'
                  onClick={() => {
                    setSearchTableValue('')
                    // setCurrentPageFunc(1)
                  }}
                ></i>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>

      <div
        className={'table_container_responsive'}
        style={{
          overflowX: responsive ? 'auto' : 'visible',
          maxHeight: responsive ? 1000000 : 'unset',
        }}
      >
        <div
          className={'table_container'}
          style={{
            maxHeight: maxHeight ? maxHeight : 'unset',
            overflowY: maxHeight ? 'auto' : 'visible',
            overflowX: 'visible',
          }}
        >
          <div className={'mobile_workers d-md-none'}>
            {dataRows.map((row, key) => {
              return (
                <div
                  key={currentPage + row.id}
                  className={'mobile_workers__item'}
                  style={row.favorite ? { backgroundColor: 'rgb(98 110 212 / 26%)' } : {}}
                >
                  <div className={'mobile_workers__item_info'}>
                    <div className={'worker_name'}>
                      {showCheckbox && (
                        <input
                          checked={row.checked}
                          type={'checkbox'}
                          onChange={() => checkRow(row)}
                          key={currentPage + row + key}
                        />
                      )}
                      {row.name}
                    </div>
                    <div className={'worker_info__values'}>
                      {!row.favorite ? (
                        <div
                          className={'add_to_favorite'}
                          tooltip={t('table.watchWorker')}
                          onClick={() => props.addToFavorite(row)}
                        >
                          <i className='fas fa-eye'></i>
                        </div>
                      ) : (
                        <div
                          className={'remove_from_favorite'}
                          tooltip={t('table.removeFromWatch')}
                          onClick={() => props.addToFavorite(row)}
                        >
                          <i className='fas fa-eye-slash'></i>
                        </div>
                      )}
                      {row.status}
                    </div>
                  </div>

                  <div className={'worker_hw'}>{row.type}</div>
                  <div className={'mobile_workers__item_info'}>
                    {row.uptime}
                    {row.cards}
                    <div className={'worker_info__values'}>
                      <img className={'worker_coin_icon'} alt={row.coin} src={tryRequire(row.coin)} />
                      {row.hashRate}
                      {row.effShares}
                    </div>
                  </div>
                  <div className={'worker_actions_mobile'}>{row.action}</div>
                </div>
              )
            })}
          </div>
          <table className={props.className + ' table table-striped table-bordered d-none d-md-table'}>
            <thead
              className={stickyHeader ? 'sticky_header thead-light' : 'thead-light'}
              style={{ top: maxHeight || responsive ? -1 : null }}
            >
              <tr>
                {firstColumnCheckbox ? (
                  <th className={'tableCheckbox'}>
                    <input checked={allChecked} type={'checkbox'} onChange={() => allCheckbox()} />
                  </th>
                ) : null}
                {dataColumns &&
                  dataColumns.map(column => {
                    return (
                      <th
                        className={column.sort ? 'sorting_column' : ''}
                        key={column.field + column.sorted + column.orderDirection}
                        onClick={() => {
                          if (column.sort) makeSort(column.field)
                        }}
                      >
                        {column.label}
                        {column.sort && column.sorted ? (
                          <span className={'column_sort_direction'}>
                            {column.orderDirection === 'asc' ? (
                              <i className='fas fa-caret-up'></i>
                            ) : (
                              <i className='fas fa-caret-down'></i>
                            )}
                          </span>
                        ) : column.sort && !column.sorted ? (
                          <span style={{ opacity: 0.7 }} className={'column_sort'}>
                            <i className='fas fa-sort'></i>
                          </span>
                        ) : null}
                      </th>
                    )
                  })}
              </tr>
            </thead>
            <tbody>
              {dataRows.map((row, key) => {
                return (
                  <tr
                    key={currentPage + row.id}
                    style={row.favorite ? { backgroundColor: 'rgb(98 110 212 / 26%)' } : {}}
                  >
                    {firstColumnCheckbox ? (
                      <td className={'tableCheckbox'}>
                        <input
                          checked={row.checked}
                          type={'checkbox'}
                          onChange={() => checkRow(row)}
                          key={currentPage + row + key}
                        />
                      </td>
                    ) : null}
                    {dataColumns &&
                      dataColumns.map(column => {
                        const styles = {}
                        if (column.width) styles.minWidth = column.width
                        if (column.maxWidth) styles.maxWidth = column.maxWidth
                        return (
                          <td
                            style={styles}
                            key={currentPage + key + column.field}
                            className={column.addToFavorite ? 'add_to_favorite_column' : ''}
                          >
                            {row[column.field]}
                            {column.addToFavorite ? (
                              !row.favorite ? (
                                <div
                                  className={'add_to_favorite'}
                                  tooltip={t('table.watchWorker')}
                                  onClick={() => props.addToFavorite(row)}
                                >
                                  <i className='fas fa-eye'></i>
                                </div>
                              ) : (
                                <div
                                  className={'remove_from_favorite'}
                                  tooltip={t('table.removeFromWatch')}
                                  onClick={() => props.addToFavorite(row)}
                                >
                                  <i className='fas fa-eye-slash'></i>
                                </div>
                              )
                            ) : null}
                          </td>
                        )
                      })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Row>
        {showEntries && duplicateEntries ? (
          <Col sm={6} md={5}>
            <div className={'dataTables_entries'}>
              <label>{t('table.showPerPage')}</label>
              <select
                className='custom-select custom-select-sm form-control form-control-sm'
                value={showCount}
                onChange={e => {
                  setShowCountFunc(parseInt(e.target.value))
                  setCurrentPageFunc(1)
                  //uncheckAll()
                }}
              >
                {entries.map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  )
                })}
              </select>
              {total > 0 ? (
                <div className='dataTables_info'>
                  {total > showCount && currentPage != 1
                    ? total > showCount && currentPage < 3
                      ? 1 + showCount
                      : 1 + showCount * (currentPage - 1)
                    : 1}{' '}
                  -{' '}
                  {total > showCount
                    ? showCount * currentPage > total
                      ? total + ' ' + t('table.of')
                      : showCount * currentPage + ' ' + t('table.of')
                    : null}{' '}
                  {total}
                </div>
              ) : (
                <div className='dataTables_info'>{t('table.showing')} 0</div>
              )}
            </div>
          </Col>
        ) : null}
        {showPagination ? (
          <Col sm={duplicateEntries ? 6 : 12} md={duplicateEntries ? 7 : 12}>
            <div className='dataTables_paginate'>
              <ul data-test='pagination' className='pagination'>
                <li
                  data-test='page-item'
                  className={currentPage === 1 ? 'disabled page-item' : 'page-item'}
                  onClick={prevPage}
                >
                  <button data-test='page-link' aria-label='Previous' className='page-link page-link'>
                    <span>{t('table.previous')}</span>
                  </button>
                </li>

                {currentPage - 2 > 0 ? (
                  <li
                    onClick={() => setCurrentPageFunc(1)}
                    data-test='page-item'
                    className='page-item d-none d-md-flex'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage === 3 ? '1' : '1..'}
                    </button>
                  </li>
                ) : null}

                {currentPage - 1 > 0 ? (
                  <li onClick={() => setCurrentPageFunc(1)} data-test='page-item' className='page-item d-md-none'>
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage === 2 ? '1' : '1..'}
                    </button>
                  </li>
                ) : null}

                {currentPage - 1 > 0 ? (
                  <li
                    onClick={() => setCurrentPageFunc(currentPage - 1)}
                    data-test='page-item'
                    className='page-item d-none d-md-flex'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage - 1}
                    </button>
                  </li>
                ) : null}

                <li data-test='page-item' className='active page-item'>
                  <button data-test='page-link' className='page-link page-link'>
                    {currentPage}
                  </button>
                </li>

                {(currentPage + +1) * showCount < total ||
                ((currentPage + +1) * showCount - total > 0 && (currentPage + +1) * showCount - total < showCount) ? (
                  <li
                    onClick={() => setCurrentPageFunc(currentPage + +1)}
                    data-test='page-item'
                    className='page-item d-none d-md-flex'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      {currentPage + +1}
                    </button>
                  </li>
                ) : null}
                {(currentPage + +1) * showCount < total ||
                ((currentPage + +1) * showCount - total > 0 && (currentPage + +1) * showCount - total < showCount) ? (
                  <li
                    onClick={() =>
                      setCurrentPageFunc(
                        Math.floor(total % showCount === 0 ? (total - 1) / showCount : total / showCount) + +1,
                      )
                    }
                    data-test='page-item'
                    className='page-item d-md-none'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      ..
                      {Math.floor(total % showCount === 0 ? (total - 1) / showCount : total / showCount) + +1}
                    </button>
                  </li>
                ) : null}
                {(currentPage + +2) * showCount < total ||
                ((currentPage + +2) * showCount - total > 0 && (currentPage + +2) * showCount - total < showCount) ? (
                  <li
                    onClick={() =>
                      setCurrentPageFunc(
                        Math.floor(total % showCount === 0 ? (total - 1) / showCount : total / showCount) + +1,
                      )
                    }
                    data-test='page-item'
                    className='page-item d-none d-md-flex'
                  >
                    <button data-test='page-link' className='page-link page-link'>
                      ..
                      {Math.floor(total % showCount === 0 ? (total - 1) / showCount : total / showCount) + +1}
                    </button>
                  </li>
                ) : null}

                <li
                  data-test='page-item'
                  className={currentPage * showCount >= total ? 'disabled page-item' : 'page-item'}
                  onClick={nextPage}
                >
                  <button data-test='page-link' aria-label='Next' className='page-link page-link'>
                    <span>{t('table.next')}</span>
                  </button>
                </li>
              </ul>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  )
}

const mapStateToProps = store => ({
  clearCheck: store.main.clearCheck,
})
export default connect(mapStateToProps, null)(TableWorkers)
