import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import { Table } from '../../../components/Table'
import * as actions from '../actions'
import * as presetsActions from '../../MiningPresets/actions'
import { CreateScheduleModal } from '../components/modals'
import Cookies from 'js-cookie'
import CustomPoolActions from '../../CustomPools/components/CustomPoolActions'
import { ScheduleActions } from '../components'

const SchedulePage = ({ ...props }) => {
  const { t } = useTranslation('common')
  const [schedules, setSchedules] = useState([])
  const setSchedulesList = props.setSchedulesList
  const setMiningPresetList = props.setMiningPresetList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )

  useEffect(() => {
    props.miningPresetList?.length > 0 && setSchedulesList({ page: currentPage, size: showCount, orderBy: 'id' })
  }, [props.miningPresetList, currentPage, showCount])

  useLayoutEffect(() => {
    setMiningPresetList({ page: 1, size: 1000, orderBy: 'nameSymbol' })
  }, [setMiningPresetList])

  useEffect(() => {
    const schedulesArray = props.schedulesList
      ? props.schedulesList.map(schedule => {
          return {
            ...schedule,
            status: schedule.action === 'stop' ? 'Stop' : 'Active',
            newPreset: props.miningPresetList?.find(preset => +preset.id === +schedule.secondPresetId)?.name,
            initialPreset: props.miningPresetList?.find(preset => +preset.id === +schedule.firstPresetId)?.name,
            actions: <ScheduleActions schedule={schedule} />,
          }
        })
      : []
    setSchedules(schedulesArray)
  }, [props.schedulesList])

  const schedulesData = {
    columns: [
      {
        label: t('pages.schedule.id'),
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.schedule.name'),
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.schedule.event'),
        field: 'event',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.schedule.switchOn'),
        field: 'activator',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.schedule.status'),
        field: 'status',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.schedule.initialPreset'),
        field: 'initialPreset',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.schedule.newPreset'),
        field: 'newPreset',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.schedule.action'),
        field: 'actions',
        width: 10,
      },
    ],
    rows: schedules,
  }

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.schedule.schedule')}</h3>
          </div>
        </div>
        <CreateScheduleModal />
        <div className={'mining_preset__body'}>
          <Table
            className={'table table-striped'}
            data={schedulesData}
            name={'schedules'}
            responsive
            noBottomColumns
            stickyHeader
            total={props.schedulesTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  schedulesList: store.schedule.schedulesList,
  schedulesTotal: store.schedule.schedulesTotal,
  miningPresetList: store.presets.miningPresetList,
})

const mapDispatchToProps = {
  setSchedulesList: actions.setScheduleList,
  setMiningPresetList: presetsActions.setMiningPresetList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage)
