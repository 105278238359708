import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const NotificationDropdown = () => {
  const [menu, setMenu] = useState(false)
  const { t } = useTranslation('common')
  const toggle = () => {
    setMenu(!menu)
  }
  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className='dropdown d-inline-block' tag='li'>
        <DropdownToggle
          className='btn header-item noti-icon waves-effect'
          id='page-header-notifications-dropdown'
          tag='button'
        >
          <i className='mdi mdi-bell-outline'></i>
          <span className='badge badge-danger badge-pill'>0</span>
        </DropdownToggle>

        <DropdownMenu className='dropdown-menu-lg p-0' right>
          <div className='p-3'>
            <Row className='align-items-center'>
              <Col>
                <h5 className='m-0 font-size-16'> {t('topBar.notifications')} (0) </h5>
              </Col>
            </Row>
          </div>
          <div className='p-2 border-top'>
            <Link className='btn btn-sm btn-link font-size-14 btn-block text-center' to='#'>
              {' '}
              {t('topBar.viewAll')}{' '}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}
export default NotificationDropdown
