import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import * as actions2 from '../../../MiningPresets/actions'
import SelectWithImages from '../../../../components/UI/SelectWithImages'

const WalletModalEdit = props => {
  const { t } = useTranslation('common')
  const [walletName, setWalletName] = useState('')
  const [walletAddress, setWalletAddress] = useState('')
  const [coin, setCoin] = useState(null)
  const [source, setSource] = useState('')
  const [showAlertApply, setShowAlertApply] = useState(false)
  useEffect(() => {
    setWalletName(props.wallet.name)
    setWalletAddress(props.wallet.address)
    setSource(props.wallet.source)
    setCoin(() => {
      const coin = props.coinsList.find(coin => coin.nameSymbol === props.wallet.coin)
      if (!coin) return null
      return {
        ...coin,
        icon: coin.nameSymbol,
        label: coin.nameSymbol,
        value: coin.nameSymbol,
      }
    })
  }, [props.open, props.coinsList])

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'wallet_name': {
        setWalletName(e.target.value)
        return
      }
      case 'wallet_address': {
        setWalletAddress(e.target.value)
        return
      }
      case 'wallet_coin': {
        setCoin(e.target.options[e.target.selectedIndex].value)
        return
      }
      case 'wallet_source': {
        setSource(e.target.value)
        return
      }
      default:
        return
    }
  }

  const onChangeSelect = option => {
    const newErrors = errors
    delete newErrors['wallet_coin']
    setErrors(newErrors)
    setCoin(option)
  }

  const [errors, setErrors] = useState({})

  const saveEdits = () => {
    const newErrors = {}
    if (!walletName || walletName.length === 0) newErrors.wallet_name = { message: t('pages.wallets.walletNameError') }
    if (!walletAddress || walletAddress.length === 0)
      newErrors.wallet_address = {
        message: t('pages.wallets.walletAddressError'),
      }
    if ((coin && !coin.value) || !coin) newErrors.wallet_coin = { message: t('pages.wallets.walletCoinError') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return
    setShowAlertApply(true)
  }

  const submitFunc = (apply = false) => {
    props.updateWallet({
      id: props.wallet.id,
      name: walletName,
      address: walletAddress,
      coin: coin.value,
      source,
      applyAllRigs: apply,
    })
    props.toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <SweetAlert
        title={t('pages.wallets.wouldUouLikeApplyWallet')}
        // warning
        showCancel
        cancelBtnText='Next Start'
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertApply}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          submitFunc(true)
          setShowAlertApply(false)
        }}
        onCancel={() => {
          submitFunc(false)
          setShowAlertApply(false)
        }}
        confirmBtnText={'Now'}
      >
        {t('pages.wallets.nextStartNow')}
      </SweetAlert>

      <div className={'actions__item'} tooltip={t('pages.wallets.editWallet')} flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.wallets.editWallet')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.wallets.name')}</Label>
                <Input
                  className={errors.wallet_name ? 'invalid' : ''}
                  type={'text'}
                  name={'wallet_name'}
                  onChange={onChange}
                  value={walletName}
                />
                <div className={'error'}>{errors.wallet_name && errors.wallet_name.message}</div>
              </Col>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.wallets.address')}</Label>
                <Input
                  className={errors.wallet_address ? 'invalid' : ''}
                  type={'text'}
                  name={'wallet_address'}
                  onChange={onChange}
                  value={walletAddress}
                />
                <div className={'error'}>{errors.wallet_address && errors.wallet_address.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.wallets.coin')}</Label>
                <SelectWithImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  name={'wallet_coin'}
                  onchange={onChangeSelect}
                  error={errors.wallet_coin}
                  value={coin}
                  placeholder={t('inputs.selectCoin')}
                  options={props.coinsList.map(coin => {
                    return {
                      ...coin,
                      icon: coin.nameSymbol,
                      label: coin.nameSymbol,
                      value: coin.nameSymbol,
                    }
                  })}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.wallet_coin && errors.wallet_coin.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.wallets.source')}</Label>
                <Input
                  className={errors.wallet_name ? 'invalid' : ''}
                  type={'text'}
                  name={'wallet_source'}
                  onChange={onChange}
                  value={source}
                />
                <div className={'error'}>{errors.wallet_source && errors.wallet_source.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.wallets.cancel')}
          </Button>
          <Button color='primary' onClick={saveEdits}>
            {t('pages.wallets.save')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  coinsList: store.presets.coinsList,
})

const mapDispatchToProps = {
  updateWallet: actions2.updateWallet,
  setWalletsList: actions2.setWalletsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletModalEdit)
