import React, { useState } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import { Input, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const PowerManagementTab = () => {
  const [generic_pdu_device_management, set_generic_pdu_device_management] = useState(true)
  const { t } = useTranslation('common')

  const [allow_to_assign_pdu_and_outlet, set_allow_to_assign_pdu_and_outlet] = useState(false)
  const [worker_default_manual_reboot_duration_time, set_worker_default_manual_reboot_duration_time] = useState(false)
  const [worker_default_automatic_reboot_duration_time, set_worker_default_automatic_reboot_duration_time] =
    useState(false)

  const [worker_default_manual_reboot_duration_time_sec, set_worker_default_manual_reboot_duration_time_sec] =
    useState('')
  const [worker_default_automatic_reboot_duration_time_sec, set_worker_default_automatic_reboot_duration_time_sec] =
    useState('')

  return (
    <div className={'settings__power_management'}>
      <div className={'settings__section'}>
        <div className={'settings__section_header'}>
          <h4>{t('pages.settings.genericPDUDeviceManagement')}</h4>
          <Switch
            onColor='#02a499'
            onChange={() => set_generic_pdu_device_management(!generic_pdu_device_management)}
            checked={generic_pdu_device_management}
            height={20}
            width={40}
          />
        </div>
        <div className={'settings__section_body'} style={{ opacity: generic_pdu_device_management ? 1 : 0.2 }}>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={allow_to_assign_pdu_and_outlet && generic_pdu_device_management}
                onChange={() => set_allow_to_assign_pdu_and_outlet(!allow_to_assign_pdu_and_outlet)}
                disabled={!generic_pdu_device_management}
              />
              {t('pages.settings.allowAssignPDUOutlet')}
            </Label>
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={worker_default_manual_reboot_duration_time && generic_pdu_device_management}
                onChange={() =>
                  set_worker_default_manual_reboot_duration_time(!worker_default_manual_reboot_duration_time)
                }
                disabled={!generic_pdu_device_management}
              />
              {t('pages.settings.workerDefaultManualReboot')}
            </Label>
            <Input
              placeholder={'20'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={worker_default_manual_reboot_duration_time_sec}
              onChange={e => set_worker_default_manual_reboot_duration_time_sec(e.target.value)}
              disabled={!generic_pdu_device_management || !worker_default_manual_reboot_duration_time}
            />
          </div>
          <div className={'settings__row'}>
            <Label className={'checkboxLabel'}>
              <input
                type={'checkbox'}
                checked={worker_default_automatic_reboot_duration_time && generic_pdu_device_management}
                onChange={() =>
                  set_worker_default_automatic_reboot_duration_time(!worker_default_automatic_reboot_duration_time)
                }
                disabled={!generic_pdu_device_management}
              />
              {t('pages.settings.workerDefaultAutomaticReboot')}
            </Label>
            <Input
              placeholder={'20'}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={worker_default_automatic_reboot_duration_time_sec}
              onChange={e => set_worker_default_automatic_reboot_duration_time_sec(e.target.value)}
              disabled={!generic_pdu_device_management || !worker_default_automatic_reboot_duration_time}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  powerUnitList: store.presets.powerUnitList,
})

export default connect(mapStateToProps, null)(PowerManagementTab)
