import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input } from 'reactstrap'

import * as actions from '../../actions'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const PowerUnitOutletManagement = () => {
  const [modal, setModal] = useState(false)
  const { t } = useTranslation('common')
  const outlets = [
    {
      id: 1,
      name: 'test',
      number: 1,
      worker: null,
      mDuraction: null,
      aDuraction: null,
    },
    {
      id: 2,
      name: 'test',
      number: 2,
      worker: 22,
      mDuraction: 30,
      aDuraction: 50,
    },
    {
      id: 3,
      name: 'test',
      number: 3,
      worker: null,
      mDuraction: null,
      aDuraction: null,
    },
  ]

  const toggle = () => setModal(!modal)

  const savePowerUnit = () => {}

  return (
    <div onClick={e => e.stopPropagation()}>
      <div
        className={'actions__item'}
        tooltip={t('pages.powerUnits.outletManagement')}
        flow='left'
        onClick={toggle}
        //onClick={(e)=>{setShowAlertDelete(true)}}
      >
        <i className='fas fa-plug'></i>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={'modal-dialog-centered popup popup_large '}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={toggle}>
          {t('pages.powerUnits.AddNewPowerManagementUnit')}
        </ModalHeader>
        <ModalBody>
          <div className={'powerUnit__body'}>
            <div className={'outlets'}>
              <Row className={'outletRow'}>
                <Col md={2}>{t('pages.powerUnits.co')}</Col>
                <Col md={4}>{t('pages.powerUnits.workerName')}</Col>
                <Col md={3}>{t('pages.powerUnits.rebootDurationManual')}</Col>
                <Col md={3}>{t('pages.powerUnits.RebootDurationAutomatic')}</Col>
              </Row>
              {outlets.map(outlet => {
                return (
                  <Row key={outlet.id} className={'outletRow'}>
                    <Col md={2}>{outlet.number}</Col>
                    <Col md={4}>
                      <Select placeholder={t('inputs.select')} classNamePrefix={'form_control_react_select'} />
                    </Col>
                    <Col md={3}>
                      <Input
                        value={outlet.mDuraction}
                        onKeyPress={event => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        value={outlet.aDuraction}
                        onKeyPress={event => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                      />
                    </Col>
                  </Row>
                )
              })}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={savePowerUnit}>
            {t('pages.powerUnits.saveAdd')}
          </Button>
          <Button color='secondary' onClick={toggle}>
            {t('pages.powerUnits.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  walletsList: store.presets.walletsList,
  coinsList: store.presets.coinsList,
  poolsList: store.presets.poolsList,
  minersList: store.presets.minersList,
  pool: store.presets.pool,
  averageHashList: store.presets.averageHashList,
  overclockingProfilesList: store.profiles.overclockingProfilesList,
  algoCoinsList: store.profiles.algoCoinsList,
})

const mapDispatchToProps = {
  createMiningPreset: actions.createMiningPreset,
  createWalletInPreset: actions.createWallet,
  setWalletsList: actions.setWalletsList,
  setMinersList: actions.setMinersList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerUnitOutletManagement)
