import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Label, Input, Row } from 'reactstrap'
import * as actionsProfiles from '../../actions'
import { useTranslation } from 'react-i18next'
import SelectWithCryptoImages from '../../../../components/UI/SelectWithCryptoImages'
import SelectWithImages from '../../../../components/UI/SelectWithImages'

const SlotModalEdit = props => {
  const { t } = useTranslation('common')
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [coin, setCoin] = useState(null)
  const [dualCoin, setDualCoin] = useState(null)
  const [algo, setAlgo] = useState(null)
  const [dualAlgo, setDualAlgo] = useState(null)
  const [comment, setComment] = useState('')

  useEffect(() => {
    setName(props.slot.name)
    setComment(props.slot.comment)
    setCoin(() => {
      const coin = props.coinsList.find(coin => coin.nameSymbol === props.slot.coin)
      if (!coin) return null
      return {
        ...coin,
        icon: coin.nameSymbol,
        label: coin.nameSymbol,
        value: coin.nameSymbol,
      }
    })
    setDualCoin(() => {
      const coin = props.coinsList.find(coin => coin.nameSymbol === props.slot.dualCoin)
      if (!coin) return null
      return {
        ...coin,
        icon: coin.nameSymbol,
        label: coin.nameSymbol,
        value: coin.nameSymbol,
      }
    })
    setAlgo(() => {
      const algo = props.algoCoinsList.find(algo => algo.algo === props.slot.algo)
      console.log(algo)
      if (!algo) return null
      return {
        ...algo,
        label: algo.algo,
        value: algo.algo,
      }
    })
    setDualAlgo(() => {
      const algo = props.algoCoinsList.find(algo => algo.algo === props.slot.dualAlgo)
      if (!algo) return null
      return {
        ...algo,
        label: algo.algo,
        value: algo.algo,
      }
    })
  }, [props.open, props.algoCoinsList, props.coinsList])

  const update = async () => {
    const newErrors = {}
    if (!name || name.length === 0) newErrors.name = { message: t('pages.overclocking.nameError') }
    if (!algo) newErrors.algo = { message: t('pages.overclocking.algoError') }
    setErrors(newErrors)
    console.log(newErrors)
    if (Object.keys(newErrors).length > 0) return
    const res = await props.updateSlot({
      id: +props.slot.id,
      name: name,
      coin: coin?.value || null,
      dualCoin: dualCoin?.value || null,
      algo: algo?.value || null,
      dualAlgo: dualAlgo?.value || null,
      comment: comment,
    })
    if (res) props.toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'actions__item'} tooltip={t('pages.overclocking.editSlot')} flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        onClick={e => e.stopPropagation()}
        className={'modal-dialog-centered popup'}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.overclocking.editSlot')} {props.slot ? props.slot.name : ''}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className={'form-group'}>
              <Label>{t('pages.overclocking.slotName')}</Label>
              <Input
                className={errors.name ? 'invalid' : ''}
                type={'text'}
                name={'name'}
                value={name}
                onChange={e => {
                  setName(e.target.value)
                  errors.name = null
                  setErrors(errors)
                }}
              />
              <div className={'error'}>{errors.name && errors.name.message}</div>
            </Col>
            <Col xs={6} className={'form-group'}>
              <Label>{t('pages.overclocking.slotAlgo')}</Label>
              <SelectWithCryptoImages
                onChange={e => {
                  e.stopPropagation()
                }}
                placeholder={t('inputs.selectAlgo')}
                value={algo}
                onchange={option => {
                  setAlgo(option)
                  setCoin(null)
                  errors.algo = null
                  setErrors(errors)
                }}
                error={errors.algo}
                options={
                  props.algoCoinsList &&
                  props.algoCoinsList.map(algo => {
                    return {
                      ...algo,
                      label: algo.algo,
                      value: algo.algo,
                    }
                  })
                }
                hideSelectedOptions={true}
              />
              <div className={'error'}>{errors.algo && errors.algo.message}</div>
            </Col>
            {algo && algo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotCoin')}</Label>
                <SelectWithImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectCoin')}
                  value={coin}
                  onchange={option => setCoin(option)}
                  error={errors.coin}
                  options={[
                    { label: 'Unset', value: null },
                    ...(props.coinsList &&
                      props.coinsList
                        .filter(coin => algo.coins && algo.coins.indexOf(coin.nameSymbol) >= 0)
                        .map(coin => {
                          return {
                            ...coin,
                            icon: coin.nameSymbol,
                            label: coin.nameSymbol,
                            value: coin.nameSymbol,
                          }
                        })),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.coin && errors.coin.message}</div>
              </Col>
            )}
            {algo && algo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotDualAlgo')}</Label>
                <SelectWithCryptoImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectAlgo')}
                  value={dualAlgo}
                  onchange={option => {
                    setDualAlgo(option)
                    setDualCoin(null)
                  }}
                  error={errors.dualAlgo}
                  options={[
                    { label: 'Unset', value: null },
                    ...(props.algoCoinsList &&
                      props.algoCoinsList.map(algo => {
                        return {
                          ...algo,
                          label: algo.algo,
                          value: algo.algo,
                        }
                      })),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.dualAlgo && errors.dualAlgo.message}</div>
              </Col>
            )}
            {algo && algo.value && dualAlgo && dualAlgo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotDualCoin')}</Label>
                <SelectWithImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectCoin')}
                  value={dualCoin}
                  onchange={option => setDualCoin(option)}
                  error={errors.dualCoin}
                  options={[
                    { label: 'Unset', value: null },
                    ...(props.coinsList &&
                      props.coinsList
                        .filter(coin => dualAlgo.coins && dualAlgo.coins.indexOf(coin.nameSymbol) >= 0)
                        .map(coin => {
                          return {
                            ...coin,
                            icon: coin.nameSymbol,
                            label: coin.nameSymbol,
                            value: coin.nameSymbol,
                          }
                        })),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.dualCoin && errors.dualCoin.message}</div>
              </Col>
            )}
            <Col xs={12} className={'form-group'}>
              <Label>{t('pages.overclocking.slotComment')}</Label>
              <Input type={'textarea'} name={'comment'} onChange={e => setComment(e.target.value)} value={comment} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={update}>
            {t('pages.overclocking.edit')}
          </Button>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.overclocking.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  algoCoinsList: store.profiles.algoCoinsList,
  coinsList: store.presets.coinsList,
})

const mapDispatchToProps = {
  updateSlot: actionsProfiles.updateSlot,
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotModalEdit)
