import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'

import * as actions from '../../RigsData/actions'
import * as presetActions from '../../MiningPresets/actions'
import { RigFullInfo, CardTurboModal, SetMiningPreset } from './modals'
import SweetAlert from 'react-bootstrap-sweetalert'

const WorkerActions = props => {
  const { t } = useTranslation('common')
  const [isRigFullInfo, setIsRigFullInfo] = useState(false)
  const [isCardTurboModal, setIsCardTurboModal] = useState(false)
  const [isSetMiningPreset, setIsSetMiningPreset] = useState(false)
  const [showAlertTurnOff, setShowAlertTurnOff] = useState(false)
  const [showAlertStart, setShowAlertStart] = useState(false)
  const [showAlertReboot, setShowAlertReboot] = useState(false)
  const [showAlertHardReboot, setShowAlertHardReboot] = useState(false)
  const [showAlertPause, setShowAlertPause] = useState(false)
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }
  const rebootWorker = async workerID => {
    const reboot = {
      rigId: parseInt(workerID),
      powerOf: false,
      hardReboot: false,
      reboot: true,
    }
    await props.resetOrPowerOfWorker(reboot).then()
    setShow(false)
  }

  const turnOFFWorker = async workerID => {
    const reboot = {
      rigId: parseInt(workerID),
      powerOf: true,
      reboot: false,
      hardReboot: false,
    }
    await props.resetOrPowerOfWorker(reboot).then()
    setShow(false)
  }

  const hardRebootWorker = async workerID => {
    const reboot = {
      rigId: parseInt(workerID),
      hardReboot: true,
      reboot: false,
      powerOf: false,
    }
    await props.resetOrPowerOfWorker(reboot).then()
    setShow(false)
  }

  const turnOff = () => {
    props.setModalShow(true)
    setShowAlertTurnOff(true)
    setShow(false)
  }
  const start = () => {
    setShowAlertStart(true)
    props.setModalShow(true)
  }
  const pause = () => {
    setShowAlertPause(true)
    props.setModalShow(true)
  }
  const reboot = () => {
    setShowAlertReboot(true)
    setShow(false)
    props.setModalShow(true)
  }
  const hardReboot = () => {
    setShowAlertHardReboot(true)
    setShow(false)
    props.setModalShow(true)
  }

  return useMemo(
    () => (
      <div className={'workerActions'}>
        <div className={'alerts'}>
          <SweetAlert
            title={t('sweetAlerts.areConfirm')}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            custom
            customIcon={<i className='fas fa-power-off sweet-alert-icon'></i>}
            show={showAlertTurnOff}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              turnOFFWorker(props.worker.id)
              setShowAlertTurnOff(false)
              props.setModalShow(false)
            }}
            onCancel={() => {
              setShowAlertTurnOff(false)
              props.setModalShow(false)
            }}
            confirmBtnText={t('sweetAlerts.confirm')}
            cancelBtnText={t('sweetAlerts.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.workerOff')}
          </SweetAlert>

          <SweetAlert
            title={t('sweetAlerts.areConfirm')}
            custom
            customIcon={<i className='fas fa-play sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertStart}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              props.setRigMinerAction({
                rigId: props.worker.id,
                action: 'run',
              })
              setShowAlertStart(false)
              props.setModalShow(false)
            }}
            onCancel={() => {
              setShowAlertStart(false)
              props.setModalShow(false)
            }}
            confirmBtnText={t('sweetAlerts.confirm')}
            cancelBtnText={t('sweetAlerts.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.miningOn')}
          </SweetAlert>

          <SweetAlert
            title={t('sweetAlerts.areConfirm')}
            custom
            customIcon={<i className='fas fa-stop sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertPause}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              props.setRigMinerAction({
                rigId: props.worker.id,
                action: 'stop',
              })
              setShowAlertPause(false)
              props.setModalShow(false)
            }}
            onCancel={() => {
              setShowAlertPause(false)
              props.setModalShow(false)
            }}
            confirmBtnText={t('sweetAlerts.confirm')}
            cancelBtnText={t('sweetAlerts.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.miningOff')}
          </SweetAlert>

          <SweetAlert
            title={t('sweetAlerts.areConfirm')}
            custom
            customIcon={<i className='fas fa-sync sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertReboot}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              rebootWorker(props.worker.id)
              setShowAlertReboot(false)
              props.setModalShow(false)
            }}
            onCancel={() => {
              setShowAlertReboot(false)
              props.setModalShow(false)
            }}
            confirmBtnText={t('sweetAlerts.confirm')}
            cancelBtnText={t('sweetAlerts.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.workerReboot')}
          </SweetAlert>

          <SweetAlert
            title={t('sweetAlerts.areConfirm')}
            custom
            customIcon={<i className='fas fa-sync sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertHardReboot}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              hardRebootWorker(props.worker.id)
              setShowAlertHardReboot(false)
              props.setModalShow(false)
            }}
            onCancel={() => {
              setShowAlertHardReboot(false)
              props.setModalShow(false)
            }}
            confirmBtnText={t('sweetAlerts.confirm')}
            cancelBtnText={t('sweetAlerts.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.workerRebootHard')}
          </SweetAlert>

          <SweetAlert
            title={t('sweetAlerts.areConfirm')}
            custom
            customIcon={<i className='fas fa-trash sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertDelete}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              props.deleteRig({ rigId: props.worker.id })
              setShowAlertReboot(false)
              props.setModalShow(false)
            }}
            onCancel={() => {
              setShowAlertDelete(false)
              props.setModalShow(false)
            }}
            confirmBtnText={t('sweetAlerts.confirm')}
            cancelBtnText={t('sweetAlerts.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.workerRemoved')}
          </SweetAlert>
        </div>
        <div className={'actions__items'}>
          <div
            className={'actions__item'}
            tooltip={t('pages.workers.setMiningPreset')}
            flow='left'
            onClick={() => {
              setIsSetMiningPreset(true)
              props.setModalShow(true)
            }}
          >
            <i className='fas fa-plane-departure'></i>
          </div>
          {isSetMiningPreset && (
            <SetMiningPreset
              onCancel={() => {
                setIsSetMiningPreset(false)
                props.setModalShow(false)
              }}
              worker={props.worker}
            />
          )}

          {props.worker.action === 'stop' ? (
            props.worker.miningPresetId ? (
              <div
                className={'actions__item'}
                tooltip={t('pages.workers.startMining')}
                flow='left'
                onClick={() => start()}
              >
                <i className='fas fa-play'></i>
              </div>
            ) : (
              <div className={'actions__item'} tooltip={t('pages.workers.assignPresetStartMining')} flow='left'>
                <i className='fas fa-play'></i>
              </div>
            )
          ) : null}

          {props.worker.action === 'run' && props.worker.miningPresetId ? (
            <div
              className={'actions__item'}
              tooltip={t('pages.workers.stopMining')}
              flow='left'
              onClick={() => pause()}
            >
              <i className='fas fa-stop'></i>
            </div>
          ) : null}

          {props.worker.action === 'stopError' ? (
            <div
              className={'actions__item'}
              tooltip={t('pages.workers.startMining')}
              flow='left'
              onClick={() => start()}
            >
              <i className='fas fa-play'></i>
            </div>
          ) : null}

          <div
            className={'actions__item'}
            tooltip={t('pages.workers.workerInformation')}
            flow='left'
            onClick={() => {
              setIsRigFullInfo(true)
              props.setModalShow(true)
            }}
          >
            <i className='fas fa-chart-bar'></i>
          </div>
          {isRigFullInfo && (
            <RigFullInfo
              newWorker={props.worker}
              onCancel={() => {
                setIsRigFullInfo(false)
                props.setModalShow(false)
              }}
            />
          )}

          <Dropdown isOpen={show} toggle={toggle} direction={'left'}>
            <DropdownToggle className={'actions__item'} tooltip={t('pages.workers.workerPowerActions')} flow='left'>
              <i className='fas fa-power-off'></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => turnOff()}>{t('pages.workers.turnOff')}</DropdownItem>
              <DropdownItem onClick={() => reboot()}>{t('pages.workers.reboot')}</DropdownItem>
              <DropdownItem onClick={() => hardReboot()}>{t('pages.workers.hardReboot')}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {props.worker.videoCards && props.worker.videoCards.length > 0 && (
            <div
              className={'actions__item'}
              tooltip={t('pages.workers.overclocking')}
              flow='left'
              onClick={() => {
                setIsCardTurboModal(true)
                props.setModalShow(true)
              }}
            >
              <i className='fas fa-sliders-h'></i>
            </div>
          )}
          {isCardTurboModal && (
            <CardTurboModal
              onCancel={() => {
                setIsCardTurboModal(false)
                props.setModalShow(false)
              }}
              rigId={props.worker.id}
              rigName={props.worker.name}
            />
          )}
        </div>
      </div>
    ),

    [
      props.worker.id,
      show,
      showAlertTurnOff,
      showAlertStart,
      showAlertReboot,
      showAlertHardReboot,
      showAlertPause,
      showAlertDelete,
      isSetMiningPreset,
      isCardTurboModal,
      isRigFullInfo,
      props.worker.action,
    ],
  )
}

const mapDispatchToProps = {
  deleteRig: actions.deleteRig,
  setRigMinerAction: actions.setRigMinerAction,
  resetOrPowerOfWorker: actions.resetOrPowerOfWorker,
  setMiningPresetList: presetActions.setMiningPresetList,
}

export default connect(null, mapDispatchToProps)(WorkerActions)
