import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import uuid from 'node-uuid'

const MiningPresetOption = props => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation('common')

  const togglePreset = () => {
    setShow(!show)
    props.setHideAll()
  }

  const selectMiningPreset = () => {
    togglePreset()
    props.selectMiningPreset(props.preset)
  }
  const tryRequire = icon => {
    try {
      return require('../../../assets/images/coinIcons/' + icon.toLowerCase() + '.png').default
    } catch (err) {
      return require('../../../assets/images/coinIcons/none.png').default
    }
  }

  return (
    <div key={uuid()} style={{ display: props.hideAll && !show ? 'none' : 'block' }}>
      <Button color={'secondary'} className={'mining_preset_button'} onClick={togglePreset}>
        <div className={'mining_preset_used'}>{props.countRigs}</div>
        {props.usedInCurrent ? (
          <div
            style={{ color: '#d3d018' }}
            className={'mining_preset_used mining_preset_used_star'}
            tooltip={t('pages.miningPresets.usedInCurrentRig')}
            flow={'left'}
          >
            <i className='fas fa-star'></i>
          </div>
        ) : null}
        <h6>{props.preset.name}</h6>
        {props.preset.flightSheets.map(el => {
          return (
            <div key={uuid()} className={'mining_preset_info'}>
              <span tooltip={t('pages.miningPresets.coin')}>
                {el ? <img alt='' src={tryRequire(el.coin)} /> : null}
                {el && el.coin}
                {el && el.dualCoin && <> / {el.dualCoin}</>}
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ color: '#949fff' }} tooltip={t('pages.miningPresets.pool')}>
                <i className='fas fa-chart-network'></i> {el && el.poolObj.title}
                {el && el.dualPoolObj && <> / {el.dualPoolObj.title}</>}
              </span>
              <br />
              <span style={{ color: '#fff' }} tooltip={t('pages.miningPresets.wallet')}>
                <i className='fas fa-wallet'></i> {el && el.walletObj.name}
                {el && el.dualWalletObj && <> / {el.dualWalletObj.name}</>}
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ color: '#e4909c' }} tooltip={t('pages.miningPresets.miner')}>
                {' '}
                <i className='fas fa-dice-d8'></i> {el && el.miner}
              </span>
            </div>
          )
        })}
      </Button>
      <div className={!show ? 'mining_preset_selected' : 'mining_preset_selected selected'}>
        {t('pages.miningPresets.areYouWantApply')} "{props.preset.name}" {t('pages.miningPresets.toSelected')}{' '}
        {props.manyRigs ? t('pages.miningPresets.workers') : t('pages.miningPresets.worker')}?
        <div className={'mining_preset_selected__buttons'}>
          <Button color={'secondary'} onClick={togglePreset}>
            {t('pages.miningPresets.cancel')}
          </Button>
          <Button color={'primary'} onClick={selectMiningPreset}>
            {t('pages.miningPresets.apply')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default connect(null, null)(MiningPresetOption)
