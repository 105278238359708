import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import { Table } from '../../../../components/Table'
import * as actions from '../../actions'
import InviteCodeActions from '../../components/InviteCodeActions'
import { CreateInviteCodeModal } from '../../components/modals'
import { Row, Col } from 'reactstrap'
import Cookies from 'js-cookie'

const InviteCodes = props => {
  const [inviteCodes, setInviteCodes] = useState([])
  const setInviteCodesList = props.setInviteCodesList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 100,
  )
  useEffect(() => {
    setInviteCodesList({ page: currentPage, size: showCount, orderBy: 'id' })
  }, [setInviteCodesList, currentPage, showCount])

  useEffect(() => {
    const inviteCodesArray = props.inviteCodesList.map(inviteCode => {
      return {
        ...inviteCode,
        action: <InviteCodeActions inviteCode={inviteCode} />,
      }
    })
    setInviteCodes(inviteCodesArray)
  }, [props.inviteCodesList])

  const inviteCodesData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'Invite Code',
        field: 'inviteCode',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'possibleUsersNum',
        field: 'possibleUsersNum',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'factUsersNum',
        field: 'factUsersNum',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'Actions',
        field: 'action',
        width: 10,
      },
    ],
    rows: inviteCodes,
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <CreateInviteCodeModal />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            name={'inviteCodes'}
            className={'dc__table'}
            responsive
            data={inviteCodesData}
            total={props.inviteCodesTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  inviteCodesList: store.admin.inviteCodesList,
  inviteCodesTotal: store.admin.inviteCodesTotal,
})

const mapDispatchToProps = {
  setInviteCodesList: actions.setInviteCodesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteCodes)
