import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'

import * as actions from '../../actions'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const PowerUnitModal = () => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const { t } = useTranslation('common')
  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'power_unit__create'}>
        <Button onClick={toggle} color='primary' className={'power_unit__create_button'}>
          {t('pages.powerUnits.addNew')}
        </Button>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={'modal-dialog-centered popup popup_large '}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={toggle}>
          {t('pages.powerUnits.newPowerManagementUnit')}
        </ModalHeader>
        <ModalBody>
          <div className={'powerUnit__body'}>
            <div className={'powerUnit__add'}>
              <div className={'powerUnit__add__row'}>
                <label> {t('pages.powerUnits.unitName')} </label>
                <Input placeholder={t('pages.powerUnits.name')} />
              </div>
              <div className={'powerUnit__add__row'}>
                <label>{t('pages.powerUnits.RPCServer')}</label>
                <Select
                  classNamePrefix={'form_control_react_select'}
                  placeholder={t('pages.powerUnits.selectRPCServer')}
                  options={[
                    { value: '1', label: 'Server 1' },
                    { value: '2', label: 'Server 2' },
                    { value: '3', label: 'Server 3' },
                  ]}
                />
              </div>
              <div className={'powerUnit__add__row'}>
                <label>{t('pages.powerUnits.unitType')}</label>
                <Select
                  classNamePrefix={'form_control_react_select'}
                  placeholder={t('pages.powerUnits.selectPMUType')}
                  options={[
                    { value: '1', label: 'Generic PDU device' },
                    { value: '2', label: 'Generic Relay' },
                  ]}
                />
              </div>
              <div className={'powerUnit__add__row'}>
                <label>{t('pages.powerUnits.communicationProtocol')}</label>
                <h5>SNMP/WEB</h5>
              </div>
              <div className={'powerUnit__add__row'}>
                <label>{t('pages.powerUnits.IPAddress')}</label>
                <Input placeholder={'0.0.0.0'} />
              </div>
              <div className={'powerUnit__add__row'}>
                <label>{t('pages.powerUnits.readCommunityName')}</label>
                <Input placeholder={'public'} />
              </div>
              <div className={'powerUnit__add__row'}>
                <label>{t('pages.powerUnits.writeCommunityCame')}</label>
                <Input placeholder={'private'} />
              </div>
              <div className={'powerUnit__add__row'}>
                <label>{t('pages.powerUnits.dc')}</label>
                <Select
                  classNamePrefix={'form_control_react_select'}
                  placeholder={t('pages.powerUnits.selectDataCenter')}
                  options={[
                    { value: '1', label: 'DC' },
                    { value: '2', label: 'Room' },
                  ]}
                />
              </div>
              <div className={'powerUnit__add__row powerUnit__add__row_center'}>
                <Button color={'primary'}>{t('pages.powerUnits.verifyTest')}</Button>
              </div>
            </div>
            <div className={'powerUnit__status'}>
              <h5 className={'powerUnit__status_header'}>{t('pages.powerUnits.status')}</h5>
              <div className={'powerUnit__status__row powerUnit__status__row_center'}>
                <div className='powerUnit__status_text form-control'>
                  {t('pages.powerUnits.connectingRPC')}........{t('pages.powerUnits.ok')}
                  <br />
                  {t('pages.powerUnits.connectingPowerManagementUnit')}......{t('pages.powerUnits.ok')}
                  <br />
                  {t('pages.powerUnits.GatheringDeviceInformation')}........{t('pages.powerUnits.ok')}
                  <br />
                </div>
              </div>
              <div className={'powerUnit__status__row'}>
                <label>{t('pages.powerUnits.unitModel')}</label>
                <h5>RackDPU AP 7953</h5>
              </div>
              <div className={'powerUnit__status__row'}>
                <label>{t('pages.powerUnits.controlOutlets')}</label>
                <h5>24</h5>
              </div>
              <div className={'powerUnit__status_answer'}>{t('pages.powerUnits.deviceReadyPair')}</div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='primary'>{t('pages.powerUnits.saveAdd')}</Button>
          <Button color='secondary' onClick={toggle}>
            {t('pages.powerUnits.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  walletsList: store.presets.walletsList,
  coinsList: store.presets.coinsList,
  poolsList: store.presets.poolsList,
  minersList: store.presets.minersList,
  pool: store.presets.pool,
  averageHashList: store.presets.averageHashList,
  overclockingProfilesList: store.profiles.overclockingProfilesList,
  algoCoinsList: store.profiles.algoCoinsList,
})

const mapDispatchToProps = {
  createMiningPreset: actions.createMiningPreset,
  createWalletInPreset: actions.createWallet,
  setWalletsList: actions.setWalletsList,
  setMinersList: actions.setMinersList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerUnitModal)
