import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'
import Select from 'react-select'

const EditScheduleModal = props => {
  const { t } = useTranslation('common')
  const eventOptions = [{ label: 'Block98', value: 'block98' }]
  const activatorOptions = [
    { label: t('pages.schedule.byEvent'), value: 'byEvent' },
    { label: t('pages.schedule.manual'), value: 'manual' },
  ]
  const [errors, setErrors] = useState({})
  const [name, setName] = useState(props.schedule?.name)
  const [event, setEvent] = useState(eventOptions.find(option => option.value === props.schedule?.event))
  const [activator, setActivator] = useState(
    activatorOptions.find(option => option.value === props.schedule?.activator),
  )
  const [initialPreset, setInitialPreset] = useState(() => {
    const preset = props.miningPresetList.find(preset => preset.id == props.schedule?.firstPresetId)
    return preset && { label: preset.name, value: preset.id }
  })
  const [newPreset, setNewPreset] = useState(() => {
    const preset = props.miningPresetList.find(preset => preset.id == props.schedule?.secondPresetId)
    return preset && { label: preset.name, value: preset.id }
  })

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'name': {
        setName(e.target.value)
        return
      }
    }
  }

  const submitFunc = () => {
    const newErrors = {}
    if (name.length === 0) newErrors.name = { message: t('pages.schedule.nameError') }
    if (!activator) newErrors.activator = { message: t('pages.schedule.activatorError') }
    if (!event) newErrors.event = { message: t('pages.schedule.eventError') }
    if (!initialPreset) newErrors.initialPreset = { message: t('pages.schedule.initialPresetError') }
    if (!newPreset) newErrors.newPreset = { message: t('pages.schedule.newPresetError') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    props.updateSchedule({
      scheduleId: props.schedule.id,
      name: name,
      event: event.value,
      activator: activator.value,
      action: props.schedule.action,
      firstPresetId: initialPreset.value,
      secondPresetId: newPreset.value,
    })
    props.toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'actions__item'} tooltip={t('pages.schedule.editSchedule')} flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.schedule.editSchedule')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.schedule.name')}</Label>
                <Input
                  className={errors.name ? 'invalid' : ''}
                  type={'text'}
                  name={'name'}
                  onChange={onChange}
                  value={name}
                />
                <div className={'error'}>{errors.name && errors.name.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.schedule.event')}</Label>
                <Select
                  isClearable={true}
                  hideSelectedOptions
                  placeholder={t('inputs.select')}
                  className={errors.event ? 'invalid-select' : ''}
                  classNamePrefix={'form_control_react_select'}
                  onChange={option => {
                    const newErrors = errors
                    delete newErrors['event']
                    setErrors(newErrors)

                    setEvent(option)
                  }}
                  value={event}
                  options={eventOptions}
                />
                <div className={'error'}>{errors.event && errors.event.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.schedule.activator')}</Label>
                <Select
                  isClearable={true}
                  hideSelectedOptions
                  placeholder={t('inputs.select')}
                  className={errors.activator ? 'invalid-select' : ''}
                  classNamePrefix={'form_control_react_select'}
                  onChange={option => {
                    const newErrors = errors
                    delete newErrors['activator']
                    setErrors(newErrors)

                    setActivator(option)
                  }}
                  value={activator}
                  options={activatorOptions}
                />
                <div className={'error'}>{errors.activator && errors.activator.message}</div>
              </Col>

              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.schedule.initialPreset')}</Label>
                <Select
                  isClearable={true}
                  hideSelectedOptions
                  placeholder={t('inputs.select')}
                  className={errors.initialPreset ? 'invalid-select' : ''}
                  classNamePrefix={'form_control_react_select'}
                  onChange={option => {
                    const newErrors = errors
                    delete newErrors['initialPreset']
                    setErrors(newErrors)

                    setInitialPreset(option)
                  }}
                  value={initialPreset}
                  options={props.miningPresetList.map(preset => {
                    return { label: preset.name, value: preset.id }
                  })}
                />
                <div className={'error'}>{errors.initialPreset && errors.initialPreset.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.schedule.newPreset')}</Label>
                <Select
                  isClearable={true}
                  hideSelectedOptions
                  placeholder={t('inputs.select')}
                  className={errors.newPreset ? 'invalid-select' : ''}
                  classNamePrefix={'form_control_react_select'}
                  onChange={option => {
                    const newErrors = errors
                    delete newErrors['newPreset']
                    setErrors(newErrors)

                    setNewPreset(option)
                  }}
                  value={newPreset}
                  options={props.miningPresetList.map(preset => {
                    return { label: preset.name, value: preset.id }
                  })}
                />
                <div className={'error'}>{errors.newPreset && errors.newPreset.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.schedule.cancel')}
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            {t('pages.schedule.edit')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  miningPresetList: store.presets.miningPresetList,
})

const mapDispatchToProps = {
  updateSchedule: actions.updateSchedule,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditScheduleModal)
