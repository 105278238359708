import React, { useState } from 'react'
import { WalletModalEdit } from './modals'
import { connect } from 'react-redux'
import * as actions from '../../MiningPresets/actions'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { useTranslation } from 'react-i18next'

const WalletsActions = props => {
  const [showWallet, setShowWallet] = useState(false)
  const { t } = useTranslation('common')
  const toggleWalletEdit = () => {
    setShowWallet(!showWallet)
  }
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const deleteWalletFunc = async () => {
    setShowAlertDelete(false)
    props.deleteWallet({ walletID: +props.wallet.id })
  }

  return (
    <div className={'actions__items'}>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertDelete}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          deleteWalletFunc()
        }}
        onCancel={() => {
          setShowAlertDelete(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        {t('pages.wallets.removeWallet')}
      </SweetAlert>
      <WalletModalEdit toggle={toggleWalletEdit} show={showWallet} wallet={props.wallet} />
      <div
        className={'actions__item'}
        tooltip={t('pages.wallets.deleteWallet')}
        flow='left'
        onClick={() => {
          setShowAlertDelete(true)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  deleteWallet: actions.deleteWallet,
}

export default connect(null, mapDispatchToProps)(WalletsActions)
