import React from 'react'
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'

const SelectWithCryptoImages = props => {
  const { Option } = components
  const { t } = useTranslation('common')
  const { SingleValue } = components
  const tryRequire = icon => {
    try {
      return require('../../assets/images/coinIcons/' + icon.toLowerCase() + '.png').default
    } catch (err) {
      5
      return require('../../assets/images/coinIcons/none.png').default
    }
  }

  const IconOption = props => {
    return (
      <Option {...props}>
        {props.data.label}{' '}
        {props.data.coins && props.data.coins.split(', ').length > 0
          ? props.data.coins.split(', ').map(coin => {
              return <img key={coin} alt='' src={tryRequire(coin)} />
            })
          : null}
      </Option>
    )
  }
  const singleValue = props => (
    <SingleValue {...props}>
      {props.data.label} {props.data.coins ? '(' + props.data.coins.split(', ').length + ')' : '(0)'}
    </SingleValue>
  )

  return (
    <div onClick={e => e.stopPropagation()}>
      <Select
        components={{ Option: IconOption, SingleValue: singleValue }}
        options={props.options}
        classNamePrefix={'form_control_react_select'}
        className={props.error ? 'invalid-select' : ''}
        onChange={props.onchange}
        value={props.value}
        name={props.name}
        isSearchable
        isClearable
        maxMenuHeight={200}
        hideSelectedOptions
        placeholder={props.placeholder ? props.placeholder : t('inputs.select')}
      />
    </div>
  )
}

export default SelectWithCryptoImages
