import { types } from './types'

const initState = {
  coinsList: [],
  algoCoinsList: [],
  inviteCodesList: [],
  poolsList: [],
  minersList: [],
  coinsTotal: 0,
  algoCoinsTotal: 0,
  inviteCodesTotal: 0,
  poolsTotal: 0,
  minersTotal: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_COINS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.coinsList = data.items
      stateCopy.coinsTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_COIN: {
      const { data } = action
      const stateCopy = { ...state }
      const coinsListCopy = [...stateCopy.coinsList, data]
      stateCopy.coinsList = coinsListCopy
      stateCopy.coinsTotal += 1
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_COIN: {
      const { data } = action
      const index = state.coinsList.findIndex(coin => +coin.id === +data.id)
      return {
        ...state,
        coinsList: [...state.coinsList.slice(0, index), data, ...state.coinsList.slice(index + 1)],
      }
    }

    case types.DELETE_COIN: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.coinsList]
      stateCopy.coinsList = rigsListCopy.filter(coin => +coin.id !== data)
      stateCopy.coinsTotal -= 1
      return {
        ...stateCopy,
      }
    }

    case types.SET_ALGOCOINS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.algoCoinsList = data.items
      stateCopy.algoCoinsTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_ALGOCOIN: {
      const { data } = action
      const stateCopy = { ...state }
      const algoCoinsListCopy = [...stateCopy.algoCoinsList, data]
      stateCopy.algoCoinsList = algoCoinsListCopy
      stateCopy.algoCoinsTotal += 1
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_ALGOCOIN: {
      const { data } = action
      const index = state.algoCoinsList.findIndex(algoCoin => +algoCoin.id === +data.id)
      return {
        ...state,
        algoCoinsList: [...state.algoCoinsList.slice(0, index), data, ...state.algoCoinsList.slice(index + 1)],
      }
    }

    case types.DELETE_ALGOCOIN: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.algoCoinsList]
      stateCopy.algoCoinsList = rigsListCopy.filter(algoCoin => +algoCoin.id !== data)
      stateCopy.algoCoinsTotal -= 1
      return {
        ...stateCopy,
      }
    }

    case types.SET_INVITECODES_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.inviteCodesList = data.items
      stateCopy.inviteCodesTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_INVITECODE: {
      const { data } = action
      const stateCopy = { ...state }
      const inviteCodesListCopy = [...stateCopy.inviteCodesList, data]
      stateCopy.inviteCodesList = inviteCodesListCopy
      stateCopy.inviteCodesTotal += 1
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_INVITECODE: {
      const { data } = action
      const index = state.inviteCodesList.findIndex(invitecode => +invitecode.id === +data.id)
      return {
        ...state,
        inviteCodesList: [...state.inviteCodesList.slice(0, index), data, ...state.inviteCodesList.slice(index + 1)],
      }
    }

    case types.DELETE_INVITECODE: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.inviteCodesList]
      stateCopy.inviteCodesList = rigsListCopy.filter(invitecode => +invitecode.id !== data)
      stateCopy.inviteCodesTotal -= 1

      return {
        ...stateCopy,
      }
    }

    case types.SET_POOLS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.poolsList = data.items
      stateCopy.poolsTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_POOL: {
      const { data } = action
      const stateCopy = { ...state }
      const poolsListCopy = [...stateCopy.poolsList, data]
      stateCopy.poolsList = poolsListCopy
      stateCopy.poolsTotal += 1
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_POOL: {
      const { data } = action
      const index = state.poolsList.findIndex(pool => +pool.id === +data.id)
      return {
        ...state,
        poolsList: [...state.poolsList.slice(0, index), data, ...state.poolsList.slice(index + 1)],
      }
    }

    case types.DELETE_POOL: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.poolsList]
      stateCopy.poolsList = rigsListCopy.filter(pool => +pool.id !== data)
      stateCopy.poolsTotal -= 1
      return {
        ...stateCopy,
      }
    }

    case types.SET_MINERS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.minersList = data.items
      stateCopy.minersTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_MINER: {
      const { data } = action
      const stateCopy = { ...state }
      const minersListCopy = [...stateCopy.minersList, data]
      stateCopy.minersList = minersListCopy
      stateCopy.minersTotal += 1

      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_MINER: {
      const { data } = action
      const index = state.minersList.findIndex(miner => +miner.id === +data.id)
      return {
        ...state,
        minersList: [...state.minersList.slice(0, index), data, ...state.minersList.slice(index + 1)],
      }
    }

    case types.DELETE_MINER: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.minersList]
      stateCopy.minersList = rigsListCopy.filter(miner => +miner.id !== data)
      stateCopy.minersTotal -= 1

      return {
        ...stateCopy,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
