import { types } from './types'

const initState = {
  overclockingProfilesList: [],
  slotsList: [],
  algoCoinsList: [],
  overclockingProfilesTotal: 0,
  slotsTotal: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_PROFILES_DATA: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.overclockingProfilesList = data.items
      stateCopy.overclockingProfilesTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_OVERCLOCKING_PROFILE: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.overclockingProfilesList = [...stateCopy.overclockingProfilesList, { ...data }]
      return {
        ...stateCopy,
      }
    }

    case types.SET_ALGOCOINS_DATA: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.algoCoinsList = data.items
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_OVERCLOCKING_PROFILE: {
      const { data } = action
      const stateCopy = { ...state }
      const overclockingProfilesListCopy = [...stateCopy.overclockingProfilesList]
      stateCopy.overclockingProfilesList = overclockingProfilesListCopy.map(profile => {
        if (profile.id === data.id) {
          console.log(data)
          profile = { ...data }
        }
        return profile
      })
      return {
        ...stateCopy,
      }
    }

    case types.DELETE_PROFILE: {
      const { data } = action
      const stateCopy = { ...state }
      const overclockingProfilesListCopy = [...stateCopy.overclockingProfilesList]
      stateCopy.overclockingProfilesList = overclockingProfilesListCopy.filter(profile => +profile.id !== +data.id)
      return { ...stateCopy }
    }

    case types.SET_SLOTS_DATA: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.slotsList = data.items
      stateCopy.slotsTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_SLOT: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.slotsList = [...stateCopy.slotsList, { ...data }]
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_SLOT: {
      const { data } = action
      const stateCopy = { ...state }
      const slotsListCopy = [...stateCopy.slotsList]
      stateCopy.slotsList = slotsListCopy.map(slot => {
        if (slot.id === data.id) {
          slot = { ...data }
        }
        return slot
      })
      return {
        ...stateCopy,
      }
    }

    case types.DELETE_SLOT: {
      const { data } = action
      const stateCopy = { ...state }
      const slotsListCopy = [...stateCopy.slotsList]
      stateCopy.slotsList = slotsListCopy.filter(slot => +slot.id !== +data)
      return { ...stateCopy }
    }

    default: {
      return state
    }
  }
}

export default reducer
