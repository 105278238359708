import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'
import * as actions2 from '../../../MiningPresets/actions'
import SelectWithImages from '../../../../components/UI/SelectWithImages'
import { useTranslation } from 'react-i18next'

const CreateWalletModal = props => {
  const [walletName, setWalletName] = useState('')
  const [walletAddress, setWalletAddress] = useState('')
  const [coin, setCoin] = useState('')
  const [source, setSource] = useState('')
  const { t } = useTranslation('common')

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'wallet_name': {
        setWalletName(e.target.value)
        return
      }
      case 'wallet_address': {
        setWalletAddress(e.target.value)
        return
      }
      case 'wallet_coin': {
        setCoin(e.target.options[e.target.selectedIndex].value)
        return
      }
      case 'wallet_source': {
        setSource(e.target.value)
        return
      }
      default:
        return
    }
  }
  const onChangeSelect = option => {
    const newErrors = errors
    delete newErrors['wallet_coin']
    setErrors(newErrors)
    setCoin(option)
  }

  const [errors, setErrors] = useState({})
  const submitFunc = () => {
    const newErrors = {}
    if (!walletName || walletName.length === 0) newErrors.wallet_name = { message: t('pages.wallets.walletNameError') }
    if (!walletAddress || walletAddress.length === 0)
      newErrors.wallet_address = { message: t('pages.wallets.walletAddressError') }
    if ((coin && !coin.value) || !coin) newErrors.wallet_coin = { message: t('pages.wallets.walletCoinError') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    props.createWallet({
      name: walletName,
      address: walletAddress,
      coin: coin.value,
      source,
    })
    props.toggleWallet()
  }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <Modal isOpen={props.isOpenWallet} toggle={props.toggleWallet} className={'modal-dialog-centered'}>
        <ModalHeader tag={'h4'} toggle={props.toggleWallet}>
          {t('pages.wallets.addWallet')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.wallets.name')}</Label>
                <Input
                  className={errors.wallet_name ? 'invalid' : ''}
                  type={'text'}
                  name={'wallet_name'}
                  onChange={onChange}
                />
                <div className={'error'}>{errors.wallet_name && errors.wallet_name.message}</div>
              </Col>
              <Col xs={12} className={'form-group'}>
                <Label>{t('pages.wallets.address')}</Label>
                <Input
                  className={errors.wallet_address ? 'invalid' : ''}
                  type={'text'}
                  name={'wallet_address'}
                  onChange={onChange}
                />
                <div className={'error'}>{errors.wallet_address && errors.wallet_address.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.wallets.coin')}</Label>
                <SelectWithImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectCoin')}
                  name={'wallet_coin'}
                  value={coin}
                  onchange={onChangeSelect}
                  error={errors.wallet_coin}
                  options={props.coinsList.map(coin => {
                    return {
                      ...coin,
                      icon: coin.nameSymbol,
                      label: coin.nameSymbol,
                      value: coin.nameSymbol,
                    }
                  })}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.wallet_coin && errors.wallet_coin.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.wallets.source')}</Label>
                <Input className={errors.wallet_name} type={'text'} name={'wallet_source'} onChange={onChange} />
                <div className={'error'}>{errors.wallet_source && errors.wallet_source.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggleWallet}>
            {t('pages.wallets.cancel')}
          </Button>
          <Button color='primary' onClick={submitFunc}>
            {t('pages.wallets.create')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  coinsList: store.presets.coinsList,
})

const mapDispatchToProps = {
  createWallet: actions2.createWallet,
  setWalletsList: actions2.setWalletsList,
  setCoinsList: actions2.setCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWalletModal)
