import React from 'react'

import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

const MainPage = () => {
  // useEffect(() => {
  //   setRigsList({ page: 1, size: 100, orderBy: 'id' })
  //   setMiningPresetList({ page: 1, size: 100, filters: {} })
  // }, [])

  // let rooms = 0
  // let DC = 0
  // let consumption = 0
  // let gpu_consumption = 0
  // let card_count = 0
  // let rig_count = 0
  // let max_temp = 0
  // let avg_temp = 0

  // for (let i = 0; i < rigsList.length; i++) {
  //   consumption += +rigsList[i].powCons
  //   if (rigsList[i].videoCards) {
  //     rigsList[i].videoCards.map((card) => {
  //       gpu_consumption += +card.powerInfo
  //       avg_temp += +card.gpuTemp
  //       if (card.gpuTemp > max_temp) max_temp = card.gpuTemp
  //       return card
  //     })
  //     card_count += +rigsList[i].videoCards.length
  //     rig_count++
  //   }
  // }
  // avg_temp = avg_temp / card_count

  const { t } = useTranslation('common')

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className={'workers_page'}>
          <div className={'workers_body'}>
            <div>
              {t('pages.main.graphs')}
              <br />
              {t('pages.main.graphs')}
              <br />
              {t('pages.main.graphs')}
              <br />
              {t('pages.main.graphs')}
              <br />
              {t('pages.main.graphs')}
              <br />
              {t('pages.main.graphs')}
              <br />
              {t('pages.main.graphs')}
              <br />
              {t('pages.main.graphs')}
              <br />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(null, null)(MainPage)
