import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  HIDE_RIGHT_SIDEBAR,
  ADD_ALERT,
  REMOVE_ALERT,
} from './actionTypes'

export const changeLayout = layout => async dispatch => {
  try {
    dispatch({
      type: CHANGE_LAYOUT,
      payload: layout,
    })
  } catch (error) {
    console.log('error: ', error)
  }
}

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})

export const changeSidebarType = (sidebarType, isMobile) => async dispatch => {
  try {
    dispatch({
      type: CHANGE_SIDEBAR_TYPE,
      payload: { sidebarType, isMobile },
    })
  } catch (errors) {
    console.log(errors)
  }
}

export const changeTopbarTheme = topbarTheme => async dispatch => {
  try {
    dispatch({
      type: CHANGE_TOPBAR_THEME,
      payload: topbarTheme,
    })
  } catch (error) {
    console.log('error: ', error)
  }
}

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
})

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
})

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
})

export const addAlert = (text, style) => {
  return {
    type: ADD_ALERT,
    text,
    style,
  }
}

export const removeAlert = id => {
  return {
    type: REMOVE_ALERT,
    id,
  }
}
