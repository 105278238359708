import { types } from './types'

const initState = {
  workersItems: [],
  clearCheck: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_WORKER_ITEMS: {
      const { data } = action
      return {
        ...state,
        workersItems: data,
      }
    }
    case types.SET_CLEAR_CHECK: {
      return {
        ...state,
        clearCheck: +state.clearCheck + +1,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
