// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  HIDE_RIGHT_SIDEBAR,
  ADD_ALERT,
  REMOVE_ALERT,
} from './actionTypes'
import uuid from 'node-uuid'

const INIT_STATE = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'dark',
  leftSideBarType: 'default',
  topbarTheme: 'dark',
  showRightSidebar: false,
  isMobile: false,
  alerts: [],
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      }
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      }
    case ADD_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            text: action.text,
            style: action.style,
            id: uuid(),
          },
        ],
      }
    case REMOVE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter(alert => {
          if (alert.id === action.id) {
            return false
          } else {
            return true
          }
        }),
      }
    default:
      return state
  }
}

export default Layout
