import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import { Table } from '../../../../components/Table'
import * as actions from '../../actions'
import AlgoCoinActions from '../../components/AlgoCoinActions'
import { CreateAlgoCoinModal } from '../../components/modals'
import { Row, Col } from 'reactstrap'
import Cookies from 'js-cookie'

const AlgoCoins = props => {
  const [algoCoins, setAlgoCoins] = useState([])
  const setAlgoCoinsList = props.setAlgoCoinsList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 100,
  )

  useEffect(() => {
    setAlgoCoinsList({ page: currentPage, size: showCount, orderBy: 'id' })
  }, [setAlgoCoinsList, currentPage, showCount])

  useEffect(() => {
    const algoCoinsArray = props.algoCoinsList.map(algoCoin => {
      return {
        ...algoCoin,
        action: <AlgoCoinActions algoCoin={algoCoin} />,
      }
    })
    setAlgoCoins(algoCoinsArray)
  }, [props.algoCoinsList])

  const algoCoinsData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'Algorithm',
        field: 'algo',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'coins',
        field: 'coins',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'Actions',
        field: 'action',
        width: 10,
      },
    ],
    rows: algoCoins,
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <CreateAlgoCoinModal />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            name={'algoCoins'}
            className={'dc__table'}
            responsive
            data={algoCoinsData}
            total={props.algoCoinsTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  algoCoinsList: store.admin.algoCoinsList,
  algoCoinsTotal: store.admin.algoCoinsTotal,
})

const mapDispatchToProps = {
  setAlgoCoinsList: actions.setAlgoCoinsList,
  setCoinsList: actions.setCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlgoCoins)
