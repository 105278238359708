export const types = {
  SET_WALLETS_LIST: 'wallets/setList',
  SET_COINS_LIST: 'coin/setList',
  SET_POOLS_LIST: 'pool/setList',
  SET_POOL: 'pool/setPool',
  SET_MINERS_LIST: 'miner/setList',
  SET_PRESETS_LIST: 'miningPreset/setList',
  SET_AVERAGE_HASH_LIST: 'miningPreset/setAverageHashList',
  UPDATE_MINING_PRESET: 'miningPreset/update',
  DELETE_MINING_PRESET: 'miningPreset/delete',
  CREATE_MINING_PRESET: 'miningPreset/createMiningPreset',
  CREATE_WALLET: 'wallets/createWallet',
  DELETE_WALLET: 'wallets/deleteWallet',
  UPDATE_WALLET: 'wallets/updateWallet',
  SET_AUTOFAN: 'settings/setAutofan',
  ACTION_AUTOFAN: 'settings/actionAutofan',
  SET_GENERAL_SETTINGS: 'settings/setGeneralSettings',
  SET_LOCAL_SETTINGS: 'settings/setLocalSettings',
  DEL_LOCAL_SETTINGS: 'settings/deleteLocalSettings',
  UPDATE_GENERAL_SETTINGS: 'settings/updateGeneralSettings',
  UPDATE_LOCAL_SETTINGS: 'settings/updateLocalSettings',
  DEFOULT_GENERAL_SETTINGS: 'settings/defoultGeneralSettings',
  DEFOULT_LOCAL_SETTINGS: 'settings/defoultLocalSettings',
}
