import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Col, Label, Row } from 'reactstrap'
import * as rigActions from '../../../RigsData/actions'
import MiningPresetOption from '../../../MiningPresets/components/MiningPresetOption'
import { useTranslation } from 'react-i18next'

const SetMiningPreset = props => {
  const [modal, setModal] = useState(false)
  const [hideAll, setHideAll] = useState(false)
  const [presetCountRigs, setPresetCountRigs] = useState([])
  const { t } = useTranslation('common')

  useEffect(() => {
    setTimeout(() => toggle(), 10)
  }, [])

  useEffect(() => {
    setPresetCountRigs(props.rigsMPs)
  }, [props.rigsMPs])

  const toggle = async () => {
    if (!modal) {
      await props.setRigsMPs()
    }
    setModal(!modal)
    setHideAll(false)
    if (modal) {
      setTimeout(() => props.onCancel(), 500)
    }
  }
  const makeAllHidden = () => setHideAll(!hideAll)

  const selectMiningPreset = async preset => {
    props.workersItems
      ? await props.setRigsAction({
          rigIds: props.workersItems
            .map(worker => {
              return worker.id
            })
            .toString(),
          miningPresetId: parseInt(preset.id),
        })
      : await props.setRigMiner({
          rigId: props.worker.id,
          miningPresetId: parseInt(preset.id),
          action: 'run',
        })
    if (props.workersItems) props.clearSelected()
    toggle()
  }

  const countInArray = (array, value) => {
    return array.reduce((n, x) => n + (x === value), 0)
  }

  const [search, setSearch] = useState('')

  return (
    <Modal isOpen={modal} toggle={toggle} className={'modal-dialog-centered'}>
      <ModalHeader tag={'h4'} toggle={toggle}>
        {t('pages.workers.selectMiningPresetFor')}{' '}
        {!props.workersItems ? props.worker.name : t('pages.workers.selectedRigs')}
      </ModalHeader>
      <ModalBody className={'mining_preset_options'}>
        {props.workersItems && (
          <Row>
            <Col xs={12} className={'form-group'}>
              <Label>{t('pages.workers.selectedRigs')}</Label>
              <Input
                readOnly={true}
                type={'textarea'}
                value={props.workersItems && props.workersItems.map(rig => rig.name).join(', ')}
              />
            </Col>
          </Row>
        )}
        <div className={'quicksearch'}>
          <Input
            onChange={e => {
              setSearch(e.target.value)
            }}
            value={search}
            name={'quicksearch'}
            className={'form-control'}
            placeholder={t('pages.workers.quickSearch')}
          />
          <i style={{ display: search ? 'block' : 'none' }} className='fas fa-times' onClick={() => setSearch('')}></i>
        </div>
        <div className={'mining_presets_list'}>
          {props.miningPresetList
            .filter(preset => {
              if (
                search.length === 0 ||
                preset.name.toLowerCase().search(search.toLowerCase()) >= 0 ||
                (props.worker && props.worker.miningPresetId === +preset.id)
              )
                return true
              return false
            })
            .map((preset, key) => {
              const count = countInArray(presetCountRigs, preset.id)
              return (
                <MiningPresetOption
                  hideAll={hideAll}
                  setHideAll={makeAllHidden}
                  preset={preset}
                  selectMiningPreset={selectMiningPreset}
                  key={key}
                  countRigs={count}
                  manyRigs={props.workersItems}
                  usedInCurrent={!props.workersItems ? props.worker.miningPresetId === +preset.id : false}
                />
              )
            })}
        </div>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  )
}

const mapStateToProps = store => ({
  miningPresetList: store.presets.miningPresetList,
  rigsList: store.rigs.rigsList,
  rigsMPs: store.rigs.rigsMPs,
})

const mapDispatchToProps = {
  setRigMiner: rigActions.setRigMiner,
  setRigsAction: rigActions.setRigsAction,
  setRigsMPs: rigActions.setRigsMPs,
}

export default connect(mapStateToProps, mapDispatchToProps)(SetMiningPreset)
