import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import uuid from 'node-uuid'
import { Row, Col } from 'reactstrap'
import * as actions from '../../../../RigsData/actions'
import { useTranslation } from 'react-i18next'

const ActivityLogs = ({ getRigLogs, clearRigLogs, ...props }) => {
  const { t } = useTranslation('common')
  useEffect(() => {
    if (props.worker.id) {
      getRigLogs({
        page: 1,
        size: 100,
        filters: { workerId: +props.worker.id },
      })
    }

    return () => {
      clearRigLogs()
    }
  }, [clearRigLogs, getRigLogs, props.worker.id])

  const now = new Date().getTime()
  const getLogTime = timeData => {
    const time = Math.abs(timeData - now)
    let result, units
    if (time > 1000 * 60 * 60 * 24) {
      result = parseInt(time / (1000 * 60 * 60 * 24), 10)
      units = t('pages.workers.days')
    } else if (time > 1000 * 60 * 60) {
      result = parseInt(time / (1000 * 60 * 60), 10)
      units = t('pages.workers.hours')
    } else if (time > 1000 * 60) {
      result = parseInt(time / (1000 * 60), 10)
      units = t('pages.workers.minutes')
    } else {
      result = parseInt(time / 1000, 10)
      units = t('pages.workers.seconds')
    }
    return result + ' ' + units
  }

  return (
    <div>
      <Row className='worker_charts'>
        <Col xs={12} className='worker-block worker-block_logs'>
          {props.rigLogs.map(log => {
            return (
              <div
                key={uuid()}
                className={`active-log__row ${
                  log.action.toLowerCase().includes('error') ? 'active-log__row_error' : ''
                }`}
              >
                <span className='active-log__time'>{moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                <p
                  key={uuid()}
                  className={`active-log
                  ${
                    log.action.toLowerCase().includes('create') ||
                    log.action.toLowerCase().includes('run') ||
                    log.action.toLowerCase().includes('success')
                      ? 'green'
                      : log.action.toLowerCase().includes('error')
                      ? 'red'
                      : log.action.toLowerCase().includes('powerof') ||
                        log.action.toLowerCase().includes('reboot') ||
                        log.action.toLowerCase().includes('automatic worker software reset') ||
                        log.action.toLowerCase().includes('stop')
                      ? 'orange'
                      : log.action.toLowerCase().includes('submitted')
                      ? 'white'
                      : ''
                  }`}
                >
                  {log.action}
                  {log.description ? (
                    <span className='active-log__descr'>
                      {' '}
                      (
                      {log.description && log.description.toLowerCase().includes('temperature')
                        ? props.worker.videoCards.map(card => {
                            if (card.uuid === log.cardUuid) {
                              return 'GPU' + card.cardNum + ' have '
                            }
                            return ''
                          })
                        : ''}
                      {log.description ? log.description : ''}
                      {log.logsNum > 1 ? ' ( ' + log.logsNum + ' times ) ' : ''})
                    </span>
                  ) : (
                    ''
                  )}
                </p>
                <p className='active-log__time active-log__time_ago'>
                  {getLogTime(Date.parse(log.createdAt))} {t('pages.workers.ago')}
                </p>
              </div>
            )
          })}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = store => ({
  rigLogs: store.rigs.logsList,
})

const mapDispatchToProps = {
  getRigLogs: actions.getRigLogs,
  clearRigLogs: actions.clearRigLogs,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogs)
