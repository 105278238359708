import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { DropdownToggle, Dropdown } from 'reactstrap'
import { EditDataCenter, DeleteDataCenter, StatisticsDataCenter } from './modals'
import * as actions from '../actions'

const DataCenterActions = props => {
  const { t } = useTranslation('common')
  const [isOnDelete, setIsOnDelete] = useState(false)
  const [dataCenterEdit, setDataCenterEdit] = useState(false)
  const [dataCenterStats, setDataCenterStats] = useState(false)

  const toggleEdit = () => {
    setDataCenterEdit(!dataCenterEdit)
  }

  const toggleDelete = () => {
    setIsOnDelete(!isOnDelete)
  }

  const toggleStats = () => {
    setDataCenterStats(!dataCenterStats)
  }

  return (
    <div className={'actions__items'}>
      <Dropdown toggle={toggleEdit} direction={'left'}>
        <DropdownToggle
          tooltip={t('pages.dcManagement.editDC')}
          flow={'left'}
          className={'actions__item actions__item_dc'}
        >
          <i className='fa fa-pencil'></i>
        </DropdownToggle>
      </Dropdown>
      <EditDataCenter onFinish={toggleEdit} isActive={dataCenterEdit} dataCenter={props.dataCenter} />

      <Dropdown toggle={toggleDelete} direction={'left'}>
        <DropdownToggle
          tooltip={t('pages.dcManagement.deleteDC')}
          flow={'left'}
          className={'actions__item actions__item_dc'}
        >
          <i className='fa fa-trash'></i>
        </DropdownToggle>
      </Dropdown>

      <DeleteDataCenter onFinish={toggleDelete} isActive={isOnDelete} dataCenter={props.dataCenter} />

      <Dropdown toggle={toggleStats} direction={'left'}>
        <DropdownToggle
          tooltip={t('pages.dcManagement.statDC')}
          flow={'left'}
          className={'actions__item actions__item_dc'}
        >
          <i className='fa fa-chart-bar'></i>
        </DropdownToggle>
      </Dropdown>
      <StatisticsDataCenter onFinish={toggleStats} isActive={dataCenterStats} dataCenter={props.dataCenter} />
    </div>
  )
}

const mapDispatchToProps = {
  deleteDataCenter: actions.deleteDataCenter,
}

export default connect(null, mapDispatchToProps)(DataCenterActions)
