import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'
import * as actions from '../../actions'
import { useTranslation } from 'react-i18next'

const RoomModalEdit = props => {
  const [errors, setErrors] = useState({})
  const { t } = useTranslation('common')
  const [room, setRoom] = useState({ ...props.room })

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'room_name': {
        setRoom({ ...room, name: e.target.value })
        return
      }
      case 'room_dataCenter_id': {
        setRoom({ ...room, dataCenterId: e.target.value })
        return
      }
      default:
        return
    }
  }

  const submitFunc = () => {
    props.updateRoom({
      name: room.name,
      dataCenterId: +room.dataCenterId,
      roomId: +props.room.id,
    })
    props.toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'actions__item'} tooltip={t('pages.dcManagement.editRoom')} flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.dcManagement.updateRoom')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <Label>{t('pages.dcManagement.dataCenterID')}</Label>
                <select
                  className={errors.room_dataCenter_id ? 'form-control invalid' : 'form-control'}
                  name={'room_dataCenter_id'}
                  onChange={onChange}
                  placeholder={t('inputs.select')}
                >
                  <option value={props.room.dataCenterId}>
                    {props.room.dcName}({props.room.dataCenterId})
                  </option>
                  {props.dataCenters.map((dc, key) => {
                    return (
                      <option key={key} value={dc.id}>
                        {dc.name} ({dc.id})
                      </option>
                    )
                  })}
                </select>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.dcManagement.name')}</Label>
                <Input
                  className={errors.room_name ? 'invalid' : ''}
                  type={'text'}
                  name={'room_name'}
                  onChange={onChange}
                  value={room.name}
                />
                <div className={'error'}>{errors.room_name && errors.room_name.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.dcManagement.cancel')}
          </Button>
          <Button color='primary' onClick={submitFunc}>
            {t('pages.dcManagement.update')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  dataCenters: store.dcManagement.dataCentersList,
})

const mapDispatchToProps = {
  updateRoom: actions.updateRoom,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomModalEdit)
