import React, { useState } from 'react'
import { connect } from 'react-redux'
import { PowerUnitModal } from '../components/modals'
import PowerUnitActions from '../components/PowerUnitActions'
import Table from '../../../components/Table/Table'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const PowerUnitsPage = () => {
  const { t } = useTranslation('common')
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )
  const powerUnitsList = [
    {
      id: 1,
      name: 'test',
      ipAddress: '127.0.0.1',
      RPCServer: 'RPC1',
      room: '1/1',
      model: 'APC 7922',
      status: 'on',
    },
    {
      id: 2,
      name: 'test',
      ipAddress: '127.0.0.1',
      RPCServer: 'RPC1',
      room: '1/2',
      model: 'APC 7922',
      status: 'off',
    },
    {
      id: 3,
      name: 'test',
      ipAddress: '127.0.0.1',
      RPCServer: 'RPC1',
      room: '1/3',
      model: 'Avocent PM3000',
      status: 'failed',
    },
  ]

  const powerUnitsArray = powerUnitsList.map(powerUnit => {
    return {
      ...powerUnit,
      action: <PowerUnitActions preset={powerUnit} />,
    }
  })

  const powerUnits = {
    columns: [
      {
        label: 'ID',
        field: t('pages.powerUnits.id'),
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.powerUnits.name'),
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.powerUnits.IPAddress'),
        field: 'ipAddress',
        sort: 'asc',
      },
      {
        label: t('pages.powerUnits.RPCServer'),
        field: 'RPCServer',
        sort: 'asc',
      },
      {
        label: t('pages.powerUnits.room'),
        field: 'room',
        sort: 'asc',
      },
      {
        label: t('pages.powerUnits.model'),
        field: 'model',
        sort: 'asc',
      },
      {
        label: t('pages.powerUnits.status'),
        field: 'status',
        sort: 'asc',
      },
      {
        label: t('pages.powerUnits.action'),
        field: 'action',
      },
    ],
    rows: powerUnitsArray,
  }

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.powerUnits.powerUnits')}</h3>
          </div>
        </div>
        <PowerUnitModal />
        <div className={'mining_preset__body'}>
          <Table
            className={'powerUnit__table table table-striped'}
            data={powerUnits}
            responsive
            noBottomColumns
            firstColumnCheckbox
            stickyHeader
            total={powerUnitsList.length}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  powerUnitList: store.presets.powerUnitList,
})

export default connect(mapStateToProps, null)(PowerUnitsPage)
