import React, { useState } from 'react'
import RoomModalEdit from './modals/RoomModalEdit'
import { connect } from 'react-redux'
import * as actions from '../actions'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { useTranslation } from 'react-i18next'

const RoomActions = props => {
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const { t } = useTranslation('common')
  const [showRoom, setShowRoom] = useState(false)

  const toggleRoomEdit = () => {
    setShowRoom(!showRoom)
  }

  const deleteRoomFunc = async () => {
    setShowAlertDelete(false)
    props.deleteRoom({ roomId: +props.room.id })
  }
  return (
    <div className={'actions__items'}>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertDelete}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          deleteRoomFunc()
        }}
        onCancel={() => {
          setShowAlertDelete(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        {t('pages.dcManagement.roomRemovedPermanently')}
      </SweetAlert>
      <RoomModalEdit toggle={toggleRoomEdit} show={showRoom} room={props.room} />
      <div
        className={'actions__item'}
        tooltip={t('pages.dcManagement.deleteRoom')}
        flow='left'
        onClick={() => {
          setShowAlertDelete(true)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  deleteRoom: actions.deleteRoom,
}

export default connect(null, mapDispatchToProps)(RoomActions)
