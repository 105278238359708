import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, Label, Input } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import UiRangeSlider from '../../../../components/UI/RangeSlider'
import RangeSliderSpecial from '../../../../components/UI/RangeSliderSpecial'
import * as actions from '../../../RigsData/actions'
import * as actionsProfiles from '../../actions'
import Select, { components } from 'react-select'
import SweetAlert from 'react-bootstrap-sweetalert'
import SelectWithCryptoImages from '../../../../components/UI/SelectWithCryptoImages'
import { Trans, useTranslation } from 'react-i18next'
import CheckGpuErrorMessage from '../../../Workers/components/modals/CheckGpuErrorMessage'
import Switch from 'react-switch'
import GPUBLockMobile from '../../../Workers/components/modals/GPUBLockMobile'
import GPUBLockMobileSelect from '../../../Workers/components/modals/GPUBLockMobileSelect'
import SaveProfileDialog from '../../../Workers/components/modals/SaveProfileDialog'

const OverclockingProfileModalEdit = props => {
  const advancedPage = props.profile && props.profile.type === 'advanced'
  const modal = false
  const { t } = useTranslation('common')
  const [coreClock, setCoreClock] = useState('')
  const [memoryClock, setMemoryClock] = useState('')
  const [powerLimit, setPowerLimit] = useState('')
  const [fan, setFan] = useState('')
  const [coreClockAMD, setCoreClockAMD] = useState('')
  const [memoryClockAMD, setMemoryClockAMD] = useState('')

  const [coreVddcAMD, setCoreVddcAMD] = useState('')
  const [memoryVddcAMD, setMemoryVddcAMD] = useState('')
  const [fanAMD, setFanAMD] = useState('')
  const [action, setAction] = useState('on_fly')
  const [dpm, setDPM] = useState('1')
  const [mdpm, setMDPM] = useState('1')
  const [socFrequency, setSocFrequency] = useState('')
  const [memControlVoltage, setmemControlVoltage] = useState('')
  const [socVDDmax, setsocVDDmax] = useState('')
  const [haveAMD1000, setHaveAMD1000] = useState(false)
  const [differentCards, setDifferentCards] = useState(false)
  const [differentManufacturer, setDifferentManufacturer] = useState(false)
  const [manufacturer, setManufacturer] = useState(false)

  const [slots, setSlots] = useState([])

  useEffect(() => {
    let maxRigSlot = 0
    if (props.profile) {
      const slotsOptions = props.overclockingProfilesList
        .filter(profile => profile.profileType === 'forMining' && profile.rigId == props.profile.rigId)
        .reverse()
        .map(profile => {
          if (maxRigSlot < profile.presetSlotNumber) maxRigSlot = profile.presetSlotNumber
          return {
            value: profile.presetSlotNumber,
            label: 'Slot ' + profile.presetSlotNumber,
            description: profile.slotDescription,
          }
        })
      slotsOptions.push({
        value: +maxRigSlot + 1,
        label: 'Slot ' + (+maxRigSlot + 1),
        description: '',
      })
      setSlots(slotsOptions)
    }

    if (
      props.profile &&
      props.profile.type === 'general' &&
      (props.profile.gpuClock !== null || props.profile.powerLimit !== null)
    ) {
      setCoreClock(props.profile.gpuClock || '')
      setCoreClockAMD(props.profile.gpuClock || '')
      setMemoryClock(props.profile.memClock || '')
      setMemoryClockAMD(props.profile.memClock || '')
      setPowerLimit(props.profile.powerLimit || '')
      setMemoryVddcAMD(props.profile.memVddc || '')
      setCoreVddcAMD(props.profile.coreVddc || '')
      setFan(props.profile.fan || '')
      setFanAMD(props.profile.fan || '')
      setSocFrequency(props.profile.socFrequency || '')
      setmemControlVoltage(props.profile.memControlVoltage || '')
      setsocVDDmax(props.profile.socVDDmax || '')

      if (props.profile.action) {
        setAction(props.profile.action)
      }
      setDPM(props.profile.dpm)
      setMDPM(props.profile.mdpm)
    }
    setAlgorithm(
      props.profile && props.profile.overclockingAlgo
        ? {
            label: props.profile.overclockingAlgo,
            value: props.profile.overclockingAlgo,
            coins:
              props.algoCoinsList &&
              props.algoCoinsList.find(algo => algo.algo === props.profile.overclockingAlgo) &&
              props.algoCoinsList.find(algo => algo.algo === props.profile.overclockingAlgo).coins,
          }
        : null,
    )
    if (props.profile && props.profile.amdmemorytweak) {
      setAmdmemorytweak(true)
      setAmdmemorytweakString2(props.profile.amdmemorytweak)
      setAmdmemorytweakString(props.profile.amdmemorytweak)
    } else {
      setAmdmemorytweak(false)
    }
    if (props.profile && props.profile.onGodAnEthArgPill) {
      setOhGodAnETHlagementPill(true)
      setOhGodAnETHlagementPillString(props.profile.onGodAnEthArgPill)
      setOhGodAnETHlagementPillString2(props.profile.onGodAnEthArgPill)
    } else {
      setOhGodAnETHlagementPill(false)
    }
    if (props.profile && props.profile.autoTune) {
      setAutoTune(true)
      setAutoTuneEditString(props.profile.autoTune)
      setAutoTuneEditString2(props.profile.autoTune)
    } else {
      setAutoTune(false)
    }

    const nvidia = []
    const amd = []
    const videoCardsNames = []
    if (props.profile && props.profile.overclockingCards && props.profile.overclockingCards.length > 0) {
      props.profile.overclockingCards.forEach(card => {
        if (card.cardType === 'nvidia') {
          nvidia.push(card.id)
        } else if (card.cardType === 'amd') {
          amd.push(card.id)
        }
        if (
          videoCardsNames.length === 0 ||
          (videoCardsNames.length > 0 && videoCardsNames[0] !== card.gpuName.toLowerCase())
        )
          videoCardsNames.push(card.gpuName.toLowerCase())
        if (
          card.cardType.toLowerCase() === 'amd' &&
          (card.gpuName.includes('5300') ||
            card.gpuName.includes('5500') ||
            card.gpuName.includes('5600') ||
            card.gpuName.includes('5700') ||
            card.gpuName.includes('6300') ||
            card.gpuName.includes('6400') ||
            card.gpuName.includes('6500') ||
            card.gpuName.includes('6600') ||
            card.gpuName.includes('6650') ||
            card.gpuName.includes('6700') ||
            card.gpuName.includes('6750') ||
            card.gpuName.includes('6800') ||
            card.gpuName.includes('6900') ||
            card.gpuName.includes('6950'))
        ) {
          setHaveAMD1000(true)
        }
      })
      //console.log(videoCardsNames)
      if (videoCardsNames.length > 1) setDifferentCards(true)

      if (nvidia.length > 0 && amd.length > 0) setDifferentManufacturer(true)
    }
  }, [props.algoCoinsList, props.profile])

  const setApplyAction = e => {
    setAction(e.target.value)
  }

  const [cardsForEach, setCardsForEach] = useState([])

  const checkCardsFunc = (val, card, param) => {
    const checkCard = cardsForEach.find(c => +c.id === +card.id)
    if (!checkCard) {
      setCardsForEach([...cardsForEach, card])
    } else {
      const updatedCards = cardsForEach.map(c => {
        if (+c.id === +card.id) c[param] = val
        return c
      })
      setCardsForEach(updatedCards)
    }
  }

  const setCoreClockSpecial = (value, card) => {
    checkCardsFunc(value, card, 'gpuClock')
    card.gpuClock = value
  }
  const setMemoryClockSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memClock')
    card.memClock = value
  }
  const setPowerLimitSpecial = (value, card) => {
    checkCardsFunc(value, card, 'powerLimit')
    card.powerLimit = value
  }
  const setFanSpecial = (value, card) => {
    checkCardsFunc(value, card, 'fan')
    card.fan = value
  }
  const setCoreVddcSpecial = (value, card) => {
    checkCardsFunc(value, card, 'coreVddc')
    card.coreVddc = value
  }
  const setMemVddcSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memVddc')
    card.memVddc = value
  }
  const setDPMVddcSpecial = (event, card) => {
    checkCardsFunc(event.target.value, card, 'dpm')
    card.dpm = event.target.value
  }
  const setMDPMVddcSpecial = (event, card) => {
    checkCardsFunc(event.target.value, card, 'mdpm')
    card.mdpm = event.target.value
  }

  const setSocFrequencySpecial = (value, card) => {
    checkCardsFunc(value, card, 'socFrequency')
    card.socFrequency = value
  }

  const setmemControlVoltageSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memControlVoltage')
    card.memControlVoltage = value
  }
  const setsocVDDmaxSpecial = (value, card) => {
    checkCardsFunc(value, card, 'socVDDmax')
    card.socVDDmax = value
  }
  const [showCardsErrorAlertProfileSave, setShowCardsErrorAlertProfileSave] = useState(false)
  const [showCardsErrorAlertProfileUpdate, setShowCardsErrorAlertProfileUpdate] = useState(false)
  const [showCardsErrorAlertProfileUpdateApply, setShowCardsErrorAlertProfileUpdateApply] = useState(false)
  const [gpuProfileSaveModal, setGpuProfileSaveModal] = useState(false)
  const [gpuProfileUpdateModal, setGpuProfileUpdateModal] = useState(false)
  const [gpuProfileUpdateApplyModal, setGpuProfileUpdateApplyModal] = useState(false)
  const [cardErrors, setErrorStrings] = useState([])

  const toggleGpuErrMessProfileSave = () => {
    setGpuProfileSaveModal(!gpuProfileSaveModal)
  }
  const toggleGpuErrMessProfileUpdate = () => {
    setGpuProfileUpdateModal(!gpuProfileUpdateModal)
  }
  const toggleGpuErrMessProfileUpdateApply = () => {
    setGpuProfileUpdateApplyModal(!gpuProfileUpdateApplyModal)
  }
  const toggleSaveDialog = () => {
    setShowSaveDefault(!showSaveDefault)
  }

  const answerYesSetForGpuCheckProfileUpdate = async () => {
    await UpdateProfileGeneral()
    setGpuProfileUpdateModal(!gpuProfileUpdateModal)
  }

  const answerYesSetForGpuCheckProfileUpdateApply = async () => {
    await UpdateProfileGeneral()
    await props.applyOverclockingProfile({
      rigId: parseInt(props.profile.rigId),
      id: parseInt(props.profile.id),
    })
    setGpuProfileUpdateApplyModal(!gpuProfileUpdateApplyModal)
  }

  const answerYesSetForGpuCheckProfileSave = async () => {
    await SaveProfileGeneral()
    setGpuProfileSaveModal(!gpuProfileSaveModal)
  }

  const [algorithm, setAlgorithm] = useState('')
  const locked = false
  const [amdmemorytweak, setAmdmemorytweak] = useState(false)
  const [amdmemorytweakEdit, setAmdmemorytweakEdit] = useState(false)
  const [amdmemorytweakString, setAmdmemorytweakString] = useState('amdmemorytweak --REF 30')
  const [amdmemorytweakString2, setAmdmemorytweakString2] = useState('amdmemorytweak --REF 30')
  const [ohGodAnETHlagementPill, setOhGodAnETHlagementPill] = useState(false)
  const [ohGodAnETHlagementPillEdit, setOhGodAnETHlagementPillEdit] = useState(false)
  const [ohGodAnETHlagementPillString, setOhGodAnETHlagementPillString] = useState('OhGodAnETHlagement')
  const [ohGodAnETHlagementPillString2, setOhGodAnETHlagementPillString2] = useState('OhGodAnETHlagement')

  const setAlgo = option => {
    //console.log('option: ', option)
    setAlgorithm(option)
  }

  const resetAmdmemorytweak = () => {
    setAmdmemorytweakString2(amdmemorytweakString)
    setAmdmemorytweakEdit(false)
  }
  const saveAmdmemorytweak = () => {
    setAmdmemorytweakString(amdmemorytweakString2)
    setAmdmemorytweakEdit(false)
  }
  const resetOhGodAnETHlagementPill = () => {
    setOhGodAnETHlagementPillString2(ohGodAnETHlagementPillString)
    setOhGodAnETHlagementPillEdit(false)
  }
  const saveOhGodAnETHlagementPill = () => {
    setOhGodAnETHlagementPillString(ohGodAnETHlagementPillString2)
    setOhGodAnETHlagementPillEdit(false)
  }

  const [autoTune, setAutoTune] = useState(false)
  const [autoTuneEdit, setAutoTuneEdit] = useState(false)
  const [autoTuneEditString, setAutoTuneEditString] = useState('')
  const [autoTuneEditString2, setAutoTuneEditString2] = useState('')
  const [p0, setP0] = useState(false)

  const resetAutoTune = () => {
    setAutoTuneEditString2(autoTuneEditString)
    setAutoTuneEdit(false)
  }
  const saveAutoTune = () => {
    setAutoTuneEditString(autoTuneEditString2)
    setAutoTuneEdit(false)
  }

  const [profileName, setProfileName] = useState('')
  const [profileType, setProfileType] = useState(null)
  const [presetSlotNumber, setpresetSlotNumber] = useState(null)
  const [slotDescription, setSlotDescription] = useState('')

  const SaveProfile = async (profileName = '', profileType, presetSlotNumber = null, slotDescription = null) => {
    setProfileName(profileName)
    setProfileType(profileType)
    setpresetSlotNumber(presetSlotNumber)
    setSlotDescription(slotDescription)
    if (advancedPage === true || locked) {
      await SaveProfileAdvanced(profileName, profileType, presetSlotNumber, slotDescription)
      setShowSaveDefault(false)
    } else {
      const needsCards = []
      props.profile.overclockingCards.forEach(card => {
        needsCards.push(card)
      })
      const checkGpu = needsCards.every(
        card => card.gpuName === needsCards[0].gpuName && card.memInfo === needsCards[0].memInfo,
      )

      if (!checkGpu) {
        setGpuProfileSaveModal(true)
      } else {
        await SaveProfileGeneral(profileName, profileType, presetSlotNumber, slotDescription)
        setShowSaveDefault(false)
      }
    }
  }

  const SaveProfileAdvanced = async (profileName, profileType, presetSlotNumber, slotDescription) => {
    const cards = []

    props.profile.overclockingCards.forEach(card => {
      if (card.cardType === 'amd') {
        cards.push({
          cardId: card.cardId,
          memClock: card.memClock.toString(),
          gpuClock: card.gpuClock.toString(),
          fan: card.fan.toString(),
          coreVddc: card.coreVddc.toString(),
          memVddc: card.memVddc.toString(),
          dpm: card.dpm.toString(),
          mdpm: card.mdpm.toString(),
          socFrequency: card.socFrequency.toString(),
          memControlVoltage: card.memControlVoltage.toString(),
          socVDDmax: card.socVDDmax.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      } else {
        cards.push({
          cardId: card.cardId,
          powerLimit: card.powerLimit.toString(),
          gpuClock: card.gpuClock.toString(),
          memClock: card.memClock.toString(),
          fan: card.fan.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      }
    })

    const result = await props.saveOverclockingProfile({
      rigId: parseInt(props.profile.rigId),
      name: profileName,
      profileType: profileType,
      presetSlotNumber: presetSlotNumber,
      slotDescription: slotDescription,
      type: 'advanced',
      action,
      overclockingAlgo: algorithm ? algorithm.value : null,
      onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
      amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
      autoTune: autoTune === true ? autoTuneEditString : '',
      overclockingCards: cards,
    })
    console.log(result)
    if (result) {
      props.profile.id = result.id
      props.profile.name = profileName
      if (profileType && profileType === 'forMining') {
        let changeCurrentSlot = false
        const slotsOptions = slots.map(slot => {
          if (slot.value === presetSlotNumber) {
            if (slot.description != '') changeCurrentSlot = true
            slot.description = slotDescription
          }
          return slot
        })
        if (!changeCurrentSlot)
          slotsOptions.push({
            value: +slots.length + 1,
            label: 'Slot ' + (+slots.length + 1),
            description: '',
          })
        setSlots(slotsOptions)
      }
    }
  }

  const SaveProfileGeneral = async (profileName, profileType, presetSlotNumber, slotDescription) => {
    if (props.profile.cardType === 'amd') {
      const result = await props.saveOverclockingProfile({
        rigId: parseInt(props.profile.rigId),
        name: profileName,
        type: 'general',
        cardType: 'amd',
        profileType: profileType,
        presetSlotNumber: presetSlotNumber,
        slotDescription: slotDescription,
        gpuName: props.profile.gpuName,
        memClock: memoryClockAMD.toString(),
        gpuClock: coreClockAMD.toString(),
        fan: fanAMD.toString(),
        coreVddc: coreVddcAMD.toString(),
        memVddc: memoryVddcAMD.toString(),
        dpm: dpm.toString(),
        mdpm: mdpm.toString(),
        socFrequency: socFrequency ? socFrequency.toString() : null,
        memControlVoltage: memControlVoltage ? memControlVoltage.toString() : null,
        socVDDmax: socVDDmax ? socVDDmax.toString() : null,
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
      if (result) {
        props.profile.id = result.id
        props.profile.name = profileName
        if (profileType && profileType === 'forMining') {
          let changeCurrentSlot = false
          const slotsOptions = slots.map(slot => {
            if (slot.value === presetSlotNumber) {
              if (slot.description != '') changeCurrentSlot = true
              slot.description = slotDescription
            }
            return slot
          })
          if (!changeCurrentSlot)
            slotsOptions.push({
              value: +slots.length + 1,
              label: 'Slot ' + (+slots.length + 1),
              description: '',
            })
          setSlots(slotsOptions)
        }
      }
    }
    if (props.profile.cardType === 'nvidia') {
      const result = await props.saveOverclockingProfile({
        rigId: parseInt(props.profile.rigId),
        name: profileName,
        type: 'general',
        cardType: 'nvidia',
        profileType: profileType,
        presetSlotNumber: presetSlotNumber,
        slotDescription: slotDescription,
        gpuName: props.profile.gpuName,
        powerLimit: powerLimit.toString(),
        gpuClock: coreClock.toString(),
        memClock: memoryClock.toString(),
        fan: fan.toString(),
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
      if (result) {
        props.profile.id = result.id
        props.profile.name = profileName
        if (profileType && profileType === 'forMining') {
          let changeCurrentSlot = false
          const slotsOptions = slots.map(slot => {
            if (slot.value === presetSlotNumber) {
              if (slot.description != '') changeCurrentSlot = true
              slot.description = slotDescription
            }
            return slot
          })
          if (!changeCurrentSlot)
            slotsOptions.push({
              value: +slots.length + 1,
              label: 'Slot ' + (+slots.length + 1),
              description: '',
            })
          setSlots(slotsOptions)
        }
      }
    }
  }

  const UpdateProfile = async (apply = false) => {
    if (advancedPage === true || locked) {
      await UpdateProfileAdvanced()
      await props.applyOverclockingProfile({
        rigId: parseInt(props.profile.rigId),
        id: parseInt(props.profile.id),
      })
    } else {
      const needsCards = []
      props.profile.overclockingCards.forEach(card => {
        needsCards.push(card)
      })
      const checkGpu = needsCards.every(
        card => card.gpuName === needsCards[0].gpuName && card.memInfo === needsCards[0].memInfo,
      )

      if (!checkGpu) {
        if (apply) {
          setGpuProfileUpdateApplyModal(true)
        } else {
          setGpuProfileUpdateModal(true)
        }
      } else {
        await UpdateProfileGeneral()
        await props.applyOverclockingProfile({
          rigId: parseInt(props.profile.rigId),
          id: parseInt(props.profile.id),
        })
      }
    }
    props.toggle()
  }

  const UpdateProfileGeneral = async () => {
    if (props.profile.cardType === 'amd') {
      await props.updateOverclockingProfile({
        id: props.profile.id,
        rigId: parseInt(props.profile.rigId),
        name: props.profile.name,
        profileType: props.profile.profileType,
        presetSlotNumber: props.profile.presetSlotNumber,
        slotDescription: props.profile.slotDescription,
        type: 'general',
        cardType: 'amd',
        gpuName: props.profile.gpuName,
        memClock: memoryClockAMD.toString(),
        gpuClock: coreClockAMD.toString(),
        fan: fanAMD.toString(),
        coreVddc: coreVddcAMD.toString(),
        memVddc: memoryVddcAMD.toString(),
        dpm: dpm.toString(),
        mdpm: mdpm.toString(),
        socFrequency: socFrequency ? socFrequency.toString() : null,
        memControlVoltage: memControlVoltage ? memControlVoltage.toString() : null,
        socVDDmax: socVDDmax ? socVDDmax.toString() : null,
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
    }
    if (props.profile.cardType === 'nvidia') {
      await props.updateOverclockingProfile({
        id: props.profile.id,
        rigId: parseInt(props.profile.rigId),
        name: props.profile.name,
        profileType: props.profile.profileType,
        presetSlotNumber: props.profile.presetSlotNumber,
        slotDescription: props.profile.slotDescription,
        type: 'general',
        cardType: 'nvidia',
        gpuName: props.profile.gpuName,
        powerLimit: powerLimit.toString(),
        gpuClock: coreClock.toString(),
        memClock: memoryClock.toString(),
        fan: fan.toString(),
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
    }
  }
  const UpdateProfileAdvanced = async () => {
    const cards = []

    props.profile.overclockingCards.forEach(card => {
      if (card.cardType === 'amd') {
        cards.push({
          cardId: card.cardId,
          memClock: card.memClock.toString(),
          gpuClock: card.gpuClock.toString(),
          fan: card.fan.toString(),
          coreVddc: card.coreVddc.toString(),
          memVddc: card.memVddc.toString(),
          dpm: card.dpm.toString(),
          mdpm: card.mdpm.toString(),
          socFrequency: card.socFrequency.toString(),
          memControlVoltage: card.memControlVoltage.toString(),
          socVDDmax: card.socVDDmax.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      } else {
        cards.push({
          cardId: card.cardId,
          powerLimit: card.powerLimit.toString(),
          gpuClock: card.gpuClock.toString(),
          memClock: card.memClock.toString(),
          fan: card.fan.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      }
    })

    await props.updateOverclockingProfile({
      id: props.profile.id,
      rigId: parseInt(props.profile.rigId),
      name: props.profile.name,
      profileType: props.profile.profileType,
      presetSlotNumber: props.profile.presetSlotNumber,
      slotDescription: props.profile.slotDescription,
      type: 'advanced',
      action,
      overclockingAlgo: algorithm ? algorithm.value : null,
      onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
      amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
      autoTune: autoTune === true ? autoTuneEditString : '',
      overclockingCards: cards,
      p0: p0 === true ? p0 : false,
    })
  }

  const saveAndApply = async () => {
    await UpdateProfile(true)
  }

  const [profileRigs, setProfileRigs] = useState([])
  const [profileRigsSelect, setProfileRigsSelect] = useState([])
  const [showAlertOverClockPresets, setShowAlertOverClockPresets] = useState(false)
  const [showSaveDefault, setShowSaveDefault] = useState(false)

  const checkWhatClicked = e => {
    setLastClickedElement(e.target)
  }

  const [lastClickedElement, setLastClickedElement] = useState(null)
  const [lastClickedElementClickCount, setLastClickedElementClickCount] = useState(0)

  const doubleCardUp = (e, card, number, property) => {
    number--
    let clickCount = 0
    if (e.target === lastClickedElement) clickCount = lastClickedElementClickCount + +1
    const sum = number - clickCount
    if (sum > -1) {
      if (props.profile.overclockingCards[sum]) {
        if (property === 'gpuClock') props.profile.overclockingCards[sum].gpuClock = card.gpuClock
        if (property === 'memClock') props.profile.overclockingCards[sum].memClock = card.memClock
        if (property === 'coreVddc') props.profile.overclockingCards[sum].coreVddc = card.coreVddc
        if (property === 'fan') props.profile.overclockingCards[sum].fan = card.fan
        if (property === 'dpm') props.profile.overclockingCards[sum].dpm = card.dpm
        if (property === 'mdpm') props.profile.overclockingCards[sum].mdpm = card.mdpm
        if (property === 'powerLimit') props.profile.overclockingCards[sum].powerLimit = card.powerLimit
        if (property === 'memVddc') props.profile.overclockingCards[sum].memVddc = card.memVddc
      }
    }
    setLastClickedElementClickCount(clickCount)
  }

  const doubleCardDown = (e, card, number, property) => {
    number++
    let clickCount = 0
    if (e.target === lastClickedElement) clickCount = lastClickedElementClickCount + +1
    const sum = number + +clickCount
    if (props.profile.overclockingCards && sum <= props.profile.overclockingCards.length) {
      if (props.profile.overclockingCards[sum]) {
        if (property === 'gpuClock') props.profile.overclockingCards[sum].gpuClock = card.gpuClock
        if (property === 'memClock') props.profile.overclockingCards[sum].memClock = card.memClock
        if (property === 'coreVddc') props.profile.overclockingCards[sum].coreVddc = card.coreVddc
        if (property === 'fan') props.profile.overclockingCards[sum].fan = card.fan
        if (property === 'dpm') props.profile.overclockingCards[sum].dpm = card.dpm
        if (property === 'mdpm') props.profile.overclockingCards[sum].mdpm = card.mdpm
        if (property === 'powerLimit') props.profile.overclockingCards[sum].powerLimit = card.powerLimit
        if (property === 'memVddc') props.profile.overclockingCards[sum].memVddc = card.memVddc
      }
    }
    setLastClickedElementClickCount(clickCount)
  }

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <Label className={'checkboxLabel'}>
            <input name={'logs'} type={'checkbox'} checked={profileRigs.find(rig => rig === props.data.value)} />
            {props.label}
          </Label>
        </components.Option>
      </div>
    )
  }

  const selectRig = option => {
    if (option && option.length > 0) {
      const rigs = []
      option.forEach(rig => {
        rigs.push(rig.value)
      })
      setProfileRigs(rigs)
    } else {
      setProfileRigs([])
    }
    setProfileRigsSelect(option)
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'actions__item'} tooltip={t('pages.overclocking.editProfile')} flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <SweetAlert
        title={t('pages.workers.notAllParametersSpecified')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showCardsErrorAlertProfileUpdate}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          setShowCardsErrorAlertProfileUpdate(false)
          UpdateProfile()
        }}
        onCancel={() => {
          setShowCardsErrorAlertProfileUpdate(false)
        }}
        confirmBtnText={t('sweetAlerts.yes')}
        calcelBtnText={t('sweetAlerts.no')}
        showCloseButton={false}
      >
        <div className={'videoCards_errors'}>
          {cardErrors.map(error => {
            return <div>{error}</div>
          })}
        </div>
        <h5>{t('pages.workers.wouldYouLikeContinueAnyway')}</h5>
      </SweetAlert>
      <SweetAlert
        title={t('pages.workers.notAllParametersSpecified')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showCardsErrorAlertProfileSave}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          toggleSaveDialog()
          setShowCardsErrorAlertProfileSave(false)
        }}
        onCancel={() => {
          setShowCardsErrorAlertProfileSave(false)
        }}
        confirmBtnText={t('sweetAlerts.yes')}
        calcelBtnText={t('sweetAlerts.no')}
        showCloseButton={false}
      >
        <div className={'videoCards_errors'}>
          {cardErrors.map(error => {
            return <div>{error}</div>
          })}
        </div>
        <h5>{t('pages.workers.wouldYouLikeContinueAnyway')}</h5>
      </SweetAlert>
      <SweetAlert
        title={t('pages.workers.notAllParametersSpecified')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showCardsErrorAlertProfileUpdateApply}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          saveAndApply()
          setShowCardsErrorAlertProfileUpdateApply(false)
        }}
        onCancel={() => {
          setShowCardsErrorAlertProfileUpdateApply(false)
        }}
        confirmBtnText={t('sweetAlerts.yes')}
        calcelBtnText={t('sweetAlerts.no')}
        showCloseButton={false}
      >
        <div className={'videoCards_errors'}>
          {cardErrors.map(error => {
            return <div>{error}</div>
          })}
        </div>
        <h5>{t('pages.workers.wouldYouLikeContinueAnyway')}</h5>
      </SweetAlert>

      <SweetAlert
        title={t('pages.overclocking.confirmOverclockingRigsWithProfile') + ': ' + props.profile && props.profile.name}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertOverClockPresets}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        style={{
          overflow: 'visible',
        }}
        customClass={'sweet_alert_with_select'}
        onConfirm={() => {
          saveAndApply()
          setShowAlertOverClockPresets(false)
        }}
        onCancel={() => {
          setShowAlertOverClockPresets(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        <Form className={'modal-body'}>
          <Row>
            <Col xs={12} className={'form-group text-left mt-10'}>
              <h6>{t('pages.overclocking.selectRigs')}</h6>
              <Select
                placeholder={t('inputs.select')}
                options={
                  props.profile &&
                  props.rigsList
                    .filter(rig => {
                      //rig.videoCards && rig.videoCards.length > 0 && console.log(rig)
                      return (
                        rig.videoCards &&
                        rig.videoCards.length > 0 &&
                        (rig.id === props.profile.rigId ||
                          rig.videoCards[0].gpuName === props.profile.gpuName ||
                          (rig.videoCards.length > 1 && rig.videoCards[1].gpuName === props.profile.gpuName))
                      )
                    })
                    .map(rig => {
                      return {
                        ...rig,
                        label: '(' + rig.id + ') ' + rig.name,
                        value: rig.id,
                      }
                    })
                }
                value={profileRigsSelect}
                isMulti
                closeMenuOnSelect={false}
                onChange={selectRig}
                hideSelectedOptions={false}
                classNamePrefix={'form_control_react_select'}
                className={props.error ? 'invalid-select select-with-checkbox' : 'select-with-checkbox'}
                components={{
                  Option,
                }}
                allowSelectAll={true}
              />
            </Col>
          </Row>
        </Form>
      </SweetAlert>
      <SaveProfileDialog
        name={props.profile ? props.profile.name : ''}
        profileType={props.profile ? props.profile.profileType : ''}
        slotDescription={props.profile ? props.profile.slotDescription : ''}
        open={showSaveDefault}
        toggle={toggleSaveDialog}
        save={SaveProfile}
        advancedPage={advancedPage}
        slots={slots}
        slot={
          props.profile
            ? {
                value: props.profile.presetSlotNumber,
                label: 'Slot ' + props.profile.presetSlotNumber,
                description: props.profile.slotDescription,
              }
            : null
        }
      />
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        onClick={e => e.stopPropagation()}
        className={'modal-dialog-centered popup popup_large card_turbo '}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.overclocking.overclockingProfile')} {props.profile ? props.profile.name : ''}
        </ModalHeader>
        <ModalBody>
          <CheckGpuErrorMessage
            gpuModal={gpuProfileSaveModal}
            toggleGpuErrMess={toggleGpuErrMessProfileSave}
            answerYesSet={answerYesSetForGpuCheckProfileSave}
          />
          <CheckGpuErrorMessage
            gpuModal={gpuProfileUpdateModal}
            toggleGpuErrMess={toggleGpuErrMessProfileUpdate}
            answerYesSet={answerYesSetForGpuCheckProfileUpdate}
          />
          <CheckGpuErrorMessage
            gpuModal={gpuProfileUpdateApplyModal}
            toggleGpuErrMess={toggleGpuErrMessProfileUpdateApply}
            answerYesSet={answerYesSetForGpuCheckProfileUpdateApply}
          />

          <Tabs>
            <TabList className={'card_tabs'}>
              {props.profile && props.profile.type === 'general' ? (
                <Tab>{t('pages.workers.general')}</Tab>
              ) : (
                <Tab>{t('pages.workers.advanced')}</Tab>
              )}
              <Tab>{t('pages.workers.additional')}</Tab>
            </TabList>

            {props.profile && props.profile.type === 'general' ? (
              <TabPanel>
                <Form className={'overclocking-default'}>
                  <div className={'description'}>{t('pages.workers.generalTip')}</div>

                  {props.profile && props.profile.cardType !== 'amd' ? (
                    <div className={'general_nvidia'}>
                      <h6>NVIDIA</h6>
                      <Row>
                        <Col>
                          <div className={'form-group advanced_card_field'}>
                            <Label>
                              {coreClock >= 500 ? t('pages.workers.lockCoreClock') : t('pages.workers.coreClockOffset')}
                              , {t('pages.workers.Mhz')}
                            </Label>
                            <UiRangeSlider
                              default={coreClock}
                              setValue={setCoreClock}
                              min={-9999}
                              max={9999}
                              step={1}
                              disabled={locked}
                            />
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>{t('pages.workers.memoryClock')}</Label>
                            <UiRangeSlider
                              default={memoryClock}
                              setValue={setMemoryClock}
                              min={-9999}
                              max={9999}
                              step={1}
                              disabled={locked}
                            />
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>{t('pages.workers.powerLimit')}</Label>
                            <UiRangeSlider
                              default={powerLimit}
                              setValue={setPowerLimit}
                              min={1}
                              max={999}
                              step={1}
                              disabled={locked}
                            />
                          </div>
                          <div className={'form-group advanced_card_field'}>
                            <Label>{t('pages.workers.FAN')}</Label>
                            <UiRangeSlider
                              default={fan}
                              isFan={true}
                              setValue={setFan}
                              min={1}
                              max={100}
                              step={1}
                              disabled={locked}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  {props.profile && props.profile.cardType === 'amd' ? (
                    <div className={'general_amd'}>
                      <h6>AMD</h6>
                      <div>
                        {haveAMD1000 ? (
                          <div>
                            <Row className={'advanced_card_row'}>
                              <Col>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.coreClock')}</Label>
                                  <UiRangeSlider
                                    default={coreClockAMD}
                                    setValue={setCoreClockAMD}
                                    min={-9999}
                                    max={9999}
                                    step={1}
                                    disabled={locked}
                                  />
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.memoryClock')}</Label>
                                  <UiRangeSlider
                                    default={memoryClockAMD}
                                    setValue={setMemoryClockAMD}
                                    min={-9999}
                                    max={9999}
                                    step={1}
                                    disabled={locked}
                                  />
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.coreVDDC')}</Label>
                                  <UiRangeSlider
                                    default={coreVddcAMD}
                                    setValue={setCoreVddcAMD}
                                    min={1}
                                    max={9999}
                                    step={1}
                                    disabled={locked}
                                  />
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.memoryVDDC')}</Label>
                                  <UiRangeSlider
                                    default={memoryVddcAMD}
                                    setValue={setMemoryVddcAMD}
                                    min={1}
                                    max={9999}
                                    step={1}
                                    disabled={locked}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className={'advanced_card_row'}>
                              <Col>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.socFrequency')}</Label>
                                  <UiRangeSlider
                                    default={socFrequency}
                                    setValue={setSocFrequency}
                                    min={1}
                                    max={9999}
                                    step={1}
                                    disabled={locked}
                                  />
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.soCVDDMax')}</Label>
                                  <UiRangeSlider
                                    default={socVDDmax}
                                    setValue={setsocVDDmax}
                                    min={1}
                                    max={9999}
                                    step={1}
                                    disabled={locked}
                                  />
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.memoryController')}</Label>
                                  <UiRangeSlider
                                    default={memControlVoltage}
                                    setValue={setmemControlVoltage}
                                    min={1}
                                    max={9999}
                                    step={1}
                                    disabled={locked}
                                  />
                                </div>
                                <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                  <Label>{t('pages.workers.FAN')}</Label>
                                  <UiRangeSlider
                                    default={fanAMD}
                                    setValue={setFanAMD}
                                    isFan={true}
                                    min={1}
                                    max={100}
                                    step={1}
                                    disabled={locked}
                                  />
                                </div>
                                <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                  <Label>{t('pages.workers.DPM')}</Label>
                                  <select
                                    className={'form-control'}
                                    onChange={event => setDPM(event.target.value)}
                                    defaultValue={dpm}
                                    disabled={locked}
                                  >
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                  </select>
                                </div>
                                <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                  <Label>{t('pages.workers.MDPM')}</Label>
                                  <select
                                    className={'form-control'}
                                    onChange={event => setMDPM(event.target.value)}
                                    defaultValue={mdpm}
                                    disabled={locked}
                                  >
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <Row className={'advanced_card_row'}>
                            <Col>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.coreClock')}</Label>
                                <UiRangeSlider
                                  default={coreClockAMD}
                                  setValue={setCoreClockAMD}
                                  min={-9999}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.memoryClock')}</Label>
                                <UiRangeSlider
                                  default={memoryClockAMD}
                                  setValue={setMemoryClockAMD}
                                  min={-9999}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.coreVDDC')}</Label>
                                <UiRangeSlider
                                  default={coreVddcAMD}
                                  setValue={setCoreVddcAMD}
                                  min={1}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.memoryVDDC')}</Label>
                                <UiRangeSlider
                                  default={memoryVddcAMD}
                                  setValue={setMemoryVddcAMD}
                                  min={1}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.memoryController')}</Label>
                                <UiRangeSlider
                                  default={memControlVoltage}
                                  setValue={setmemControlVoltage}
                                  min={1}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                <Label>{t('pages.workers.FAN')}</Label>
                                <UiRangeSlider
                                  default={fanAMD}
                                  setValue={setFanAMD}
                                  isFan={true}
                                  min={1}
                                  max={100}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                <Label>{t('pages.workers.DPM')}</Label>
                                <select
                                  className={'form-control'}
                                  onChange={event => setDPM(event.target.value)}
                                  defaultValue={dpm}
                                  disabled={locked}
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>5</option>
                                  <option value={6}>6</option>
                                  <option value={7}>7</option>
                                </select>
                              </div>
                              <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                <Label>{t('pages.workers.MDPM')}</Label>
                                <select
                                  className={'form-control'}
                                  onChange={event => setMDPM(event.target.value)}
                                  defaultValue={mdpm}
                                  disabled={locked}
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <Row className={'card_note'}>
                    <Col sm={12}>
                      <Trans i18nKey='pages.workers.generalNotes' ns={'common'}>
                        Note: General tab normally used to overclock similar cards at same settings.
                        <br />
                        Advanced tab used for overclocking each card separately with specific settings
                        <br />
                        Additional tab used for adding special parameters/Pills and select mining algorithm for specific
                        settings
                      </Trans>
                    </Col>
                  </Row>
                </Form>
              </TabPanel>
            ) : (
              <TabPanel onClick={checkWhatClicked}>
                <Form className={'d-md-none advanced-mobile'}>
                  <div className={'description'}>{t('pages.workers.individualSettingsForEachCard')}</div>
                  {differentManufacturer ? (
                    <div className={'selectManufacturer'}>
                      {t('pages.workers.AMDNVIDIACardsDetected')}
                      <br />
                      <Switch
                        uncheckedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 10,
                              color: '#fff',
                              paddingRight: 10,
                            }}
                          >
                            {' '}
                            NVIDIA
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 10,
                              color: '#fff',
                              paddingLeft: 10,
                            }}
                          >
                            {' '}
                            AMD
                          </div>
                        }
                        onColor='#ff0000'
                        offColor='#008000'
                        onChange={() => {
                          setManufacturer(!manufacturer)
                        }}
                        checked={manufacturer}
                        height={20}
                        width={60}
                      />
                    </div>
                  ) : null}

                  {props.profile &&
                  props.profile.overclockingCards &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'nvidia') &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'nvidia').length > 0 &&
                  (!differentManufacturer || (differentManufacturer && !manufacturer)) ? (
                    <div>
                      <h5>{t('pages.workers.coreClock')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'nvidia')
                          .map(card => {
                            if (!card.gpuClock) card.gpuClock = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.gpuClock}
                                key={card.id + 'gpuClock'}
                                setValueFunc={setCoreClockSpecial}
                                card={card}
                                min={-9999}
                                max={9999}
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.memoryClock')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'nvidia')
                          .map(card => {
                            if (!card.memClock) card.memClock = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.memClock}
                                key={card.id + 'memClock'}
                                setValueFunc={setMemoryClockSpecial}
                                card={card}
                                min={-9999}
                                max={9999}
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.powerLimit')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'nvidia')
                          .map(card => {
                            if (!card.powerLimit) card.powerLimit = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.powerLimit}
                                key={card.id + 'powerLimit'}
                                setValueFunc={setPowerLimitSpecial}
                                card={card}
                                min={1}
                                max={999}
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.FAN')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'nvidia')
                          .map(card => {
                            if (!card.fan) card.fan = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.fan}
                                key={card.id + 'fan'}
                                setValueFunc={setFanSpecial}
                                card={card}
                                isFan={true}
                                min={1}
                                max={100}
                              />
                            )
                          })}
                      </div>
                    </div>
                  ) : null}

                  {props.profile &&
                  props.profile.overclockingCards &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'amd') &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'amd').length > 0 &&
                  (!differentManufacturer || (differentManufacturer && manufacturer)) ? (
                    <div>
                      <h5>{t('pages.workers.coreClock')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'amd')
                          .map(card => {
                            if (!card.gpuClock) card.gpuClock = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.gpuClock}
                                key={card.id + 'gpuClock'}
                                setValueFunc={setCoreClockSpecial}
                                card={card}
                                min={-9999}
                                max={9999}
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.memoryClock')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'amd')
                          .map(card => {
                            if (!card.memClock) card.memClock = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.memClock}
                                key={card.id + 'memClock'}
                                setValueFunc={setMemoryClockSpecial}
                                card={card}
                                min={-9999}
                                max={9999}
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.coreVDDC')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'amd')
                          .map(card => {
                            if (!card.coreVddc) card.coreVddc = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.coreVddc}
                                key={card.id + 'coreVddc'}
                                setValueFunc={setCoreVddcSpecial}
                                card={card}
                                min={1}
                                max={9999}
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.memoryVDDC')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'amd')
                          .map(card => {
                            if (!card.memVddc) card.memVddc = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.memVddc}
                                key={card.id + 'memVddc'}
                                setValueFunc={setMemVddcSpecial}
                                card={card}
                                min={1}
                                max={9999}
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.memoryController')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'amd')
                          .map(card => {
                            if (!card.memControlVoltage) card.memControlVoltage = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.memControlVoltage}
                                key={card.id + 'memVddc'}
                                setValueFunc={setmemControlVoltageSpecial}
                                card={card}
                                min={1}
                                max={9999}
                              />
                            )
                          })}
                      </div>
                      {haveAMD1000 && (
                        <>
                          <h5>{t('pages.workers.socFrequency')}</h5>
                          <div className={'gpu-row'}>
                            {props.profile.overclockingCards
                              .filter(
                                card =>
                                  card.cardType === 'amd' &&
                                  (card.gpuName.includes('5300') ||
                                    card.gpuName.includes('5500') ||
                                    card.gpuName.includes('5600') ||
                                    card.gpuName.includes('5700') ||
                                    card.gpuName.includes('6300') ||
                                    card.gpuName.includes('6400') ||
                                    card.gpuName.includes('6500') ||
                                    card.gpuName.includes('6600') ||
                                    card.gpuName.includes('6650') ||
                                    card.gpuName.includes('6700') ||
                                    card.gpuName.includes('6750') ||
                                    card.gpuName.includes('6800') ||
                                    card.gpuName.includes('6900') ||
                                    card.gpuName.includes('6950')),
                              )
                              .map(card => {
                                if (!card.socFrequency) card.socFrequency = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.socFrequency}
                                    key={card.id + 'socFrequency'}
                                    setValueFunc={setSocFrequencySpecial}
                                    card={card}
                                    min={1}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.soCVDDMax')}</h5>
                          <div className={'gpu-row'}>
                            {props.profile.overclockingCards
                              .filter(
                                card =>
                                  card.cardType === 'amd' &&
                                  (card.gpuName.includes('5300') ||
                                    card.gpuName.includes('5500') ||
                                    card.gpuName.includes('5600') ||
                                    card.gpuName.includes('5700') ||
                                    card.gpuName.includes('6300') ||
                                    card.gpuName.includes('6400') ||
                                    card.gpuName.includes('6500') ||
                                    card.gpuName.includes('6600') ||
                                    card.gpuName.includes('6650') ||
                                    card.gpuName.includes('6700') ||
                                    card.gpuName.includes('6750') ||
                                    card.gpuName.includes('6800') ||
                                    card.gpuName.includes('6900') ||
                                    card.gpuName.includes('6950')),
                              )
                              .map(card => {
                                if (!card.socVDDmax) card.socVDDmax = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.socFrequency}
                                    key={card.id + 'socVDDmax'}
                                    setValueFunc={setsocVDDmaxSpecial}
                                    card={card}
                                    min={1}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                        </>
                      )}
                      <h5>{t('pages.workers.DPM')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'amd')
                          .map(card => {
                            if (
                              card.gpuName.includes('5300') ||
                              card.gpuName.includes('5500') ||
                              card.gpuName.includes('5600') ||
                              card.gpuName.includes('5700') ||
                              card.gpuName.includes('6300') ||
                              card.gpuName.includes('6400') ||
                              card.gpuName.includes('6500') ||
                              card.gpuName.includes('6600') ||
                              card.gpuName.includes('6650') ||
                              card.gpuName.includes('6700') ||
                              card.gpuName.includes('6750') ||
                              card.gpuName.includes('6800') ||
                              card.gpuName.includes('6900') ||
                              card.gpuName.includes('6950')
                            )
                              card.card1000series = true
                            if (!card.dpm) card.dpm = 1
                            return (
                              <GPUBLockMobileSelect
                                defaultValue={card.dpm}
                                key={card.id + 'dpm'}
                                setValueFunc={setDPMVddcSpecial}
                                card={card}
                                options={
                                  card.card1000series
                                    ? [
                                        { value: 1, label: 1 },
                                        { value: 2, label: 2 },
                                      ]
                                    : [
                                        { value: 1, label: 1 },
                                        { value: 2, label: 2 },
                                        { value: 3, label: 3 },
                                        { value: 4, label: 4 },
                                        { value: 5, label: 5 },
                                        { value: 6, label: 6 },
                                        { value: 7, label: 7 },
                                      ]
                                }
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.MDPM')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'amd')
                          .map(card => {
                            if (!card.mdpm) card.mdpm = 1
                            return (
                              <GPUBLockMobileSelect
                                defaultValue={card.mdpm}
                                key={card.id + 'mdpm'}
                                setValueFunc={setMDPMVddcSpecial}
                                card={card}
                                options={[
                                  { value: 1, label: 1 },
                                  { value: 2, label: 2 },
                                ]}
                              />
                            )
                          })}
                      </div>
                      <h5>{t('pages.workers.FAN')}</h5>
                      <div className={'gpu-row'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'amd')
                          .map(card => {
                            if (!card.fan) card.fan = ''
                            return (
                              <GPUBLockMobile
                                defaultValue={card.fan}
                                key={card.id + 'fan'}
                                setValueFunc={setFanSpecial}
                                card={card}
                                isFan={true}
                                min={1}
                                max={100}
                              />
                            )
                          })}
                      </div>
                    </div>
                  ) : null}
                </Form>
                <Form className={'d-none d-md-block'}>
                  <div className={'description'}>{t('pages.workers.individualSettingsForEachCard')}</div>
                  {differentManufacturer ? (
                    <div className={'selectManufacturer'}>
                      {t('pages.workers.AMDNVIDIACardsDetected')}
                      <br />
                      <Switch
                        uncheckedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 10,
                              color: '#fff',
                              paddingRight: 10,
                            }}
                          >
                            {' '}
                            NVIDIA
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 10,
                              color: '#fff',
                              paddingLeft: 10,
                            }}
                          >
                            {' '}
                            AMD
                          </div>
                        }
                        onColor='#ff0000'
                        offColor='#008000'
                        onChange={() => {
                          setManufacturer(!manufacturer)
                        }}
                        checked={manufacturer}
                        height={20}
                        width={60}
                      />
                    </div>
                  ) : null}
                  {props.profile &&
                  props.profile.overclockingCards &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'nvidia') &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'nvidia').length > 0 &&
                  (!differentManufacturer || (differentManufacturer && !manufacturer)) ? (
                    <div>
                      <Row className={'hide-on-mobile'}>
                        <Col sm={12} md={3} className={'form-group gpu_info first'}></Col>
                        <Col sm={12} md={9}>
                          <Row className={'advanced_card_row'}>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.coreClock')}</Label>
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.memoryClock')}</Label>
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.powerLimit')}</Label>
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>{t('pages.workers.FAN')}</Label>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      <div className={'detailed_cards'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType === 'nvidia')
                          .map((card, key) => {
                            if (!card.gpuClock) card.gpuClock = ''
                            if (!card.memClock) card.memClock = ''
                            if (!card.powerLimit) card.powerLimit = ''
                            if (!card.fan) card.fan = ''
                            if (!card.coreVddc) card.coreVddc = ''
                            if (!card.memVddc) card.memVddc = ''
                            if (!card.dpm) card.dpm = 1
                            if (!card.mdpm) card.mdpm = 1

                            return (
                              <Row key={key}>
                                <Col sm={12} md={3} className={'form-group gpu_info first'}>
                                  <div className={'gpu_number'}>
                                    GPU{card.cardNum} - {card.cardSlot ? card.cardSlot.substring(5) : ''} -{' '}
                                    <span className={'gpu_name'}>
                                      <span style={{ color: '#949fff' }}>
                                        {card.vendorName && card.vendorName !== 'null' ? card.vendorName : ''}{' '}
                                      </span>
                                      {card.gpuName.replace(/NVIDIA/g, '')}
                                    </span>
                                  </div>
                                  <div className={'gpu_memory'}>
                                    {!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}
                                    {card.memInfo && <span className='card__memType'> • {card.memInfo}</span>}
                                    {card.memType && <span className='card__memType'> {card.memType}</span>}
                                    {!(card.memInfo || card.memType) &&
                                      card.cardType === 'nvidia' &&
                                      card.DefPwrLimit && (
                                        <span className='card__power'>
                                          {' • '}
                                          {card.nvGpuPwrMin}W / {card.DefPwrLimit}W / {card.nvGpuPwrMax}W
                                        </span>
                                      )}
                                  </div>
                                  {card.memInfo && card.memType && (
                                    <div className={'gpu_memory'}>
                                      {card.cardType === 'nvidia' && card.DefPwrLimit && (
                                        <span className='card__power'>
                                          {card.nvGpuPwrMin}W / {card.DefPwrLimit}W / {card.nvGpuPwrMax}W
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Col>
                                <Col sm={12} md={9}>
                                  <Row className={'advanced_card_row'}>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.coreClock')}</Label>
                                      <RangeSliderSpecial
                                        default={card.gpuClock}
                                        setValue={setCoreClockSpecial}
                                        min={-9999}
                                        max={9999}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'gpuClock')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'gpuClock')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.memoryClock')}</Label>
                                      <RangeSliderSpecial
                                        default={card.memClock}
                                        setValue={setMemoryClockSpecial}
                                        min={-9999}
                                        max={9999}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1 + props.profile.overclockingCards.length}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'memClock')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'memClock')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>

                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.powerLimit')}</Label>
                                      <RangeSliderSpecial
                                        default={card.powerLimit}
                                        setValue={setPowerLimitSpecial}
                                        min={1}
                                        max={999}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1 + props.profile.overclockingCards.length * 2}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'powerLimit')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'powerLimit')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>

                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.FAN')}</Label>
                                      <RangeSliderSpecial
                                        default={card.fan}
                                        isFan={true}
                                        setValue={setFanSpecial}
                                        min={1}
                                        max={100}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1 + props.profile.overclockingCards.length * 4}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'fan')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'fan')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                </Col>
                                <hr />
                              </Row>
                            )
                          })}
                      </div>
                    </div>
                  ) : null}
                  {props.profile &&
                  props.profile.overclockingCards &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'amd') &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'amd').length > 0 &&
                  (!differentManufacturer || (differentManufacturer && manufacturer)) ? (
                    <div>
                      <Row className={'hide-on-mobile'}>
                        <Col sm={12} md={3} className={'form-group gpu_info first'}></Col>
                        <Col sm={12} md={9}>
                          <Row className={'advanced_card_row'}>
                            <div className={'form-group advanced_card_field'}>
                              <Label>
                                <Trans i18nKey='pages.workers.coreClockBR' ns={'common'}>
                                  Core Clock,
                                  <br />
                                  Mhz
                                </Trans>
                              </Label>
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>
                                <Trans i18nKey='pages.workers.memoryClockBR' ns={'common'}>
                                  Memory Clock,
                                  <br />
                                  Mhz
                                </Trans>
                              </Label>
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>
                                <Trans i18nKey='pages.workers.coreVDDCBR' ns={'common'}>
                                  coreVDDC,
                                  <br />
                                  mV
                                </Trans>
                              </Label>
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>
                                <Trans i18nKey='pages.workers.memoryVDDCBR' ns={'common'}>
                                  memVDDC,
                                  <br />
                                  mV
                                </Trans>
                              </Label>
                            </div>
                            <div className={'form-group advanced_card_field'}>
                              <Label>
                                <Trans i18nKey='pages.workers.memoryControllerBR' ns={'common'}>
                                  mem Contrl,
                                  <br />
                                  mV
                                </Trans>
                              </Label>
                            </div>
                            {haveAMD1000 ? (
                              <div className={'form-group advanced_card_field'}>
                                <Label>
                                  <Trans i18nKey='pages.workers.socFrequencyBR' ns={'common'}>
                                    SoC Frequency,
                                    <br />
                                    Mhz
                                  </Trans>
                                </Label>
                              </div>
                            ) : null}
                            {haveAMD1000 ? (
                              <div className={'form-group advanced_card_field'}>
                                <Label>
                                  <Trans i18nKey='pages.workers.soCVDDMaxBR' ns={'common'}>
                                    Soc VDDMax,
                                    <br />
                                    mV
                                  </Trans>
                                </Label>
                              </div>
                            ) : null}

                            <div className={'form-group advanced_card_field advanced_card_field__select'}>
                              <Label>{t('pages.workers.FAN')}</Label>
                            </div>
                            <div className={'form-group advanced_card_field advanced_card_field__select'}>
                              <Label>{t('pages.workers.DPM')}</Label>
                            </div>
                            <div className={'form-group advanced_card_field  advanced_card_field__select'}>
                              <Label>{t('pages.workers.MDPM')}</Label>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      <div className={'detailed_cards'}>
                        {props.profile.overclockingCards
                          .filter(card => card.cardType !== 'nvidia')
                          .map((card, key) => {
                            if (!card.gpuClock) card.gpuClock = ''
                            if (!card.memClock) card.memClock = ''
                            if (!card.powerLimit) card.powerLimit = ''
                            if (!card.fan) card.fan = ''
                            if (!card.coreVddc) card.coreVddc = ''
                            if (!card.memVddc) card.memVddc = ''
                            if (!card.memControlVoltage) card.memControlVoltage = ''
                            if (!card.socFrequency) card.socFrequency = ''
                            if (!card.socVDDmax) card.socVDDmax = ''
                            if (!card.dpm) card.dpm = 1
                            if (!card.mdpm) card.mdpm = 1
                            let card1000series = false
                            if (
                              card.gpuName.includes('5300') ||
                              card.gpuName.includes('5500') ||
                              card.gpuName.includes('5600') ||
                              card.gpuName.includes('5700') ||
                              card.gpuName.includes('6300') ||
                              card.gpuName.includes('6400') ||
                              card.gpuName.includes('6500') ||
                              card.gpuName.includes('6600') ||
                              card.gpuName.includes('6650') ||
                              card.gpuName.includes('6700') ||
                              card.gpuName.includes('6750') ||
                              card.gpuName.includes('6800') ||
                              card.gpuName.includes('6900') ||
                              card.gpuName.includes('6950')
                            )
                              card1000series = true
                            return (
                              <Row key={key}>
                                <Col sm={12} md={3} className={'form-group gpu_info first'}>
                                  <div className={'gpu_number'}>
                                    GPU{card.cardNum} - {card.cardSlot ? card.cardSlot.substring(5) : ''} -
                                    <span className={'gpu_name'} style={{ color: '#e4909c' }}>
                                      <span style={{ color: '#949fff' }}>
                                        {card.vendorName && card.vendorName !== 'null' ? card.vendorName : ''}{' '}
                                      </span>
                                      {card.gpuName.replace(/AMD/g, '')}
                                    </span>
                                  </div>
                                  <div className={'gpu_memory'}>
                                    {!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}
                                    {card.memInfo && card.memInfo !== 'null' ? (
                                      <span className='card__memInfo card__power'>{' - ' + card.memInfo}</span>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={12} md={9}>
                                  <Row className={'advanced_card_row'}>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.coreClock')}</Label>
                                      <RangeSliderSpecial
                                        default={card.gpuClock}
                                        setValue={setCoreClockSpecial}
                                        min={-9999}
                                        max={9999}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'gpuClock')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'gpuClock')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.memoryClock')}</Label>
                                      <RangeSliderSpecial
                                        default={card.memClock}
                                        setValue={setMemoryClockSpecial}
                                        min={-9999}
                                        max={9999}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1 + props.profile.overclockingCards.length}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'memClock')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'memClock')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.coreVDDC')}</Label>
                                      <RangeSliderSpecial
                                        default={card.coreVddc}
                                        setValue={setCoreVddcSpecial}
                                        min={1}
                                        max={9999}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1 + props.profile.overclockingCards.length * 2}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'coreVddc')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'coreVddc')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.memoryVDDC')}</Label>
                                      <RangeSliderSpecial
                                        default={card.memVddc}
                                        setValue={setMemVddcSpecial}
                                        min={1}
                                        max={9999}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1 + props.profile.overclockingCards.length * 3}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'memVddc')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'memVddc')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>

                                    <div className={'form-group advanced_card_field'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.memoryController')}</Label>
                                      <RangeSliderSpecial
                                        default={card.memControlVoltage}
                                        setValue={setmemControlVoltageSpecial}
                                        min={1}
                                        max={9999}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1 + props.profile.overclockingCards.length * 4}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'memControlVoltage')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'memControlVoltage')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>

                                    {haveAMD1000 ? (
                                      <div
                                        className={
                                          card1000series
                                            ? 'form-group advanced_card_field'
                                            : 'form-group advanced_card_field hide-on-mobile'
                                        }
                                      >
                                        {card1000series ? (
                                          <>
                                            <Label className={'show-on-mobile'}>
                                              {t('pages.workers.socFrequency')}
                                            </Label>
                                            <RangeSliderSpecial
                                              default={card.socFrequency}
                                              setValue={setSocFrequencySpecial}
                                              min={1}
                                              max={9999}
                                              step={1}
                                              card={card}
                                              tabindex={key + +1 + props.profile.overclockingCards.length * 5}
                                            />
                                            <div className={'advanced_card_field__arrows'}>
                                              <div
                                                className={'advanced_card_field__arrows_arrow'}
                                                onClick={e => doubleCardUp(e, card, key, 'socFrequency')}
                                              >
                                                <i className='fas fa-chevron-double-up'></i>
                                              </div>
                                              <div
                                                className={'advanced_card_field__arrows_arrow'}
                                                onClick={e => doubleCardDown(e, card, key, 'socFrequency')}
                                              >
                                                <i className='fas fa-chevron-double-down'></i>
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    ) : null}
                                    {haveAMD1000 ? (
                                      <div
                                        className={
                                          card1000series
                                            ? 'form-group advanced_card_field'
                                            : 'form-group advanced_card_field hide-on-mobile'
                                        }
                                      >
                                        {card1000series ? (
                                          <>
                                            <Label className={'show-on-mobile'}>{t('pages.workers.soCVDDMax')}</Label>
                                            <RangeSliderSpecial
                                              default={card.socVDDmax}
                                              setValue={setsocVDDmaxSpecial}
                                              min={1}
                                              max={9999}
                                              step={1}
                                              card={card}
                                              tabindex={key + +1 + props.profile.overclockingCards.length * 6}
                                            />
                                            <div className={'advanced_card_field__arrows'}>
                                              <div
                                                className={'advanced_card_field__arrows_arrow'}
                                                onClick={e => doubleCardUp(e, card, key, 'socVDDmax')}
                                              >
                                                <i className='fas fa-chevron-double-up'></i>
                                              </div>
                                              <div
                                                className={'advanced_card_field__arrows_arrow'}
                                                onClick={e => doubleCardDown(e, card, key, 'socVDDmax')}
                                              >
                                                <i className='fas fa-chevron-double-down'></i>
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    ) : null}

                                    <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.FAN')}</Label>
                                      <RangeSliderSpecial
                                        default={card.fan}
                                        setValue={setFanSpecial}
                                        isFan={true}
                                        min={1}
                                        max={100}
                                        step={1}
                                        card={card}
                                        tabindex={key + +1 + props.profile.overclockingCards.length * 7}
                                      />
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'fan')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'fan')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.DPM')}</Label>
                                      <select
                                        onChange={event => setDPMVddcSpecial(event, card)}
                                        value={card.dpm}
                                        tabIndex={key + +1 + props.profile.overclockingCards.length * 8}
                                        className={'form-control'}
                                      >
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        {!card1000series && (
                                          <>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                            <option value={7}>7</option>
                                          </>
                                        )}
                                      </select>
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'dpm')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'dpm')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                      <Label className={'show-on-mobile'}>{t('pages.workers.MDPM')}</Label>
                                      <select
                                        className={'form-control'}
                                        onChange={event => setMDPMVddcSpecial(event, card)}
                                        value={card.mdpm}
                                        tabIndex={key + +1 + props.profile.overclockingCards.length * 9}
                                      >
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                      </select>
                                      <div className={'advanced_card_field__arrows'}>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardUp(e, card, key, 'mdpm')}
                                        >
                                          <i className='fas fa-chevron-double-up'></i>
                                        </div>
                                        <div
                                          className={'advanced_card_field__arrows_arrow'}
                                          onClick={e => doubleCardDown(e, card, key, 'mdpm')}
                                        >
                                          <i className='fas fa-chevron-double-down'></i>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                </Col>
                                <hr />
                              </Row>
                            )
                          })}
                      </div>
                    </div>
                  ) : null}
                </Form>
              </TabPanel>
            )}
            <TabPanel>
              <Form>
                <div className={'description'}>{t('pages.workers.additionalOverclockingParameters')}</div>
                <div>
                  {props.profile &&
                  props.profile.overclockingCards &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'amd') &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'amd').length > 0 ? (
                    <div className={'cardTweak'}>
                      <Label className={'checkboxLabel'}>
                        <input
                          name={'rotateLogs'}
                          type={'checkbox'}
                          checked={amdmemorytweak}
                          onChange={() => {
                            setAmdmemorytweak(!amdmemorytweak)
                          }}
                        />
                        {t('pages.workers.amdmemorytweak')}
                      </Label>
                      <Button
                        color={'primary'}
                        disabled={!amdmemorytweak}
                        onClick={() => {
                          setAmdmemorytweakEdit(true)
                        }}
                      >
                        {t('pages.workers.edit')}
                      </Button>
                    </div>
                  ) : null}
                  {props.profile &&
                  props.profile.overclockingCards &&
                  props.profile.overclockingCards.filter(
                    card => card.cardType === 'nvidia' && card.gpuName.includes('1080'),
                  ) &&
                  props.profile.overclockingCards.filter(
                    card => card.cardType === 'nvidia' && card.gpuName.includes('1080'),
                  ).length > 0 ? (
                    <div className={'cardTweak'}>
                      <Label className={'checkboxLabel'}>
                        <input
                          name={'rotateLogs'}
                          type={'checkbox'}
                          checked={ohGodAnETHlagementPill}
                          onChange={() => {
                            setOhGodAnETHlagementPill(!ohGodAnETHlagementPill)
                          }}
                        />
                        {t('pages.workers.OhGodAnETHlagementPill')}
                      </Label>
                      <Button
                        color={'primary'}
                        disabled={!ohGodAnETHlagementPill}
                        onClick={() => {
                          setOhGodAnETHlagementPillEdit(true)
                        }}
                      >
                        {t('pages.workers.edit')}
                      </Button>
                    </div>
                  ) : null}

                  {props.profile &&
                  props.profile.overclockingCards &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'nvidia') &&
                  props.profile.overclockingCards.filter(card => card.cardType === 'nvidia').length > 0 ? (
                    <div className={'cardTweak'}>
                      <Label className={'checkboxLabel'}>
                        <input
                          name={'p0'}
                          type={'checkbox'}
                          checked={p0}
                          onChange={() => {
                            setP0(!p0)
                          }}
                        />
                        {t('pages.workers.P0mode')}
                      </Label>
                    </div>
                  ) : null}

                  <div className={'cardTweak'}>
                    <Label className={'checkboxLabel'}>
                      <input
                        name={'rotateLogs'}
                        type={'checkbox'}
                        checked={autoTune}
                        onChange={() => {
                          setAutoTune(!autoTune)
                        }}
                      />
                      {t('pages.workers.autoTune')}
                    </Label>
                    <Button
                      color={'primary'}
                      disabled={!autoTune}
                      onClick={() => {
                        setAutoTuneEdit(true)
                      }}
                    >
                      {t('pages.workers.edit')}
                    </Button>
                  </div>
                </div>
                <Row className={'card_algorithm'}>
                  <Col xs={12} sm={4} md={3}>
                    <Label>{t('pages.workers.algorithm')}</Label>
                    <SelectWithCryptoImages
                      name={'algo'}
                      placeholder={t('inputs.selectAlgo')}
                      hideSelectedOptions
                      classNamePrefix={'form_control_react_select'}
                      onchange={setAlgo}
                      value={algorithm}
                      options={props.algoCoinsList.map(algo => {
                        return {
                          ...algo,
                          label: algo.algo,
                          value: algo.algo,
                        }
                      })}
                    />
                  </Col>
                </Row>
                <div
                  className={'cardTweakString'}
                  style={{
                    height: amdmemorytweakEdit && amdmemorytweak ? '170px' : '0',
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                      <textarea
                        className='form-control'
                        name='amdmemorytweak'
                        value={amdmemorytweakString2}
                        onChange={e => {
                          setAmdmemorytweakString2(e.target.value)
                        }}
                      ></textarea>
                    </Col>
                    <Col xs={12} className={'cardTweakString__Buttons'}>
                      <Button color='primary' onClick={saveAmdmemorytweak}>
                        {t('pages.workers.save')}
                      </Button>
                      <Button color='secondary' onClick={resetAmdmemorytweak}>
                        {t('pages.workers.cancel')}
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div
                  className={'cardTweakString'}
                  style={{
                    height: ohGodAnETHlagementPillEdit && ohGodAnETHlagementPill ? '170px' : '0',
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                      <textarea
                        className='form-control'
                        name='ohGodAnETHlagementPilltweak'
                        value={ohGodAnETHlagementPillString2}
                        onChange={e => {
                          setOhGodAnETHlagementPillString2(e.target.value)
                        }}
                      ></textarea>
                    </Col>
                    <Col xs={12} className={'cardTweakString__Buttons'}>
                      <Button color='primary' onClick={saveOhGodAnETHlagementPill}>
                        {t('pages.workers.save')}
                      </Button>
                      <Button color='secondary' onClick={resetOhGodAnETHlagementPill}>
                        {t('pages.workers.cancel')}
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div
                  className={'cardTweakString'}
                  style={{
                    height: autoTuneEdit && autoTune ? '170px' : '0',
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                      <textarea
                        className='form-control'
                        name='ohGodAnETHlagementPilltweak'
                        value={autoTuneEditString2}
                        onChange={e => {
                          setAutoTuneEditString2(e.target.value)
                        }}
                      ></textarea>
                    </Col>
                    <Col xs={12} className={'cardTweakString__Buttons'}>
                      <Button color='primary' onClick={saveAutoTune}>
                        {t('pages.workers.save')}
                      </Button>
                      <Button color='secondary' onClick={resetAutoTune}>
                        {t('pages.workers.cancel')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </TabPanel>
          </Tabs>
          <div className={'actions_card'}>
            <Row>
              <Col sm={4} className={'radio_buttons'}>
                <div>
                  <input
                    type='radio'
                    id='apply_action1'
                    name='apply_action'
                    value='on_fly'
                    onChange={e => setApplyAction(e)}
                    defaultChecked={action === 'on_fly'}
                  />
                  <Label htmlFor='apply_action1'>{t('pages.workers.applyFly')}</Label>
                </div>
                <div>
                  <input
                    type='radio'
                    id='apply_action2'
                    name='apply_action'
                    value='restart'
                    onChange={e => setApplyAction(e)}
                    defaultChecked={action === 'restart'}
                  />
                  <Label htmlFor='apply_action2'>{t('pages.workers.restartAndApply')}</Label>
                </div>
                <div>
                  <input
                    type='radio'
                    id='apply_action3'
                    name='apply_action'
                    value='reboot'
                    onChange={e => setApplyAction(e)}
                    defaultChecked={action === 'reboot'}
                  />
                  <Label htmlFor='apply_action3'>{t('pages.workers.rebootAndApply')}</Label>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='success'
            onClick={() => {
              if (props.profile && props.profile.type === 'advanced') {
                const errorStrings = []
                setErrorStrings([])
                props.profile.overclockingCards.map(card => {
                  let errorString = ''
                  if (!card.gpuClock || card.gpuClock.length <= 0)
                    errorString.length > 0
                      ? (errorString += ', ' + t('pages.workers.coreClockError'))
                      : (errorString =
                          t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.coreClockError'))
                  if (!card.memClock || card.memClock.length <= 0)
                    errorString.length > 0
                      ? (errorString += ', ' + t('pages.workers.memoryClockError'))
                      : (errorString =
                          t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.memoryClockError'))
                  if (card.cardType === 'amd' && (!card.coreVddc || card.coreVddc.length <= 0))
                    errorString.length > 0
                      ? (errorString += ', ' + t('pages.workers.coreVDDCError'))
                      : (errorString = t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.coreVDDCError'))
                  if (errorString.length > 0) errorStrings.push(errorString + ' ' + t('pages.workers.notSpecified'))
                  return card
                })
                setErrorStrings(errorStrings)
                if (errorStrings.length > 0) {
                  setShowCardsErrorAlertProfileUpdate(true)
                  return false
                }
                UpdateProfile()
              } else {
                if (
                  (isNaN(parseFloat(coreVddcAMD)) ||
                    isNaN(parseFloat(memoryClockAMD)) ||
                    isNaN(parseFloat(coreClockAMD))) &&
                  (isNaN(parseFloat(memoryClock)) || isNaN(parseFloat(coreClock)))
                ) {
                  setErrorStrings([t('pages.workers.somethingNotSpecified')])
                  setShowCardsErrorAlertProfileUpdate(true)
                  return false
                } else {
                  UpdateProfile()
                }
              }
            }}
          >
            {t('pages.overclocking.save')}
          </Button>
          {props.profile && props.profile.type === 'advanced' ? (
            <Button
              color='primary'
              onClick={() => {
                setShowAlertOverClockPresets(true)
              }}
            >
              {t('pages.overclocking.saveApply')}
            </Button>
          ) : (
            <Button
              color='primary'
              onClick={() => {
                setShowAlertOverClockPresets(true)
              }}
            >
              {t('pages.overclocking.saveApply')}
            </Button>
          )}

          <Button
            color='success'
            onClick={() => {
              if (props.profile && props.profile.type === 'advanced') {
                const errorStrings = []
                setErrorStrings([])
                props.profile.overclockingCards.map(card => {
                  let errorString = ''
                  if (!card.gpuClock || card.gpuClock.length <= 0)
                    errorString.length > 0
                      ? (errorString += ', ' + t('pages.workers.coreClockError'))
                      : (errorString =
                          t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.coreClockError'))
                  if (!card.memClock || card.memClock.length <= 0)
                    errorString.length > 0
                      ? (errorString += ', ' + t('pages.workers.memoryClockError'))
                      : (errorString =
                          t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.memoryClockError'))
                  if (card.cardType === 'amd' && (!card.coreVddc || card.coreVddc.length <= 0))
                    errorString.length > 0
                      ? (errorString += ', ' + t('pages.workers.coreVDDCError'))
                      : (errorString = t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.coreVDDCError'))
                  if (errorString.length > 0) errorStrings.push(errorString + ' ' + t('pages.workers.notSpecified'))
                  return card
                })
                setErrorStrings(errorStrings)
                if (errorStrings.length > 0) {
                  setShowCardsErrorAlertProfileSave(true)
                  return false
                }
                toggleSaveDialog()
              } else {
                if (
                  (isNaN(parseFloat(coreVddcAMD)) ||
                    isNaN(parseFloat(memoryClockAMD)) ||
                    isNaN(parseFloat(coreClockAMD))) &&
                  (isNaN(parseFloat(memoryClock)) || isNaN(parseFloat(coreClock)))
                ) {
                  setErrorStrings([t('pages.workers.somethingNotSpecified')])
                  setShowCardsErrorAlertProfileSave(true)
                  return false
                } else {
                  toggleSaveDialog()
                }
              }
            }}
          >
            {t('pages.workers.saveAs')}
          </Button>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.workers.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = store => ({
  rigsList: store.rigs.rigsList,
  algoCoinsList: store.profiles.algoCoinsList,
  overclockingProfilesList: store.profiles.overclockingProfilesList,
})

const mapDispatchToProps = {
  overclocking: actions.overclocking,
  overclockingEach: actions.overclockingEach,
  saveOverclockingProfile: actionsProfiles.saveOverclockingProfile,
  setOverclockingProfilesList: actionsProfiles.setOverclockingProfilesList,
  updateOverclockingProfile: actionsProfiles.updateOverclockingProfile,
  applyOverclockingProfile: actionsProfiles.applyOverclockingProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverclockingProfileModalEdit)
