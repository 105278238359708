import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'
import Select from 'react-select'
import uuid from 'node-uuid'
import moment from 'moment/moment'

const LogsScheduleModal = props => {
  const { t } = useTranslation('common')

  useEffect(() => {
    props.show &&
      props.logsSchedule({
        id: props.schedule.id,
      })
  }, [props.show])
  const now = new Date().getTime()
  const getLogTime = timeData => {
    const time = Math.abs(timeData - now)
    let result, units
    if (time > 1000 * 60 * 60 * 24) {
      result = parseInt(time / (1000 * 60 * 60 * 24), 10)
      units = t('pages.workers.days')
    } else if (time > 1000 * 60 * 60) {
      result = parseInt(time / (1000 * 60 * 60), 10)
      units = t('pages.workers.hours')
    } else if (time > 1000 * 60) {
      result = parseInt(time / (1000 * 60), 10)
      units = t('pages.workers.minutes')
    } else {
      result = parseInt(time / 1000, 10)
      units = t('pages.workers.seconds')
    }
    return result + ' ' + units
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'actions__item'} tooltip={t('pages.schedule.logsSchedule')} flow='left' onClick={props.toggle}>
        <i className='fas fa-bars'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.schedule.logsSchedule')} {props.schedule.name}
        </ModalHeader>
        <ModalBody>
          <Row className={'scheduleLogs'}>
            {props.logs.map(log => {
              return (
                <div key={log.id} className={`active-log__row`}>
                  <span className='active-log__time'>{moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                  <p
                    key={uuid()}
                    className={`active-log
                  ${
                    log.action.toLowerCase().includes('create') ||
                    log.action.toLowerCase().includes('run') ||
                    log.action.toLowerCase().includes('success')
                      ? 'green'
                      : log.action.toLowerCase().includes('error')
                      ? 'red'
                      : log.action.toLowerCase().includes('powerof') ||
                        log.action.toLowerCase().includes('reboot') ||
                        log.action.toLowerCase().includes('automatic worker software reset') ||
                        log.action.toLowerCase().includes('stop')
                      ? 'orange'
                      : log.action.toLowerCase().includes('submitted')
                      ? 'white'
                      : ''
                  }`}
                  >
                    {log.action}
                  </p>
                  <p className='active-log__time active-log__time_ago'>
                    {getLogTime(Date.parse(log.createdAt))} {t('pages.workers.ago')}
                  </p>
                </div>
              )
            })}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.schedule.close')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  logs: store.schedule.scheduleLogs,
})

const mapDispatchToProps = {
  logsSchedule: actions.logsSchedule,
}

export default connect(mapStateToProps, mapDispatchToProps)(LogsScheduleModal)
