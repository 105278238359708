import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

import * as actions from '../../actions'
import CreateServerModal from './CreateServerModal'
import ServerActions from '../ServerActions'
import SweetAlert from 'react-bootstrap-sweetalert'
import Select from 'react-select'

const EditPoolModal = props => {
  const [title, setTitle] = useState(props.pool.title)
  const [sslPort, setSslPort] = useState(props.pool.sslPort)
  const [stratumPort, setStratumPort] = useState(props.pool.stratumPort)
  const [altStratumPort, setAltStratumPort] = useState(props.pool.altStratumPort)
  const [type, setType] = useState(props.pool.type)
  const [coins, setCoins] = useState(
    props.pool.coins && props.pool.coins.length > 0
      ? props.pool.coins.split(', ').map(coin => {
          return { label: coin, value: coin }
        })
      : [],
  )
  const [soloPort, setSoloPort] = useState(props.pool.soloPort)
  const [poolServers, setPoolServers] = useState(
    props.pool.poolServers
      ? props.pool.poolServers.map((server, index) => {
          return {
            ...server,
            order: index,
          }
        })
      : [],
  )
  const [poolServersOld] = useState(
    props.pool.poolServers
      ? props.pool.poolServers.map((server, index) => {
          return {
            ...server,
            order: index,
          }
        })
      : [],
  )
  const [serverOrder, setServerOrder] = useState(props.pool.poolServers ? props.pool.poolServers.length : 0)

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'title': {
        setTitle(e.target.value)
        return
      }
      case 'sslPort': {
        setSslPort(e.target.value)
        return
      }
      case 'stratumPort': {
        setStratumPort(e.target.value)
        return
      }
      case 'altStratumPort': {
        setAltStratumPort(e.target.value)
        return
      }
      case 'type': {
        setType(e.target.value)
        return
      }
      case 'coins': {
        setCoins(e.target.value)
        return
      }
      case 'soloPort': {
        setSoloPort(e.target.value)
        return
      }
      default:
        return
    }
  }

  const [errors, setErrors] = useState({})

  const submitFunc = () => {
    //setErrors({})
    const newErrors = {}
    if (title.length === 0) newErrors.title = { message: "Pool Title can't be empty" }
    if (poolServers.length === 0) newErrors.poolServers = { message: "Servers can't be empty" }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    setUpdateMethod('no')

    let different_servers = false
    if (poolServers.length !== poolServersOld.length) {
      different_servers = true
    } else {
      poolServers.map(server => {
        if (!poolServersOld.find(server_old => server_old.address === server.address)) different_servers = true
        return server
      })
      poolServersOld.map(server_old => {
        if (!poolServers.find(server => server_old.address === server.address)) different_servers = true
        return server_old
      })
    }

    if (different_servers) {
      setShowUpdateRigs(true)
    } else {
      updatePoolsFunc()
    }
  }

  const [showUpdateRigs, setShowUpdateRigs] = useState(false)
  const [updateMethod, setUpdateMethod] = useState('no')

  const updatePoolsFunc = () => {
    props.updatePool({
      id: props.pool.id,
      title: title,
      sslPort: sslPort,
      stratumPort: stratumPort,
      altStratumPort: altStratumPort,
      soloPort: soloPort,
      type: type,
      coins: coins.map(coin => coin.value).join(', '),
      updateRigs: updateMethod,
      poolServers: poolServers.map(server => {
        if (server.id) {
          return {
            id: server.id,
            country: server.country,
            address: server.address,
            type: server.type,
            coin: server.coin,
          }
        } else {
          return {
            country: server.country,
            address: server.address,
            type: server.type,
            coin: server.coin,
          }
        }
      }),
    })
    props.toggle()
  }

  const [showServerModal, setShowServerModal] = useState(false)
  const toggleServerModal = () => {
    setShowServerModal(!showServerModal)
  }

  const addServer = server => {
    server.order = serverOrder
    setPoolServers([...poolServers, server])
    setServerOrder(+serverOrder + +1)
  }
  const updateServer = server => {
    const index = poolServers.findIndex(fServer => fServer.order === server.order)
    setPoolServers([...poolServers.slice(0, index), server, ...poolServers.slice(index + 1)])
  }
  const removeServer = server => {
    setPoolServers(poolServers.filter(fServer => fServer !== server))
  }
  return (
    <div onClick={e => e.stopPropagation()}>
      <SweetAlert
        title='Confirm Update'
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        custom
        show={showUpdateRigs}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          updatePoolsFunc()
          setShowUpdateRigs(false)
        }}
        onCancel={() => {
          setShowUpdateRigs(false)
        }}
        confirmBtnText={'Confirm'}
        showCloseButton={true}
      >
        Workers which working on current Pool need update
        <div className={'update_pool_radio_buttons'}>
          <div>
            <input
              type='radio'
              id='update_action1'
              name='update_action'
              value='no'
              onChange={() => {
                setUpdateMethod('no')
              }}
              defaultChecked={true}
            />
            <Label htmlFor='update_action1'>Not Update</Label>
          </div>
          <div>
            <input
              type='radio'
              id='update_action2'
              name='update_action'
              value='reboot'
              onChange={() => {
                setUpdateMethod('reboot')
              }}
            />
            <Label htmlFor='update_action2'>Reboot and Update</Label>
          </div>
          <div>
            <input
              type='radio'
              id='update_action3'
              name='update_action'
              value='reboot_later'
              onChange={() => {
                setUpdateMethod('reboot_later')
              }}
            />
            <Label htmlFor='update_action3'>Update in Next Reboot</Label>
          </div>
        </div>
      </SweetAlert>
      <div className={'actions__item'} tooltip='Edit Pool' flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          Edit Pool
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>Title</Label>
                <Input
                  className={errors.title ? 'invalid' : ''}
                  type={'text'}
                  name={'title'}
                  onChange={onChange}
                  value={title}
                />
                <div className={'error'}>{errors.title && errors.title.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>SslPort</Label>
                <Input
                  className={errors.sslPort ? 'invalid' : ''}
                  type={'text'}
                  name={'sslPort'}
                  onChange={onChange}
                  value={sslPort}
                />
                <div className={'error'}>{errors.sslPort && errors.sslPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>StratumPort</Label>
                <Input
                  className={errors.stratumPort ? 'invalid' : ''}
                  type={'text'}
                  name={'stratumPort'}
                  onChange={onChange}
                  value={stratumPort}
                />
                <div className={'error'}>{errors.stratumPort && errors.stratumPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>AltStratumPort</Label>
                <Input
                  className={errors.altStratumPort ? 'invalid' : ''}
                  type={'text'}
                  name={'altStratumPort'}
                  onChange={onChange}
                  value={altStratumPort}
                />
                <div className={'error'}>{errors.altStratumPort && errors.altStratumPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>SoloPort</Label>
                <Input
                  className={errors.soloPort ? 'invalid' : ''}
                  type={'text'}
                  name={'soloPort'}
                  onChange={onChange}
                  value={soloPort}
                />
                <div className={'error'}>{errors.soloPort && errors.soloPort.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>Type</Label>
                <Input
                  className={errors.type ? 'invalid' : ''}
                  type={'text'}
                  name={'type'}
                  onChange={onChange}
                  value={type}
                />
                <div className={'error'}>{errors.type && errors.type.message}</div>
              </Col>
              <Col xs={12} className={'form-group'}>
                <Label>Coins</Label>
                <Select
                  classNamePrefix={errors.coins ? 'invalid form_control_react_select' : 'form_control_react_select'}
                  value={coins}
                  onChange={option => {
                    setCoins(option)
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  isMulti={true}
                  options={props.coinsList.map(coin => {
                    return { value: coin.nameSymbol, label: coin.nameSymbol }
                  })}
                />
                <div className={'error'}>{errors.coins && errors.coins.message}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={'form-group'}>
                <div className={'editPoolServers'}>
                  <h5>Servers</h5>
                  <CreateServerModal
                    key={poolServers + poolServers.length}
                    addServer={addServer}
                    show={showServerModal}
                    toggle={toggleServerModal}
                  />
                </div>
                <table className={'table table-striped table-bordered'}>
                  <thead className={'thead-light'}>
                    <tr>
                      <th>Country</th>
                      <th>Address</th>
                      <th>Type</th>
                      <th>Coin</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poolServers.map(server => {
                      return (
                        <tr key={server.order}>
                          <td>{server.country}</td>
                          <td>{server.address}</td>
                          <td>{server.type}</td>
                          <td>{server.coin}</td>
                          <td>
                            <ServerActions
                              key={server.order}
                              server={server}
                              updateServer={updateServer}
                              removeServer={removeServer}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className={'error'}>{errors.poolServers && errors.poolServers.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  coinsList: store.admin.coinsList,
  algoCoinsList: store.admin.algoCoinsList,
})

const mapDispatchToProps = {
  updatePool: actions.updatePool,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPoolModal)
