import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CustomInput, FormGroup, Label } from 'reactstrap'
import {
  changeLeftSidebarType,
  changeBodyAttribute,
  hideRightSidebar,
  changeLeftSidebarThemeFunction,
} from './cssFunction'

import { changeSidebarType, changeTopbarTheme, changeLayout, changeSidebarTheme } from '../store/actions'
//SimpleBar
import SimpleBar from 'simplebar-react'

const RightSidebar = props => {
  const { t } = useTranslation('common')
  const [sidebarType, setSidebarType] = useState(props.leftSideBarType)
  const [layoutWidth, setLayoutWidth] = useState(props.layoutWidth)
  const [sidebarTheme, setSidebarTheme] = useState(props.leftSideBarTheme)
  const propsArr = useMemo(() => {
    return {
      changeTopbarTheme: props.changeTopbarTheme,
      layoutType: props.layoutType,
      changeSidebarType: props.changeSidebarType,
    }
  }, [props.changeTopbarTheme, props.layoutType, props.changeSidebarType])

  const changeTopbarThemeFunction = theme => {
    changeBodyAttribute('data-topbar', theme)
  }
  // change layout
  useEffect(() => {
    const changeLayoutFunction = layout => {
      try {
        if (layout === 'horizontal') {
          propsArr.changeTopbarTheme('dark')
          document.body.removeAttribute('data-sidebar')
          document.body.removeAttribute('data-sidebar-size')
        } else {
          propsArr.changeTopbarTheme('dark')
        }
        changeBodyAttribute('data-layout', layout)
      } catch (error) {
        console.log(error)
      }
    }
    changeLayoutFunction(propsArr.layoutType)
  }, [propsArr])

  // change layout width
  useEffect(() => {
    /**
     * Changes the layout width
     */
    const changeLayoutWidthFunction = width => {
      try {
        if (width === 'boxed') {
          propsArr.changeSidebarType('icon')
        } else {
          propsArr.changeSidebarType('default')
        }
        changeBodyAttribute('data-layout-size', width)
      } catch (error) {
        console.log(error)
      }
    }
    changeLayoutWidthFunction(layoutWidth)
  }, [layoutWidth, propsArr])

  // change topbarTheme
  useEffect(() => {
    changeTopbarThemeFunction(props.topbarTheme)
  }, [props.topbarTheme])

  // change sidebarType
  useEffect(() => {
    changeLeftSidebarType(props.leftSideBarType)
    setSidebarType(props.leftSideBarType)
  }, [props.leftSideBarType])

  // change sidebarType
  useEffect(() => {
    changeLeftSidebarThemeFunction(props.leftSideBarTheme)
    setSidebarTheme(props.leftSideBarTheme)
  }, [props.leftSideBarTheme])

  /**
   * Hides the right sidebar
   */
  const hideRightbar = () => {
    hideRightSidebar()
  }

  const changeLayoutFunc = e => {
    if (e.target.checked) {
      props.changeLayout(e.target.value)
    }
  }

  /**
   * Changes layout width
   */
  const changeLayoutWidth = e => {
    if (e.target.checked) {
      setLayoutWidth(e.target.value)
    }
  }

  // change left sidebar design
  const changeLeftSidebarTypeFunc = e => {
    if (e.target.checked) {
      props.changeSidebarType(e.target.value)
    }
  }

  // change left sidebar theme
  const changeLeftSidebarTheme = e => {
    if (e.target.checked) {
      props.changeSidebarTheme(e.target.value)
    }
  }

  // change topbar theme
  const changeTopbarThemeFunc = e => {
    if (e.target.checked) {
      props.changeTopbarTheme(e.target.value)
    }
  }

  return (
    <React.Fragment>
      <div className='right-bar'>
        <SimpleBar style={{ height: '900px' }}>
          <div data-simplebar className='h-100'>
            <div className='rightbar-title px-3 py-4'>
              <Link to='#' onClick={hideRightbar} className='right-bar-toggle float-right'>
                <i className='mdi mdi-close noti-icon'></i>
              </Link>
              <h5 className='m-0'>{t('topBar.settings')}</h5>
            </div>

            <hr className='my-0' />

            <div className='p-4'>
              <FormGroup>
                <Label for='layout'>{t('topBar.layouts')}</Label>
                <div>
                  <CustomInput
                    type='radio'
                    id='verticalLayout'
                    name='layout'
                    label={t('topBar.vertical')}
                    value='vertical'
                    checked={props.layoutType === 'vertical'}
                    onChange={changeLayoutFunc}
                  />
                  <CustomInput
                    type='radio'
                    id='horizontalLayout'
                    name='layout'
                    label={t('topBar.horizontal')}
                    value='horizontal'
                    checked={props.layoutType === 'horizontal'}
                    onChange={changeLayoutFunc}
                  />
                </div>
              </FormGroup>

              <hr />

              <FormGroup>
                <Label for='layout'>{t('topBar.layoutWidth')}</Label>
                <div>
                  <CustomInput
                    type='radio'
                    id='fluid'
                    name='layoutWidth'
                    label={t('topBar.fluid')}
                    value='fluid'
                    checked={layoutWidth !== 'boxed'}
                    onChange={changeLayoutWidth}
                  />
                  <CustomInput
                    type='radio'
                    id='boxed'
                    name='layoutWidth'
                    label={t('topBar.boxed')}
                    value='boxed'
                    checked={layoutWidth === 'boxed'}
                    onChange={changeLayoutWidth}
                  />
                </div>
              </FormGroup>

              <hr />
              <FormGroup>
                <Label>{t('topBar.topbarTheme')}</Label>
                <div>
                  <CustomInput
                    type='radio'
                    id='topbar-light'
                    name='topbar-theme'
                    label={t('topBar.light')}
                    value='light'
                    checked={props.topbarTheme === 'light'}
                    onChange={changeTopbarThemeFunc}
                  />

                  <CustomInput
                    type='radio'
                    id='topbar-dark'
                    name='topbar-theme'
                    label={t('topBar.dark')}
                    value='dark'
                    checked={props.topbarTheme === 'dark'}
                    onChange={changeTopbarThemeFunc}
                  />
                </div>
              </FormGroup>

              {props.layoutType === 'vertical' ? (
                <React.Fragment>
                  <hr />
                  <FormGroup>
                    <Label>{t('topBar.leftSidebarType')}</Label>
                    <div>
                      <CustomInput
                        type='radio'
                        id='defaultSidebar'
                        name='type'
                        label='Default'
                        value='default'
                        checked={sidebarType === 'default'}
                        onChange={changeLeftSidebarTypeFunc}
                      />
                      <CustomInput
                        type='radio'
                        id='compactSidebar'
                        name='type'
                        label={t('topBar.compact')}
                        value='compact'
                        checked={sidebarType === 'compact'}
                        onChange={changeLeftSidebarTypeFunc}
                      />
                      <CustomInput
                        type='radio'
                        id='iconSidebar'
                        name='type'
                        label={t('topBar.icon')}
                        value='icon'
                        checked={sidebarType === 'icon'}
                        onChange={changeLeftSidebarTypeFunc}
                      />
                    </div>
                  </FormGroup>

                  <hr />

                  <FormGroup>
                    <Label>{t('topBar.leftSidebarTheme')}</Label>
                    <div>
                      <CustomInput
                        type='radio'
                        id='light'
                        name='theme'
                        label={t('topBar.light')}
                        value='light'
                        checked={sidebarTheme === 'light'}
                        onChange={changeLeftSidebarTheme}
                      />
                      <CustomInput
                        type='radio'
                        id='dark'
                        name='theme'
                        label={t('topBar.dark')}
                        value='dark'
                        checked={sidebarTheme === 'dark'}
                        onChange={changeLeftSidebarTheme}
                      />
                    </div>
                  </FormGroup>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </SimpleBar>
      </div>
      {/* Right bar overlay */}
      <div className='rightbar-overlay'></div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return { ...state.layout }
}

export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeSidebarType,
  changeSidebarTheme,
})(RightSidebar)
