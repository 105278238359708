import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { DropdownItem, DropdownMenu, Dropdown, DropdownToggle, Input, Label, Tooltip } from 'reactstrap'
import Switch from 'react-switch'

// import images
import logodarkImg from '../../assets/images/logo.png'
import logosmImg from '../../assets/images/logo-sm.png'
import logolightImg from '../../assets/images/logo.png'
import logosmLightImg from '../../assets/images/logo-sm-light.png'
// Import other Dropdown
import LanguageDropdown from '../../components/LanguageDropdown'
import NotificationDropdown from '../../components/NotificationDropdown'
import ProfileMenu from '../../components/ProfileMenu'
import * as rigsActions from '../../pages/RigsData/actions'
import * as DCactions from '../../pages/DCManagement/actions'
import * as mpActions from '../../pages/MiningPresets/actions'
import * as mainActions from '../../pages/Workers/actions'
import * as settingsActions from '../../pages/Settings/actions'

import { WorkerCreateModal, SetMiningPreset, WorkerUpgrade } from '../../pages/Workers/components/modals'
import { RoomCreateModal } from '../../pages/DCManagement/components/modals'
import { SlotToRigs } from '../../pages/OverclockingProfiles/components/modals'
import { CreateWalletModal } from '../../pages/Wallets/components/modals'
import { CreateDataCenter } from '../../pages/DCManagement/components/modals'
import SweetAlert from 'react-bootstrap-sweetalert'

import { useTranslation } from 'react-i18next'
import WorkerActionsMobile from './WorkerActionsMobile'
import Cookies from 'js-cookie'
import CritTempModal from 'pages/Settings/components/critTempModal'

const TopBar = React.forwardRef((props, ref) => {
  const [createMenu, setCreateMenu] = useState(false)
  const [modal, setModal] = useState(false)
  const [walletModal, setWalletModal] = useState(false)
  const [dataCenterModal, setDataCenterModal] = useState(false)
  const [roomModal, setRoomModal] = useState(false)
  const [fanHover, setFanHover] = useState('fas fa-fan')
  const [critTempStyle, setCritTempStyle] = useState('fas fa-temperature-high')
  const setDataCentersList = props.setDataCentersList
  const setCoinsList = props.setCoinsList
  const { t } = useTranslation('common')

  const [autofan_gpu, set_autofan_gpu] = useState(false)
  const [target_core_temp, set_target_core_temp] = useState('')
  const [target_mem_temp, set_target_mem_temp] = useState('')
  const [min_fan_speed, set_min_fan_speed] = useState('')
  const [max_fan_speed, set_max_fan_speed] = useState('')
  const [keep_target_temp, set_keep_target_temp] = useState(false)
  const [cold_case, set_cold_case] = useState(false)
  const [tooltip_open, set_tooltip_open] = useState(false)

  const [showAlertTurnOff, setShowAlertTurnOff] = useState(false)
  const [showAlertRebootHard, setShowAlertRebootHard] = useState(false)
  const [showAlertStart, setShowAlertStart] = useState(false)
  const [showAlertReboot, setShowAlertReboot] = useState(false)
  const [showAlertPause, setShowAlertPause] = useState(false)
  const [showAlertClearErrors, setShowAlertClearErrors] = useState(false)
  const [showAutofan, setShowAutofan] = useState(false)
  const [showCritTemp, setShowCritTemp] = useState(false)
  const [show, setShow] = useState(false)
  const toggleTooltip = () => {
    set_tooltip_open(!tooltip_open)
  }

  useEffect(() => {
    if (showAutofan) {
      set_autofan_gpu(props.autofan.status ? props.autofan.status : false)
      set_target_core_temp(props.autofan.targetTemp ? props.autofan.targetTemp : '')
      set_target_mem_temp(props.autofan.targetMemTemp ? props.autofan.targetMemTemp : '')
      set_min_fan_speed(props.autofan.minFanSpeed ? props.autofan.minFanSpeed : '')
      set_max_fan_speed(props.autofan.maxFanSpeed ? props.autofan.maxFanSpeed : '')
      set_keep_target_temp(props.autofan.keepTargetTemp ? props.autofan.keepTargetTemp : false)
      set_cold_case(props.autofan.coldCase ? props.autofan.coldCase : false)
    }
  }, [showAutofan])

  const actionAutofan = async btn_value => {
    const result = await props.actionAutofan({
      type: 'local',
      rigsIds: props.workersItems.map(el => el.id).toString(),
      autofanId: +props.autofan.id,
      minFanSpeed: +min_fan_speed,
      maxFanSpeed: +max_fan_speed,
      targetMemTemp: +target_mem_temp,
      targetTemp: +target_core_temp,
      status: btn_value === 'apply' ? autofan_gpu : !autofan_gpu,
      keepTargetTemp: keep_target_temp,
      coldCase: cold_case,
    })
    if (result && !btn_value) {
      set_autofan_gpu(!autofan_gpu)
    }
  }

  useEffect(() => {
    setDataCentersList({ page: 1, size: 1000 })
    setCoinsList({ page: 1, size: 1000, orderBy: 'nameSymbol' })
  }, [setDataCentersList, setCoinsList])

  const toggle = () => {
    //event.preventDefault()
    setModal(!modal)
    setCreateMenu(false)
  }
  const clearSelected = () => {
    if (props.userData.id !== '1') props.setClearCheck()
  }

  const toggleWallet = () => {
    setWalletModal(!walletModal)
    setCreateMenu(false)
  }

  const selectCreateItem = createType => {
    switch (createType) {
      case 'dataCenter': {
        setDataCenterModal(true)
        return
      }
      case 'wallet': {
        setWalletModal(true)
        return
      }
      default:
        setCreateMenu(false)
    }
  }

  const toggleRoom = () => {
    setRoomModal(!roomModal)
    setCreateMenu(false)
  }

  /**
   * Toggle sidebar
   */
  const toggleMenu = () => {
    props.toggleMenuCallback()
  }

  /**
   * Toggles the sidebar
   */
  // const toggleRightbar = () => {
  //   props.toggleRightSidebar()
  // }

  /**
   * Toggle full screen
   */
  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  const renewalRigs = () => {
    const currentPage = Cookies.get(window.location.pathname + 'dataTable' + 'currentPage')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'currentPage'))
      : 1
    const showCount = Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10
    const orderBy = Cookies.get(window.location.pathname + 'dataTable' + 'orderBy')
      ? Cookies.get(window.location.pathname + 'dataTable' + 'orderBy')
      : 'id'

    const orderDirection = Cookies.get(window.location.pathname + 'dataTable' + 'orderDirection')
      ? Cookies.get(window.location.pathname + 'dataTable' + 'orderDirection')
      : 'DESC'

    let filterObj = {}
    if (props.filter === 'online') filterObj = { online: true }
    if (props.filter === 'offline') filterObj = { offline: true }
    if (props.filter === 'haveAlerts') filterObj = { haveAlerts: true }
    if (props.filter === 'notDetectedGPU') filterObj = { notDetectedGPU: true }
    if (props.filter === 'amdGPU') filterObj = { amdGPU: true }
    if (props.filter === 'nvidiaGPU') filterObj = { nvidiaGPU: true }
    if (props.filter === 'offlineGPU') filterObj = { offlineGPU: true }
    if (props.filter === 'highTempGPU') filterObj = { highTempGPU: true }
    if (props.filter === 'otherProblemsGPU') filterObj = { otherProblemsGPU: true }
    if (props.filter === 'minTemp') filterObj = { minTemp: true }
    if (props.filter === 'maxTemp') filterObj = { maxTemp: true }
    if (props.filter === 'dcId') filterObj = { dcId: true }
    if (props.filter === 'roomId') filterObj = { dcId: true }

    if (props.search) {
      props.setRigsList({
        page: currentPage,
        size: showCount,
        orderBy: orderBy,
        orderDirection: orderDirection,
        search: props.search,
      })
    }

    if (props.filter) {
      props.setFilterRigsList({
        page: currentPage,
        size: showCount,
        orderBy: orderBy,
        orderDirection: orderDirection,
        ...filterObj,
      })
    }
    if (props.dopFilter) {
      props.setDopFilterRigsList({
        page: currentPage,
        size: showCount,
        orderBy: orderBy,
        orderDirection: orderDirection,
        ...props.dopFilter,
      })
    }
    if (!props.search && !props.filter && !props.dopFilter)
      props.setRigsList({ page: currentPage, size: showCount, orderBy: orderBy, orderDirection: orderDirection })
  }

  const updateRigs = () => {
    props.updateRigs({ userId: +props.userData.id })
  }

  const toggleDropdown = () => {
    setShow(!show)
  }
  const rebootWorker = async () => {
    await props
      .setRigsAction({
        rigIds: props.workersItems
          .map(worker => {
            return worker.id
          })
          .toString(),
        powerOff: false,
        hardReboot: false,
        reboot: true,
      })
      .then()
  }

  const rebootHardWorker = async () => {
    await props
      .setRigsAction({
        rigIds: props.workersItems
          .map(worker => {
            return worker.id
          })
          .toString(),
        powerOff: false,
        reboot: false,
        hardReboot: true,
      })
      .then()
  }

  const turnOFFWorker = async () => {
    await props
      .setRigsAction({
        rigIds: props.workersItems
          .map(worker => {
            return worker.id
          })
          .toString(),
        powerOff: true,
        reboot: false,
        hardReboot: false,
      })
      .then()
  }

  const turnOff = () => {
    setShowAlertTurnOff(true)
    setShow(false)
  }
  const rebootHard = () => {
    setShowAlertRebootHard(true)
    setShow(false)
  }
  const reboot = () => {
    setShowAlertReboot(true)
    setShow(false)
  }
  const start = () => {
    setShowAlertStart(true)
  }
  const pause = () => setShowAlertPause(true)

  const [isSetMiningPreset, setIsSetMiningPreset] = useState(false)
  const [isSetSlotToRigs, setIsSetSlotToRigs] = useState(false)
  const [isWorkerUpgrade, setIsWorkerUpgrade] = useState(false)

  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className={'alerts'}>
          <SweetAlert
            title={t('sweetAlerts.top.areConfirm')}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            custom
            customIcon={<i className='fas fa-power-off sweet-alert-icon'></i>}
            show={showAlertTurnOff}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              turnOFFWorker()
              setShowAlertTurnOff(false)
            }}
            onCancel={() => {
              setShowAlertTurnOff(false)
            }}
            confirmBtnText={t('sweetAlerts.top.confirm')}
            cancelBtnText={t('sweetAlerts.top.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.top.workerOff')}
          </SweetAlert>
          <SweetAlert
            title={t('sweetAlerts.top.areConfirm')}
            custom
            customIcon={<i className='fas fa-play sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertStart}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              props.setRigsAction({
                rigIds: props.workersItems
                  .map(worker => {
                    return worker.id
                  })
                  .toString(),
                action: 'run',
              })

              setShowAlertStart(false)
            }}
            onCancel={() => {
              setShowAlertStart(false)
            }}
            confirmBtnText={t('sweetAlerts.top.confirm')}
            cancelBtnText={t('sweetAlerts.top.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.top.miningOn')}
          </SweetAlert>
          <SweetAlert
            title={t('sweetAlerts.top.areConfirm')}
            custom
            customIcon={<i className='fas fa-stop sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertPause}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              props.setRigsAction({
                rigIds: props.workersItems
                  .map(worker => {
                    return worker.id
                  })
                  .toString(),
                action: 'stop',
              })
              setShowAlertPause(false)
            }}
            onCancel={() => {
              setShowAlertPause(false)
            }}
            confirmBtnText={t('sweetAlerts.top.confirm')}
            cancelBtnText={t('sweetAlerts.top.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.top.miningOff')}
          </SweetAlert>
          <SweetAlert
            title={t('sweetAlerts.top.areConfirm')}
            custom
            customIcon={<i className='fas fa-sync sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertReboot}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              rebootWorker()
              setShowAlertReboot(false)
            }}
            onCancel={() => {
              setShowAlertReboot(false)
            }}
            confirmBtnText={t('sweetAlerts.top.confirm')}
            cancelBtnText={t('sweetAlerts.top.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.top.workerReboot')}
          </SweetAlert>
          <SweetAlert
            title={t('sweetAlerts.top.areConfirm')}
            custom
            customIcon={<i className='fas fa-sync sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertRebootHard}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              rebootHardWorker()
              setShowAlertRebootHard(false)
            }}
            onCancel={() => {
              setShowAlertRebootHard(false)
            }}
            confirmBtnText={t('sweetAlerts.top.confirm')}
            cancelBtnText={t('sweetAlerts.top.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.top.workerRebootHard')}
          </SweetAlert>
          <SweetAlert
            title={t('sweetAlerts.top.areConfirm')}
            custom
            customIcon={<i className='far fa-times-circle sweet-alert-icon'></i>}
            showCancel
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            show={showAlertClearErrors}
            focusCancelBtn={true}
            focusConfirmBtn={false}
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              props.setRigsAction({
                rigIds: props.workersItems
                  .map(worker => {
                    return worker.id
                  })
                  .toString(),
                action: 'clearErrors',
              })
              setShowAlertClearErrors(false)
            }}
            onCancel={() => {
              setShowAlertClearErrors(false)
            }}
            confirmBtnText={t('sweetAlerts.top.confirm')}
            cancelBtnText={t('sweetAlerts.top.cancel')}
            showCloseButton={true}
          >
            {t('sweetAlerts.top.resetErrors')}
          </SweetAlert>
          <SweetAlert
            title={t('sweetAlerts.top.autofanGpu')}
            customIcon={<i className='far fa-times-circle sweet-alert-icon'></i>}
            show={showAutofan}
            showCancel
            focusCancelBtn={false}
            focusConfirmBtn={true}
            confirmBtnBsStyle='primary'
            cancelBtnBsStyle='secondary'
            confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
            cancelBtnStyle={{
              borderColor: 'rgb(40, 96, 144)',
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
            }}
            onConfirm={() => {
              actionAutofan('apply')
            }}
            onCancel={() => {
              setShowAutofan(false)
            }}
            confirmBtnText={t('pages.workers.apply')}
            cancelBtnText={t('sweetAlerts.top.cancel')}
            showCloseButton={true}
          >
            <div className={'settings__power_management'}>
              <div className={'settings__section'}>
                <div className={'settings__section_header'}>
                  <h4>{t('pages.settings.autoFANGPU')}</h4>
                  <Switch
                    onColor='#02a499'
                    onChange={() => actionAutofan()}
                    checked={autofan_gpu ? autofan_gpu : false}
                    height={20}
                    width={40}
                  />
                </div>

                <div className={'settings__section_body'}>
                  <Tooltip
                    placement='top'
                    isOpen={tooltip_open}
                    autohide={true}
                    toggle={toggleTooltip}
                    target='max_value'
                  >
                    maximum value 100
                  </Tooltip>
                  <div className={'settings__row'}>
                    <Label className={'checkboxLabel'}>{t('pages.settings.targetCoreTemp')}</Label>
                    <Input
                      id='max_value'
                      placeholder={target_core_temp}
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      value={target_core_temp}
                      onChange={e => set_target_core_temp(e.target.value)}
                    />
                  </div>

                  <div className={'settings__row'}>
                    <Label className={'checkboxLabel'} id='some'>
                      {t('pages.settings.targetMemTemp')}
                    </Label>
                    <Input
                      id='max_value'
                      placeholder={target_mem_temp}
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      value={target_mem_temp}
                      onChange={e => set_target_mem_temp(e.target.value)}
                    />
                  </div>

                  <div className={'settings__row'}>
                    <Label className={'checkboxLabel'}>{t('pages.settings.minFanSpeed')}</Label>
                    <Input
                      id='max_value'
                      placeholder={min_fan_speed}
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      value={min_fan_speed}
                      onChange={e => set_min_fan_speed(e.target.value)}
                    />
                  </div>

                  <div className={'settings__row'}>
                    <Label className={'checkboxLabel'}>{t('pages.settings.maxFanSpeed')}</Label>
                    <Input
                      id='max_value'
                      placeholder={max_fan_speed}
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      value={max_fan_speed}
                      onChange={e => set_max_fan_speed(e.target.value)}
                    />
                  </div>

                  <div className={'settings__row'}>
                    <Label className={'checkboxLabel'} style={{ marginRight: '5px' }}>
                      {t('pages.settings.keepTargetTemp')}
                    </Label>
                    <Switch
                      onColor='#02a540'
                      onChange={() => set_keep_target_temp(!keep_target_temp)}
                      checked={keep_target_temp}
                      height={20}
                      width={40}
                    />
                  </div>

                  <div className={'settings__row'}>
                    <Label className={'checkboxLabel'} style={{ marginRight: '5px' }}>
                      {t('pages.settings.coldCase')}
                    </Label>
                    <Switch
                      onColor='#02a540'
                      onChange={() => {
                        set_cold_case(!cold_case)
                      }}
                      checked={cold_case}
                      height={20}
                      width={40}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SweetAlert>
        </div>

        <div className='navbar-header'>
          <div className='d-flex'>
            <div className='navbar-brand-box'>
              <Link to={`${process.env.PUBLIC_URL}/`} className='logo logo-dark'>
                <span className='logo-sm'>
                  <img src={logosmImg} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                  <img src={logodarkImg} alt='' height='17' />
                </span>
              </Link>

              <Link to={`${process.env.PUBLIC_URL}/`} className='logo logo-light'>
                <span className='logo-sm'>
                  <img src={logosmLightImg} alt='' height='35' />
                </span>
                <span className='logo-lg'>
                  <img src={logolightImg} alt='' height='50' />
                </span>
              </Link>
            </div>
            <button
              type='button'
              onClick={toggleMenu}
              className='btn btn-sm px-3 font-size-24 header-item waves-effect'
              id='vertical-menu-btn'
              ref={ref}
            >
              <i className='mdi mdi-menu'></i>
            </button>

            <div className='d-sm-block'>
              <Dropdown
                isOpen={createMenu}
                toggle={() => {
                  setCreateMenu(!createMenu)
                }}
                className='pt-3 d-inline-block creationMenu'
                // caret="true"
              >
                <DropdownToggle className='btn btn-secondary' tag='button'>
                  <span className={'d-none d-sm-block'}>
                    {t('topBar.create')} <i className='mdi mdi-chevron-down'></i>
                  </span>
                  <span className={'d-block d-sm-none'}>
                    <i className='fas fa-plus'></i>
                  </span>
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <li tag='li' onClick={() => selectCreateItem('wallet')}>
                      {t('topBar.wallet')}
                    </li>
                  </DropdownItem>

                  <DropdownItem>
                    <WorkerCreateModal toggle={toggle} isOpen={modal} />
                  </DropdownItem>

                  <DropdownItem>
                    <li tag='li' onClick={() => selectCreateItem('dataCenter')}>
                      {t('topBar.dataCenter')}
                    </li>
                  </DropdownItem>

                  <DropdownItem>
                    <RoomCreateModal toggleRoom={toggleRoom} isOpenRoom={roomModal} />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <CreateDataCenter onFinish={() => setDataCenterModal(false)} isActive={dataCenterModal} />

          <CreateWalletModal toggleWallet={toggleWallet} isOpenWallet={walletModal} />

          <div className='d-flex'>
            <div className={'actions__items'}>
              {props.workersItems.length >= 1 && (
                <WorkerActionsMobile
                  worker={props.worker}
                  workersItems={props.workersItems}
                  clearSelected={clearSelected}
                />
              )}
              {props.workersItems.length >= 1 ? (
                <div className={'actions__items d-none d-sm-flex'}>
                  <div
                    className={'actions__item'}
                    tooltip={t('tooltips.clearWorker')}
                    flow='down'
                    onClick={() => setShowAlertClearErrors(true)}
                  >
                    <i className='far fa-times-circle'></i>
                  </div>
                  <div
                    className={'actions__item'}
                    tooltip={t('tooltips.autofan')}
                    flow='down'
                    onClick={async () => {
                      await props.setAutofan({ type: 'local', rigsIds: props.workersItems.map(el => el.id).toString() })
                      setShowAutofan(true)
                    }}
                    onMouseOver={() => setFanHover('fas fa-fan fa-spin')}
                    onMouseOut={() => setFanHover('fas fa-fan')}
                  >
                    <i className={fanHover}></i>
                  </div>

                  <div
                    className={'actions__item-no-hover'}
                    tooltip={t('tooltips.setCritTemp')}
                    flow='down'
                    onClick={() => setShowCritTemp(true)}
                    onMouseOver={() => setCritTempStyle('fas fa-temperature-high color-change')}
                    onMouseOut={() => setCritTempStyle('fas fa-temperature-high')}
                  >
                    <i className={critTempStyle}></i>
                  </div>

                  {showCritTemp && (
                    <CritTempModal onCancel={() => setShowCritTemp(false)} workersItems={props.workersItems} />
                  )}

                  <div
                    className={'actions__item'}
                    tooltip={t('pages.workers.workerUpdate')}
                    flow='down'
                    onClick={() => setIsWorkerUpgrade(true)}
                  >
                    <i className='fas fa-cloud-upload-alt'></i>
                  </div>

                  {isWorkerUpgrade && (
                    <WorkerUpgrade
                      workersItems={props.workersItems}
                      clearSelected={clearSelected}
                      onCancel={() => setIsWorkerUpgrade(false)}
                    />
                  )}
                  <div
                    className={'actions__item'}
                    tooltip={t('tooltips.setSlot')}
                    flow='down'
                    onClick={() => setIsSetSlotToRigs(true)}
                  >
                    <i className='fas fa-sliders-h'></i>
                  </div>
                  {isSetSlotToRigs && (
                    <SlotToRigs
                      onCancel={() => setIsSetSlotToRigs(false)}
                      worker={props.worker}
                      workersItems={props.workersItems}
                      clearSelected={clearSelected}
                    />
                  )}
                  <div
                    className={'actions__item'}
                    tooltip={t('tooltips.setPreset')}
                    flow='down'
                    onClick={() => setIsSetMiningPreset(true)}
                  >
                    <i className='fas fa-plane-departure'></i>
                  </div>
                  {isSetMiningPreset && (
                    <SetMiningPreset
                      onCancel={() => setIsSetMiningPreset(false)}
                      worker={props.worker}
                      workersItems={props.workersItems}
                      clearSelected={clearSelected}
                    />
                  )}
                  <div className={'actions__item'} tooltip={t('tooltips.miningOn')} flow='down' onClick={() => start()}>
                    <i className='fas fa-play'></i>
                  </div>
                  <div
                    className={'actions__item'}
                    tooltip={t('tooltips.miningOff')}
                    flow='down'
                    onClick={() => pause()}
                  >
                    <i className='fas fa-stop'></i>
                  </div>

                  <Dropdown isOpen={show} toggle={toggleDropdown} direction={'left'}>
                    <DropdownToggle className={'actions__item'} tooltip={t('tooltips.powerActions')} flow='down'>
                      <i className='fas fa-power-off'></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => turnOff()}>{t('topBar.turnOff')}</DropdownItem>
                      <DropdownItem onClick={() => reboot()}>{t('topBar.reboot')}</DropdownItem>
                      <DropdownItem onClick={() => rebootHard()}>{t('topBar.rebootHard')}</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              ) : (
                ''
              )}
              <div className={'userName'} style={{ margin: '0 2%' }}>
                {t('topBar.hello')} {props.userData.name}
              </div>
              <div
                className={'actions__item'}
                onClick={renewalRigs}
                tooltip={t('pages.workers.renewalRigs')}
                flow='down'
              >
                <i className='fas fa-redo'></i>
              </div>
              {props.userData.id === '1' ? (
                <div
                  className={'actions__item'}
                  onClick={updateRigs}
                  tooltip={t('pages.workers.updateRigs')}
                  flow='down'
                >
                  <i className='fas fa-print'></i>
                </div>
              ) : null}
            </div>

            <LanguageDropdown />

            <div className='dropdown d-none d-lg-inline-block'>
              <button
                type='button'
                className='btn header-item noti-icon waves-effect'
                onClick={toggleFullscreen}
                data-toggle='fullscreen'
              >
                <i className='mdi mdi-fullscreen'></i>
              </button>
            </div>

            <NotificationDropdown />

            <ProfileMenu />

            {/*<div className="dropdown d-inline-block design_settings">
              <button
                type="button"
                onClick={toggleRightbar}
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-settings-outline"></i>
              </button>
            </div>*/}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
})
const mapStateToProps = store => ({
  workersItems: store.main.workersItems,
  search: store.rigs.search,
  filter: store.rigs.filter,
  dopFilter: store.rigs.dopFilter,
  userData: store.login,
  autofan: store.settings.autofan,
})

const mapDispatchToProps = {
  setRigsList: rigsActions.setRigsList,
  setDopFilterRigsList: rigsActions.setDopFilterRigsList,
  setFilterRigsList: rigsActions.setFilterRigsList,
  updateRigs: rigsActions.updateRigs,
  setDataCentersList: DCactions.setDataCentersList,
  setCoinsList: mpActions.setCoinsList,
  setRigsAction: rigsActions.setRigsAction,
  setClearCheck: mainActions.setClearCheck,
  visibleWorkerActions: mainActions.visibleWorkerActions,
  setAutofan: settingsActions.setAutofan,
  actionAutofan: settingsActions.actionAutofan,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TopBar)
