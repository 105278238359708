import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Label, Row } from 'reactstrap'
import SelectWithImages from '../../../components/UI/SelectWithImages'
import Select from 'react-select'

const MiningPresetRow = props => {
  const { t } = useTranslation('common')
  const toggleCreateWallet = () => {
    props.setCreateWallet(true)
    props.setConfigurePool(false)
    props.setConfigurePoolDual(false)
    props.setConfigureMiner(false)
  }
  const toggleConfigurePool = option => {
    props.preset.poolAddress = []
    props.preset.ssl = false
    props.preset.pplns = false
    //props.setPoolAddress([])
    //props.setSwitchSSL(false)
    //if (props.pool) props.pool.poolServers=[]
    setShowConfiguratePool(false)
    /*if (isNaN(parseInt(event.target.value))){
            props.setConfigurePool(false)
            props.setCreateWallet(false)
            props.setConfigureMiner(false)

        }else{*/
    delete props.preset.errors.pool
    setShowConfiguratePool(true)
    props.setConfigurePool(true)
    props.setCreateWallet(false)
    props.setConfigureMiner(false)
    props.setConfigurePoolDual(false)
    props.preset.pool = option
    //props.setPool(option)
    props.setCurrentPreset(props.preset)
  }

  const toggleConfigurePoolDual = option => {
    props.preset.poolAddressDual = []
    //props.setPoolAddress([])
    //props.setSwitchSSLDual(false)
    props.preset.sslDual = false
    props.preset.pplnsDual = false
    //if (props.pool) props.pool.poolServers=[]
    setShowConfiguratePoolDual(false)
    /*if (isNaN(parseInt(event.target.value))){
            props.setConfigurePool(false)
            props.setCreateWallet(false)
            props.setConfigureMiner(false)

        }else{*/
    delete props.preset.errors.poolDual
    props.setConfigurePool(false)
    props.setCreateWallet(false)
    props.setConfigureMiner(false)
    props.setConfigurePoolDual(true)
    setShowConfiguratePoolDual(true)
    props.preset.poolDual = option
    //props.setPool(option)
    props.setCurrentPreset(props.preset)
  }

  const showConfigurePool = () => {
    // props.setSwitchSSL(props.preset.ssl)
    //props.setPoolAddress(props.preset.poolAddress)
    // if (props.pool) props.pool.poolServers=[]
    //props.setPool(props.preset.pool)
    if (showConfiguratePool) {
      delete props.preset.errors.pool
      props.setConfigurePool(true)
      props.setCreateWallet(false)
      props.setConfigureMiner(false)
      props.setConfigurePoolDual(false)
    }
    props.setCurrentPreset(props.preset)
  }

  const showConfigurePoolDual = () => {
    // props.setSwitchSSLDual(props.preset.sslDual)
    //props.setPoolAddress(props.preset.poolAddress)
    // if (props.pool) props.pool.poolServers=[]
    //props.setPool(props.preset.pool)
    if (showConfiguratePoolDual) {
      delete props.preset.errors.poolDual
      props.setConfigurePool(false)
      props.setCreateWallet(false)
      props.setConfigureMiner(false)
      props.setConfigurePoolDual(true)
    }
    props.setCurrentPreset(props.preset)
  }

  const toggleConfigureMiner = option => {
    setShowConfigurateMiner(false)
    props.preset.miner = option
    delete props.preset.errors.miner
    //props.preset.minerValues = {errors:{},worker:'$wallet.$workername'}
    setShowConfigurateMiner(true)
    props.setConfigurePool(false)
    props.setConfigurePoolDual(false)
    props.setCreateWallet(false)
    props.setConfigureMiner(true)
    //console.log(props.preset.miner.dualCoins.split(', ').includes("ETC"))
    props.setCurrentPreset(props.preset)
  }

  const showConfigureMiner = () => {
    if (showConfigurateMiner) {
      delete props.preset.errors.miner
      props.setConfigurePool(false)
      props.setCreateWallet(false)
      props.setConfigurePoolDual(false)
      props.setConfigureMiner(true)
    }
    props.setCurrentPreset(props.preset)
  }

  const [showConfiguratePool, setShowConfiguratePool] = useState(props.edit)
  const [showConfiguratePoolDual, setShowConfiguratePoolDual] = useState(props.edit)
  const [showConfigurateMiner, setShowConfigurateMiner] = useState(props.edit)

  const [updateState, setUpdateState] = useState(false)
  const changeCoin = option => {
    if (option?.value !== '') delete props.preset.errors.coin
    if (!option) {
      props.preset.coin = ''
    } else {
      props.preset.coin = option
    }

    if (
      option?.value !== 'Other' &&
      props.preset.miner &&
      props.preset.miner.minerVersions &&
      props.preset.miner.minerVersions.filter(
        version =>
          version.params &&
          version.params.filter(
            param =>
              props.algoCoinsList.filter(algo => {
                return algo.coins.split(', ').find(coin => coin === option?.value) && algo.algo === param.algo
              }).length > 0,
          ).length > 0,
      ).length === 0
    ) {
      props.preset.miner = ''
      props.setConfigureMiner(false)
      setShowConfigurateMiner(false)
      setShowConfiguratePoolDual(false)
      props.setConfigurePoolDual(false)
    }

    if (
      option?.value !== 'Other' &&
      props.preset.miner &&
      props.preset.miner.minerValues.version &&
      props.preset.miner.minerValues.algo &&
      props.preset.miner.minerVersions &&
      props.preset.miner.minerVersions.filter(
        version =>
          props.preset.miner.minerValues.version.value === version.version &&
          version.params &&
          version.params.filter(
            param =>
              props.algoCoinsList.filter(algo => {
                return algo.coins.split(', ').find(coin => coin === option?.value) && algo.algo === param.algo
              }).length > 0 && props.preset.miner.minerValues.algo.value.toLowerCase() === param.algo.toLowerCase(),
          ).length > 0,
      ).length === 0
    ) {
      if (
        props.preset.miner &&
        props.preset.miner.minerVersions &&
        props.preset.miner.minerVersions.filter(
          version =>
            version.params &&
            version.params.filter(
              param =>
                props.algoCoinsList.filter(algo => {
                  return algo.coins.split(', ').find(coin => coin === option?.value) && algo.algo === param.algo
                }).length > 0,
            ).length > 0,
        ).length === 0
      ) {
        props.preset.miner = ''
        props.setConfigureMiner(false)
        setShowConfigurateMiner(false)
        setShowConfiguratePoolDual(false)
        props.setConfigurePoolDual(false)
      } else {
        props.preset.miner.minerValues.version = ''
        props.preset.miner.minerValues.algo = ''
        props.preset.miner.minerValues.dualMode = false
      }
    }
    if (
      option?.value !== 'Other' &&
      props.preset.pool &&
      props.preset.pool.coins &&
      !props.preset.pool.coins.split(', ').includes(option?.value)
    ) {
      props.preset.pool = ''
      props.setConfigurePool(false)
      setShowConfiguratePool(false)
      props.preset.poolAddress = []
    } else {
      if (props.preset.pool && props.preset.pool.type === 'SP') props.preset.poolAddress = []
    }
    props.preset.wallet = ''

    props.setCreateWallet(false)
    props.setCurrentPreset(props.preset)
    setUpdateState(!updateState)
  }

  const changeCoinDual = option => {
    if (option?.value !== '') delete props.preset.errors.coinDual
    if (!option) {
      props.preset.coinDual = ''
    } else {
      props.preset.coinDual = option
    }
    if (
      props.preset.poolDual &&
      props.preset.poolDual.coins &&
      !props.preset.poolDual.coins.split(', ').includes(option?.value)
    ) {
      props.preset.poolDual = ''
      props.setConfigurePoolDual(false)
      setShowConfiguratePoolDual(false)
      props.preset.poolAddressDual = []
    } else {
      if (props.preset.poolDual && props.preset.poolDual.type === 'SP') props.preset.poolAddressDual = []
    }
    props.preset.walletDual = ''
    props.onChangeDoubleCoin(option?.value)
    props.setCreateWallet(false)
    props.setCurrentPreset(props.preset)
    setUpdateState(!updateState)
  }

  const setNewWalletAddress = option => {
    props.preset.wallet = ''
    if (option?.value !== '') delete props.preset.errors.wallet
    props.preset.wallet = option
    setUpdateState(!updateState)
  }

  const setNewWalletAddressDual = option => {
    props.preset.walletDual = ''
    if (option?.value !== '') delete props.preset.errors.walletDual
    props.preset.walletDual = option
    setUpdateState(!updateState)
  }

  const [mouse, setMouseSelected] = useState(false)
  const mouseDown = e => {
    if (e.button !== 0) return
    e.stopPropagation()
    e.preventDefault()
    setMouseSelected(true)
  }
  const mouseUp = e => {
    e.stopPropagation()
    e.preventDefault()
    setMouseSelected(false)
  }
  const mouseMove = e => {}
  return (
    <Row className={'mining_preset__row'}>
      <div className={'mining_preset__row_number'}>{props.preset.order}</div>
      <Col md={2} xs={6} className={'form-group'}>
        <Label>{t('pages.miningPresets.coin')}</Label>

        <SelectWithImages
          value={props.preset.coin}
          onchange={changeCoin}
          error={props.preset.errors.coin}
          options={props.coinsList.map(coin => {
            return {
              ...coin,
              icon: coin.nameSymbol,
              label: coin.nameSymbol,
              value: coin.nameSymbol,
            }
          })}
          hideSelectedOptions={true}
          placeholder={t('inputs.select')}
        />
        <div className={'error'}>{props.preset.errors.coin && props.preset.errors.coin.message}</div>
      </Col>
      <Col md={3} xs={6} className={'form-group'}>
        <Label>{t('pages.miningPresets.wallet')}</Label>

        <Select
          hideSelectedOptions
          classNamePrefix={'form_control_react_select'}
          className={props.preset.errors.wallet ? 'invalid-select special_select' : 'special_select'}
          onChange={setNewWalletAddress}
          value={props.preset.wallet}
          options={props.walletsList
            .filter(wallet => wallet.coin === props.preset.coin.value || props.preset.coin.value === 'Other')
            .map(wallet => {
              return {
                ...wallet,
                label: wallet.name,
                value: wallet.address,
              }
            })}
          placeholder={t('inputs.select')}
        />
        <div className={'special_select__add'}>
          <span className='special_select__tooltip_text'>Add New Wallet</span>
          <i className='fas fa-plus-square' onClick={toggleCreateWallet}></i>
        </div>
        <div className={'error'}>{props.preset.errors.wallet && props.preset.errors.wallet.message}</div>
      </Col>
      <Col md={3} xs={6} className={'form-group'}>
        <Label>{t('pages.miningPresets.pool')}</Label>
        <Select
          hideSelectedOptions
          classNamePrefix={'form_control_react_select'}
          className={props.preset.errors.pool ? 'invalid-select special_select' : 'special_select'}
          onChange={toggleConfigurePool}
          value={props.preset.pool}
          placeholder={t('inputs.select')}
          options={props.poolsList
            .filter(
              pool => props.preset.coin.value === 'Other' || pool.coins.split(', ').includes(props.preset.coin.value),
            )
            .map(pool => {
              return {
                ...pool,
                label: pool.title,
                value: pool.id,
                key: pool.id,
              }
            })}
        />
        <div className={'special_select__add'} style={{ opacity: showConfiguratePool ? '1' : '0.2' }}>
          <span className='special_select__tooltip_text' style={{ display: showConfiguratePool ? 'block' : 'none' }}>
            {t('pages.miningPresets.configurePool')}
          </span>
          <i className='fas fa-pen-square' onClick={showConfigurePool}></i>
        </div>
        <div className={'error'}>{props.preset.errors.pool && props.preset.errors.pool.message}</div>
      </Col>
      <Col md={3} xs={6} className={'form-group'}>
        <Label>{t('pages.miningPresets.miner')}</Label>
        <Select
          hideSelectedOptions
          placeholder={t('inputs.select')}
          classNamePrefix={'form_control_react_select'}
          className={props.preset.errors.miner ? 'invalid-select special_select' : 'special_select'}
          onChange={toggleConfigureMiner}
          value={props.preset.miner}
          options={props.preset.minersList.filter(miner => {
            return (
              props.preset.coin.value === 'Other' ||
              (miner.minerVersions &&
                miner.minerVersions.filter(
                  version =>
                    version.params &&
                    version.params.filter(
                      param =>
                        props.algoCoinsList.filter(algo => {
                          return (
                            algo.coins.split(', ').find(coin => coin === props.preset.coin.value) &&
                            algo.algo === param.algo
                          )
                        }).length > 0,
                    ).length > 0,
                ).length > 0)
            )
          })}
        />
        <div className={'special_select__add'} style={{ opacity: showConfigurateMiner ? '1' : '0.2' }}>
          <span className='special_select__tooltip_text' style={{ display: showConfigurateMiner ? 'block' : 'none' }}>
            {t('pages.miningPresets.setupMinerConfig')}
          </span>
          <i className='fas fa-pen-square' onClick={showConfigureMiner}></i>
        </div>
        <div className={'error'}>{props.preset.errors.miner && props.preset.errors.miner.message}</div>
      </Col>
      <Col
        md={1}
        xs={2}
        className={'form-group mining_preset__row_actions'}
        style={{ display: props.showDelete ? 'flex' : 'none' }}
      >
        <i
          className='fas fa-trash-alt'
          onClick={() => {
            props.removePreset(props.preset)
          }}
        ></i>
        <i className='fas fa-bars' onMouseDown={mouseDown} onMouseMove={mouseMove} onMouseUp={mouseUp}></i>
      </Col>

      {props.preset.miner && props.preset.miner.minerParams && props.preset.miner.minerValues.dualMode ? (
        <Col md={2} xs={6} className={'form-group'}>
          <Label>{t('pages.miningPresets.dualCoin')}</Label>

          <SelectWithImages
            value={props.preset.coinDual}
            placeholder={t('inputs.select')}
            onchange={changeCoinDual}
            error={props.preset.errors.coinDual}
            options={props.coinsList
              .filter(coin => {
                return (
                  props.preset.miner &&
                  props.preset.miner.minerVersions &&
                  props.preset.miner.minerVersions.length > 0 &&
                  props.preset.miner.minerValues.version &&
                  props.preset.miner.minerVersions.filter(
                    version =>
                      props.preset.miner.minerValues.version.value === version.version &&
                      version.dualCoins.split(', ')?.find(fCoin => fCoin === coin.nameSymbol),
                  ).length > 0
                )
              })
              .map(coin => {
                return {
                  ...coin,
                  icon: coin.nameSymbol,
                  label: coin.nameSymbol,
                  value: coin.nameSymbol,
                }
              })}
            hideSelectedOptions={true}
          />
          <div className={'error'}>{props.preset.errors.coinDual && props.preset.errors.coinDual.message}</div>
        </Col>
      ) : null}
      {props.preset.miner && props.preset.miner.minerParams && props.preset.miner.minerValues.dualMode ? (
        <Col md={3} xs={6} className={'form-group'}>
          <Label>{t('pages.miningPresets.dualWallet')}</Label>

          <Select
            hideSelectedOptions
            placeholder={t('inputs.select')}
            classNamePrefix={'form_control_react_select'}
            className={props.preset.errors.walletDual ? 'invalid-select special_select' : 'special_select'}
            onChange={setNewWalletAddressDual}
            value={props.preset.walletDual}
            options={props.walletsList
              .filter(wallet => props.preset.coinDual && wallet.coin === props.preset.coinDual.value)
              .map(wallet => {
                return {
                  ...wallet,
                  label: wallet.name,
                  value: wallet.address,
                }
              })}
          />
          <div className={'special_select__add'}>
            <span className='special_select__tooltip_text'>Add New Wallet</span>
            <i className='fas fa-plus-square' onClick={toggleCreateWallet}></i>
          </div>
          <div className={'error'}>{props.preset.errors.walletDual && props.preset.errors.walletDual.message}</div>
        </Col>
      ) : null}
      {props.preset.miner && props.preset.miner.minerParams && props.preset.miner.minerValues.dualMode ? (
        <Col md={3} xs={6} className={'form-group'}>
          <Label>{t('pages.miningPresets.dualPool')}</Label>
          <Select
            hideSelectedOptions
            placeholder={t('inputs.select')}
            classNamePrefix={'form_control_react_select'}
            className={props.preset.errors.poolDual ? 'invalid-select special_select' : 'special_select'}
            onChange={toggleConfigurePoolDual}
            value={props.preset.poolDual}
            options={props.poolsList
              .filter(
                pool =>
                  props.preset.coinDual && pool.coins && pool.coins.split(', ').includes(props.preset.coinDual.value),
              )
              .map(pool => {
                return {
                  ...pool,
                  label: pool.title,
                  value: pool.id,
                }
              })}
          />
          <div className={'special_select__add'} style={{ opacity: showConfiguratePoolDual ? '1' : '0.2' }}>
            <span
              className='special_select__tooltip_text'
              style={{ display: showConfiguratePoolDual ? 'block' : 'none' }}
            >
              {t('pages.miningPresets.configureDualPool')}
            </span>
            <i className='fas fa-pen-square' onClick={showConfigurePoolDual}></i>
          </div>
          <div className={'error'}>{props.preset.errors.poolDual && props.preset.errors.poolDual.message}</div>
        </Col>
      ) : null}
    </Row>
  )
}

export default MiningPresetRow
