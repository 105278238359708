import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditScheduleModal, LogsScheduleModal } from './modals'
import { connect } from 'react-redux'
import * as actions from '../actions'
import SweetAlert from 'react-bootstrap-sweetalert/dist'

const ScheduleActions = props => {
  const [show, setShow] = useState(false)
  const [showLogs, setShowLogs] = useState(false)
  const { t } = useTranslation('common')
  const toggleEdit = () => {
    setShow(!show)
  }
  const toggleEditLogs = () => {
    setShowLogs(!showLogs)
  }
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const deleteFunc = async () => {
    setShowAlertDelete(false)
    props.delete({ scheduleId: +props.schedule.id })
  }

  return (
    <div className={'actions__items'}>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertDelete}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          deleteFunc()
        }}
        onCancel={() => {
          setShowAlertDelete(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        {t('pages.schedule.scheduleWillRemove')}
      </SweetAlert>
      <div
        className={'actions__item'}
        tooltip={
          props.schedule.action === 'stop'
            ? t('pages.schedule.runScheduleAction')
            : t('pages.schedule.stopScheduleAction')
        }
        flow='left'
        onClick={() => {
          props.action({ scheduleId: +props.schedule.id, action: props.schedule.action === 'stop' ? 'run' : 'stop' })
        }}
      >
        <i className={props.schedule.action === 'stop' ? 'fas fa-play' : 'fas fa-stop'}></i>
      </div>
      <EditScheduleModal
        toggle={toggleEdit}
        show={show}
        schedule={props.schedule}
        key={props.schedule ? props.schedule.id : 0}
      />
      <div
        className={'actions__item'}
        tooltip={t('pages.schedule.deleteScheduleAction')}
        flow='left'
        onClick={() => {
          setShowAlertDelete(true)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
      <LogsScheduleModal
        toggle={toggleEditLogs}
        show={showLogs}
        schedule={props.schedule}
        key={props.schedule ? props.schedule.id + 'logs' : 'logs'}
      />
    </div>
  )
}

const mapDispatchToProps = {
  delete: actions.deleteSchedule,
  action: actions.actionSchedule,
}

export default connect(null, mapDispatchToProps)(ScheduleActions)
