import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import WalletsActions from '../components/WalletsActions'
import * as actions from '../../MiningPresets/actions'
import { Table } from '../../../components/Table'
import { CreateWalletModal } from '../../Wallets/components/modals'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const WalletsPage = ({ walletsList, setCoinsList, setWalletsList, totalWallets }) => {
  const [wallets, setWallets] = useState([])
  const [createWalletModal, setCreateWalletModal] = useState(false)
  const { t } = useTranslation('common')
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )

  useEffect(() => {
    setWalletsList({ page: currentPage, size: showCount })
  }, [setWalletsList, currentPage, showCount])

  useEffect(() => {
    setCoinsList({ page: 1, size: 1000, orderBy: 'nameSymbol' })
  }, [setCoinsList, setWalletsList])

  useEffect(() => {
    const walletsArray = walletsList.map(wallet => {
      return {
        ...wallet,
        action: <WalletsActions wallet={wallet} />,
      }
    })
    setWallets(walletsArray)
  }, [walletsList])

  const walletsData = {
    columns: [
      {
        label: t('pages.wallets.id'),
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.wallets.name'),
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.wallets.address'),
        field: 'address',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.wallets.coin'),
        field: 'coin',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.wallets.action'),
        field: 'action',
        width: 10,
      },
    ],
    rows: wallets,
  }

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='card-body'>
              <h3>{t('pages.wallets.wallets')}</h3>
              <div className={'d-flex justify-content-end'}>
                <Button
                  onClick={() => setCreateWalletModal(!createWalletModal)}
                  className={'mining_preset__create'}
                  color='primary'
                >
                  {t('pages.wallets.add')}
                </Button>
                <CreateWalletModal
                  toggleWallet={() => setCreateWalletModal(!createWalletModal)}
                  isOpenWallet={createWalletModal}
                />
              </div>
            </div>
            <Table
              className={'dc__table'}
              responsive={true}
              data={walletsData}
              total={totalWallets}
              setShowCount={setShowCount}
              showCount={showCount}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  walletsList: store.presets.walletsList,
  totalWallets: store.presets.totalWallets,
})

const mapDispatchToProps = {
  setWalletsList: actions.setWalletsList,
  setCoinsList: actions.setCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletsPage)
