export const types = {
  SET_DATACENTERS_LIST: 'dataCenters/setList',
  SET_DATACENTERS_RIGS_LIST: 'dataCenters/rigsList',
  SET_ROOMS_LIST: 'rooms/setList',
  CREATE_DATACENTER: 'dataCenters/createDataCenter',
  CREATE_ROOM: 'rooms/createRoom',
  UPDATE_ROOM: 'rooms/updateRoom',
  DELETE_ROOM: 'rooms/deleteRoom',
  UPDATE_DATACENTER: 'dataCenters/updateDataCenter',
  DELETE_DATACENTER: 'dataCenters/deleteDataCenter',
}
