export const types = {
  CREATE_OVERCLOCKING_PROFILE: 'profiles/createOverclockingPreset',
  UPDATE_OVERCLOCKING_PROFILE: 'profiles/updateOverclockingPreset',
  SET_PROFILES_DATA: 'profiles/overclockingProfilesList',
  DELETE_PROFILE: 'profiles/deleteOverclockingPreset',
  APPLY_OVERCLOCKING_PROFILE: 'profiles/setOverclockingPreset',
  SET_ALGOCOINS_DATA: 'profiles/algoCoinsList',
  CREATE_SLOT: 'slots/createOverclockingSlot',
  UPDATE_SLOT: 'slots/updateOverclockingSlot',
  DELETE_SLOT: 'slots/deleteOverclockingSlot',
  SET_SLOTS_DATA: 'slots/OverclockingSlotList',
  ASSIGN_SLOT: 'slots/assign_slot',
}
