export const types = {
  SET_COINS_LIST: 'coins/setList',
  CREATE_COIN: 'coins/createCoin',
  UPDATE_COIN: 'coins/updateCoin',
  DELETE_COIN: 'coins/deleteCoin',

  SET_ALGOCOINS_LIST: 'algoCoins/setList',
  CREATE_ALGOCOIN: 'algoCoins/createAlgoCoin',
  UPDATE_ALGOCOIN: 'algoCoins/updateAlgoCoin',
  DELETE_ALGOCOIN: 'algoCoins/deleteAlgoCoin',

  SET_INVITECODES_LIST: 'inviteCodes/setList',
  CREATE_INVITECODE: 'inviteCodes/createInviteCode',
  UPDATE_INVITECODE: 'inviteCodes/updateInviteCode',
  DELETE_INVITECODE: 'inviteCodes/deleteInviteCode',

  SET_POOLS_LIST: 'pools/setList',
  CREATE_POOL: 'pools/createPool',
  UPDATE_POOL: 'pools/updatePool',
  DELETE_POOL: 'pools/deletePool',

  SET_MINERS_LIST: 'miners/setList',
  CREATE_MINER: 'miners/createMiner',
  UPDATE_MINER: 'miners/updateMiner',
  DELETE_MINER: 'miners/deleteMiner',
}
