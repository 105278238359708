import React, { useState } from 'react'
import { MiningPresetModalEdit } from './modals'
import { connect } from 'react-redux'
import * as actions from '../actions'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { useTranslation } from 'react-i18next'

const MiningPresetActions = props => {
  const { t } = useTranslation('common')
  const [showMiningPreset, setShowMiningPreset] = useState(false)

  const toggleMiningPresetEdit = () => {
    setShowMiningPreset(!showMiningPreset)
  }
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const [showAlertCopy, setShowAlertCopy] = useState(false)
  const deletePresetFunc = async () => {
    setShowAlertDelete(false)
    await props.deleteMiningPreset(props.preset.id)
  }
  const copyPresetFunc = async () => {
    setShowAlertCopy(false)
    await props.copyMiningPreset(props.preset.id)
  }

  return (
    <div className={'actions__items'}>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertDelete}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          deletePresetFunc()
        }}
        onCancel={() => {
          setShowAlertDelete(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        {t('pages.miningPresets.presetRemovedPermanently')}
      </SweetAlert>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertCopy}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          copyPresetFunc()
        }}
        onCancel={() => {
          setShowAlertCopy(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        {t('pages.miningPresets.presetCopied')}
      </SweetAlert>
      <div
        className={'actions__item'}
        tooltip={t('pages.miningPresets.copyPreset')}
        flow='left'
        onClick={() => {
          setShowAlertCopy(true)
        }}
      >
        <i className='fas fa-copy'></i>
      </div>
      <div
        className={'actions__item'}
        tooltip={t('pages.miningPresets.editMiningPreset')}
        flow='left'
        onClick={toggleMiningPresetEdit}
      >
        <i className='fas fa-pencil'></i>
      </div>
      {showMiningPreset && (
        <MiningPresetModalEdit toggle={toggleMiningPresetEdit} show={showMiningPreset} preset={props.preset} />
      )}
      <div
        className={'actions__item'}
        tooltip={t('pages.miningPresets.deletePreset')}
        flow='left'
        onClick={() => {
          setShowAlertDelete(true)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  deleteMiningPreset: actions.deleteMiningPreset,
  copyMiningPreset: actions.copyMiningPreset,
}

export default connect(null, mapDispatchToProps)(MiningPresetActions)
