import { types } from './types'
const initState = {
  rigsList: [],
  rigsMPs: [],
  logsList: [],
  rigStatList: [],
  rig: {},
  newRig: null,
  deleteRigId: null,
  overclockingData: {},
  total: 0,
  cardTotal: 0,
  logsTotal: 0,
  rigStatTotal: 0,
  filter: '',
  filterApplied: false,
  search: '',
  isRigsListDownload: false,
  rigsStat: {
    isDownloadRigsStats: false,
    gpu_consumption: 0,
    card_count: 0,
    card_count_nvidia: 0,
    card_count_amd: 0,
    card_count_offline: 0,
    rig_count: 0,
    rig_count_offline: 0,
    rig_count_alert: 0,
    max_temp: 0,
    avg_temp: 0,
    min_temp: 0,
    min_temp_cycle: 1000,
    algorithms: [],
    hot_cards: 0,
    problem_cards: 0,
    maxTempRig: 0,
    not_detected_cards: 0,
  },
  versions: {
    osVersions: [],
    drivers: [],
  },
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_DOWNLOAD_RIGS: {
      const { data } = action
      console.log('🚀 ~ reducer ~ data:', data)
      const stateCopy = { ...state }
      stateCopy.isRigsListDownload = data
      return {
        ...stateCopy,
      }
    }

    case types.SET_FILTER_APPLIED: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.filterApplied = data
      return {
        ...stateCopy,
      }
    }

    case types.SET_RIGS_STATS: {
      const { data } = action

      const stateCopy = { ...state }
      stateCopy.rigsStat = data
      if (!stateCopy.rigsStat.isDownloadRigsStats) {
        stateCopy.rigsStat.isDownloadRigsStats = true
      }
      return {
        ...stateCopy,
      }
    }

    case types.SET_WORKERS_MPS: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.rigsMPs = data.split(',')
      return {
        ...stateCopy,
      }
    }

    case types.SET_RIGS_LIST_FILTERED: {
      const { data, search, filter, dopFilter, isRigsListDownload } = action

      const stateCopy = { ...state }
      if (stateCopy.newRig) {
        if (data.items.find(el => el.id === stateCopy.newRig.id)) {
          stateCopy.rigsList = [...data.items]
        } else {
          stateCopy.rigsList = [...data.items, stateCopy.newRig]
        }
        stateCopy.newRig = null
      } else if (stateCopy.deleteRigId) {
        stateCopy.rigsList = data.items.filter(rig => +rig.id !== stateCopy.deleteRigId)
        stateCopy.deleteRigId = null
      } else {
        stateCopy.rigsList = data.items
      }
      return {
        ...stateCopy,
        isRigsListDownload,
        search,
        filter,
        dopFilter,
        total: data.total,
      }
    }

    case types.SET_RIGS_LIST: {
      const { data, search, filter, dopFilter } = action

      const stateCopy = { ...state }
      if (state.filterApplied) {
        return stateCopy
      }
      if (stateCopy.newRig) {
        if (data.items.find(el => el.id === stateCopy.newRig.id)) {
          stateCopy.rigsList = [...data.items]
        } else {
          stateCopy.rigsList = [...data.items, stateCopy.newRig]
        }
        stateCopy.newRig = null
      } else if (stateCopy.deleteRigId) {
        stateCopy.rigsList = data.items.filter(rig => +rig.id !== stateCopy.deleteRigId)
        stateCopy.deleteRigId = null
      } else {
        stateCopy.rigsList = data.items
      }
      return {
        ...stateCopy,
        search,
        filter,
        dopFilter,
        total: data.total,
      }
    }

    case types.SET_RIGS_DATA: {
      const { data } = action
      const stateCopy = { ...state }
      if (state.filterApplied) {
        return stateCopy
      }
      if (stateCopy.newRig) {
        if (data.items.find(el => el.id === stateCopy.newRig.id)) {
          stateCopy.rigsList = [...data.items]
        } else {
          stateCopy.rigsList = [...data.items, stateCopy.newRig]
        }
        stateCopy.newRig = null
      } else if (stateCopy.deleteRigId) {
        stateCopy.rigsList = data.items.filter(rig => +rig.id !== stateCopy.deleteRigId)
        stateCopy.deleteRigId = null
      } else {
        stateCopy.rigsList = data.items
      }

      return {
        ...stateCopy,
        total: data.total,
      }
    }

    case types.OVERCLOCKING_CARDS: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = stateCopy.rigsList.map(rig => {
        if (rig.id === data.id) {
          return {
            ...rig,
            overclockingAlgo: data.overclockingAlgo,
            onGodAnEthArgPill: data.onGodAnEthArgPill,
            amdmemorytweak: data.amdmemorytweak,
            overclockingPresetType: data.overclockingPresetType,
            overclockingAllCards: data.overclockingAllCards,
          }
        }
        return { ...rig }
      })
      stateCopy.rigsList = rigsListCopy
      return {
        ...stateCopy,
      }
    }

    case types.REBOOT_POWEROF_RIG: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.rigsList]
      stateCopy.rigsList = rigsListCopy.map(rig => {
        if (+rig.id === data.rigId) {
          return {
            ...rig,
            powerOf: data.powerOf,
            reboot: data.reboot,
            hardReboot: data.hardReboot,
          }
        }
        return rig
      })
      return {
        ...stateCopy,
      }
    }

    case types.RIG_ERRORS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      const stateRig = { ...stateCopy.rig }

      return {
        ...stateCopy,
        rig: {
          ...stateRig,
          rigErrors: data.items,
        },
      }
    }

    ////////// Get Rig for Worker Info Modal ///////////////////////////////////////////
    case types.SET_WORKER_INFORMATION: {
      const { data } = action
      const stateCopy = { ...state }
      data.powerInfo = 0
      data.eff =
        +data.gpuTotalSpeed <= 0 ||
        !data.active ||
        !data.videoCards ||
        data.action === 'stop' ||
        data.action === 'stopError'
          ? 0
          : data.gpuTotalShares === 'null' && (+data.gpuRej === 0 || !data.gpuRej || data.gpuRej === 'null')
          ? 100
          : +data.gpuTotalShares == 0 || !data.gpuTotalShares || data.gpuTotalShares == 'null'
          ? 0
          : isNaN(((data.gpuTotalShares - data.gpuRej - data.gpuInv) / +data.gpuTotalShares) * 100)
          ? 0
          : (((data.gpuTotalShares - data.gpuRej - data.gpuInv) / +data.gpuTotalShares) * 100).toFixed(2)
      data.videoCards &&
        data.videoCards.forEach(card => {
          if (card.detected) {
            data.powerInfo += +card.powerInfo
          }
        })
      data.hashRateValue = 0
      data.hashRateValueDual = 0
      return {
        ...stateCopy,
        rig: {
          ...data,
        },
      }
    }

    case types.SET_WORKER_TIME: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        rig: { ...stateCopy.rig, uptime: data.uptime, downTime: data.downTime },
      }
    }

    ////////// Get Rig for Card Turbo Modal ////////////////////////////////////////////
    case types.SET_OVERCLOCKING_DATA: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        overclockingData: data,
      }
    }

    /////////// DELETE_RIG /////////////////////////////////////////////////////////////
    case types.DELETE_RIG: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.rigsList]
      stateCopy.rigsList = rigsListCopy.filter(rig => +rig.id !== data)
      stateCopy.deleteRigId = data
      return {
        ...stateCopy,
      }
    }

    /////////// DELETE_CARD /////////////////////////////////////////////////////
    case types.DELETE_CARD: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        rigsList: stateCopy.rigsList.map(rig => {
          if (data && rig.id === data.rigId) {
            return {
              ...rig,
              videoCards: rig.videoCards.filter(card => card.id !== data.cardId),
            }
          } else return { ...rig }
        }),
        rig: {
          ...stateCopy.rig,
          videoCards: stateCopy.rig.videoCards.filter(card => card.id !== data.cardId),
        },
      }
    }

    /////////// DELETE_CARD /////////////////////////////////////////////////////
    case types.OFF_MINING_CARD: {
      const { data } = action
      const stateCopy = { ...state }
      const rig = { ...stateCopy.rig }
      return {
        ...stateCopy,
        rig: {
          ...rig,
          videoCards: rig.videoCards.map(el => {
            if (+el.id === data.cardId) {
              el.offMining = data.offMining
              return el
            }
            return el
          }),
        },
      }
    }

    /////////// CREATE_RIG /////////////////////////////////////////////////////////////
    case types.CREATE_RIG: {
      const { data } = action

      const stateCopy = { ...state }
      const rigsListCopy = [
        ...stateCopy.rigsList.map(rig => {
          return { ...rig, videoCards: rig.videoCards }
        }),
      ]
      stateCopy.rigsList = [
        ...rigsListCopy,
        {
          ...data,
          password: data.password,
          gpuRej: '0',
          powCons: '0',
          gpuTotalSpeed: '0',
          gpuTotalShares: '0',
          gpuErr: '0',
          gpuTotalTime: '0d 00h 00m 00ss',
        },
      ]
      stateCopy.newRig = {
        ...data,
        password: data.password,
        gpuRej: '0',
        powCons: '0',
        gpuTotalSpeed: '0',
        gpuTotalShares: '0',
        gpuErr: '0',
        gpuTotalTime: '0d 00h 00m 00ss',
      }
      return {
        ...stateCopy,
      }
    }

    //////////////////////////// Update RIG
    case types.UPDATE_RIG: {
      const { data } = action

      const rigIndex = state.rigsList.findIndex(({ id }) => +id === +data.id)
      const updateRig = state.rigsList[rigIndex]
      const rig = state.rig

      const updatedRig = {
        ...updateRig,
        name: data.name,
      }

      if (rigIndex < 0) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          rig: { ...rig, name: data.name },
          rigsList: [...state.rigsList.slice(0, rigIndex), updatedRig, ...state.rigsList.slice(rigIndex + 1)],
        }
      }
    }
    ///////////////////////////////////////////////////////////////
    case types.SET_ACTION_PRESET_RIG: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        rigsList: stateCopy.rigsList.map(rig => {
          if ((data && rig.id === data.id) || (data.actionRig && rig.id === data.actionRig.id)) {
            if (data.actionRig) {
              return {
                ...rig,
                miningPreset: { id: data.actionRig.miningPresetId },
                coin: data.actionRig.coin,
                coinDual: data.actionRig.coinDual ? data.actionRig.coinDual : '',
                miner: data.actionRig.miner,
                miningPresetId: data.actionRig.miningPresetId,
                action: data.actionRig.action,
                backLight: data.actionRig.backLight,
              }
            } else {
              return {
                ...rig,
                miningPreset: { id: data.miningPresetId },
                coin: data.coin,
                coinDual: data.coinDual ? data.coinDual : '',
                miner: data.miner,
                miningPresetId: data.miningPresetId,
                action: data.action,
                backLight: data.backLight,
              }
            }
          } else return { ...rig }
        }),
      }
    }

    case types.SET_CONSOLE: {
      const { data } = action
      const stateCopy = { ...state }

      return {
        ...stateCopy,
        rig: { ...stateCopy.rig, getReqFromRigConsole: data.getReqFromRigConsole, rigConsole: data.rigConsole },
      }
    }

    case types.GET_CONSOLE_DATA: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        rig: { ...stateCopy.rig, rigConsole: data.rigConsole, getReqFromRigConsole: data.getReqFromRigConsole },
      }
    }

    //////////////////////////////////////////////////////// Get Worker Logs List
    case types.RIG_LOGS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.logsList = data.items
      return {
        ...stateCopy,
      }
    }

    /////////////////////////////////////////////////////////// Create RIG SETTINGS
    case types.CREATE_RIG_SETTINGS: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [
        ...stateCopy.rigsList.map(rig => {
          if (rig.id === data.rigId) {
            return { ...rig, rigSettings: data, rigSettingsId: data.id }
          }
          return { ...rig }
        }),
      ]
      stateCopy.rigsList = [...rigsListCopy]
      return {
        ...stateCopy,
      }
    }

    /////////////////////////////////////////////////////// Clear activity worker logs
    case types.DELETE_LOGS: {
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        logsList: [],
        logsTotal: 0,
      }
    }

    ///////////////////////////////////////////////////// Update RIG ERROR
    case types.UPDATE_RIGERROR: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.rigsList]

      stateCopy.rigsList = rigsListCopy.map(el => {
        if (el.id === stateCopy.rig.id) {
          el.errorsTotal -= data.split(',').length
        }
        return el
      })

      const rigErrorsCopy = stateCopy.rig.rigErrors
      stateCopy.rig.rigErrors = rigErrorsCopy.filter(error => !data.split(',').includes(error.id))

      return {
        ...stateCopy,
      }
    }

    //////////////////////////////////////// Get Rig Stats List (Chart-Line Zoom)
    case types.SET_RIG_STATS: {
      const { data } = action
      const stateCopy = { ...state }
      if (data.items) {
        stateCopy.rigStatList = data.items
      } else {
        stateCopy.rigStatList = []
      }
      return {
        ...stateCopy,
        rigStatTotal: data.total,
      }
    }

    case types.SET_VERSIONS: {
      const { data } = action
      const stateCopy = { ...state }
      if (data.type === 'os') {
        stateCopy.versions.osVersions = data.items
      }
      if (data.type === 'driver') {
        stateCopy.versions.drivers = data.items
      }

      return {
        ...stateCopy,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
