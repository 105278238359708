import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const WorkerCreatedMessageModal = props => {
  const { t } = useTranslation('common')
  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} className={'modal-dialog-centered'}>
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.workers.workerSetupInfo')}
        </ModalHeader>
        <ModalBody style={{ color: '#a8b2bc' }}>
          <div style={{ margin: '3% 0' }}>{t('pages.workers.congrats')}</div>
          <div style={{ margin: '4% 0' }}>
            {t('pages.workers.downloadImagesNVIDIA')}{' '}
            <a
              style={{ color: 'white' }}
              href={'https://repos.amos.farm/amos_nv.img.xz'}
              target={'_blank'}
              rel='noopener noreferrer'
            >
              {t('pages.workers.here')}
            </a>{' '}
            {t('pages.workers.andForAMD')}{' '}
            <a
              style={{ color: 'white' }}
              href={'https://repos.amos.farm/amos_amd.img.xz'}
              target={'_blank'}
              rel='noopener noreferrer'
            >
              {t('pages.workers.here')}
            </a>{' '}
            {t('pages.workers.installationGuide')} <br />
            {t('pages.workers.goodLuck')}
          </div>
          <div style={{ margin: '3% 0' }}>
            {t('pages.workers.workerID')}: <span style={{ color: 'white' }}>{props.rig.id}</span>
          </div>
          <div style={{ margin: '1% 0' }}>
            {t('pages.workers.workerPassword')}: <span style={{ color: 'white' }}>{props.rig.password}</span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.workers.ok')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default WorkerCreatedMessageModal
