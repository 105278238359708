import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

const EditServerModal = props => {
  const { t } = useTranslation('common')
  const [country, setCountry] = useState(props.server.country)
  const [address, setAddress] = useState(props.server.address)
  const [type, setType] = useState(props.server.type)
  const [coin, setCoin] = useState(props.server.coin)

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'country': {
        setCountry(e.target.value)
        return
      }
      case 'address': {
        setAddress(e.target.value)
        return
      }
      case 'type': {
        setType(e.target.value)
        return
      }
      case 'coin': {
        setCoin(e.target.value)
        return
      }
      default:
        return
    }
  }

  const [errors, setErrors] = useState({})

  const submitFunc = () => {
    const newErrors = {}
    if (address.length === 0) newErrors.address = { message: t('pages.customPools.serverAddressError') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    props.updateServer({
      id: props.server.id,
      country: country,
      address: address,
      type: type,
      coin: coin,
      order: props.server.order,
    })
    props.toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'actions__item'} tooltip={t('pages.customPools.editServer')} flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.customPools.editServer')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={8} className={'form-group'}>
                <Label>{t('pages.customPools.IPAddress')}</Label>
                <Input
                  className={errors.address ? 'invalid' : ''}
                  type={'text'}
                  name={'address'}
                  onChange={onChange}
                  value={address}
                />
                <div className={'error'}>{errors.address && errors.address.message}</div>
              </Col>
              <Col xs={4} className={'form-group'}>
                <Label>{t('pages.customPools.countryRegion')}</Label>
                <Input
                  className={errors.country ? 'invalid' : ''}
                  type={'text'}
                  name={'country'}
                  onChange={onChange}
                  value={country}
                />
                <div className={'error'}>{errors.country && errors.country.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.customPools.cancel')}
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            {t('pages.customPools.save')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default connect(null, null)(EditServerModal)
