import React, { useState } from 'react'
import { Input } from 'reactstrap'

const UiRangeSliderSpecial = props => {
  const [valOld, setValOld] = useState(props.default)
  return (
    <div className={props.className}>
      <Input
        onChange={e => {
          props.setValue(e.target.value, props.card)
          if (!isNaN(parseFloat(e.target.value))) {
            setValOld(parseFloat(e.target.value))
          }
        }}
        onBlur={e => {
          let s = e.target.value
          console.log('🚀 ~ file: RangeSliderSpecial.js:17 ~ UiRangeSliderSpecial ~ s:', s)
          if (parseFloat(s) === 0) {
            setValOld('0')
            props.setValue('0', props.card)
          } else {
            if (s.length === 0) {
              s = '0'
              if (props.isFan) s = '60'
              setValOld(s)
              props.setValue(s, props.card)
            } else if (!isNaN(parseFloat(s))) {
              s = parseFloat(s)
              if (s < props.min) s = props.min.toString()
              if (s > props.max) s = props.max.toString()
              props.setValue(s, props.card)
              setValOld(s)
            } else {
              props.setValue(valOld, props.card)
            }
          }
        }}
        value={props.default}
        step={props.step}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
        tabIndex={props.tabindex}
      />
    </div>
  )
}

export default UiRangeSliderSpecial
