import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Label, Input } from 'reactstrap'

const EditMinerParamModal = props => {
  const [paramName, setParamName] = useState(props.param ? props.param.paramName : '')
  const [constsLabels, setConstsLabels] = useState(
    props.param && props.param.consts
      ? props.param.consts
          .reverse()
          .map(constant => {
            return constant.paramLabel
          })
          .join('\n')
      : '',
  )
  const [constsValues, setConstsValues] = useState(
    props.param && props.param.consts
      ? props.param.consts
          .map(constant => {
            return constant.paramValue
          })
          .join('\n')
      : '',
  )

  const onChange = e => {
    if (e.target.value.length > 0) {
      const newErrors = errors
      delete newErrors[e.target.name]
      setErrors(newErrors)
    }
    switch (e.target.name) {
      case 'paramName': {
        setParamName(e.target.value)
        return
      }
      case 'constsLabels': {
        setConstsLabels(e.target.value)
        return
      }
      case 'constsValues': {
        setConstsValues(e.target.value)
        return
      }
      default:
        return
    }
  }

  const [errors, setErrors] = useState({})

  const submitFunc = () => {
    const newErrors = {}
    if (paramName.length === 0) newErrors.paramName = { message: "Param Address can't be empty" }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return

    const consts = []
    const constsLabelsArray = constsLabels.split('\n')
    const constsValuesArray = constsValues.split('\n')
    for (let i = constsValuesArray.length - 1; i >= 0; i--) {
      consts.push({
        paramLabel: constsLabelsArray[i],
        paramValue: constsValuesArray[i],
      })
    }
    props.updateParam({
      id: props.param.id,
      paramName: paramName,
      consts: consts,
      order: props.param.order,
    })
    props.toggle()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={'actions__item'} tooltip='Edit Miner Param' flow='left' onClick={props.toggle}>
        <i className='fas fa-pencil'></i>
      </div>
      <Modal
        isOpen={props.show}
        toggle={props.toggle}
        className={'modal-dialog-centered'}
        onClick={e => e.stopPropagation()}
      >
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          Edit Param
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} className={'form-group'}>
                <Label>Param Name</Label>
                <Input
                  className={errors.paramName ? 'invalid' : ''}
                  type={'text'}
                  name={'paramName'}
                  onChange={onChange}
                  value={paramName}
                />
                <div className={'error'}>{errors.paramName && errors.paramName.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>Consts Labels</Label>
                <textarea
                  className={errors.constsLabels ? 'invalid form-control' : 'form-control'}
                  type={'text'}
                  name={'constsLabels'}
                  onChange={onChange}
                  value={constsLabels}
                  style={{ minHeight: 150 }}
                ></textarea>
                <div className={'error'}>{errors.constsLabels && errors.constsLabels.message}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>Consts Values</Label>
                <textarea
                  className={errors.constsValues ? 'invalid form-control' : 'form-control'}
                  type={'text'}
                  name={'constsValues'}
                  onChange={onChange}
                  value={constsValues}
                  style={{ minHeight: 150 }}
                ></textarea>
                <div className={'error'}>{errors.constsValues && errors.constsValues.message}</div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={props.toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => submitFunc()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default connect(null, null)(EditMinerParamModal)
