import { types } from './types'

const initState = {
  id: null,
  name: '',
  email: '',
  token: null,
  refreshTocen: null,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_AUTH_USER:
      return {
        ...state,
        id: action.data.id,
        name: action.data.userName,
        roles: action.data.roles,
        email: action.data.email,
        refreshTocen: action.data.refreshToken,
        token: action.data.token,
        telegramCode: action.data.telegramCode,
      }
    default: {
      return state
    }
  }
}

export default reducer
