import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Col, Form, Input, Row } from 'reactstrap'
import { Modal } from 'components'
import * as actions from '../../actions'
import { useTranslation } from 'react-i18next'

const DeleteDataCenter = props => {
  const [errors, setErrors] = useState({})
  const { t } = useTranslation('common')
  const submitButton = useRef()

  const submitFunc = () => {
    submitButton.current.click()
  }

  const handlerDCRemove = async event => {
    event.preventDefault()
    let isValid = true
    const formCheckboxes = event.currentTarget.elements
    const dataCenterCheckbox = {
      rpc: formCheckboxes.rpc,
      pdu: formCheckboxes.pdu,
      linked: formCheckboxes.linked,
      stats: formCheckboxes.stats,
      unlink: formCheckboxes.unlink,
    }

    Object.keys(dataCenterCheckbox).forEach(key => {
      if (!dataCenterCheckbox[key].checked) {
        isValid = false
        errors[key] = t('pages.dcManagement.shouldBeChecked')
      } else {
        delete errors[key]
      }
    })

    if (!event.currentTarget.elements.pass.value) {
      isValid = false
      errors.pass = t('pages.dcManagement.error')
    } else {
      delete errors.pass
    }

    setErrors({ ...errors })

    if (isValid) {
      const isDeleted = await props.deleteDataCenter({
        dataCenterId: +props.dataCenter.id,
      })
      isDeleted && props.onFinish()
    }
  }

  return (
    <Modal
      title={`${t('pages.dcManagement.deleteDC')} - ${props.dataCenter.name}`}
      subTitle={`${t('pages.dcManagement.areYouSure')} ${props.dataCenter.name}?`}
      onFinish={submitFunc}
      finishTitle={t('pages.dcManagement.delete')}
      cancelTitle={t('pages.dcManagement.cancel')}
      isOpen={props.isActive}
      onCancel={props.onFinish}
    >
      <Form onSubmit={handlerDCRemove}>
        <Row className={'form-content'}>
          <div className={'form-agree'}>
            <div className={'form-item'}>
              <div className={'form-item__check'}>
                <input
                  type='checkbox'
                  className={`${errors.rpc ? 'form-item__checkbox_error' : 'form-item__checkbox'}`}
                  name='rpc'
                />
                {t('pages.dcManagement.iConfirmRPC')}
              </div>
            </div>

            <div className={'form-item'}>
              <div className={'form-item__check'}>
                <input
                  type='checkbox'
                  className={`${errors.pdu ? 'form-item__checkbox_error' : 'form-item__checkbox'}`}
                  name='pdu'
                />
                {t('pages.dcManagement.iConfirmPDU')}
              </div>
            </div>
            <div className={'form-item'}>
              <div className={'form-item__check'}>
                <input
                  type='checkbox'
                  className={`${errors.linked ? 'form-item__checkbox_error' : 'form-item__checkbox'}`}
                  name='linked'
                />
                {t('pages.dcManagement.iConfirmWorkers')}
              </div>
            </div>
            <div className={'form-item'}>
              <div className={'form-item__check'}>
                <input
                  type='checkbox'
                  className={`${errors.stats ? 'form-item__checkbox_error' : 'form-item__checkbox'}`}
                  name='stats'
                />
                {t('pages.dcManagement.iConfirmStat')}
              </div>
            </div>
            <div className={'form-item'}>
              <div className={'form-item__check'}>
                <input
                  type='checkbox'
                  className={`${errors.unlink ? 'form-item__checkbox_error' : 'form-item__checkbox'}`}
                  name='unlink'
                />
                {t('pages.dcManagement.iWantUnlink')}
              </div>
            </div>
          </div>
          <Col xs={6} className={'dc-form-group'}>
            <div className={'form-item'}>
              <p className={'form-item__label'}> {t('pages.dcManagement.enterSupervisorPassword')}</p>
              <div className={'form-item__input'}>
                <Input
                  invalid={!!errors.pass}
                  type={'text'}
                  name={'pass'}
                  placeholder={t('pages.dcManagement.supervisorPassword')}
                  className='dc-input'
                />
                <div className={'error'}>{errors.pass}</div>
              </div>
            </div>
          </Col>
        </Row>
        <button type='submit' ref={submitButton} style={{ width: 0, height: 0, border: 'none', opacity: 0 }} />
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  deleteDataCenter: actions.deleteDataCenter,
}

export default connect(null, mapDispatchToProps)(DeleteDataCenter)
