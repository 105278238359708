import { types } from './types'

import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'

const LOGIN_QUERY = `
  mutation Login($email: String!, $password: String!, $captchaToken: String!) {
    login(email: $email, password: $password, captchaToken: $captchaToken) {
      token
      refreshToken
      email
      userName
      inviteCode
      telegramCode
      roles
    }
  }
`

const ME_QUERY = `
  query {
    me {
      id
      token
      refreshToken
      email
      userName
      inviteCode
      telegramCode
      roles
    }
  }
`
const RESET_PASS = `
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`
const UPDATE_PASSWORD = `
  mutation updatePassword($userId: ID!, $oldPass: String!, $newPass: String!) {
    updatePassword(userId: $userId, oldPass: $oldPass, newPass: $newPass)
  }
`

const DELETE_ACCOUNT = `
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`

export const login =
  ({ email, password, history, captchaToken }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: LOGIN_QUERY,
        variables: { email, password, captchaToken },
      })

      guardFromErrors(errors)
      if (!data && data.login === null) {
        throw new Error('Response body is empty')
      }
      if (data && data.login === null) {
        throw new Error('Login or Password incorrect')
      }

      const { token, refreshToken, roles } = data.login
      localStorage.setItem('tokenStorage', JSON.stringify({ token, refreshToken, roles }))
      dispatch({
        type: types.SET_AUTH_USER,
        data: login,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      history.push(`${process.env.PUBLIC_URL}/`)
      return {
        status: true,
        error: '',
      }
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const resetPassword =
  ({ email, history }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: RESET_PASS,
        variables: { email },
      })
      console.log('error: ', errors)
      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      if (!data.resetPassword) {
        if (errors)
          errors.map(error => {
            dispatch(addAlert(error.message, 'danger'))
            return error
          })
        dispatch({
          type: 'SET_LOADING_STATE',
          active: false,
        })
        return false
      }
      dispatch(addAlert('New Password was sent to Your email', 'success'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      setTimeout(() => {
        history.push(`${process.env.PUBLIC_URL}/login`)
      }, 3000)
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const getMe =
  ({ token, refreshToken }) =>
  async dispatch => {
    try {
      const { data } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: ME_QUERY,
      })

      guardFromErrors(data.errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      const { me } = data
      if (!me) {
        throw new Error('Unauthorized!')
      }

      dispatch({
        type: types.SET_AUTH_USER,
        data: { ...me, token, refreshToken },
      })
    } catch (e) {
      const errorMessage = extractErrorInfo(e)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      // dispatch(errorNotification(errorMessage))
    }
  }

export const logOut = history => async dispatch => {
  localStorage.removeItem('tokenStorage')
  dispatch({ type: 'LOG_OUT' })
  history.push(`${process.env.PUBLIC_URL}/login`)
  window.location.href = '/login'
}

export const changePassword = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_PASSWORD,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty')
    }

    const { updatePassword } = data
    if (!updatePassword) {
      throw new Error("Can't change Password")
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Password was successfully updated', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const deleteAccount =
  ({ userId, history }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      //console.log(userId)
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DELETE_ACCOUNT,
        variables: { userId },
      })
      console.log('error: ', errors)
      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty')
      }

      if (!data.deleteUser) {
        if (errors)
          errors.map(error => {
            dispatch(addAlert(error.message, 'danger'))
            return error
          })
        dispatch({
          type: 'SET_LOADING_STATE',
          active: false,
        })
        return false
      }
      dispatch(addAlert('Account terminated', 'success'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      history.push(`${process.env.PUBLIC_URL}/login`)
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }
