import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import * as actions from '../actions'
import { Table } from '../../../components/Table'

const RigsDataPage = ({ setRigsList, rigsList, rigCardsList }) => {
  const [rigCards, setRigCards] = useState([])

  useEffect(() => {
    setRigsList({ page: 1, size: 10000, orderBy: 'id' })
  }, [setRigsList])

  useEffect(() => {
    const rigCardsArray = rigCardsList.map(card => {
      return {
        ...card,
        action: <div className={'actions__item'}></div>,
      }
    })
    setRigCards(rigCardsArray)
  }, [rigCardsList])

  const rigsData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'IP',
        field: 'ip',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'Name',
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'userId',
        field: 'userId',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'Password',
        field: 'password',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'rigMac',
        field: 'rigMac',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'cpu',
        field: 'cpu',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'disk',
        field: 'disk',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'mb',
        field: 'mb',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'ram',
        field: 'ram',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'miningString',
        field: 'miningString',
      },
    ],
    rows: rigsList,
  }

  const rigsStat = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'Name',
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'gpuTotalSpeed',
        field: 'gpuTotalSpeed',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'gpuTotalShares',
        field: 'gpuTotalShares',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'gpuRej',
        field: 'gpuRej',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'powCons',
        field: 'powCons',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'gpuTotalTime',
        field: 'gpuTotalTime',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'gpuErr',
        field: 'gpuErr',
        sort: true,
        sortType: 'string',
      },
    ],
    rows: rigsList,
  }

  const cardsStat = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'cardType',
        field: 'cardType',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'hashCard',
        field: 'hashCard',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'blocksNumber',
        field: 'blocksNumber',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'badBlocsNumber',
        field: 'badBlocsNumber',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'gpuTemp',
        field: 'gpuTemp',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'fanInfo',
        field: 'fanInfo',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'gpuLoad',
        field: 'gpuLoad',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'GPU Clock',
        field: 'gpuClockStat',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'MEM Clock',
        field: 'memClockStat',
        sort: true,
        sortType: 'int',
      },
    ],
    rows: rigCardsList,
  }

  const rigCardsData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'rigId',
        field: 'rigId',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'cardType',
        field: 'cardType',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'gpuName',
        field: 'gpuName',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'vram',
        field: 'vram',
        sort: true,
        sortType: 'string',
      },
    ],
    rows: rigCards,
  }
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h3>Rigs table</h3>
                <Table name={'rigsData'} data={rigsData} responsive />
                <h3>Rigs stat</h3>
                <Table name={'rigsStat'} responsive data={rigsStat} />
                <h3>Rig Cards table</h3>
                <Table name={'rigCards'} responsive data={rigCardsData} />
                <h3>Rig Cards stat</h3>
                <Table name={'rigCardsStat'} responsive data={cardsStat} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  rigsList: store.rigs.rigsList,
  rigCardsList: store.rigs.rigCardsList,
})

const mapDispatchToProps = {
  setRigsList: actions.setRigsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RigsDataPage)
