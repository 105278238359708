const initState = {
  isRigListLoading: false,
  isRigLoading: false,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_LOADING_STATE': {
      Object.keys(action).forEach(key => {
        if (key !== 'type') {
          state[key] = action[key]
        }
      })
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
