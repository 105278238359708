import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import { Table } from '../../../../components/Table'
import * as actions from '../../actions'
import PoolActions from '../../components/PoolActions'
import { CreatePoolModal } from '../../components/modals'
import { Row, Col } from 'reactstrap'
import Cookies from 'js-cookie'

const Pools = props => {
  const [pools, setPools] = useState([])
  const setPoolsList = props.setPoolsList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 250,
  )

  useEffect(() => {
    setPoolsList({ page: currentPage, size: showCount, orderBy: 'id' })
  }, [setPoolsList, currentPage, showCount])

  useEffect(() => {
    const poolsArray = props.poolsList.map(pool => {
      return {
        ...pool,
        action: <PoolActions pool={pool} />,
      }
    })
    setPools(poolsArray)
  }, [props.poolsList])

  const poolsData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: 'Title',
        field: 'title',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'sslPort',
        field: 'sslPort',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'stratumPort',
        field: 'stratumPort',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'altStratumPort',
        field: 'altStratumPort',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'type',
        field: 'type',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'coins',
        field: 'coins',
        sort: false,
      },
      {
        label: 'soloPort',
        field: 'soloPort',
        sort: true,
        sortType: 'string',
      },
      {
        label: 'Actions',
        field: 'action',
        width: 10,
      },
    ],
    rows: pools,
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <CreatePoolModal />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            name={'pools'}
            className={'dc__table'}
            responsive
            data={poolsData}
            total={props.poolsTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  poolsList: store.admin.poolsList,
  poolsTotal: store.admin.poolsTotal,
})

const mapDispatchToProps = {
  setPoolsList: actions.setPoolsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pools)
