import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import MetisMenu from 'metismenujs'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

const Sidebar = props => {
  const location = useLocation()

  const initMenu = useCallback(() => {
    new MetisMenu('#side-menu')

    let matchingMenuItem = null
    const ul = document.getElementById('side-menu')
    const items = ul.getElementsByTagName('a')
    for (let i = 0; i < items.length; ++i) {
      items[i].classList.remove('mm-active')
    }
    for (let i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname && items[i].pathname !== '#') {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [])

  useEffect(() => {
    initMenu()
  }, [initMenu, location])
  const { t } = useTranslation('common')
  const activateParentDropdown = item => {
    item.classList.add('mm-active')
  }

  const [show, setShow] = useState(false)

  const toggle = () => {
    setShow(!show)
  }

  return (
    <div id='sidebar-menu'>
      <Modal isOpen={show} toggle={toggle} className={'modal-dialog-centered'} onClick={e => e.stopPropagation()}>
        <ModalHeader tag={'h4'} toggle={toggle}>
          {t('sidebar.telegram_bot')}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={9} className={'form-group'}>
              <p>
                <Trans i18nKey='sidebar.telegram_bot_add' ns={'common'}>
                  Add our telegram bot: <b>@Amos_Panel_bot</b>
                </Trans>
              </p>
              <p>
                {t('sidebar.telegram_bot_request')}
                <br />
                <b>{t('sidebar.telegram_bot_key')}:</b> {props && props.userData && props.userData.telegramCode}
              </p>
            </Col>
            <Col xs={3} className={'form-group'} style={{ textAlign: 'right' }}>
              <i className='fab fa-brands fa-telegram telegram-icon-modal'></i>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            {t('modal.close')}
          </Button>
        </ModalFooter>
      </Modal>
      <ul onClick={() => props.changeSidebarType('default', false)} className='metismenu list-unstyled' id='side-menu'>
        <li className='menu-title'>{t('sidebar.menu.main')}</li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/`} className='waves-effect'>
            <i className='ti-home'></i>
            <span>{t('sidebar.menu.dashboard')}</span>
          </Link>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/workers`} className=' waves-effect'>
            <span className={'workers_sidebar_icon_svg_rig'}></span>
            <span>{t('sidebar.menu.workers')}</span>
          </Link>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/miningPresets`} className=' waves-effect'>
            <i className='fas fa-plane-departure'></i>
            <span>{t('sidebar.menu.miningPresets')}</span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/overclockingProfiles`} className=' waves-effect'>
            <i className='fas fa-sliders-h'></i>
            <span>{t('sidebar.menu.overclockingProfiles')}</span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/slots`} className=' waves-effect'>
            <i className='fas fa-sliders-h'></i>
            <span>{t('sidebar.menu.overclockingSlots')}</span>
          </Link>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/wallets`} className=' waves-effect'>
            <i className='fas fa-wallet'></i>
            <span>{t('sidebar.menu.wallets')}</span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/customPools`} className=' waves-effect'>
            <i className='fas fa-poll'></i>
            <span>{t('sidebar.menu.customPools')}</span>
          </Link>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/dataCenters`} className='waves-effect'>
            <i className='fas fa-server'></i>
            <span>{t('sidebar.menu.dataCenters')}</span>
          </Link>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/rooms`} className=' waves-effect'>
            <i className='far fa-square'></i>
            <span>{t('sidebar.menu.rooms')}</span>
          </Link>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/download`} className=' waves-effect'>
            <i className='fas fa-download'></i>
            <span>{t('sidebar.menu.downloadInstall')}</span>
          </Link>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/powerUnits`} className=' waves-effect'>
            <i className='fas fa-plug'></i>
            <span>{t('sidebar.menu.powerUnits')}</span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/schedule`} className=' waves-effect'>
            <i className='fas fa-calendar-alt'></i>
            <span>{t('sidebar.menu.schedule')}</span>
          </Link>
        </li>

        <li>
          <Link to={`${process.env.PUBLIC_URL}/settings`} className=' waves-effect'>
            <i className='fas fa-cogs'></i>
            <span>{t('sidebar.menu.settings')}</span>
          </Link>
        </li>
        {props && props.userData && props.userData.id === '1' ? (
          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/adminPage`}
              className={location.pathname === '/adminPage' ? 'mm-active waves-effect' : 'waves-effect'}
            >
              <i className='fas fa-cogs'></i>
              <span>{t('sidebar.menu.adminPage')}</span>
            </Link>
          </li>
        ) : null}
        <hr />
        <li>
          <Link
            to={{}}
            className=' waves-effect'
            onClick={e => {
              e.stopPropagation()
              toggle()
            }}
          >
            <i className='fab fa-brands fa-telegram'></i>
            <span>{t('sidebar.menu.telegramBot')}</span>
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/donate`} className=' waves-effect'>
            <i className='fas fa-lightbulb-dollar'></i>
            <span>{t('sidebar.menu.donate')}</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

const mapStateToProps = store => ({
  userData: store.login,
})

export default connect(mapStateToProps, null)(Sidebar)
