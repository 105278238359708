import { types } from './types'

const initState = {
  walletsList: [],
  totalWallets: 0,
  coinsList: [],
  totalCoins: 0,
  poolsList: [],
  totalPools: 0,
  minersList: [],
  totalMiners: 0,
  averageHashList: [],
  totalAverageHash: 0,
  miningPresetList: [],
  totalPresets: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_WALLETS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.walletsList = data.items
      stateCopy.totalWallets = data.total
      return {
        ...stateCopy,
      }
    }

    case types.SET_AVERAGE_HASH_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.averageHashList = data.items
      stateCopy.totalAverageHash = data.total
      return {
        ...stateCopy,
      }
    }

    case types.SET_COINS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.coinsList = data.items
      stateCopy.totalCoins = data.total
      return {
        ...stateCopy,
      }
    }

    case types.SET_POOLS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      return {
        ...stateCopy,
        poolsList: data.items,
        totalPools: data.total,
      }
    }

    case types.SET_POOL: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.pool = data
      return {
        ...stateCopy,
      }
    }

    case types.SET_MINERS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.minersList = data.items
      stateCopy.totalMiners = data.total
      return {
        ...stateCopy,
      }
    }

    case types.SET_PRESETS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.miningPresetList = data.items
      stateCopy.totalPresets = data.total
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_MINING_PRESET: {
      const { data } = action
      const stateCopy = { ...state }

      const miningPresetListCopy = [...stateCopy.miningPresetList]
      stateCopy.miningPresetList = miningPresetListCopy.map(preset => {
        if (preset.id === data.id) {
          preset = { ...data }
        }
        return preset
      })

      return {
        ...stateCopy,
      }
    }

    case types.CREATE_MINING_PRESET: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.miningPresetList = [...stateCopy.miningPresetList, { ...data }]
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_WALLET: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.walletsList = [...stateCopy.walletsList, { ...data }]
      return {
        ...stateCopy,
      }
    }

    case types.DELETE_WALLET: {
      const { data } = action
      const stateCopy = { ...state }
      const walletsListCopy = [...stateCopy.walletsList]
      stateCopy.walletsList = walletsListCopy.filter(wallet => +wallet.id !== data.walletID)
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_WALLET: {
      const { data } = action
      const stateCopy = { ...state }
      const walletsListCopy = [...stateCopy.walletsList]
      stateCopy.walletsList = walletsListCopy.map(w => {
        if (w.id === data.id) {
          w = { ...data }
        }
        return w
      })
      return {
        ...stateCopy,
      }
    }

    case types.DELETE_MINING_PRESET: {
      const { data } = action
      const stateCopy = { ...state }
      const miningPresetListCopy = [...stateCopy.miningPresetList]
      stateCopy.miningPresetList = miningPresetListCopy.filter(mp => mp.id !== data)
      return {
        ...stateCopy,
      }
    }

    case types.COPY_MINING_PRESET: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.miningPresetList = [...stateCopy.miningPresetList, { ...data }]
      return {
        ...stateCopy,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
