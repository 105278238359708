import React, { useEffect, useRef, useState } from 'react'

const GPUBLockMobileSelect = ({ card, setValueFunc, defaultValue, options = [], onClick, disableDoubleButtons }) => {
  const [showInput, setShowInput] = useState(false)
  const blockRef = useRef(null)
  const inputRef = useRef(null)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          showInput &&
          ref &&
          ref.current &&
          !ref.current.contains(event.target) &&
          !(event.target?.className.toString() && event.target.className.toString().includes('doubleArrow'))
        ) {
          disableDoubleButtons()
          setShowInput(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, showInput])
  }
  useOutsideAlerter(blockRef)
  return (
    <div
      ref={blockRef}
      className={showInput ? 'gpu-block active' : 'gpu-block'}
      onClick={() => {
        setShowInput(true)
        setTimeout(() => {
          inputRef.current && inputRef.current.focus()
        }, 100)
        onClick()
      }}
    >
      {!showInput && (
        <>
          GPU{card.cardNum}
          <span>{defaultValue}</span>
        </>
      )}
      <select
        ref={inputRef}
        className={showInput ? 'form-control' : 'd-none'}
        onChange={event => setValueFunc(event, card)}
        value={defaultValue}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {showInput && (
        <div className={'gpu-description'}>
          <div className={'gpu_number'}>
            {card.cardNum}-{' '}
            <span className={'gpu_name'} style={{ color: card.cardType === 'amd' ? '#e4909c' : '#01b95d' }}>
              <span style={{ color: '#949fff' }}>
                {card.vendorName && card.vendorName !== 'null' ? card.vendorName : ''}{' '}
              </span>
              {card.gpuName.replace(/NVIDIA/g, '').replace(/AMD/g, '')}
            </span>
          </div>
          <div className={'gpu_memory'}>
            {!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}
            {card.memInfo && <span className='card__memType'> • {card.memInfo}</span>}
            {card.memType && <span className='card__memType'> {card.memType}</span>}
            {/*{!(card.memInfo || card.memType) &&
                      card.cardType === 'nvidia' &&
                      card.DefPwrLimit && (
                          <span className="card__power">
                                              {' • '}
                              {card.nvGpuPwrMin}W /{' '}
                              {card.DefPwrLimit}W /{' '}
                              {card.nvGpuPwrMax}W
                                            </span>
                      )}
                  {card.memInfo && card.memType &&card.cardType === 'nvidia' &&
                      card.DefPwrLimit && (
                          <span className="card__power">
                                                {card.nvGpuPwrMin}W /{' '}
                              {card.DefPwrLimit}W /{' '}
                              {card.nvGpuPwrMax}W
                                              </span>
                      )}*/}
          </div>
        </div>
      )}
    </div>
  )
}

export default GPUBLockMobileSelect
