import React, { useState } from 'react'

import { connect } from 'react-redux'
import EditMinerParamModal from './modals/EditMinerParamModal'

const MinerParamActions = props => {
  const [show, setShow] = useState(false)

  const toggleEdit = () => {
    setShow(!show)
  }

  return (
    <div className={'actions__items'}>
      <EditMinerParamModal
        toggle={toggleEdit}
        show={show}
        param={props.param}
        key={props.param ? props.param.order : 0}
        updateParam={props.updateParam}
      />
      <div
        className={'actions__item'}
        tooltip='Delete preset'
        flow='left'
        onClick={() => {
          props.removeParam(props.param)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
    </div>
  )
}

export default connect(null, null)(MinerParamActions)
