import React, { useEffect, useRef, useState } from 'react'
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'

const Autocomplete = props => {
  const [showHelp, setShowHelp] = useState(false)
  const [value, setValue] = useState(props.value)
  const helpList = useRef(null)
  const input = useRef(null)

  function useOutsideAlerter(ref, menuButton) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          showHelp &&
          ref &&
          menuButton &&
          ref.current &&
          !ref.current.contains(event.target) &&
          menuButton.current &&
          !menuButton.current.contains(event.target)
        ) {
          setShowHelp(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, menuButton, showHelp])
  }
  useOutsideAlerter(helpList, input)

  return (
    <div onClick={e => e.stopPropagation()} style={{ position: 'relative' }} onFocus={() => setShowHelp(true)}>
      <Input
        value={value}
        innerRef={input}
        onChange={e => {
          props.onChange(e.target.value)
          setValue(e.target.value)
        }}
      />
      {showHelp && (
        <div className={'help-list'} ref={helpList} style={{ width: input?.current.offsetWidth }}>
          {props?.items
            .filter(item => item.value.indexOf(value) === 0)
            .map(item => {
              return (
                <div
                  onClick={() => {
                    setValue(item.value)
                    props.onChange(item.value)
                    setShowHelp(false)
                  }}
                  className={'help-list-item'}
                >
                  {item.value}
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default Autocomplete
