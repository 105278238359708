import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input } from 'reactstrap'
import * as actionsProfiles from '../../actions'
import { useTranslation } from 'react-i18next'
import SelectWithImages from '../../../../components/UI/SelectWithImages'
import SelectWithCryptoImages from '../../../../components/UI/SelectWithCryptoImages'
import Select, { components } from 'react-select'
import SelectSlots from '../../../../components/UI/SelectSlots'

const SlotToRigs = props => {
  const { t } = useTranslation('common')
  const [modal, setModal] = useState(false)
  const toggle = () => {
    setModal(!modal)
    if (modal) {
      setTimeout(() => props.onCancel(), 500)
    }
  }
  const [slot, setSlot] = useState(null)
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [profileName, setProfileName] = useState('')
  const [coin, setCoin] = useState(null)
  const [dualCoin, setDualCoin] = useState(null)
  const [algo, setAlgo] = useState(null)
  const [dualAlgo, setDualAlgo] = useState(null)
  const [comment, setComment] = useState('')

  useEffect(() => {
    props.setSlotsList({ page: 1, size: 1000 })
  }, [modal])

  useEffect(() => {
    setTimeout(() => toggle(), 10)
  }, [])

  const submit = async () => {
    const newErrors = {}
    if (!slot) newErrors.slot = { message: t('pages.overclocking.slotError') }
    if (!profileName || profileName.length === 0)
      newErrors.profileName = { message: t('pages.overclocking.profileNameError') }
    if (slot && slot.value === 'new' && (!name || name.length === 0))
      newErrors.name = { message: t('pages.overclocking.nameError') }
    if (slot && slot.value === 'new' && !algo) newErrors.algo = { message: t('pages.overclocking.algoError') }
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) return
    console.log('in', newErrors)
    const res = await props.assignSlot(
      slot.value === 'new'
        ? {
            rigIds: props.workersItems
              .map(worker => {
                return worker.id
              })
              .toString(),
            profileName: profileName,
            name: name,
            coin: coin?.value || null,
            dualCoin: dualCoin?.value || null,
            algo: algo?.value || null,
            dualAlgo: dualAlgo?.value || null,
            comment: comment,
          }
        : {
            rigIds: props.workersItems
              .map(worker => {
                return worker.id
              })
              .toString(),
            profileName: profileName,
            slotId: +slot.value,
          },
    )
    if (res) toggle()
  }

  return (
    <Modal isOpen={modal} toggle={toggle} onClick={e => e.stopPropagation()} className={'modal-dialog-centered popup'}>
      <ModalHeader tag={'h4'} toggle={toggle}>
        {t('pages.overclocking.assignSlot')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} className={'form-group'}>
            <Label>{t('pages.overclocking.rigs')}</Label>
            <Input
              readOnly={true}
              type={'textarea'}
              value={props.workersItems && props.workersItems.map(rig => rig.name).join(', ')}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12} className={'form-group'}>
            <Label>{t('pages.overclocking.slot')}</Label>
            <SelectSlots
              hideSelectedOptions
              className={errors.slot ? 'invalid' : ''}
              onchange={option => {
                setSlot(option)
              }}
              value={slot}
              options={[
                { value: 'new', label: 'Create New' },
                ...props.slotsList.map(slot => {
                  let coins = slot.coin ? slot.coin : ''
                  if (slot.dualCoin) coins += '+' + slot.dualCoin
                  if (coins.length > 0) coins = ' (' + coins + ')'
                  return {
                    ...slot,
                    label: slot.name + coins,
                    value: slot.id,
                  }
                }),
              ]}
            />
            <div className={'error'}>{errors.slot && errors.slot.message}</div>
          </Col>
          <Col md={6} xs={12} className={'form-group'}>
            <Label>{t('pages.overclocking.profileName')}</Label>
            <Input
              className={errors.profileName ? 'invalid' : ''}
              type={'text'}
              name={'name'}
              onChange={e => {
                setProfileName(e.target.value)
                errors.profileName = null
                setErrors(errors)
              }}
            />
            <div className={'error'}>{errors.profileName && errors.profileName.message}</div>
          </Col>
        </Row>
        {slot && slot.value === 'new' && (
          <Row>
            <Col xs={12} className={'form-group'}>
              <Label>{t('pages.overclocking.slotName')}</Label>
              <Input
                className={errors.name ? 'invalid' : ''}
                type={'text'}
                name={'name'}
                onChange={e => {
                  setName(e.target.value)
                  errors.name = null
                  setErrors(errors)
                }}
              />
              <div className={'error'}>{errors.name && errors.name.message}</div>
            </Col>
            <Col xs={6} className={'form-group'}>
              <Label>{t('pages.overclocking.slotAlgo')}</Label>
              <SelectWithCryptoImages
                onChange={e => {
                  e.stopPropagation()
                }}
                placeholder={t('inputs.selectAlgo')}
                value={algo}
                onchange={option => {
                  setAlgo(option)
                  setCoin(null)
                  errors.algo = null
                  setErrors(errors)
                }}
                error={errors.algo}
                options={props.algoCoinsList.map(algo => {
                  return {
                    ...algo,
                    label: algo.algo,
                    value: algo.algo,
                  }
                })}
                hideSelectedOptions={true}
              />
              <div className={'error'}>{errors.algo && errors.algo.message}</div>
            </Col>
            {algo && algo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotCoin')}</Label>
                <SelectWithImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectCoin')}
                  value={coin}
                  onchange={option => setCoin(option)}
                  error={errors.coin}
                  options={[
                    { label: 'Unset', value: null },
                    ...props.coinsList
                      .filter(coin => algo.coins && algo.coins.indexOf(coin.nameSymbol) >= 0)
                      .map(coin => {
                        return {
                          ...coin,
                          icon: coin.nameSymbol,
                          label: coin.nameSymbol,
                          value: coin.nameSymbol,
                        }
                      }),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.coin && errors.coin.message}</div>
              </Col>
            )}
            {algo && algo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotDualAlgo')}</Label>
                <SelectWithCryptoImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectAlgo')}
                  value={dualAlgo}
                  onchange={option => {
                    setDualAlgo(option)
                    setDualCoin(null)
                  }}
                  error={errors.dualAlgo}
                  options={[
                    { label: 'Unset', value: null },
                    ...props.algoCoinsList.map(algo => {
                      return {
                        ...algo,
                        label: algo.algo,
                        value: algo.algo,
                      }
                    }),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.dualAlgo && errors.dualAlgo.message}</div>
              </Col>
            )}
            {algo && algo.value && dualAlgo && dualAlgo.value && (
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.slotDualCoin')}</Label>
                <SelectWithImages
                  onChange={e => {
                    e.stopPropagation()
                  }}
                  placeholder={t('inputs.selectCoin')}
                  value={dualCoin}
                  onchange={option => setDualCoin(option)}
                  error={errors.dualCoin}
                  options={[
                    { label: 'Unset', value: null },
                    ...props.coinsList
                      .filter(coin => dualAlgo.coins && dualAlgo.coins.indexOf(coin.nameSymbol) >= 0)
                      .map(coin => {
                        return {
                          ...coin,
                          icon: coin.nameSymbol,
                          label: coin.nameSymbol,
                          value: coin.nameSymbol,
                        }
                      }),
                  ]}
                  hideSelectedOptions={true}
                />
                <div className={'error'}>{errors.dualCoin && errors.dualCoin.message}</div>
              </Col>
            )}
            <Col xs={12} className={'form-group'}>
              <Label>{t('pages.overclocking.slotComment')}</Label>
              <Input type={'textarea'} name={'comment'} onChange={e => setComment(e.target.value)} />
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={submit}>
          {t('pages.overclocking.assignSlotButton')}
        </Button>
        <Button color='secondary' onClick={toggle}>
          {t('pages.overclocking.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = store => ({
  algoCoinsList: store.profiles.algoCoinsList,
  coinsList: store.presets.coinsList,
  rigsList: store.rigs.rigsList,
  slotsList: store.profiles.slotsList,
})

const mapDispatchToProps = {
  createSlot: actionsProfiles.createSlot,
  setSlotsList: actionsProfiles.setSlotsList,
  assignSlot: actionsProfiles.assignSlot,
}

export default connect(mapStateToProps, mapDispatchToProps)(SlotToRigs)
