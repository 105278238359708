import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const CheckGpuErrorMessage = props => {
  const { t } = useTranslation('common')
  return (
    <div>
      <Modal isOpen={props.gpuModal} toggle={props.toggleGpuErrMess} className={'modal-dialog-centered'}>
        <ModalHeader tag={'h4'} toggle={props.toggleGpuErrMess}>
          {t('pages.workers.overclockingSetupInfo')}
        </ModalHeader>
        <ModalBody>{t('pages.workers.memoryBanksInstalled')}</ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => props.answerYesSet()}>
            {t('pages.workers.yes')}
          </Button>
          <Button color='secondary' onClick={props.toggleGpuErrMess}>
            {t('pages.workers.no')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default CheckGpuErrorMessage
