import React, { useRef } from 'react'
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'

const SelectWithImages = props => {
  const { SingleValue } = components
  const { t } = useTranslation('common')
  const tryRequire = icon => {
    try {
      return require('../../assets/images/coinIcons/' + icon.toLowerCase() + '.png').default
    } catch (err) {
      return require('../../assets/images/coinIcons/none.png').default
    }
  }

  const IconOption = props => {
    return (
      <components.Option {...props}>
        {props.data.value !== 'all' && props.data.value ? <img alt='' src={tryRequire(props.data.icon)} /> : null}
        {props.data.label}
      </components.Option>
    )
  }
  const singleValue = props => (
    <SingleValue {...props}>
      {props.data.value !== 'all' && props.data.value ? <img alt='' src={tryRequire(props.data.icon)} /> : null}
      {props.data.label}
    </SingleValue>
  )

  return (
    <div onClick={e => e.stopPropagation()}>
      <Select
        components={{ Option: IconOption, SingleValue: singleValue }}
        options={props.options}
        classNamePrefix={'form_control_react_select'}
        className={props.error ? 'invalid-select' : ''}
        onChange={props.onchange}
        value={props.value}
        name={props.name}
        isSearchable
        isClearable
        isMulti={props.isMulti}
        closeMenuOnSelect={props.closeMenuOnSelect}
        hideSelectedOptions={props.hideSelectedOptions}
        placeholder={props.placeholder ? props.placeholder : t('inputs.select')}
        maxMenuHeight={200}
      />
    </div>
  )
}

export default SelectWithImages
