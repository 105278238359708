import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import * as actions from '../actions'
import { MiningPresetModal } from '../components/modals'
import { Button, Col, Row } from 'reactstrap'
import MiningPresetActions from '../components/MiningPresetActions'
import * as actionsProfiles from '../../OverclockingProfiles/actions'
import TableMPs from '../../../components/Table/TableMPs'
import Select from 'react-select'
import Cookies from 'js-cookie'
import SelectWithImages from '../../../components/UI/SelectWithImages'

const MiningPresetsPage = ({
  setMiningPresetList,
  miningPresetList,
  setPoolsList,
  setSlotsList,
  miningPresetsTotal,
  setMinersList,
  setWalletsList,
  setAverageHashList,
  setOverclockingProfilesList,
  setAlgoCoinsList,
  coinsList,
  walletsList,
  minersList,
}) => {
  const [miningPresetsList, setMiningPresetsList] = useState([])
  const { t } = useTranslation('common')
  const parseJson = json => {
    try {
      return JSON.parse(json)
    } catch (e) {
      return ''
    }
  }

  const [coins, setCoins] = useState(() => {
    if (Cookies.get(window.location.pathname + 'miningPresetCoin')) {
      const coins = []
      for (const el of parseJson(Cookies.get(window.location.pathname + 'miningPresetCoin'))) {
        coins.push(el.value)
      }
      return coins.toString()
    }
    return ''
  })
  const [wallets, setWallets] = useState(() => {
    if (Cookies.get(window.location.pathname + 'miningPresetWallet')) {
      const coins = []
      for (const el of parseJson(Cookies.get(window.location.pathname + 'miningPresetWallet'))) {
        coins.push(el.value)
      }
      return coins.toString()
    }
    return ''
  })
  const [miners, setMiners] = useState(() => {
    if (Cookies.get(window.location.pathname + 'miningPresetMiner')) {
      const coins = []
      for (const el of parseJson(Cookies.get(window.location.pathname + 'miningPresetMiner'))) {
        coins.push(el.value)
      }
      return coins.toString()
    }
    return ''
  })
  const [fcoin, setCoin] = useState(
    Cookies.get(window.location.pathname + 'miningPresetCoin')
      ? parseJson(Cookies.get(window.location.pathname + 'miningPresetCoin'))
      : '',
  )
  const [fwallet, setWallet] = useState(
    Cookies.get(window.location.pathname + 'miningPresetWallet')
      ? parseJson(Cookies.get(window.location.pathname + 'miningPresetWallet'))
      : '',
  )
  const [fminer, setMiner] = useState(
    Cookies.get(window.location.pathname + 'miningPresetMiner')
      ? parseJson(Cookies.get(window.location.pathname + 'miningPresetMiner'))
      : '',
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )

  useEffect(() => {
    setMiningPresetList({
      page: currentPage,
      size: showCount,
      filters: { coins, wallets, miners },
    })
  }, [setMiningPresetList, currentPage, showCount, coins, wallets, miners])

  useEffect(() => {
    setPoolsList({ page: 1, size: 1000, filters: {} })
    setMinersList({ page: 1, size: 100 })
    setSlotsList({ page: 1, size: 1000 })
    setAverageHashList({ page: 1, size: 100 })
    setOverclockingProfilesList({ page: 1, size: 100 })
    setAlgoCoinsList({ page: 1, size: 1000 })
    setWalletsList({ page: 1, size: 100 })
  }, [setAlgoCoinsList, setAverageHashList, setMinersList, setOverclockingProfilesList, setPoolsList, setWalletsList])

  useEffect(() => {
    const miningPresetsArray = miningPresetList
      // .filter(miningPreset => {
      //   let wallet = ''
      //   let coin = ''
      //   let miner = ''
      //   let walletDual = ''
      //   let coinDual = ''
      //   if (miningPreset && miningPreset.flightSheets.length > 0) {
      //     wallet = miningPreset.flightSheets[0].wallet
      //     coin = miningPreset.flightSheets[0].coin
      //     miner = miningPreset.flightSheets[0].miner
      //     if (
      //       miningPreset.flightSheets[0].minerParams &&
      //       miningPreset.flightSheets[0].minerParams.dualMode === true &&
      //       miningPreset.flightSheets[0].dualWalletObj
      //     ) {
      //       walletDual = miningPreset.flightSheets[0].dualWallet
      //       coinDual = miningPreset.flightSheets[0].dualCoin
      //     }
      //   }
      //   let ret = true
      //   if (fcoin && fcoin.length > 0) {
      //     if (!fcoin.find(ffcoin => ffcoin.value === coin || ffcoin.value === coinDual)) ret = false
      //   }
      //   if (fwallet && fwallet.length > 0) {
      //     try {
      //       if (!fwallet.find(ffwallet => ffwallet.value === wallet || ffwallet.value === walletDual)) ret = false
      //     } catch (e) {
      //       ret = false
      //     }
      //   }
      //   if (fminer && fminer.length > 0) {
      //     try {
      //       if (!fminer.find(ffminer => ffminer.value === miner)) ret = false
      //     } catch (e) {
      //       ret = false
      //     }
      //   }
      //   return ret
      // })
      .map(miningPreset => {
        let wallet = ''
        let coin = ''
        let miner = ''
        let walletDual = ''
        let coinDual = ''
        if (miningPreset && miningPreset.flightSheets.length > 0) {
          wallet = miningPreset.flightSheets[0].walletObj.name
          coin = miningPreset.flightSheets[0].coin
          miner = miningPreset.flightSheets[0].miner
          if (
            miningPreset.flightSheets[0].minerParams &&
            miningPreset.flightSheets[0].minerParams.dualMode === true &&
            miningPreset.flightSheets[0].dualWalletObj
          ) {
            walletDual = miningPreset.flightSheets[0].dualWalletObj.name
            coinDual = miningPreset.flightSheets[0].dualCoin
          }
        }
        return {
          id: miningPreset.id,
          ...miningPreset,
          action: <MiningPresetActions key={miningPreset.name && miningPreset.name} preset={miningPreset} />,
          wallet: walletDual ? wallet + '/' + walletDual : wallet,
          coin: coinDual.length > 0 ? coin + '/' + coinDual : coin,
          miner: miner,
        }
      })
    setMiningPresetsList(miningPresetsArray)
  }, [miningPresetList, fcoin, fwallet, fminer])

  const resetFilters = () => {
    setCoin(null)
    Cookies.set(window.location.pathname + 'rigCoin', JSON.stringify(null), {
      expires: 30,
    })
    setMiner(null)
    Cookies.set(window.location.pathname + 'rigMiner', JSON.stringify(null), {
      expires: 30,
    })
    setWallet(null)
    Cookies.set(window.location.pathname + 'rigWallet', JSON.stringify(null), {
      expires: 30,
    })
  }

  const miningPresets = {
    columns: [
      {
        label: t('pages.miningPresets.id'),
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.miningPresets.name'),
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.miningPresets.wallet'),
        field: 'wallet',
        sort: 'asc',
      },
      {
        label: t('pages.miningPresets.coin'),
        field: 'coin',
        sort: 'asc',
      },
      {
        label: t('pages.miningPresets.miner'),
        field: 'miner',
        sort: 'asc',
      },
      {
        label: t('pages.miningPresets.action'),
        field: 'action',
      },
    ],
    rows: miningPresetsList,
  }

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.miningPresets.miningPresets')}</h3>
          </div>
        </div>
        <MiningPresetModal />
        <div className={'mining_preset__filters'}>
          <Row className={'workers_filters__elements'}>
            <Col xs={12} sm={4} md={3} className={'workers_filters__element'}>
              <label>{t('pages.miningPresets.coin')}</label>
              <SelectWithImages
                classNamePrefix={'form_control_react_select'}
                value={fcoin}
                placeholder={t('inputs.selectCoin')}
                onchange={option => {
                  setCoin(option)
                  const coins = []
                  for (const el of option) {
                    coins.push(el.value)
                  }
                  setCoins(coins.toString())
                  Cookies.set(window.location.pathname + 'miningPresetCoin', JSON.stringify(option), { expires: 30 })
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                options={coinsList.map(coin => {
                  return {
                    icon: coin.nameSymbol,
                    label: coin.nameSymbol,
                    value: coin.nameSymbol,
                  }
                })}
              />
            </Col>
            <Col sm={4} md={3} className={'workers_filters__element'}>
              <label>{t('pages.miningPresets.wallet')}</label>
              <Select
                classNamePrefix={'form_control_react_select'}
                value={fwallet}
                onChange={option => {
                  setWallet(option)
                  const wallets = []
                  for (const el of option) {
                    wallets.push(el.value)
                  }
                  setWallets(wallets.toString())
                  Cookies.set(window.location.pathname + 'miningPresetWallet', JSON.stringify(option), { expires: 30 })
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                options={walletsList.map(wallet => {
                  return {
                    value: wallet.address,
                    label: wallet.name,
                  }
                })}
                placeholder={t('pages.miningPresets.all')}
              />
            </Col>
            <Col sm={4} md={3} className={'workers_filters__element'}>
              <label>{t('pages.miningPresets.miner')}</label>
              <Select
                classNamePrefix={'form_control_react_select'}
                value={fminer}
                onChange={option => {
                  setMiner(option)
                  const miners = []
                  for (const el of option) {
                    miners.push(el.value)
                  }
                  setMiners(miners.toString())
                  Cookies.set(window.location.pathname + 'miningPresetMiner', JSON.stringify(option), { expires: 30 })
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                options={minersList.map(miner => {
                  return {
                    value: miner.name,
                    label: miner.name,
                  }
                })}
                placeholder={t('pages.miningPresets.all')}
              />
            </Col>
          </Row>
        </div>
        <Row className={'justify-content-end'}>
          {fcoin || fwallet || fminer ? (
            <Col sm={3} md={2} className={'workers_filters__element favoriteFirst'}>
              <Button color={'primary'} onClick={() => resetFilters()}>
                {t('pages.miningPresets.clearFilters')}
              </Button>
            </Col>
          ) : null}
        </Row>
        <div className={'mining_preset__body'}>
          <TableMPs
            className={'mining_preset__table table table-striped'}
            data={miningPresets}
            responsive
            noBottomColumns
            firstColumnCheckbox
            stickyHeader
            miningPresetsTotal={miningPresetsTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  miningPresetList: store.presets.miningPresetList,
  miningPresetsTotal: store.presets.totalPresets,
  walletsList: store.presets.walletsList,
  coinsList: store.presets.coinsList,
  poolsList: store.presets.poolsList,
  minersList: store.presets.minersList,
  pool: store.presets.pool,
  averageHashList: store.presets.averageHashList,
  overclockingProfilesList: store.profiles.overclockingProfilesList,
  slotsList: store.profiles.slotsList,
})

const mapDispatchToProps = {
  setMiningPresetList: actions.setMiningPresetList,
  setPoolsList: actions.setPoolsList,
  setWalletsList: actions.setWalletsList,
  setAverageHashList: actions.setAverageHashList,
  setMinersList: actions.setMinersList,
  setPool: actions.setPool,
  setOverclockingProfilesList: actionsProfiles.setOverclockingProfilesList,
  setAlgoCoinsList: actionsProfiles.setAlgoCoinsList,
  setSlotsList: actionsProfiles.setSlotsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(MiningPresetsPage)
