import React, { useState } from 'react'

import { EditCoinModal } from './modals'
import { connect } from 'react-redux'
import * as actions from '../actions'
import SweetAlert from 'react-bootstrap-sweetalert/dist'

const CoinActions = props => {
  const [show, setShow] = useState(false)

  const toggleEdit = () => {
    setShow(!show)
  }
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const deleteFunc = async () => {
    setShowAlertDelete(false)
    props.delete({ id: +props.coin.id })
  }

  return (
    <div className={'actions__items'}>
      <SweetAlert
        title='Are you confirm?'
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertDelete}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          deleteFunc()
        }}
        onCancel={() => {
          setShowAlertDelete(false)
        }}
        confirmBtnText={'Confirm'}
        showCloseButton={true}
      >
        Coin will be removed permanently
      </SweetAlert>
      <EditCoinModal toggle={toggleEdit} show={show} coin={props.coin} key={props.coin ? props.coin.id : 0} />
      <div
        className={'actions__item'}
        tooltip='Delete preset'
        flow='left'
        onClick={() => {
          setShowAlertDelete(true)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  delete: actions.deleteCoin,
}

export default connect(null, mapDispatchToProps)(CoinActions)
