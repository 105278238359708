import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input, Form } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import * as actions from '../../../MiningPresets/actions'
import { connect } from 'react-redux'
import * as actionsProfiles from '../../../OverclockingProfiles/actions'

const SaveProfileDialog = props => {
  const { t } = useTranslation('common')
  const [profileError, setProfileError] = useState(false)
  const [slotError, setSlotError] = useState(false)
  const [profileTypeError, setProfileTypeError] = useState(false)
  const [profileName, setProfileName] = useState('')
  const [profileType, setProfileType] = useState(null)
  const [slot, setSlot] = useState(null)
  const profileTypes = [
    { value: 'public', label: t('pages.overclocking.public') },
    { value: 'private', label: t('pages.overclocking.private') },
    { value: 'forMining', label: t('pages.overclocking.miningPreset') },
  ]
  const save = () => {
    let errors = false
    setProfileError(false)
    setSlotError(false)
    setProfileTypeError(false)
    if (profileName.length <= 0) {
      setProfileError(true)
      errors = true
    }
    if (!profileType) {
      setProfileTypeError(true)
      errors = true
    }
    if (profileType && profileType.value === 'forMining' && (!slot || (slot && slot.value < 1))) {
      setSlotError(true)
      errors = true
    }
    if (!errors) {
      props.save(profileName, profileType && profileType.value, slot && +slot.value, props.apply)
    }
  }
  useEffect(() => {
    if (props.open) {
      setProfileName('')
      setProfileType(null)
      setSlot(null)
      props.setSlotsList({ page: 1, size: 1000 })
    }
    if (props.open && props.name) {
      console.log(props)
      setProfileName(props.name)
      setProfileType(profileTypes.find(type => type.value === props.profileType))
      if (props.slot)
        setSlot(() => {
          const slot = props.slotsList.find(slot => slot.id == props.slot)
          if (!slot) return null
          return {
            ...slot,
            label: slot.name,
            value: slot.id,
          }
        })
    }
  }, [props.open, props.slot])

  const slotOption = props => {
    return (
      <components.Option {...props}>
        {props.data.label}
        {props.data.comment && <div className={'slot_description'}>{props.data.comment}</div>}
      </components.Option>
    )
  }

  return (
    <div>
      <Modal isOpen={props.open} toggle={props.toggle} className={'modal-dialog-centered'}>
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {props.name
            ? t('pages.workers.saveProfile') + ' ' + props.name
            : t('pages.workers.pleaseEnterOverclockingProfile')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.workers.name')}</Label>
                <Input
                  className={profileError ? 'invalid' : ''}
                  type={'text'}
                  name={'profile_name'}
                  value={profileName}
                  onChange={e => {
                    setProfileName(e.target.value)
                  }}
                />
                <div className={'error'}>{profileError ? t('pages.workers.pleaseEnterProfileName') : ''}</div>
              </Col>
              <Col xs={6} className={'form-group'}>
                <Label>{t('pages.overclocking.profileType')}</Label>
                <Select
                  classNamePrefix={profileTypeError ? 'invalid form_control_react_select' : 'form_control_react_select'}
                  value={profileType}
                  onChange={option => {
                    setProfileType(option)
                  }}
                  placeholder={t('inputs.select')}
                  options={profileTypes}
                />
                <div className={'error'}>{profileTypeError ? t('pages.workers.pleaseSelectProfileType') : ''}</div>
              </Col>
            </Row>
            {profileType && profileType.value === 'forMining' && (
              <Row>
                <Col xs={12} className={'form-group'}>
                  <Label>{t('pages.overclocking.profileSlot')}</Label>
                  <Select
                    components={{ Option: slotOption }}
                    isClearable={true}
                    hideSelectedOptions
                    placeholder={t('inputs.select')}
                    classNamePrefix={'form_control_react_select'}
                    onChange={option => {
                      setSlot(option)
                    }}
                    value={slot}
                    options={props.slotsList.map(slot => {
                      let coins = slot.coin ? slot.coin : ''
                      if (slot.dualCoin) coins += '+' + slot.dualCoin
                      if (coins.length > 0) coins = ' (' + coins + ')'
                      return {
                        ...slot,
                        label: slot.name + coins,
                        value: slot.id,
                      }
                    })}
                  />
                  <div className={'error'}>{slotError ? t('pages.workers.pleaseSelectSlot') : ''}</div>
                </Col>
              </Row>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={save}>
            {t('pages.workers.save')}
          </Button>
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.workers.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  slotsList: store.profiles.slotsList,
})

const mapDispatchToProps = {
  setSlotsList: actionsProfiles.setSlotsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveProfileDialog)
