import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OverclockingProfileModalEdit } from './modals'
import { connect } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert/dist'

const OverclockingProfilesActions = props => {
  const [showOverclockingProfile, setShowOverclockingProfile] = useState(false)
  const { t } = useTranslation('common')
  const toggleOverclockingProfileEdit = () => {
    setShowOverclockingProfile(!showOverclockingProfile)
  }
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const deleteProfileFunc = async () => {
    setShowAlertDelete(false)
    await props.deleteOverclockingProfile({ id: props.preset.id })
  }
  return (
    <div className={'actions__items'}>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertDelete}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          deleteProfileFunc()
        }}
        onCancel={() => {
          setShowAlertDelete(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        {t('pages.overclocking.profileRemove')}
      </SweetAlert>
      <OverclockingProfileModalEdit
        toggle={toggleOverclockingProfileEdit}
        show={showOverclockingProfile}
        profile={props.profile}
      />
      <div
        className={'actions__item'}
        tooltip={t('pages.overclocking.deleteProfile')}
        flow='left'
        onClick={() => {
          setShowAlertDelete(true)
        }}
      >
        <i className='fas fa-trash'></i>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  //deleteOverclockingProfile: actions.deleteOverclockingProfile,
}

export default connect(null, mapDispatchToProps)(OverclockingProfilesActions)
