import { types } from './types'

const initState = {
  poolsList: [],
  poolsTotal: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_CUSTOM_POOLS_LIST: {
      const { data } = action
      const stateCopy = { ...state }
      stateCopy.poolsList = data.items
      stateCopy.poolsTotal = data.total
      return {
        ...stateCopy,
      }
    }

    case types.CREATE_CUSTOM_POOL: {
      const { data } = action
      const stateCopy = { ...state }
      const poolsListCopy = [...stateCopy.poolsList, data]
      stateCopy.poolsList = poolsListCopy
      stateCopy.poolsTotal = stateCopy.poolsTotal + 1
      return {
        ...stateCopy,
      }
    }

    case types.UPDATE_CUSTOM_POOL: {
      const { data } = action
      const index = state.poolsList.findIndex(pool => +pool.id === +data.id)
      return {
        ...state,
        poolsList: [...state.poolsList.slice(0, index), data, ...state.poolsList.slice(index + 1)],
      }
    }

    case types.DELETE_CUSTOM_POOL: {
      const { data } = action
      const stateCopy = { ...state }
      const rigsListCopy = [...stateCopy.poolsList]
      stateCopy.poolsList = rigsListCopy.filter(pool => +pool.id !== data)
      stateCopy.poolsTotal = stateCopy.poolsTotal - 1
      return {
        ...stateCopy,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
