import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import RoomActions from '../components/RoomActions'
import { RoomCreateModalOnPage } from '../components/modals'
import Table from '../../../components/Table/Table'
import * as actions from '../actions'
import Cookies from 'js-cookie'

const RoomsPage = ({ roomsList, dataCentersList, totalRooms, ...props }) => {
  const [rooms, setRooms] = useState([])
  const { t } = useTranslation('common')
  const [roomsModal, setRoomsModal] = useState(false)
  const setRoomsList = props.setRoomsList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )

  useEffect(() => {
    setRoomsList({ page: currentPage, size: showCount })
  }, [setRoomsList, currentPage, showCount])

  useEffect(() => {
    const roomsArray = roomsList.map(room => {
      return {
        ...room,
        action: <RoomActions room={room} />,
        dataCenter: `${room.dcName} (${room.dataCenterId})`,
      }
    })
    setRooms(roomsArray)
  }, [dataCentersList, roomsList])

  const roomsData = {
    columns: [
      {
        label: t('pages.dcManagement.id'),
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.dcManagement.name'),
        field: 'name',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.dcManagement.dataCenter'),
        field: 'dataCenter',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.dcManagement.action'),
        field: 'action',
      },
    ],
    rows: rooms,
  }

  const toggleRoom = () => setRoomsModal(!roomsModal)

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.dcManagement.roomsList')}</h3>
            <div className='dc-top'>
              <RoomCreateModalOnPage isOpenRoom={roomsModal} toggleRoom={toggleRoom} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Table
              className={'dc__table table table-striped'}
              data={roomsData}
              responsive
              noBottomColumns
              stickyHeader
              total={totalRooms}
              setShowCount={setShowCount}
              showCount={showCount}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  roomsList: store.dcManagement.roomsList,
  totalRooms: store.dcManagement.totalRooms,
})

const mapDispatchToProps = {
  setRoomsList: actions.setRoomsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsPage)
