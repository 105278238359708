import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Modal, ModalHeader, Button, ModalFooter, ModalBody, Label } from 'reactstrap'
import * as rigActions from '../../../RigsData/actions'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

const WorkerUpgrade = props => {
  const [modal, setModal] = useState(false)
  const [version, setVersion] = useState([])
  const [osVers, setOsVers] = useState([{ value: 'select', label: 'select' }])
  const [driversVers, setDriversVers] = useState([])
  const [driver, setDriver] = useState([])
  const [forceUpdate, setForceUpdate] = useState(false)

  const toggle = async () => {
    setModal(!modal)
    if (modal) {
      setTimeout(() => props.onCancel(), 500)
    }
    if (!modal) {
      await props.setOsVersions({ page: 1, size: 10 }).then(data => {
        const changedData = data.map(el => {
          return { value: el.vers, label: el.vers }
        })
        changedData.push({ value: '', label: '____' })
        setOsVers(changedData)
      })
      await props.setDrivers({ page: 1, size: 10 }).then(data => {
        const changedData = data.map(el => {
          return { value: el.vers, label: el.vers }
        })
        changedData.push({ value: '', label: '____' })
        setDriversVers(changedData)
      })
    }
  }
  const { t } = useTranslation('common')

  useEffect(() => {
    setTimeout(() => toggle(), 10)
  }, [])

  const workerUpdate = async () => {
    const upgradeData = {
      rigIds: props.workersItems && props.workersItems.map(worker => worker.id).toString(),
      osVersionUpd: version.value,
      driverVersionUpd: driver.value,
      forceUpdate: forceUpdate,
    }
    const isUpdated = await props.downloadUpdates(upgradeData)
    props.clearSelected()
    isUpdated && toggle()
  }

  return (
    <Modal className={'modal-dialog-centered'} isOpen={modal} onCancel={toggle} toggle={toggle}>
      <ModalHeader tag={'h4'} toggle={toggle}>
        {t('pages.workers.workerUpdate')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} className={'form-group'}>
            <Label>{t('pages.workers.OSVersion')}</Label>
            <Select
              classNamePrefix={'form_control_react_select'}
              placeholder={t('pages.workers.selectOsVersion')}
              onChange={value => setVersion(value)}
              value={version}
              options={osVers}
            ></Select>
          </Col>

          <Col xs={12} className={'form-group'}>
            <Label>{t('pages.workers.driverVersion')}</Label>
            <Select
              classNamePrefix={'form_control_react_select'}
              placeholder={t('pages.workers.selectVideoDriver')}
              onChange={value => setDriver(value)}
              value={driver}
              options={driversVers}
            ></Select>
          </Col>

          <Col xs={12} className={'form-group'}>
            <Label className={'checkboxLabel'}>
              <input
                name={'watchdog'}
                type={'checkbox'}
                checked={forceUpdate}
                onChange={() => setForceUpdate(!forceUpdate)}
              />
              {t('pages.workers.forceUpdate')}
            </Label>
            {/* <Label className={'checkboxLabel'}>
              <input name={'watchdog'} type={'checkbox'} checked={reboot} onChange={() => setReboot(!reboot)} />
              {t('pages.workers.rebootAfterCompletion')}
            </Label> */}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          {t('pages.workers.cancel')}
        </Button>
        <Button color='primary' onClick={workerUpdate}>
          {t('pages.workers.apply')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = store => ({
  versions: store.versions,
})

const mapDispatchToProps = {
  downloadUpdates: rigActions.downloadUpdates,
  setOsVersions: rigActions.setOsVersions,
  setDrivers: rigActions.setDrivers,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerUpgrade)
