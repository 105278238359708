import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Col, Form, Input, Row } from 'reactstrap'
import { Modal } from 'components'
import * as actions from '../../actions'
import { useTranslation } from 'react-i18next'

const EditDataCenter = props => {
  const [errors, setErrors] = useState({})
  const { t } = useTranslation('common')
  const submitButton = useRef()

  const onChange = e => {
    if (e.target.name !== 'address' && e.target.name !== 'description') {
      const value = e.target.value.trim()
      if (!value) {
        errors[e.target.name] = t('pages.dcManagement.error')
      } else {
        delete errors[e.target.name]
      }
      setErrors({ ...errors })
    }
  }

  const submitFunc = () => {
    submitButton.current.click()
  }

  const handlerFormSubmit = async event => {
    event.preventDefault()
    const formElements = event.currentTarget.elements
    const dataCenterInput = {
      dataCenterId: +props.dataCenter.id,
      name: formElements.name.value,
      address: formElements.address.value,
      description: formElements.description.value,
      powerCapacity: +formElements.powerCapacity.value,
      lineType: formElements.lineType.value,
      transformerCapacity: +formElements.transformerCapacity.value,
      linkRPCServer: formElements.linkRPCServer.value,
    }

    let isValid = true

    Object.keys(dataCenterInput).forEach(key => {
      if (key !== 'address' && key !== 'description') {
        if (!dataCenterInput[key]) {
          isValid = false
          errors[key] = t('pages.dcManagement.error')
        } else {
          delete errors[key]
        }
      }
    })

    setErrors({ ...errors })

    if (isValid) {
      const isUpdated = await props.updateDataCenter(dataCenterInput)
      isUpdated && props.onFinish()
    }
  }

  return (
    <Modal
      title={`${t('pages.dcManagement.editDC')} - ${props.dataCenter.name}`}
      onFinish={submitFunc}
      finishTitle={t('pages.dcManagement.save')}
      cancelTitle={t('pages.dcManagement.cancel')}
      isOpen={props.isActive}
      onCancel={props.onFinish}
    >
      <Form onSubmit={handlerFormSubmit}>
        <Row className={'form-content'}>
          <Col xs={6} className={'form-group dc-form-group'}>
            <div className={'form-item'}>
              <p className={'form-item__label'}>{t('pages.dcManagement.DCName')}</p>
              <div className={'form-item__input'}>
                <Input
                  invalid={!!errors.name}
                  type={'text'}
                  onChange={onChange}
                  name={'name'}
                  defaultValue={props.dataCenter.name}
                  className='dc-input'
                />
                <div className={'error'}>{errors.name}</div>
              </div>
            </div>

            <div className={'form-item '}>
              <p className={'form-item__label'}>{t('pages.dcManagement.address')}</p>
              <div className={'form-item__input'}>
                <Input
                  type={'text'}
                  onChange={onChange}
                  name={'address'}
                  placeholder={t('pages.dcManagement.address')}
                  defaultValue={props.dataCenter.address}
                  className='dc-input'
                />
              </div>
            </div>

            <div className={'form-item'}>
              <p className={'form-item__label'}>{t('pages.dcManagement.description')}</p>
              <div className={'form-item__input'}>
                <Input
                  type={'textarea'}
                  rows={4}
                  maxLength={100}
                  onChange={onChange}
                  name={'description'}
                  placeholder={t('pages.dcManagement.description')}
                  defaultValue={props.dataCenter.description}
                  className='dc-input'
                />
              </div>
            </div>
          </Col>

          <Col xs={6} className={'form-group dc-form-group'}>
            <div className={'form-item'}>
              <p className={'form-item__label'}>{t('pages.dcManagement.powerCapacity')}</p>
              <div className={'form-item__input'}>
                <Input
                  invalid={!!errors.powerCapacity}
                  type={'text'}
                  onChange={onChange}
                  name={'powerCapacity'}
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  placeholder={'100'}
                  defaultValue={props.dataCenter.powerCapacity}
                  className='dc-input'
                />
                <div className={'error'}>{errors.powerCapacity}</div>
              </div>
            </div>

            <div className={'form-item'}>
              <p className={'form-item__label'}>{t('pages.dcManagement.lineType')}</p>
              <div className={'form-item__input'}>
                <Input
                  invalid={!!errors.lineType}
                  type={'select'}
                  onChange={onChange}
                  name={'lineType'}
                  defaultValue={props.dataCenter.lineType}
                  className='dc-input'
                >
                  <option value='' disabled>
                    {t('pages.dcManagement.selectLineType')}
                  </option>
                  <option>0.4 {t('pages.dcManagement.kV')}</option>
                  <option>
                    10{t('pages.dcManagement.kV')}/0.4{t('pages.dcManagement.kV')}
                  </option>
                  <option>
                    15{t('pages.dcManagement.kV')}/0.4{t('pages.dcManagement.kV')}
                  </option>
                  <option>35-10-0.4{t('pages.dcManagement.kV')}</option>
                </Input>
                <div className={'error'}>{errors.lineType}</div>
              </div>
            </div>

            <div className={'form-item'}>
              <p className={'form-item__label'}>{t('pages.dcManagement.transformerCapacity')}</p>
              <div className={'form-item__input'}>
                <Input
                  invalid={!!errors.transformerCapacity}
                  type={'text'}
                  onChange={onChange}
                  name={'transformerCapacity'}
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  placeholder={'100'}
                  defaultValue={props.dataCenter.transformerCapacity}
                  className='dc-input'
                />
                <div className={'error'}>{errors.transformerCapacity}</div>
              </div>
            </div>

            <div className={'form-item'}>
              <p className={'form-item__label'}>{t('pages.dcManagement.linkRPCServer')}</p>
              <div className={'form-item__input'}>
                <Input
                  invalid={!!errors.linkRPCServer}
                  type={'select'}
                  onChange={onChange}
                  name={'linkRPCServer'}
                  defaultValue={props.dataCenter.linkRPCServer}
                  className='dc-input'
                >
                  <option value='' disabled>
                    {t('pages.dcManagement.selectRPCServer')}
                  </option>
                  <option>RPC Server 1</option>
                  <option>RPC Server 2</option>
                  <option>RPC Server 3</option>
                  <option>RPC Server 4</option>
                </Input>
                <div className={'error'}>{errors.linkRPCServer}</div>
              </div>
            </div>
          </Col>
        </Row>
        <button type='submit' ref={submitButton} style={{ width: 0, height: 0, border: 'none', opacity: 0 }} />
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  updateDataCenter: actions.updateDataCenter,
}

export default connect(null, mapDispatchToProps)(EditDataCenter)
