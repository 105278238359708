import { connect } from 'react-redux'

/**
 * Renders the preloader
 */

const Loader = ({ loadingState }) => {
  return Object.values(loadingState).some(item => item) ? (
    <div className='preloader'>
      <div className='status'>
        <svg>
          <rect fill='#39a445' stroke='none' x='0' width='16' height='15' rx='0.1'>
            <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.1' />
          </rect>
          <rect fill='#39a445' stroke='none' x='20' width='16' height='15' rx='0.1'>
            <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.2' />
          </rect>
          <rect fill='#39a445' stroke='none' x='40' width='16' height='15' rx='0.1'>
            <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.3' />
          </rect>
          <rect fill='#39a445' stroke='none' x='60' width='16' height='15' rx='0.1'>
            <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.4' />
          </rect>
          <rect fill='#39a445' stroke='none' x='80' width='16' height='15' rx='0.1'>
            <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.5' />
          </rect>
          <rect fill='#39a445' stroke='none' x='100' width='16' height='15' rx='0.1'>
            <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.6' />
          </rect>
          <rect fill='#39a445' stroke='none' x='120' width='16' height='15' rx='0.1'>
            <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.7' />
          </rect>
          <rect fill='#39a445' stroke='none' x='140' width='16' height='15' rx='0.1'>
            <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.8' />
          </rect>
        </svg>
      </div>
    </div>
  ) : null
}

const mapStateToProps = store => ({
  loadingState: store.loading,
})

export default connect(mapStateToProps, null)(Loader)
