export const manageBodyClass = (cssClass, action = 'toggle') => {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass)
      break
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass)
      break
    default:
      if (document.body) document.body.classList.toggle(cssClass)
      break
  }

  return true
}

/**
 * Changes the body attribute
 */
export const changeBodyAttribute = (attribute, value) => {
  if (document.body) document.body.setAttribute(attribute, value)
  return true
}

export const changeLeftSidebarType = (sidebarType, isMobile) => {
  try {
    switch (sidebarType) {
      case 'compact':
        changeBodyAttribute('data-sidebar-size', 'small')
        manageBodyClass('sidebar-enable', 'remove')
        manageBodyClass('vertical-collpsed', 'remove')
        break
      case 'icon':
        changeBodyAttribute('data-keep-enlarged', 'true')
        manageBodyClass('vertical-collpsed', 'add')
        break
      case 'condensed':
        manageBodyClass('sidebar-enable', 'add')
        if (!isMobile) manageBodyClass('vertical-collpsed', 'add')
        if (isMobile) manageBodyClass('vertical-collpsed', 'remove')
        break
      default:
        changeBodyAttribute('data-sidebar-size', '')
        manageBodyClass('sidebar-enable', 'remove')
        if (!isMobile) manageBodyClass('vertical-collpsed', 'remove')
        if (isMobile) manageBodyClass('vertical-collpsed', 'add')
        break
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

/**
 * Toggles the rightsidebar
 */
export const toggleRightSidebar = () => {
  try {
    manageBodyClass('right-bar-enabled')
  } catch (error) {
    console.log(error)
  }
}

/**
 * Show the rightsidebar
 */
export const showRightSidebar = () => {
  try {
    manageBodyClass('right-bar-enabled', 'add')
  } catch (error) {
    console.log(error)
  }
}

/**
 * Hides the rightsidebar
 */
export const hideRightSidebar = () => {
  try {
    manageBodyClass('right-bar-enabled', 'remove')
  } catch (error) {
    console.log(error)
  }
}

/**
 * Changes the left sidebar theme
 */
export const changeLeftSidebarThemeFunction = theme => {
  try {
    changeBodyAttribute('data-sidebar', theme)
  } catch (error) {
    console.log(error)
  }
}

/**
 * Changes the topbar theme
 */
// export const changeTopbarThemeFunction = (theme) => {
//   console.log('theme: ', theme)
//   changeBodyAttribute('data-topbar', theme)
// }
