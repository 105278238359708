import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { useEffect } from 'react'
import { Table } from '../../../components/Table'
import * as actions from '../actions'
import * as adminActions from '../../AdminPage/actions'
import { CreatePoolModal } from '../components/modals'
import CustomPoolActions from '../components/CustomPoolActions'
import Cookies from 'js-cookie'

const CustomPoolsPage = ({ ...props }) => {
  const { t } = useTranslation('common')
  const [pools, setPools] = useState([])
  const setPoolsList = props.setPoolsList
  const setCoinsList = props.setCoinsList
  const [currentPage, setCurrentPage] = useState(1)
  const [showCount, setShowCount] = useState(
    Cookies.get(window.location.pathname + 'dataTable' + 'showCount')
      ? parseInt(Cookies.get(window.location.pathname + 'dataTable' + 'showCount'))
      : 10,
  )

  useEffect(() => {
    setPoolsList({ page: currentPage, size: showCount, orderBy: 'id' })
  }, [setPoolsList, currentPage, showCount])

  useEffect(() => {
    setCoinsList({ page: 1, size: 1000, orderBy: 'nameSymbol' })
  }, [setCoinsList])

  useEffect(() => {
    const poolsArray = props.poolsList.map(pool => {
      return {
        ...pool,
        action: <CustomPoolActions pool={pool} />,
      }
    })
    setPools(poolsArray)
  }, [props.poolsList])

  const poolsData = {
    columns: [
      {
        label: t('pages.customPools.id'),
        field: 'id',
        sort: true,
        sortType: 'int',
      },
      {
        label: t('pages.customPools.title'),
        field: 'title',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.customPools.SSLPort'),
        field: 'sslPort',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.customPools.stratumPort'),
        field: 'stratumPort',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.customPools.altStratumPort'),
        field: 'altStratumPort',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.customPools.coins'),
        field: 'coins',
        sort: false,
        maxWidth: 220,
      },
      {
        label: t('pages.customPools.soloPort'),
        field: 'soloPort',
        sort: true,
        sortType: 'string',
      },
      {
        label: t('pages.customPools.action'),
        field: 'action',
        width: 10,
      },
    ],
    rows: pools,
  }

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3 className={'pageTitle'}>{t('pages.customPools.customPools')}</h3>
          </div>
        </div>
        <CreatePoolModal />
        <div className={'mining_preset__body'}>
          <Table
            className={'table table-striped'}
            data={poolsData}
            name={'pools'}
            responsive
            noBottomColumns
            stickyHeader
            total={props.poolsTotal}
            setShowCount={setShowCount}
            showCount={showCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  poolsList: store.customPools.poolsList,
  poolsTotal: store.customPools.poolsTotal,
})

const mapDispatchToProps = {
  setPoolsList: actions.setPoolsList,
  setCoinsList: adminActions.setCoinsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPoolsPage)
