import React from 'react'

// Redux
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// availity-reactstrap-validation

// import images
import logoSm from '../../../assets/images/logo-sm-light.png'
import maintenance from '../../../assets/images/maintenance.png'
import { useTranslation } from 'react-i18next'

const Maintenance = () => {
  const { t } = useTranslation('common')
  return (
    <React.Fragment>
      <div className='home-btn d-none d-sm-block'>
        <Link to={`${process.env.PUBLIC_URL}/`} className='text-dark'>
          <i className='fas fa-home h2'></i>
        </Link>
      </div>
      <div className='home-wrapper mt-5 text-center'>
        <div className='mb-4'>
          <img src={logoSm} alt='logo' height='22' />
        </div>

        <div className='maintenance-img'>
          <img src={maintenance} alt='' className='img-fluid mx-auto d-block' />
        </div>
        <h3 className='mt-4'>{t('pages.login.siteMaintenance')}</h3>
        <p>{t('pages.login.PleaseInSometime')}</p>
      </div>
    </React.Fragment>
  )
}

export default connect(null, null)(Maintenance)
